import {JourneyConfig} from '@app/shared/models/journey-config.model';
import {BreadcrumbJourneyPart} from '@app/shared/models/breadcrumb.model';
import {JourneyStepEnum} from '@app/shared/enums/journey-step.enum';

export const journeyConfigFeatureKey = 'journey-config';

export interface JourneyConfigState {
  breadcrumbJourneyParts: BreadcrumbJourneyPart[];
  currentJourneyConfig: JourneyConfig;
  currentStep: JourneyStepEnum;
  flatBreadcrumbJourneySteps: Set<JourneyStepEnum>;
  disabledJourneySteps: Set<JourneyStepEnum>,
  journeyConfigs: JourneyConfig[];
  journeyInstanceId: number;
  lastLoaded: string;
  isSuccess:boolean;
}

export const initialState: JourneyConfigState = {
  breadcrumbJourneyParts: [],
  currentJourneyConfig: null,
  currentStep: null,
  flatBreadcrumbJourneySteps: new Set<JourneyStepEnum>(),
  disabledJourneySteps: new Set<JourneyStepEnum>(),
  journeyConfigs: null,
  journeyInstanceId: 0,
  lastLoaded: '',
  isSuccess:false,
};
