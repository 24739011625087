import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromProfileState from './profile.state';

export const selectProfileState = createFeatureSelector<fromProfileState.ProfileState>(fromProfileState.featureKey);

export const selectProfile = createSelector(selectProfileState, state => state.profile);

export const selectProfileLoading = createSelector(selectProfileState, state => state.loading);

export const selectUpdateProfileSuccess = createSelector(selectProfileState, state => state.updateSuccess);
