import {AppState} from '../root-state';
import {Injectable} from '@angular/core';
import * as GenderActions from './gender.actions';
import {selectGenders} from './gender.selectors';
import {Observable, EMPTY, of} from 'rxjs';
import {createEffect, ofType, Actions} from '@ngrx/effects';
import {withLatestFrom, switchMap, catchError, map} from 'rxjs/operators';
import {Action, Store} from '@ngrx/store';
import {GenderService} from '@app/services/gender.service';
import {Gender} from '@app/shared/models/gender.model';
import {ErrorActions} from '@app/state/error';

@Injectable()
export class GenderEffects {
  getGenders$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(GenderActions.getGenders),
      withLatestFrom(this.store.select(selectGenders), (action, genders) => {
        if (genders.length > 0) {
          return GenderActions.loadGendersSuccess({genders});
        } else {
          return GenderActions.loadGenders();
        }
      }),
    ),
  );

  loadGenders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GenderActions.loadGenders),
      switchMap(() =>
        this.genderService.getGenders().pipe(
          map((genders: Gender[]) => GenderActions.loadGendersSuccess({genders})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    );
  });

  constructor(private actions$: Actions, private store: Store<AppState>, private genderService: GenderService) {}
}
