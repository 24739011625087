import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromMessagesState from './message.state';
import { Message } from '@app/shared/models/message.model';

export const getMessagesState = createFeatureSelector<fromMessagesState.MessageState>(fromMessagesState.messageFeatureKey);

export const getAllMessages = createSelector(
  getMessagesState,
  state => state.messages
);

export const getMessage = createSelector(
  getMessagesState,
  state => state.message
);

export const getThread = createSelector(
  getMessagesState,
  state => state.thread
);

export const getThreadLoading = createSelector(getMessagesState, state => state.loading);

export const getFilteredMessages = createSelector(
  getMessagesState,
  getAllMessages,
  (state, messages) => {
    let messagesFiltered = messages;

    if (state.selectedMessageType) {
      messagesFiltered = messages.filter(message => message.messageType.messageTypeName === state.selectedMessageType);
    }

    if (state.filteredAccountOrEntityName) {
      messagesFiltered = messagesFiltered.filter(message => message.relatedObject.relatedObjectName === state.filteredAccountOrEntityName);
    }

    return messagesFiltered;
  }
);

export const getCurrentSelectedMessage = createSelector(
  getMessagesState,
  state => {
    return state.messages.filter(x => x.id === state.currentMessageId)[0];
  }
);

export const getCurrentMessage = createSelector(
  getMessagesState, state => <Message>state.message
);

export const getMessageTypes = createSelector(
  getMessagesState,
  state => state.messageTypes
);

export const createMessageSuccess = createSelector(getMessagesState, state => ({
  isSent: state.isSent,
  stateChanged: state.stateChanged,
}));


