import {Component, OnDestroy, OnInit} from '@angular/core';
import {ErrorState} from '@app/state/error/error.state';
import {select, Store} from '@ngrx/store';
import {takeUntil, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {selectError} from '@app/state/error/error.selectors';
import {ComponentPortal} from '@angular/cdk/portal';
import {ErrorListOverlayComponent} from '@app/error/error-list-overlay/error-list-overlay.component';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'abl-error',
  template: '',
})
export class ErrorComponent implements OnInit, OnDestroy {
  private overlayRef: OverlayRef | null = null;
  private destroy$ = new Subject();
  private hasErrors$ = this.errorStore.pipe(
    select(selectError),
    tap(errors => {
      if (errors.length === 1 && !this.overlayRef) {
        this.openErrorListOverlay();
      } else if (!errors.length && this.overlayRef) {
        this.overlayRef.dispose();
        this.overlayRef = null;
      }
    }),
    takeUntil(this.destroy$),
  );

  constructor(
    private errorStore: Store<ErrorState>,
    private breakpointObserver: BreakpointObserver,
    private overlay: Overlay,
    private translate : TranslateService
  ) {}

  ngOnInit() {
    this.hasErrors$.subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private openErrorListOverlay() {
    const isMobileScreenSize = this.breakpointObserver.isMatched([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
    ]);
    const globalPositionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .bottom(isMobileScreenSize ? '0px' : '20px');
    this.overlayRef = this.overlay.create({
      hasBackdrop: false,
      positionStrategy: globalPositionStrategy,
      height: '*',
      width: isMobileScreenSize ? '100%' : null,
      maxWidth: isMobileScreenSize ? '100%' : 500,
    });
    const ErrorListOverlayPortal = new ComponentPortal(ErrorListOverlayComponent);
    this.overlayRef.attach(ErrorListOverlayPortal);
  }
}
