import {Mandate} from '@app/shared/models/mandate.model';

export const featureKey = 'mandate';

export interface MandateState {
  mandates: Mandate[];
  newMandate: Mandate | null;
  selectedMandateType: string;
  loading: boolean;
  selectedMandateTypeId: number;
  updateSuccess:boolean;
}

export const initialState: MandateState = {
  mandates: [],
  newMandate: null,
  selectedMandateType: '',
  loading: false,
  selectedMandateTypeId: null,
  updateSuccess:false,
};
