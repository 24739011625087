import { createAction, props } from '@ngrx/store';

import { CreateMessageHttpResponse, CreateMessagePayload, Message, MessageType } from '@app/shared/models/message.model';
import { HttpErrorResponse } from '@angular/common/http';


export const filter = createAction(
  '[Messages] Filter Messages',
  props<{ selectedMessageType: string, filteredAccountOrEntityName: string }>()
);

export const updateMessageReadStatusById = createAction(
  '[Messages] Update Selected Message Read Status',
  props<{ messageId: number }>()
);

export const updateMessageReadStatusByIdSuccess = createAction(
  '[Messages] [Messages] Update Selected Message Read Status Success',
  props<{ message: Message }>()
);

export const updateMessageReadStatusByIdFail = createAction(
  '[Messages] Update Selected Message Read Status Fail',
  props<{ error: HttpErrorResponse }>()
);

export const setSelectedMessageId = createAction(
  '[Messages] Set Selected Message',
  props<{ messageId: number }>()
);

export const getMessageById = createAction(
  '[Messages] Get Message by Id',
  props<{ messageId: number }>()
);

export const getMessageByIdSuccess = createAction(
  '[Messages] Get Message by Id Success',
  props<{ message: Message }>()
);

export const loadMessages = createAction(
  '[Messages] Load Messages'
);

export const LoadMessagesSuccess = createAction(
  '[Messages] Load Messages Success',
  props<{ payload: Message[] }>()
);

export const LoadMessagesFail = createAction(
  '[Messages] Load Messages Fail',
  props<{ payload: any }>()
);

export const createNewMessage = createAction(
  '[Messages] Create Message',
  props<{ payload: CreateMessagePayload }>()
);

export const createMessageSuccess = createAction(
  '[Messages API] Create Message Success',
  props<{ payload: Message }>()
);

export const loadMessageTypes = createAction(
  '[Messages] Load Message Types'
);

export const LoadMessageTypesSuccess = createAction(
  '[Messages] Load Message Types Success',
  props<{ payload: MessageType[] }>()
);

export const LoadMessageTypesFail = createAction(
  '[Messages] Load Message Types Fail',
  props<{ payload: any }>()
);

export const getThreadById = createAction(
  '[Messages] Get Message Thread By Id',
  props<{ threadId: string }>()
);

export const getThreadByIdSuccess = createAction(
  '[Messages] Get Message Thread By Id Success',
  props<{ thread: any }>()
);
export const resetMessageSuccess = createAction('[Message] Reset Invite success Flag On State');

