import {Action, createReducer, on} from '@ngrx/store';
import {CurrenciesState, initialState} from './currencies.state';
import {fetchCurrencies, fetchCurrenciesFail, fetchCurrenciesSuccess} from './currencies.actions';

const currenciesReducer = createReducer(
  initialState,
  on(fetchCurrencies, state => ({...state, loading: true})),
  on(fetchCurrenciesFail, state => ({...state, loading: false})),
  on(fetchCurrenciesSuccess, (state, {payload}) => ({
    ...state,
    currencies: payload,
    loading: false,
  })),
);

export function reducer(state: CurrenciesState, action: Action) {
  return currenciesReducer(state, action);
}
