import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { Patch } from '@app/shared/models/patch.model';
import { SourceOfFunds, SourceOfFundsResponse, sourceOfFundsTransferTypesResponse } from '@app/shared/models/source-of-funds.model';

@Injectable({
  providedIn: 'root'
})

export class SourceOfFundsService {
  headers: HttpHeaders;

  constructor(private http: HttpClient,public router : Router) {
  }
 
  fetchSourceOfFunds(individualId: number): Observable<SourceOfFundsResponse> {
    return this.http.get<SourceOfFundsResponse>(
      `${environment.apiEndpoint}/individuals/${individualId}/sourceoffunds`,
    );
  }
  createSourceOfFunds(individualId: number, request: SourceOfFunds): Observable<SourceOfFundsResponse> {
    return this.http.post<SourceOfFundsResponse>(`${environment.apiEndpoint}/individuals/${individualId}/sourceoffunds`,request
    );
  }

  patchSourceOfFunds(individualId: number, patch: Patch[]): Observable<SourceOfFundsResponse> {
    return this.http.patch<SourceOfFundsResponse>(`${environment.apiEndpoint}/individuals/${individualId}/sourceoffunds`, patch);
  }
  fetchsourceOfFundsTransferTypes():Observable<sourceOfFundsTransferTypesResponse> {
    return this.http.get<sourceOfFundsTransferTypesResponse>(`${environment.apiEndpoint}/individuals/sourceoffunds/references/transfertypes`);
  }

}
