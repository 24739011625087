import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {AttitudeToRiskEffects} from './attitude-to-risk.effects';
import * as fromAttitudeToRisk from './attitude-to-risk.reducer';
import * as fromAttitudeToRiskState from './attitude-to-risk.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromAttitudeToRiskState.attitudeToRiskFeatureKey, fromAttitudeToRisk.reducer),
    EffectsModule.forFeature([AttitudeToRiskEffects]),
  ],
  declarations: [],
})
export class AttitudeToRiskStateModule {}
