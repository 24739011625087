<div>
  <router-outlet></router-outlet>
  <!-- Developer navigation card, NOT TO BE SHOWN IN PRODUCTION!-->
  <abl-development-navigation-card
    *ngIf="!isProduction"
    data-cy="openCardButton"
  >
  </abl-development-navigation-card>
  <abl-error></abl-error>
  <abl-loader></abl-loader>
</div>
