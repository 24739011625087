import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromOnboardingState from './onboarding.state';

const selectOnboardingState = createFeatureSelector<fromOnboardingState.OnboardingState>(fromOnboardingState.featureKey);

export const selectDeepLink = createSelector(selectOnboardingState, state => state.deepLink);

export const selectIsNextDisabled = createSelector(selectOnboardingState, state => state.isNextDisabled);

export const selectIsPreviousDisabled = createSelector(selectOnboardingState, state => state.isPreviousDisabled);

export const selectViewWrapperData = createSelector(selectOnboardingState, state => state.viewWrapperData);

export const selectOnboardingMandateId = createSelector(selectOnboardingState, state => state.onboarding.onboardingMandateId);

export const selectUpdateOnboardingStateSuccess = createSelector(selectOnboardingState, state => state.isendOnboardingStateSuccess);
