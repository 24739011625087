import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {MandateEffects} from './mandate.effects';
import * as fromMandate from './mandate.reducer';
import * as fromMandateState from './mandate.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromMandateState.featureKey, fromMandate.reducer),
    EffectsModule.forFeature([MandateEffects]),
  ],
  declarations: [],
})
export class MandateStateModule {
}
