import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromIndividualChildState from './individual-child.state';

const selectIndividualChildState = createFeatureSelector<fromIndividualChildState.IndividualChildState>(
  fromIndividualChildState.individualChildFeatureKey,
);

export const selectChildForCurrentMandate = createSelector(
  selectIndividualChildState,
  state => state.childForCurrentMandate,
);

export const selectIndividualChildLoading = createSelector(selectIndividualChildState, state => state.loading);
