import {Injectable} from '@angular/core';
import {Action} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable, of} from 'rxjs';
import {catchError, exhaustMap, map, switchMap} from 'rxjs/operators';
import {ErrorActions} from '@app/state/error';
import {
  fetchSecurityQuestionAnswers,
  fetchSecurityQuestionAnswersFailure,
  fetchSecurityQuestionAnswersSuccess,
  fetchSecurityQuestions,
  fetchSecurityQuestionsFailure,
  fetchSecurityQuestionsSuccess,
  updateSecurityQuestionAnswers,
  updateSecurityQuestionAnswersFailure,
  updateSecurityQuestionAnswersSuccess,
  updatePassword,
  updatePasswordResponse,
  emailVerificationCode,
  fetchEmailVerificationCodeSuccess,
  fetchEmailVerificationCodeFailure,
  resendEmailVerificationCode,
  resendEmailVerificationCodeSuccess
} from '@app/state/user/user.actions';
import {UserService} from '@app/services/user/user.service';

@Injectable()
export class UserEffects {
  fetchSecurityQuestions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchSecurityQuestions),
      exhaustMap(() =>
        this.userService.fetchSecurityQuestions().pipe(
          map(response => fetchSecurityQuestionsSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}), fetchSecurityQuestionsFailure({error}))),
        ),
      ),
    ),
  );

  fetchSecurityQuestionAnswers$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchSecurityQuestionAnswers),
      exhaustMap(() =>
        this.userService.fetchSecurityQuestionAnswers().pipe(
          map(response => fetchSecurityQuestionAnswersSuccess({response})),
          catchError(error =>
            of(ErrorActions.newError({backEndError: error}), fetchSecurityQuestionAnswersFailure({error})),
          ),
        ),
      ),
    ),
  );

  updateSecurityQuestionAnswers$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSecurityQuestionAnswers),
      exhaustMap(({request}) =>
        this.userService.updateSecurityQuestionAnswers(request).pipe(
          map(response => updateSecurityQuestionAnswersSuccess({response})),
          catchError(error =>
            of(ErrorActions.newError({backEndError: error}), updateSecurityQuestionAnswersFailure({error})),
          ),
        ),
      ),
    ),
  );

  updatePassword$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePassword),
      switchMap(({request}) =>
        this.userService.updatePassword(request).pipe(
          map(response => updatePasswordResponse({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  emailVerificationCode$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(emailVerificationCode),
      switchMap(({request}) =>
        this.userService.EmailCodeVerification(request).pipe(
          map(response => fetchEmailVerificationCodeSuccess({response})),
          catchError(error =>
            of(ErrorActions.newError({backEndError: error}), fetchEmailVerificationCodeFailure({error})),
          ),
        ),
      ),
    ),
  );
  resendEmailVerificationCode$ : Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(resendEmailVerificationCode),
      switchMap(() =>
        this.userService.resendEmailVerificationCode().pipe(
          map(response => resendEmailVerificationCodeSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private userService: UserService) {}
}
