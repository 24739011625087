import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {EffectsModule} from '@ngrx/effects';
import {AuthRoutingModule} from './auth-routing.module';
import {AuthEffects} from '@app/state/auth/auth.effects';
import {CallbackComponent} from './components/callback/callback.component';
import {OAuthModule} from 'angular-oauth2-oidc';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {LaunchOptionsComponent} from './components/launch/launch-options.component';
import {MobileLaunchComponent} from './components/mobile-launch/mobile-launch.component';
import {MobileLoggedOutComponent} from './components/mobile-logged-out/mobile-logged-out.component';
import {SharedModule} from '@app/shared/controls/shared.module';
import {AuthStateModule} from '@app/state/auth';
import {environment} from 'environments/environment';
import {MobileRegisterComponent} from './components/mobile-register/mobile-register.component';
import {MaterialModule} from '@app/shared/material';
import {FlexLayoutModule} from '@angular/flex-layout';
import {CustomLoadingSpinnerModule} from '@app/shared/controls/custom-loading-spinner/custom-loading-spinner.module';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MobileInviteComponent } from './components/mobile-invite/mobile-invite.component';
import { WebInviteComponent } from './components/web-invite/web-invite.component';
import { WebRegisterComponent } from './components/web-register/web-register.component';

@NgModule({
  imports: [
    CommonModule,
    AuthRoutingModule,
    MatButtonModule,
    SharedModule,
    AuthStateModule,
    MatInputModule,
    ReactiveFormsModule,
    EffectsModule.forFeature([AuthEffects]),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: environment.allowedUrls,
        sendAccessToken: environment.sendAccessToken,
      },
    }),
    MaterialModule,
    FlexLayoutModule,
    CustomLoadingSpinnerModule,
  ],
  declarations: [
    CallbackComponent,
    NotFoundComponent,
    LaunchOptionsComponent,
    MobileLaunchComponent,
    MobileRegisterComponent,
    MobileLoggedOutComponent,
    MobileInviteComponent,
    WebRegisterComponent,
    WebInviteComponent,
  ],
})
export class AuthModule {}
