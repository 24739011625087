import {Action, createReducer, on} from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { fetchRetirementProvisionSuccess, saveRetirementProvisionDetails, saveRetirementProvisionSuccess, updateRetirementProvision, updateRetirementProvisionFailure, updateRetirementProvisionSuccess } from './retirement-provision.actions';
import { RetirementProvisionState, initialState } from './retirement-provision.state';
import { RetirementProvision } from '@app/shared/models/individuals/individual-retirementprovision.model';

export const adapter: EntityAdapter<RetirementProvision> = createEntityAdapter<RetirementProvision>();
const retirementProvisionReducer = createReducer(
  initialState,

  on(saveRetirementProvisionDetails, (state) => ({
    ...state,
    updateSuccess:false,
  })),

  on(saveRetirementProvisionSuccess, (state, {response}) => ({
    ...state,
    updateSuccess:true,
  })),

  on(fetchRetirementProvisionSuccess, (state, {response}) => ({
    ...state,
    updateSuccess: true,
    retirementProvision: response
  })),


on(updateRetirementProvision, state => ({
    ...state,
    updateSuccess: false,
  })),

  on(updateRetirementProvisionSuccess, (state, {response}) => ({
    ...state,
    updateSuccess: true,
    retirementProvision: response,
  })),

  on(updateRetirementProvisionFailure, state => ({
    ...state,
    updateSuccess: false,
  })),
);
export function reducer(state: RetirementProvisionState, action: Action) {
  return retirementProvisionReducer(state, action);
}
