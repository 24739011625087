import {Action, createReducer, on} from '@ngrx/store';
import { initialState, OverseasEmploymentState} from './overseas-employment.state';
import { fetchOverseasEmploymentDetailsSuccess, saveOverseasEmploymentDetailsSuccess, updateOverseasEmployment, updateOverseasEmploymentFailure, updateOverseasEmploymentSuccess } from './overseas-employment.actions';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OverseasEmployment } from '@app/shared/models/overseas-employment.model';

export const adapter: EntityAdapter<OverseasEmployment> = createEntityAdapter<OverseasEmployment>();
const overseasEmploymentReducer = createReducer(
  initialState,

  on(saveOverseasEmploymentDetailsSuccess, (state, {response}) => ({
    ...state,
    updateSuccess:true,
    overseasEmployment: response
  })),

  on(fetchOverseasEmploymentDetailsSuccess, (state, {response}) => ({
    ...state,
    overseasEmployment: response
  })),

  on(updateOverseasEmployment, state => ({
    ...state,
    updateSuccess: false,
  })),

  on(updateOverseasEmploymentSuccess, (state, {response}) => ({
    ...state,
    updateSuccess: true,
    overseasEmployment: response,
  })),

  on(updateOverseasEmploymentFailure, state => ({
    ...state,
    updateSuccess: false,
  })),
);

export function reducer(state: OverseasEmploymentState, action: Action) {
  return overseasEmploymentReducer(state, action);
}
