import * as ProductActions from './product.actions';
import * as ProductState from './product.state';
import * as ProductSelectors from './product.selectors';

export * from './product-state.module';

export {
  ProductState,
  ProductActions,
  ProductSelectors,
};
