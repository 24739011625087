import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Configuration} from '@app/shared/models/configuration.model';
import {HttpClient} from '@angular/common/http';
import {ConfigurationGroupEnum} from '@app/shared/enums/configuration.enum';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  constructor(private http: HttpClient) {
  }

  fetchConfigurations(): Observable<Configuration[]> {
    return this.http.get<Configuration[]>(`${environment.apiEndpoint}/configurations`);
  }

  fetchConfigurationByGroup(group: ConfigurationGroupEnum): Observable<Configuration[]> {
    return this.http.get<Configuration[]>(`${environment.apiEndpoint}/configurations?group=${group}`);
  }
}
