import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { IndividualEffects } from './individual.effects';
import * as fromIndividual from './individual.reducer';
import * as fromIndividualState from './individual.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromIndividualState.featureKey, fromIndividual.reducer),
    EffectsModule.forFeature([IndividualEffects])
  ],
  declarations: []
})
export class IndividualStateModule {}
