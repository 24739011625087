import { ErrorModel } from '@app/shared/models/error.model';
import { RelationshipType } from '@app/shared/models/relationship-type.model';
import { Relationship } from '@app/shared/models/relationship.model';

export const featureKey = 'relationship';

export interface RelationshipState {
  relationshipTypes: RelationshipType[];
  relationshipTypesLoading: boolean;
  relationship: Relationship;
  error: ErrorModel;
}

export const initialState: RelationshipState = {
  relationshipTypes: [],
  relationshipTypesLoading: true,
  relationship: null,
  error: null
};
