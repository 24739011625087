import { Injectable } from '@angular/core';
import { AppState } from '../root-state';
import { Observable, of } from 'rxjs';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { switchMap, catchError, map, withLatestFrom, exhaustMap } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { ErrorActions } from '../error';
import { selectProfile } from '../profile/profile.selectors';
import { fetchSourceOfFundsDetails, fetchSourceOfFundsSuccess, fetchsourceOfFundsTransferTypes, fetchsourceOfFundsTransferTypesFail, fetchsourceOfFundsTransferTypesSuccess, saveSourceOfFundsDetails, saveSourceOfFundsSuccess, updateSourceOfFunds, updateSourceOfFundsFailure, updateSourceOfFundsSuccess } from './source-of-funds.actions';
import { SourceOfFundsService } from '@app/services/source-of-funds-service/source-of-funds.service';


@Injectable()
export class SourceOfFundsEffects {

    saveSourceOfFunds$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(saveSourceOfFundsDetails),
      withLatestFrom(this.store.select(selectProfile)),
      switchMap(([{request}, profile]) => {
        return this.sourceOfFundsService.createSourceOfFunds(Number(profile.individualId), request).pipe(
          map(response => saveSourceOfFundsSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        );
      }),
    ),
  );
  fetchSourceOfFundsDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchSourceOfFundsDetails),
      withLatestFrom(this.store.select(selectProfile)),
      switchMap(([, profile]) =>
        this.sourceOfFundsService.fetchSourceOfFunds(profile.individualId).pipe(
          map(response => fetchSourceOfFundsSuccess({ response })),
          catchError(error => of(ErrorActions.newError({ backEndError: error }))),
        ),
      ),
    ),
  );
  updateSourceOfFunds$: Observable<Action> = createEffect(() =>
  this.actions$.pipe(
    ofType(updateSourceOfFunds),
    withLatestFrom(this.store.select(selectProfile)),
    exhaustMap(([{request}, profile]) =>
      this.sourceOfFundsService.patchSourceOfFunds(Number(profile.individualId), request).pipe(
        map(response => updateSourceOfFundsSuccess({response})),
        catchError(error => of(ErrorActions.newError({backEndError: error}), updateSourceOfFundsFailure({error}))),
      ),
    ),
  ),
);

fetchsourceOfFundsTransferTypes$:Observable<Action> = createEffect(() =>
this.actions$.pipe(
  ofType(fetchsourceOfFundsTransferTypes),
  exhaustMap(() =>
    this.sourceOfFundsService.fetchsourceOfFundsTransferTypes().pipe(
      map(response => fetchsourceOfFundsTransferTypesSuccess({response})),
      catchError(error => of(ErrorActions.newError({backEndError: error}), fetchsourceOfFundsTransferTypesFail({error}))),
    ),
  ),
),
);
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private sourceOfFundsService: SourceOfFundsService,
  ) { }
}

