import { on, createReducer, Action } from '@ngrx/store';
import { initialState, DocumentState } from './document.state';
import * as DocumentActions from './document.actions';

const documentReducer = createReducer(
  initialState,

  /** Load document. */
  on( DocumentActions.loadDocuments, state => ({
    ...state,
    isLoading: true,
  })),

  on( DocumentActions.loadDocumentsSuccess, (state, {response}) => ({
    ...state,
    documents: response,
    isLoading: false,
  })),

  /** Load document types. */
  on( DocumentActions.loadDocumentTypesSuccess, ( state, {response}) => ({
    ...state,
    documentTypes: response
  })),

  /** Upload document. */
  on( DocumentActions.uploadDocument, state => ({
    ...state,
    isUploading: true
  })),

  on( DocumentActions.uploadDocumentSuccess, (state, {response}) => ({
    ...state,
    documents: state.documents.concat(response),
    isUploading: false,
    uploadProgress: null,
  })),

  /** Set upload progress. */
  on( DocumentActions.setUploadProgress, (state, {progress}) => ({
    ...state,
    uploadProgress: progress
  })),

  /** Reset loading. */
  on( DocumentActions.setLoadingFalse, state => ({
    ...state,
    isUploading: false,
    isLoading: false,
  })),
);

export function reducer(state: DocumentState, action: Action) {
  return documentReducer(state, action);
}
