import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import { UserService } from './services/user/user.service';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {

  constructor(public translate: TranslateService, public userService : UserService) {
    this.userService.languageData.subscribe(data=>{
      if(data == undefined){
        this.translate.currentLang = 'en';
      }
      else {
        this.translate.currentLang = data;
      }
    });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const lang = this.translate.currentLang;
     request = request.clone({
    setHeaders: {
        'language': lang,
    }
  })
     return next.handle(request);
  }
}
