import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: '[ablIntegerOnly]',
})
export class IntegerOnlyDirective {
  /** Stop event on either decimal or letter e and + and -  key press. To be used with input type number. */
  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    var symbolPressed;
    symbolPressed = event.key;

    var pattern = new RegExp("^[0-9]*$");
    var valid = pattern.test(symbolPressed);

    if(!valid){
      if(!(symbolPressed === "Enter" || 
        symbolPressed === "Backspace" || 
        symbolPressed === "Tab" || 
        symbolPressed === "Delete" ||
        symbolPressed === "ArrowLeft" ||
        symbolPressed === "ArrowRight"
      )){
        event.preventDefault();
      }
    } 
  }
}
