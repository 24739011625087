import {Action, createReducer, on} from '@ngrx/store';
import {initialState, RelationshipState} from './relationship.state';
import * as RelationshipActions from './relationship.actions';

const entityReducer = createReducer(
  initialState,

  on(RelationshipActions.loadRelationshipTypesByEntityType, state => ({...state, relationshipTypesLoading: true})),

  on(RelationshipActions.loadRelationshipTypes, state => ({...state, relationshipTypesLoading: true})),

  on(RelationshipActions.loadRelationshipTypesFail, state => ({...state, relationshipTypesLoading: false})),

  on(RelationshipActions.loadRelationshipTypesSuccess, (state, {response}) => ({
    ...state,
    relationshipTypes: response,
    relationshipTypesLoading: false,
  })),

  on(RelationshipActions.createRelationship, state => ({...state, relationshipTypesLoading: true})),

  on(RelationshipActions.createRelationshipSuccess, (state, {response}) => ({
    ...state,
    relationshipTypesLoading: false,
    relationship: response,
  })),

  on(RelationshipActions.createRelationshipFail, (state, {error}) => ({...state, relationshipTypesLoading: false, error: error})),
);

export function reducer(state: RelationshipState, action: Action) {
  return entityReducer(state, action);
}
