import {createAction, props} from '@ngrx/store';
import {JourneyHistoryStep} from '@app/shared/models/journey-history-step';

/** Fetch history step. */
export const fetchCurrentJourneyHistoryStep = createAction('[JourneyHistory] Fetch Current JourneyHistoryStep', props<{mandateId: number}>());

export const fetchCurrentJourneyHistoryStepSuccess = createAction(
  '[JourneyHistory] Fetch Current JourneyHistoryStep Success',
  props<{response: JourneyHistoryStep}>(),
);

/** Create history step. */
export const createJourneyHistoryStep = createAction('[JourneyHistory] Create JourneyHistoryStep', props<{request: string}>());

export const createJourneyHistoryStepSuccess = createAction(
  '[JourneyHistory API] Create JourneyHistoryStep Success',
  props<{response: JourneyHistoryStep}>(),
);

/** Clear history. */
export const clearJourneyHistory = createAction('[JourneyHistory ] Clear Journey History');
