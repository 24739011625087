import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {LoqateAddressFindRequest, V2Address} from '@app/shared/models/address.model';
import {
  LoqateAddressFindItem,
  LoqateAddressRetrieveRequest,
  LoqateFailError,
  RawLoqateResponse,
} from '@app/shared/models/loqate.model';
import {mapRawLoqateAddressFindResponse, mapRawLoqateAddressRetrieveResponse} from '@app/services/loqate/loqate-utils';

@Injectable({
  providedIn: 'root',
})
export class LoqateAddressService {
  private loqateAddressKey: string;

  constructor(private http: HttpClient) {
    this.loqateAddressKey = environment.loqateAddressValidationKey;
  }

  /** Method uses the Loqate Find API endpoint. */
  findAddress(req: LoqateAddressFindRequest): Observable<LoqateAddressFindItem[] | LoqateFailError> {
    const params = new HttpParams()
      .set('Key', this.loqateAddressKey)
      .set('Text', req.Text)
      .set('Container', req.Container)
      .set('Method', req.Method)
      .set('Origin', req.Origin)
      .set('Filter', req.Filter)
      .set('Language', req.Language)
      .set('Countries', req.Countries)
      .set('Limit', req.Limit);
    return this.http
      .get<RawLoqateResponse>(`${environment.loqateServiceAddressValidationUrl}`, {params})
      .pipe(map(rawResponse => mapRawLoqateAddressFindResponse(rawResponse)));
  }

  /**
   * Method uses the Loqate Retrieve API endpoint and returns Obs<V2Address>.
   * I had to make two retrieve functions seeing as we are mapping to V2Address here. */
  v2RetrieveAddress(req: LoqateAddressRetrieveRequest): Observable<V2Address | LoqateFailError> {
    const params = new HttpParams().set('Key', this.loqateAddressKey).set('Id', req.id);
    return this.http
      .get<RawLoqateResponse>(`${environment.loqateServiceRetrieveAddressUrl}`, {
        params,
      })
      .pipe(map(rawResponse => mapRawLoqateAddressRetrieveResponse(rawResponse))); // We are only interested in the first address that comes back.
  }
}

/** Create a formatted address query for Loqate Find request. */
export function createFindLoqateAddressRequest(
  text?: string | null,
  container?: string | null,
): LoqateAddressFindRequest {
  return {
    Text: text ?? '',
    Container: container,
    Method: 'Match',
    Origin: '',
    Filter: '',
    Language: 'EN',
    Countries: '',
    Limit: '100',
  };
}
