import { Injectable } from '@angular/core';
import { AppState } from '../root-state';
import { Observable, of } from 'rxjs';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { switchMap, catchError, map, withLatestFrom, exhaustMap } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { ErrorActions } from '../error';
import { deleteLiabilities, deleteLiabilitiesSuccess, fetchLiabilitiesDetails, fetchLiabilitiesSuccess, saveLiabilitiesDetails, saveLiabilitiesSuccess } from './liabilities.actions';
import { selectProfile } from '../profile/profile.selectors';
import { LiabilitiesService } from '@app/services/liabilities-service/liabilities.service';


@Injectable()
export class LiabilitiesEffects {

  saveLiabilities$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(saveLiabilitiesDetails),
      withLatestFrom(this.store.select(selectProfile)),
      switchMap(([{ request }, profile]) =>
        this.liabilitiesService.createLiabilities(Number(profile.individualId), request).pipe(
          map(response => saveLiabilitiesSuccess({ response })),
          catchError(error => of(ErrorActions.newError({ backEndError: error }))),
        ),
      ),
    ));
  fetchLiabilitiesDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchLiabilitiesDetails),
      withLatestFrom(this.store.select(selectProfile)),
      switchMap(([, profile]) =>
        this.liabilitiesService.fetchLiabilities(profile.individualId).pipe(
          map(response => fetchLiabilitiesSuccess({ response })),
          catchError(error => of(ErrorActions.newError({ backEndError: error }))),
        ),
      ),
    ),
  );
  deleteLiabilities$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteLiabilities),
      withLatestFrom(this.store.select(selectProfile)),
      exhaustMap(([, profile]) =>
        this.liabilitiesService.deleteLiabilities(profile.individualId).pipe(
          map(response => deleteLiabilitiesSuccess({ response })),
          catchError(error => of(ErrorActions.newError({ backEndError: error }))),
        ),
      ),
    ),
  );
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private liabilitiesService: LiabilitiesService,
  ) { }
}

