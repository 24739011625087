<ul class="circle-container">
  <li>
    <div [@scale8]="state$ | async"></div>
  </li>
  <li>
    <div [@scale7]="state$ | async"></div>
  </li>
  <li>
    <div [@scale6]="state$ | async"></div>
  </li>
  <li>
    <div [@scale5]="state$ | async"></div>
  </li>
  <li>
    <div [@scale4]="state$ | async"></div>
  </li>
  <li>
    <div [@scale3]="state$ | async"></div>
  </li>
  <li>
    <div [@scale2]="state$ | async"></div>
  </li>
  <li>
    <div [@scale1]="state$ | async"></div>
  </li>
</ul>
