import { createAction, props } from '@ngrx/store';
import { EidvLatest } from '@app/shared/models/eidv.model';

// Eidv
export const loadEidv = createAction(
  '[Eidv] Load Eidv Latest'
);

export const loadEidvLatestSuccess = createAction(
  '[Eidv] Load Eidv Latest Success',
  props<{ payload: EidvLatest }>()
);

export const loadEidvLatestFail = createAction(
  '[Eidv] Load Eidv Latest Fail',
  props<{ payload: any }>()
);



