import {PRIMARY_OUTLET, Router, UrlSegment} from '@angular/router';
import {CurrencyPipe, PercentPipe} from '@angular/common';
import {AddressResponse} from '@app/shared/models/address.model';
import * as moment from 'moment';
import {UntypedFormGroup} from '@angular/forms';
import {Patch} from '@app/shared/models/patch.model';
import {PortfolioActivityEventStatusId} from '@app/shared/enums/portfolio.enum';
import {saveAs} from 'file-saver';
import {Platform} from '@angular/cdk/platform';

export default class Utils {
  /** Returns the URL segments from provided Router. */
  static getUrlSegmentsFromRouter(router: Router): UrlSegment[] {
    return router.parseUrl(router.url).root.children[PRIMARY_OUTLET].segments;
  }

  /** Returns the URL segments from provided raw URL. */
  static getUrlSegmentsFromUrl(router: Router, url: string): UrlSegment[] {
    return router.parseUrl(url).root.children[PRIMARY_OUTLET].segments;
  }

  /** Currency pipe. */
  static convertToCurrency(locale: string, value: number, currencyCode?: string, digitsInfo?: string): string {
    return new CurrencyPipe(locale).transform(value, currencyCode ?? 'GBP', 'symbol', digitsInfo ?? '1.0-0');
  }

  /** Percent pipe. */
  static convertToPercent(locale: string, value: number, digitsInfo?: string): string {
    return new PercentPipe(locale).transform(value, digitsInfo ?? '1.0-2');
  }

  /** Returns a Patch[] type array. */
  static createPatchListFromFormGroup(formGroup: UntypedFormGroup, checkControlDirty: boolean = true): Patch[] {
    return Object.entries<string>(formGroup.value)
      .filter(([key]) => (checkControlDirty ? formGroup.get(key).dirty : true))
      .map(([key, value]) => {
        return {op: 'replace', path: `/${key}`, value: typeof value === 'string' ? value.trim() : value};
      });
  }

  static createPatchGroupListFromFormGroup(formGroup: UntypedFormGroup, checkControlDirty: boolean = true): Patch[] {
    return Object.entries<string>(formGroup.value)
      .map(([key, value]) => {
        return {op: 'replace', path: `/mandateTypeId`, value: typeof value === 'string' ? value.trim() : value};
      });
  }

  static createPatchList(obj: {[index: string]: string | number | boolean}): Patch[] {
    return Object.entries<string | number | boolean>(obj).map(([key, value]) => {
      return {op: 'replace', path: `/${key}`, value: typeof value === 'string' ? value.trim() : value};
    });
  }

  /**
   * Used inside array sort function when sorting addresses by their date from,
   * specifically for AddressResponse type.
   */
  static sortAddressesByDateFromCompareFunc(a: AddressResponse, b: AddressResponse): number {
    return !a.dateFrom || !b.dateFrom ? 0 : moment(a.dateFrom).unix() - moment(b.dateFrom).unix();
  }

  /** Returns style class names .complete, .pending or .incomplete based on PortfolioActivityEventStatusId. */
  static getStatusStyleClass(id: PortfolioActivityEventStatusId): string {
    switch (true) {
      case id === PortfolioActivityEventStatusId.Accepted || id === PortfolioActivityEventStatusId.Complete:
        return 'complete';
      case id === PortfolioActivityEventStatusId.New ||
        id === PortfolioActivityEventStatusId.Processing ||
        id === PortfolioActivityEventStatusId.PendingApproval:
        return 'pending';
      case id === PortfolioActivityEventStatusId.Rejected ||
        id === PortfolioActivityEventStatusId.Locked ||
        id === PortfolioActivityEventStatusId.Void:
        return 'incomplete';
    }
  }

  /** Current tax year, eg. 2020/2021 */
  static get currentTaxYear(): string {
    const taxYearBeginning = moment().set('month', 3).set('date', 6).toDate();
    return moment().isBefore(taxYearBeginning)
      ? `${moment().subtract(1, 'y').year()}/${moment().year()}`
      : `${moment().year()}/${moment().add(1, 'y').year()}`;
  }

  /** Handles whether to download the file for Android by checking the platform type. */
  static handleDownloadFile(
    platform: Platform,
    id: string,
    data: ArrayBuffer,
    type = 'application/octet-stream',
    fileName = 'file-to-download.txt',
  ) {
    if (platform.ANDROID) {
      this.downloadFileAndroid(id, data, type, fileName);
    } else if (platform.IOS) {
      this.downloadFileIOS(data, type, fileName);
    } else {
      this.downloadFile(data, type, fileName);
    }
  }

  /** Download file. */
  static downloadFile(data: ArrayBuffer, type: string, fileName: string) {
    if (!data) {
      return;
    }
    const blobData = new Blob([new Uint8Array(data)], {type: `${type};charset=utf-8`});
    saveAs(blobData, fileName);
  }

  /** Download file for Android. */
  static downloadFileAndroid(id: string, data: ArrayBuffer, type: string, fileName: string) {
    if (!data) {
      return;
    }
    const url = `${window.location.origin}/file-download/${fileName}/${id}`; // We want to alter the URL passed to window.open.
    window.open(url);
    window.URL.revokeObjectURL(url);
  }

  /** Download file for IOS without type in its options. */
  static downloadFileIOS(data: ArrayBuffer, type: string, fileName: string) {
    if (!data) {
      return;
    }
    const blobData = new Blob([new Uint8Array(data)]);
    saveAs(blobData, fileName);
  }

  /** Open file new browser tab. */
  static openFile(data: ArrayBuffer, type: string, fileName: string) {
    if (!data) {
      return;
    }
    const url = window.URL.createObjectURL(new File([data], fileName, {type}));
    window.open(url);
    window.URL.revokeObjectURL(url);
  }

  /** hide characters with asterix except first two and last  */
  static hideDataWithAsterisk(d: string): string {
    return d[0] + d[1] + '*'.repeat(d.length - 3) + d.slice(-1);
  }

  /** Capitalize first letter in string */
  static titleCaseString(title: string): string {
    return title[0].toUpperCase() + title.slice(1);
  }
}
