import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { DeletedItem } from '@app/shared/models/deleted-item.model';
import { FinancialDependents } from '@app/shared/models/individuals/individual-financial-dependents.model';

export const saveFinancialDependentsDetails = createAction(
  '[FinancialDependents/API] Save financial-dependents Details',
  props<{request: FinancialDependents}>());

export const saveFinancialDependentsSuccess = createAction(
  '[FinancialDependents/API] Save financial-dependents Details Success',
  props<{response : any }>()
);
export const saveFinancialDependentsFail = createAction(
  '[FinancialDependents/API] Save financial-dependents Details Fail',
  props<{ error: any }>()
);
export const fetchFinancialDependentsDetails = createAction('[FinancialDependents Details] Fetch financial-dependents Details');

export const fetchFinancialDependentsSuccess = createAction(
  '[FinancialDependents Details] Fetch financial-dependents Success',
  props<{response: any}>(),
);

export const fetchFinancialDependentsFail = createAction(
  '[FinancialDependents Details] Fetch financial-dependents Fail',
  props<{error: HttpErrorResponse}>(),
);

export const deleteFinancialDependents = createAction('[FinancialDependents Details] Update financial-dependents Details', props<{id: number}>());

export const deleteFinancialDependentsSuccess = createAction(
  '[FinancialDependents Details] Update FinancialDependents Details Success',
  props<{response: DeletedItem}>(),
);

export const deleteFinancialDependentsFailure = createAction(
  '[FinancialDependents Details] Update financial-dependents Details Failure',
  props<{error: HttpErrorResponse}>(),
);

export const resetDeleteFinancialDependentsSuccess = createAction(
  '[Delete FinancialDependents] Reset Delete financial-dependents Success',
);

