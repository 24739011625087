import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AddressEffects } from './address.effects';
import * as fromAddress from './address.reducer';
import * as fromAddressesState from './address.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromAddressesState.featureKey, fromAddress.reducer),
    EffectsModule.forFeature([AddressEffects])
  ],
  declarations: []
})
export class AddressStateModule {}
