import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Organisation} from '@app/shared/models/organisation.model';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrganisationService {
  constructor(private http: HttpClient) {}

  fetchOrganisations(): Observable<Organisation[]> {
    return this.http.get<Organisation[]>(`${environment.apiEndpoint}/organisations`);
  }
}
