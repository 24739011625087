import {createReducer, on} from '@ngrx/store';
import {initialState} from '@app/state/isa-transfer/isa-transfer.state';
import {
  createIsaTransferSuccess,
  deleteIsaTransferSuccess,
  fetchIsaProvidersSuccess,
  fetchIsaTransfersSuccess,
  fetchIsaTransferTypesSuccess,
  fetchIsaTypesSuccess,
} from '@app/state/isa-transfer/isa-transfer.actions';
import {IsaTransfer} from '@app/shared/models/portfolio/isa.model';

export const reducer = createReducer(
  initialState,

  on(fetchIsaTransfersSuccess, (state, {response}) => ({
    ...state,
    isaTransfers: response,
    combinedIsaTransferValue: sumIsaTransferValues(response),
  })),

  on(createIsaTransferSuccess, (state, {response}) => {
    const isaTransfers = [...state.isaTransfers, response];
    return {
      ...state,
      isaTransfers,
      combinedIsaTransferValue: sumIsaTransferValues(isaTransfers),
    };
  }),

  on(deleteIsaTransferSuccess, (state, {id}) => {
    const isaTransfers = [...state.isaTransfers.filter(t => t.id !== id)];
    return {
      ...state,
      isaTransfers,
      combinedIsaTransferValue: sumIsaTransferValues(isaTransfers),
    };
  }),

  on(fetchIsaProvidersSuccess, (state, {response}) => ({
    ...state,
    isaProviders: response,
  })),

  on(fetchIsaTypesSuccess, (state, {response}) => ({
    ...state,
    isaTypes: response,
  })),

  on(fetchIsaTransferTypesSuccess, (state, {response}) => ({
    ...state,
    isaTransferTypes: response,
  })),
);

function sumIsaTransferValues(isaTransfers: IsaTransfer[]): number {
  return isaTransfers.length
    ? isaTransfers
        .map(t => t.transferValue)
        .reduce((prevTransferValue, currTransferValue) => prevTransferValue + currTransferValue)
    : 0;
}
