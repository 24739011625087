import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { EmploymentStatus } from '@app/shared/models/employment-status.model';

@Injectable({
  providedIn: 'root',
})
export class EmploymentStatusService {
  constructor(private http: HttpClient) {}

  getEmploymentStatuses(): Observable<EmploymentStatus[]> {
    return this.http.get<EmploymentStatus[]>(
      `${environment.apiEndpoint}/individuals/references/employmentStatuses`
    );
  }
}
