import { Action, createReducer, on } from '@ngrx/store';
import { initialState, NotificationState } from './notification.state';
import { NotificationActions } from '@app/state/notification/index';

const notificationReducer = createReducer(
  initialState,
  on(NotificationActions.loadNotificationsSuccess, (state, { response }) => ({
    ...state,
    notificationSummary: response
  }))
);

export function reducer(state: NotificationState, action: Action) {
  return notificationReducer(state, action);
}
