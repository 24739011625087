import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {catchError, exhaustMap, map, switchMap} from 'rxjs/operators';
import {
  createMessageSuccess,
  createNewMessage,
  getMessageById,
  getMessageByIdSuccess,
  getThreadById,
  getThreadByIdSuccess,
  loadMessages,
  LoadMessagesSuccess,
  loadMessageTypes,
  LoadMessageTypesSuccess,
  updateMessageReadStatusById,
  updateMessageReadStatusByIdSuccess,
} from './message.actions';
import {Message, MessageThread, MessageType} from '@app/shared/models/message.model';
import {MessageService} from '@app/services/message/message.service';
import {ErrorActions} from '@app/state/error';

@Injectable()
export class MessageEffects {
  loadCollection$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMessages),
      exhaustMap(() =>
        this.messageService.getMessages().pipe(
          map(
            (messages: Message[]) => LoadMessagesSuccess({payload: messages}),
            catchError(error => of(ErrorActions.newError({backEndError: error}))),
          ),
        ),
      ),
    ),
  );

  loadMessageTypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMessageTypes),
      exhaustMap(({}) =>
        this.messageService.getMessageTypes().pipe(
          map((messageTypes: any) => {
            return messageTypes.map(messageType => {
              return (messageType = {
                id: messageType.id,
                label: messageType.messageTypeName,
                value: messageType.messageTypeName,
              });
            });
          }),
          map((messageTypes: MessageType[]) => {
            return LoadMessageTypesSuccess({payload: messageTypes});
          }),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  loadMessageById$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(getMessageById),
      switchMap(action =>
        this.messageService.getMessage(action.messageId).pipe(
          map((messages: Message) => getMessageByIdSuccess({message: messages})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  loadMessageByThreadId$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(getThreadById),
      switchMap(action =>
        this.messageService.getThread(action.threadId).pipe(
          map((thread: MessageThread[]) => getThreadByIdSuccess({thread})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  updateMessageReadStatus$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMessageReadStatusById),
      switchMap(action => {
        return this.messageService.upDateMessageReadStatus(action.messageId).pipe(
          map((message: Message) => updateMessageReadStatusByIdSuccess({message: message})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        );
      }),
    ),
  );

  createNewMessage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(createNewMessage),
      switchMap(action => {
        return this.messageService.postMessage(action.payload).pipe(
          map((payload: Message) => createMessageSuccess({payload: payload})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        );
      }),
    ),
  );

  constructor(private actions$: Actions, private messageService: MessageService) {}
}
