import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { Liabilities, LiabilitiesResponse } from '@app/shared/models/liabilities.model';
import { DeletedItem } from '@app/shared/models/deleted-item.model';

@Injectable({
  providedIn: 'root'
})

export class LiabilitiesService {
  headers: HttpHeaders;

  constructor(private http: HttpClient,public router : Router) {
  }
 
  fetchLiabilities(individualId: number): Observable<LiabilitiesResponse> {
    return this.http.get<LiabilitiesResponse>(
      `${environment.apiEndpoint}/individuals/${individualId}/liabilities`,
    );
  }

  deleteLiabilities(individualId: number): Observable<DeletedItem> {
    return this.http.delete<DeletedItem>(
      `${environment.apiEndpoint}/individuals/${individualId}/liabilities`,
    );
  }
  createLiabilities(
    individualId: number,
    request: Liabilities,
  ): Observable<LiabilitiesResponse> {
    return this.http.post<LiabilitiesResponse>(
      `${environment.apiEndpoint}/individuals/${individualId}/liabilities`,
      request,
    );
  }

}
