import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoaderComponent} from './loader.component';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {CustomLoadingSpinnerModule} from '@app/shared/controls/custom-loading-spinner/custom-loading-spinner.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

@NgModule({
  declarations: [LoaderComponent],
  exports: [LoaderComponent],
  imports: [BrowserAnimationsModule, CommonModule, MatProgressSpinnerModule, CustomLoadingSpinnerModule],
})
export class LoaderModule {}
