import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromPhoneState from './phone.state';

export const selectPhoneState = createFeatureSelector<fromPhoneState.PhoneState>(fromPhoneState.featureKey);

export const selectPhoneNumbers = createSelector(selectPhoneState, state => state.phoneNumbers);

export const selectPhoneNumberTypes = createSelector(selectPhoneState, state => state.phoneTypes);

export const selectAddPhoneNumberSuccess = createSelector(selectPhoneState, state => state.addPhoneNumberSuccess);

export const selectDeletePhoneNumberSuccess = createSelector(selectPhoneState, state => state.deletePhoneNumberSuccess);
