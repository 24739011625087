export enum ProductCodeEnum {
  Gia = 'GIA',
  Isa = 'ISA',
  Jisa = 'JISA',
}

export enum ProductIdEnum {
  Gia = 1,
  Isa = 2,
  Jisa = 3,
}

export enum IsaTypeIdEnum {
  StocksSharesIsa = 1,
  CashIsa = 2,
  StocksSharesJisa = 3,
  CashJisa = 4,
  CTF = 5,
}

export enum IsaTransferTypeIdEnum {
  Full = 1,
  Partial = 2,
}
