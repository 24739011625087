import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EnvironmentsEnum} from '@app/shared/enums/environments.enum';
import {LinksEnum, mcInroyLinks} from '@app/shared/enums/links.enum';
import {WxlOnboardAPIError} from '@app/shared/models/error.model';
import {environment} from 'environments/environment';
import {timer} from 'rxjs';
import {tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'abl-error-list-item',
  templateUrl: './error-list-item.component.html',
  styleUrls: ['./error-list-item.component.scss'],
})
export class ErrorListItemComponent implements OnInit {
  env = environment.tenant;
  envEnum = EnvironmentsEnum.mcInroy;
  @Input() error: WxlOnboardAPIError;
  @Output() closeError = new EventEmitter();
  FAQ = this.env !== this.envEnum ? LinksEnum.FAQ : mcInroyLinks.Contacts;
  text = this.env !== this.envEnum ? this.translate.get('contact_MWise_Support') : 'contact the McInroy & Wood new client team';

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    timer(20000)
      .pipe(tap(() => this.closeError.emit()))
      .subscribe();
  }
}
