import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'environments/environment';
import {
  Individual,
  IndividualOverview,
  IndividualSummary,
  SpouseRelationCreateResponse,
} from '@app/shared/models/individuals/individual.model';
import {Title} from '@app/shared/models/title.model';
import {RelationshipType} from '@app/shared/models/relationship-type.model';
import {SpouseRelationCreate} from '@app/shared/models/individuals/individual-participant.model';
import {
  PowerOfAttorney,
  PowerOfAttorneyResponse,
} from '@app/shared/models/individuals/individual-powerofattorney.model';
import {
  DeletedInterestedParty,
  InterestedPartiesResponse,
  InterestedParty,
} from '@app/shared/models/individuals/individual-interestedparties';
import {InstructionsResponse, Instructions} from '@app/shared/models/individuals/individual-instructions';

import {DeletedItem} from '@app/shared/models/deleted-item.model';
import {Assets, AssetsPayload} from '@app/shared/models/individuals/individual-assets.model';
import {
  Income,
  IncomeResponse,
  TaxableInstructions,
  PaymentFrequencyTypes,
  IncomeTypes,
  InvestmentIncomePyaload,
  InvestmentIncome,
  AnnualSpendingPayload,
  AnnualSpending,
  MonthendTypes,
  QuarterendTypes,
} from '@app/shared/models/individuals/individual-income';
import {Patch} from '@app/shared/models/patch.model';
import {Taxdetails, TaxdetailsPayload} from '@app/shared/models/individuals/individual-taxdetails.model';
import {Isadetails, IsadetailsPayload} from '@app/shared/models/individuals/individual-isadetails';
import {AccountInformationSummary} from '@app/shared/models/individual-summary.model';

@Injectable({
  providedIn: 'root',
})
export class IndividualsService {
  constructor(private http: HttpClient) {}

  fetchIndividualById(id: number): Observable<Individual> {
    return this.http.get<Individual>(`${environment.apiEndpoint}/individuals/${id}`);
  }

  patchIndividual(individualId: number, patch: Patch[]): Observable<Individual> {
    return this.http.patch<Individual>(`${environment.apiEndpoint}/individuals/${individualId}`, patch);
  }

  completeIndividualDma(individualId: number): Observable<{success: boolean}> {
    return this.http.post<{success: boolean}>(`${environment.apiEndpoint}/individuals/${individualId}/dma/complete`, null);
  }

  patchIndividualSpouseRelation(
    individualId: number,
    relationId: number,
    patch: Patch[],
    mandateId: number | null,
    isJointPartner: boolean | null,
  ): Observable<SpouseRelationCreateResponse> {
    return this.http.patch<SpouseRelationCreateResponse>(
      `${environment.apiEndpoint}/individuals/${individualId}/relation/${relationId}?isJointPartner=${isJointPartner}&mandateId=${mandateId}`,
      patch,
    );
  }

  fetchTitles(): Observable<Title[]> {
    return this.http.get<Title[]>(`${environment.apiEndpoint}/individuals/references/titles`);
  }

  fetchChildTitles(): Observable<Title[]> {
    return this.http.get<Title[]>(`${environment.apiEndpoint}/individuals/children/references/titles`);
  }

  fetchIndividualSpouseRelation(individualId: number, mandateId: number): Observable<SpouseRelationCreateResponse> {
    return this.http.get<SpouseRelationCreateResponse>(
      `${environment.apiEndpoint}/individuals/${individualId}/relation/spouse?mandateId=${mandateId}`,
    );
  }

  fetchRelationshipTypes(): Observable<RelationshipType[]> {
    return this.http.get<RelationshipType[]>(
      `${environment.apiEndpoint}/individuals/children/references/relationships`,
    );
  }

  saveAcceptance(individualId: number, acceptance: boolean): Observable<Individual> {
    return this.http.patch<Individual>(`${environment.apiEndpoint}/individuals/${individualId}`, [
      {
        op: 'replace',
        path: '/isTermsAndConditionsAccepted',
        value: acceptance,
      },
    ]);
  }
  saveIndividualSpouseRelation(
    individualId: number,
    relation: SpouseRelationCreate,
  ): Observable<SpouseRelationCreateResponse> {
    return this.http.post<SpouseRelationCreateResponse>(
      `${environment.apiEndpoint}/individuals/${individualId}/relation/spouse`,
      relation,
    );
  }

  createIndividualPowerOfAttorney(individualId: number, request: PowerOfAttorney): Observable<PowerOfAttorneyResponse> {
    return this.http.post<PowerOfAttorneyResponse>(
      `${environment.apiEndpoint}/individuals/${individualId}/powerofattorney`,
      request,
    );
  }

  createIndividualInterestedParties(
    individualId: number,
    request: InterestedParty,
  ): Observable<InterestedPartiesResponse> {
    return this.http.post<InterestedPartiesResponse>(
      `${environment.apiEndpoint}/individuals/${individualId}/interestedparties`,
      request,
    );
  }

  fetchIndividualPowerOfAttorney(individualId: number): Observable<PowerOfAttorneyResponse[]> {
    return this.http.get<PowerOfAttorneyResponse[]>(
      `${environment.apiEndpoint}/individuals/${individualId}/powerofattorney`,
    );
  }
  fetchIndividualInterestedParties(individualId: number): Observable<InterestedPartiesResponse[]> {
    return this.http.get<InterestedPartiesResponse[]>(
      `${environment.apiEndpoint}/individuals/${individualId}/interestedparties`,
    );
  }

  deleteInterestedParty(id: number, individualId: number): Observable<DeletedInterestedParty> {
    return this.http.delete<DeletedInterestedParty>(
      `${environment.apiEndpoint}/individuals/${individualId}/interestedparties/${id}`,
    );
  }

  fetchAccountInstructions(individualId: number): Observable<InstructionsResponse[]> {
    return this.http.get<InstructionsResponse[]>(`${environment.apiEndpoint}/individuals/${individualId}/instructions`);
  }

  createAccountInstructions(individualId: number, request: Instructions): Observable<any> {
    return this.http.post<any>(`${environment.apiEndpoint}/individuals/${individualId}/instructions`, request);
  }
  createIncomeDetails(individualId: number, request: Income): Observable<IncomeResponse> {
    return this.http.post<IncomeResponse>(
      `${environment.apiEndpoint}/individuals/${individualId}/incomedetails`,
      request,
    );
  }

  fetchIncomeDetails(individualId: number): Observable<any> {
    return this.http.get<any>(`${environment.apiEndpoint}/individuals/${individualId}/incomedetails`);
  }

  removeIncomeDetails(individualId: number): Observable<DeletedItem> {
    return this.http.delete<DeletedItem>(`${environment.apiEndpoint}/individuals/${individualId}/incomedetails`);
  }

  fetchTaxableInstructions(): Observable<TaxableInstructions[]> {
    return this.http.get<TaxableInstructions[]>(
      `${environment.apiEndpoint}/individuals/references/taxableinstructions`,
    );
  }

  fetchPaymentFrequencyTypes(): Observable<PaymentFrequencyTypes[]> {
    return this.http.get<PaymentFrequencyTypes[]>(
      `${environment.apiEndpoint}/individuals/references/paymentfrequencytypes`,
    );
  }

  fetchIncomeTypes(): Observable<IncomeTypes[]> {
    return this.http.get<IncomeTypes[]>(`${environment.apiEndpoint}/individuals/annualspending/references/incometypes`);
  }

  createInvestmentIncome(individualId: number, request: InvestmentIncomePyaload): Observable<InvestmentIncome> {
    return this.http.post<InvestmentIncome>(
      `${environment.apiEndpoint}/individuals/${individualId}/investmentincome`,
      request,
    );
  }

  createAnnualSpending(individualId: number, request: AnnualSpendingPayload): Observable<any> {
    return this.http.post<any>(`${environment.apiEndpoint}/individuals/annualspending`, request);
  }

  updateInvestmentIncome(individualId: number, request: Patch[]): Observable<InvestmentIncome> {
    return this.http.patch<InvestmentIncome>(
      `${environment.apiEndpoint}/individuals/${individualId}/investmentincome`,
      request,
    );
  }
  updateAnnualSpending(individualId: number, request: Patch[]): Observable<AnnualSpending> {
    return this.http.patch<AnnualSpending>(
      `${environment.apiEndpoint}/individuals/${individualId}/annualspending`,
      request,
    );
  }

  fetchInvestmentIncome(individualId: number): Observable<InvestmentIncome> {
    return this.http.get<InvestmentIncome>(`${environment.apiEndpoint}/individuals/${individualId}/investmentincome`);
  }
  fetchAnnualSpending(individualId: number): Observable<AnnualSpending> {
    return this.http.get<AnnualSpending>(`${environment.apiEndpoint}/individuals/${individualId}/annualspending`);
  }

  createAssets(individualId: number, request: AssetsPayload): Observable<Assets> {
    return this.http.post<Assets>(`${environment.apiEndpoint}/individuals/${individualId}/assets`, request);
  }
  fetchAssets(individualId: number): Observable<Assets> {
    return this.http.get<Assets>(`${environment.apiEndpoint}/individuals/${individualId}/assets`);
  }

  removeAssets(individualId: number): Observable<DeletedItem> {
    return this.http.delete<DeletedItem>(`${environment.apiEndpoint}/individuals/${individualId}/assets`);
  }

  fetchTaxdetails(individualId: number): Observable<Taxdetails> {
    return this.http.get<Taxdetails>(`${environment.apiEndpoint}/individuals/${individualId}/taxdetails`);
  }

  createTaxdetails(individualId: number, request: TaxdetailsPayload): Observable<Taxdetails> {
    return this.http.post<Taxdetails>(`${environment.apiEndpoint}/individuals/${individualId}/taxdetails`, request);
  }

  updateTaxdetails(individualId: number, request: Patch[]): Observable<Taxdetails> {
    return this.http.patch<Taxdetails>(`${environment.apiEndpoint}/individuals/${individualId}/taxdetails`, request);
  }

  createIsaaccountdetails(individualId: number, request: IsadetailsPayload): Observable<Isadetails> {
    return this.http.post<Isadetails>(
      `${environment.apiEndpoint}/individuals/${individualId}/isaaccountdetails`,
      request,
    );
  }

  fetchIsaaccountdetails(individualId: number): Observable<Isadetails[]> {
    return this.http.get<Isadetails[]>(`${environment.apiEndpoint}/individuals/${individualId}/isaaccountdetails`);
  }

  deleteIsaaccount(id: number, individualId: number): Observable<DeletedItem> {
    return this.http.delete<DeletedItem>(
      `${environment.apiEndpoint}/individuals/${individualId}/isaaccountdetails/${id}`,
    );
  }

  fetchMonthendtypes(): Observable<MonthendTypes[]> {
    return this.http.get<MonthendTypes[]>(`${environment.apiEndpoint}/individuals/taxdetails/references/monthendtypes`);
  }
  fetchQuarterendtypes(): Observable<QuarterendTypes[]> {
    return this.http.get<QuarterendTypes[]>(
      `${environment.apiEndpoint}/individuals/taxdetails/references/quarterendtypes`,
    );
  }
  fetchIndividualSummary(individualId: number): Observable<IndividualSummary> {
    return this.http.get<IndividualSummary>(`${environment.apiEndpoint}/individuals/${individualId}/summary`);
  }
  fetchIndividualOverview(individualId: number): Observable<IndividualOverview> {
    return this.http.get<IndividualOverview>(`${environment.apiEndpoint}/individuals/${individualId}/overview`);
  }
}
