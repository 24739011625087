import {InjectionToken, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {PreventAdditionalPopstateGuard} from './services/prevent-additional-popstate.guard';
import {CallbackComponent} from './components/callback/callback.component';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {environment} from '../../environments/environment';
import {LaunchOptionsComponent} from './components/launch/launch-options.component';
import {MobileLaunchComponent} from './components/mobile-launch/mobile-launch.component';
import {MobileLoggedOutComponent} from './components/mobile-logged-out/mobile-logged-out.component';
import {MobileRegisterComponent} from './components/mobile-register/mobile-register.component';
import { MobileInviteComponent } from './components/mobile-invite/mobile-invite.component';
import { WebInviteComponent } from './components/web-invite/web-invite.component';
import { WebRegisterComponent } from './components/web-register/web-register.component';

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

@NgModule({
  imports: [
    RouterModule.forChild([
      {path: 'launch', component: LaunchOptionsComponent},
      {path: 'mobile-launch/:config/:launchMode', component: MobileLaunchComponent},
      {path: 'mobile-launch/:config', component: MobileLaunchComponent},
      {path: 'mobile-register', component: MobileRegisterComponent},
      {path: 'mobile-invite', component: MobileInviteComponent},
      {path: 'register', component: WebRegisterComponent},
      {path: 'invite', component: WebInviteComponent},
      {path: 'mobile-logged-out', component: MobileLoggedOutComponent},
      {
        path: 'externalUrlRedirect',
        canActivate: [externalUrlProvider],
        component: NotFoundComponent,
      },
      {
        path: 'callback',
        canActivate: [PreventAdditionalPopstateGuard],
        component: CallbackComponent,
      },
    ]),
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: externalUrlProvider,
      useValue: () => {
        const externalUrl = `${environment.codeFlowAuthConfig.issuer}/${environment.verifyEmailEndpoint}?returnUrl=${encodeURI(
          environment.codeFlowAuthConfig.redirectUri,
        )}`;
        window.open(externalUrl, '_self');
      },
    },
  ],
})
export class AuthRoutingModule {}
