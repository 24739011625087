import {DashboardPageFilter, NavItem} from '@app/shared/models/dashboard.model';
import {DashboardPagesEnum} from '@app/shared/enums/dashboard-pages.enum';
import {MandateCategoryIdEnum} from '@app/shared/enums/mandate.enum';

const ROOT_NAV_ITEMS: NavItem[] = [
  {label: 'Accounts', path: DashboardPagesEnum.Accounts},
  {label: 'Applications', path: DashboardPagesEnum.Applications},
  {label: 'Documents', path: DashboardPagesEnum.Documents},
  {label: 'Help & support', path: DashboardPagesEnum.Support},
];

export const featureKey = 'dashboard';

export interface DashboardState {
  pageTitle: string | null;
  rootNavItems: NavItem[];
  subNavItems: NavItem[];
  selectedPortfolioId: number | null;
  pageFilter: DashboardPageFilter;
  activeSubNavItem: NavItem | null;
  isRestricted: boolean;
}

export const initialState: DashboardState = {
  pageTitle: null,
  rootNavItems: ROOT_NAV_ITEMS,
  subNavItems: [],
  selectedPortfolioId: null,
  pageFilter: MandateCategoryIdEnum.All,
  activeSubNavItem: null,
  isRestricted: false
};
