import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {catchError, exhaustMap, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {InviteActions} from './index';
import {InviteService} from '@app/services/invites/invite.service';
import {APInviteData} from '@app/shared/models/invite.model';
import {ErrorActions} from '@app/state/error';

@Injectable()
export class InviteEffects {
  loadInvite$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(InviteActions.loadInvite),
      switchMap(action =>
        this.inviteService.loadInvite().pipe(
          map((invite: APInviteData) =>
            InviteActions.loadInviteSuccess({
              payload: invite,
            }),
          ),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  createInvite$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(InviteActions.createInvite),
      exhaustMap(({invite}) =>
        this.inviteService.createInvite(invite).pipe(
          map((payload: APInviteData) =>
            InviteActions.createInviteSuccess({
              payload: payload,
            }),
          ),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private inviteService: InviteService) {}
}
