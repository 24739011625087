import { Message, MessageThread, MessageType } from '@app/shared/models/message.model';

export const messageFeatureKey = 'message';

export interface MessageState {
  // loading: any;
  messages: Message[];
  message: Message | null;
  selectedMessageType: string | null;
  filteredAccountOrEntityName: string | null;
  currentMessageId: number | null;
  messageTypes: MessageType[];
  thread: MessageThread[];
  isSent:boolean;
  stateChanged: boolean;
  loading: boolean;
}

export const initialState: MessageState = {
  messages: [],
  message: null,
  selectedMessageType: null,
  filteredAccountOrEntityName: null,
  currentMessageId: null,
  messageTypes: [],
  thread: null,
  isSent:false,
  stateChanged: false,
  loading: false,
};
