import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Action, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {filter, map, withLatestFrom} from 'rxjs/operators';
import {ROUTER_NAVIGATED, RouterNavigationAction} from '@ngrx/router-store';
import {setPageData} from '@app/state/dashboard/dashboard.actions';
import {
  AccountsPagesEnum,
  DashboardPagesEnum,
  MessagesPagesEnum,
  pageTitlesEnum,
  RootPagesEnum,
} from '@app/shared/enums/dashboard-pages.enum';
import {NavItem, SubNavMap} from '@app/shared/models/dashboard.model';
import {MandateCategoryEnum, MandateCategoryIdEnum} from '@app/shared/enums/mandate.enum';
import {fetchPortfolioSuccess} from '@app/state/portfolio/portfolio.actions';
import {RouterState, selectRouteNestedParams, selectUrl} from '@app/state/root.selectors';
import {ProductIdEnum} from '@app/shared/enums/product.enum';

const ACCOUNTS_PORTFOLIO_SUB_NAV_ITEMS: SubNavMap = {
  pageTitle: 'Portfolio',
  subNavItems: [
    {path: AccountsPagesEnum.PortfolioDetail, label: 'Portfolio'},
    {path: AccountsPagesEnum.InvestMore, label: 'Add money'},
    {path: AccountsPagesEnum.Transfer, label: 'Transfer'},
    {path: AccountsPagesEnum.RegularSavings, label: 'Regular savings'},
    {path: AccountsPagesEnum.Withdraw, label: 'Withdrawals'},
    {path: AccountsPagesEnum.Activity, label: 'Portfolio activity'},
    {path: AccountsPagesEnum.Reports, label: 'View reports'},
    {path: AccountsPagesEnum.Manage, label: 'Manage portfolio'},
  ],
};

const ACCOUNTS_SUB_NAV_ITEMS: SubNavMap = {
  pageTitle: null,
  subNavItems: [
    {path: AccountsPagesEnum.Portfolios, label: 'Portfolios'},
    {path: AccountsPagesEnum.Reports, label: 'Reports'},
    {path: AccountsPagesEnum.Activity, label: 'Activity'},
  ],
};

const APPLICATIONS_FILTER_ITEMS: SubNavMap = {
  pageTitle: 'Applications',
  subNavItems: [
    {path: MandateCategoryEnum.All, label: 'All applications', filter: MandateCategoryIdEnum.All},
    {
      path: MandateCategoryEnum.InProgress,
      label: 'In progress',
      filter: MandateCategoryIdEnum.InProgress,
    },
    {path: MandateCategoryEnum.Pending, label: MandateCategoryEnum.Pending, filter: MandateCategoryIdEnum.Pending},
    {path: MandateCategoryEnum.Complete, label: MandateCategoryEnum.Complete, filter: MandateCategoryIdEnum.Complete},
    {path: MandateCategoryEnum.Failed, label: MandateCategoryEnum.Failed, filter: MandateCategoryIdEnum.Failed},
  ],
};

const MESSAGES_SUB_NAV_ITEMS: SubNavMap = {
  pageTitle: 'Messages',
  subNavItems: [],
};

const SUB_NAV_ITEMS_BY_PATH = new Map<string, SubNavMap>([
  [`${AccountsPagesEnum.PortfolioDetail}`, ACCOUNTS_PORTFOLIO_SUB_NAV_ITEMS],
  [`${DashboardPagesEnum.Accounts}${AccountsPagesEnum.Portfolios}`, ACCOUNTS_SUB_NAV_ITEMS],
  [`${DashboardPagesEnum.Accounts}${AccountsPagesEnum.Reports}`, ACCOUNTS_SUB_NAV_ITEMS],
  [`${DashboardPagesEnum.Accounts}${AccountsPagesEnum.Activity}`, ACCOUNTS_SUB_NAV_ITEMS],
  [`${DashboardPagesEnum.Applications}`, APPLICATIONS_FILTER_ITEMS],
  [`${DashboardPagesEnum.Applications}${MandateCategoryEnum.All}`, APPLICATIONS_FILTER_ITEMS],
  [`${DashboardPagesEnum.Applications}In%20Progress`, APPLICATIONS_FILTER_ITEMS],
  [`${DashboardPagesEnum.Applications}${MandateCategoryEnum.Pending}`, APPLICATIONS_FILTER_ITEMS],
  [`${DashboardPagesEnum.Applications}${MandateCategoryEnum.Complete}`, APPLICATIONS_FILTER_ITEMS],
  [`${DashboardPagesEnum.Applications}${MandateCategoryEnum.Failed}`, APPLICATIONS_FILTER_ITEMS],
  [`${DashboardPagesEnum.Documents}`, {pageTitle: 'Documents', subNavItems: []}],
  [`${DashboardPagesEnum.Support}`, {pageTitle: 'Help & support', subNavItems: []}],
  [`${DashboardPagesEnum.Messages}${MessagesPagesEnum.Compose}`, MESSAGES_SUB_NAV_ITEMS],
  [`${DashboardPagesEnum.Messages}${MessagesPagesEnum.Inbox}`, MESSAGES_SUB_NAV_ITEMS],
  [`${DashboardPagesEnum.Messages}${MessagesPagesEnum.Open}`, MESSAGES_SUB_NAV_ITEMS],
  [`${DashboardPagesEnum.Messages}${MessagesPagesEnum.Sent}`, MESSAGES_SUB_NAV_ITEMS],
  [`${DashboardPagesEnum.Messages}${MessagesPagesEnum.Unread}`, MESSAGES_SUB_NAV_ITEMS],
  [`${DashboardPagesEnum.Notifications}`, {pageTitle: 'Notifications', subNavItems: []}],
  [`${DashboardPagesEnum.Profile}`, {pageTitle: 'My profile', subNavItems: []}],
]);

@Injectable()
export class DashboardEffects {
  setPageData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      map((r: RouterNavigationAction) => r.payload.routerState.url),
      filter(
        url =>
          !url.includes(RootPagesEnum.Risk) &&
          !url.includes(RootPagesEnum.Onboarding) &&
          !url.includes(`${AccountsPagesEnum.Portfolio}/`) &&
          !url.includes(`${MessagesPagesEnum.Open}/`), // Exclude message open because this route contains params.
      ), // We require a separate effect for setting the portfolio details page data.
      map(url => url.split('/').join('')),
      map(url => {
        const {pageTitle, subNavItems} = SUB_NAV_ITEMS_BY_PATH.get(url);
        return setPageData({pageTitle, subNavItems});
      }),
    ),
  );

  /**
   * A separate effect to update sub nav items on portfolio fetch success,
   * this is because we filter the sub nav items based on portfolio product type.
   * E.g. For GIA products we don't want to be able to make a transfer.
   * And we need to prepend each sub nav path with 'portfolio/<portfolio id>/'
   */
  setPortfolioDetailPageData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchPortfolioSuccess),
      withLatestFrom(this.routerState.select(selectUrl), this.routerState.select(selectRouteNestedParams)),
      filter(([, url, {portfolioId}]) => url.includes(`${AccountsPagesEnum.Portfolio}/`) && !!portfolioId),
      map(([{response}, url, {portfolioId}]) => {
        const {subNavItems} = SUB_NAV_ITEMS_BY_PATH.get(AccountsPagesEnum.PortfolioDetail);
        // Prepend each sub nav items' path with one that is relative to its parent.e.g accounts/<portfolioId>/invest-more.
        let prependedSubNavItems = prefixSubNavItems(subNavItems, `${AccountsPagesEnum.Portfolio}/${portfolioId}`);
        return setPageData({
          pageTitle: url.includes(AccountsPagesEnum.PortfolioDetail)
            ? response.product?.productTitle
            : getPageTitle(url),
          subNavItems: filterSubNavItems(prependedSubNavItems, response.product?.id, response.restrictedInvestment),
        });
      }),
    ),
  );

  constructor(private actions$: Actions, private routerState: Store<RouterState>) {}
}

function getPageTitle(url: string): string {
  const lastElementOfPath = url.split('/').pop();
  return pageTitlesEnum[lastElementOfPath];
}

/** Filter the sub nav item based on the product ID. */
function prefixSubNavItems(items: NavItem[], prefix: string): NavItem[] {
  return items.map(it => ({
    ...it,
    path: `${prefix}/${it.path}`,
  }));
}

/** Filter the sub nav item based on the product ID. */
function filterSubNavItems(items: NavItem[], productId: ProductIdEnum, isRestricted: boolean): NavItem[] {
  let data: NavItem[];

  if (productId === ProductIdEnum.Jisa) {
    data = items.filter(it => typeof it.path === 'string' && !it.path.includes(AccountsPagesEnum.Withdraw));
  }
  else{
    data = items;
  }

  if(isRestricted){
    data = data.filter(it => typeof it.path === 'string' && !it.path.includes(AccountsPagesEnum.RegularSavings));
  }

  return data;
}
