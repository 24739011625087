import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';
import {
  ConfirmEmailVerificationCode,
  EmailVerificationCodeResponse,
  PasswordChangeRequest,
  PasswordChangeResponse,
  SecurityQuestionAnswerList,
  SecurityQuestionList,
  UpdateSecurityQuestionAnswersRequest,
  UpdateSecurityQuestionAnswersResponse,
  resendEmailVerificationCodeResponse,
} from '@app/shared/models/user.model';

/** Fetch security questions. */
export const fetchSecurityQuestions = createAction('[User] Fetch Security Questions');

export const fetchSecurityQuestionsSuccess = createAction(
  '[User] Fetch Security Questions Success',
  props<{response: SecurityQuestionList}>(),
);

export const fetchSecurityQuestionsFailure = createAction(
  '[User] Fetch Security Questions Failure',
  props<{error: HttpErrorResponse}>(),
);

/** Fetch security question answers. */
export const fetchSecurityQuestionAnswers = createAction('[User] Fetch Security Question Answers');

export const fetchSecurityQuestionAnswersSuccess = createAction(
  '[User] Fetch Security Question Answers Success',
  props<{response: SecurityQuestionAnswerList}>(),
);

export const fetchSecurityQuestionAnswersFailure = createAction(
  '[User] Fetch Security Question Answers Failure',
  props<{error: HttpErrorResponse}>(),
);

/** Update security question answers. */
export const updateSecurityQuestionAnswers = createAction(
  '[User] Update Security Question Answers',
  props<{request: UpdateSecurityQuestionAnswersRequest}>(),
);

export const updateSecurityQuestionAnswersSuccess = createAction(
  '[User] Update Security Question Answers Success',
  props<{response: UpdateSecurityQuestionAnswersResponse}>(),
);

export const updateSecurityQuestionAnswersFailure = createAction(
  '[User] Update Security Question Answers Failure',
  props<{error: HttpErrorResponse}>());

export const updatePassword = createAction('[User] Update Password', props<{request: PasswordChangeRequest}>());

export const updatePasswordResponse = createAction(
  '[User] Update Password Response',
  props<{response: PasswordChangeResponse}>(),
);

export const emailVerificationCode = createAction (
  '[User] Verify email confirmation code', 
  props<{request: ConfirmEmailVerificationCode}>(), 
)

export const fetchEmailVerificationCodeSuccess = createAction(
  '[User] Fetch email confirmation code Success',
  props<{response: EmailVerificationCodeResponse}>(),
);

export const fetchEmailVerificationCodeFailure = createAction(
  '[User] Fetch email confirmation code Failure',
  props<{error: HttpErrorResponse}>(),
); 

export const resendEmailVerificationCode = createAction (
  '[User] Resend  email confirmation code', 
)

export const resetEmailVerificationCodeSuccess = createAction (
  '[User] Reset  email confirmation code success', 
)

export const resendEmailVerificationCodeSuccess = createAction(
  '[User] Resend email confirmation code Success',
  props<{response: resendEmailVerificationCodeResponse}>(),
);




