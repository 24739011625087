import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {of} from 'rxjs';
import {catchError, filter, map, switchMap, withLatestFrom} from 'rxjs/operators';

import {
  fetchJourneyConfigForCurrentMandate,
  fetchJourneyConfigForCurrentMandateSuccess,
  fetchJourneyConfigForNewMandate,
  fetchJourneyConfigForNewMandateSuccess,
  setCurrentJourneyConfig,
  updateJourneyConfig,
} from './journey-config.actions';
import {JourneyConfigService} from '@app/services/journey-service/journey-config.service';
import {JourneyConfigState} from './journey-config.state';
import {Router} from '@angular/router';
import {ErrorActions} from '@app/state/error';
import {TenantService} from '@app/services/tenant.service';
import {selectOnboardingMandateId} from '@app/state/onboarding/onboarding.selectors';
import {MandateState} from '@app/state';
import {JourneyHistoryState} from '@app/state/journey-history/journey-history.state';
import {selectCurrentJourneyStep} from '@app/state/journey-config/journey-config.selectors';
import {selectCurrentJourneyHistoryStep} from '@app/state/journey-history/journey-history.selectors';
import {JourneyStepEnum} from '@app/shared/enums/journey-step.enum';
import Utils from '@app/shared/util/utils';

@Injectable()
export class JourneyConfigEffects {
  /** Fetch journey config for new mandate. */
  fetchJourneyConfigForNewMandate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchJourneyConfigForNewMandate),
      withLatestFrom(this.mandateStore.select(selectOnboardingMandateId)),
      filter(([, id]) => Boolean(id)),
      switchMap(([, id]) =>
        this.journeyConfigService.fetchJourneyConfigByMandateId(id).pipe(
          map(response => fetchJourneyConfigForNewMandateSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  /** Sets the current config for a new mandate. */
  setJourneyConfigForNewMandate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchJourneyConfigForNewMandateSuccess),
      map(({response}) =>
        setCurrentJourneyConfig({journeyConfig: response, currentStep: response.parts[0].stages[0].steps[0].url}),
      ), // Set current step to be the first config step URL.
    ),
  );

  fetchJourneyConfigForCurrentMandate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchJourneyConfigForCurrentMandate),
      withLatestFrom(this.mandateStore.select(selectOnboardingMandateId)),
      filter(([, id]) => Boolean(id)),
      switchMap(([, id]) =>
        this.journeyConfigService.fetchJourneyConfigByMandateId(id).pipe(
          map(journeyConfig => fetchJourneyConfigForCurrentMandateSuccess({response: journeyConfig})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  /** Only update the journey config if there is a current journey step. */
  updateCurrentJourneyConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchJourneyConfigForCurrentMandateSuccess),
      withLatestFrom(this.journeyConfigStore.select(selectCurrentJourneyStep)),
      filter(([, currentJourneyStep]) => !!currentJourneyStep),
      map(([{response}]) => updateJourneyConfig({journeyConfig: response})),
    ),
  );

  /** Sets the current config on application continue or F5. */
  setCurrentJourneyConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchJourneyConfigForCurrentMandateSuccess),
      withLatestFrom(
        this.journeyConfigStore.select(selectCurrentJourneyStep),
        this.journeyHistoryStore.select(selectCurrentJourneyHistoryStep),
      ),
      filter(([, currentJourneyStep, currentHistoryStep]) => !currentJourneyStep && !!currentHistoryStep),
      map(([{response}, , currentHistoryStep]) => {
        const currentStep = <JourneyStepEnum>Utils.getUrlSegmentsFromUrl(this.router, currentHistoryStep.url)[1].path;
        return setCurrentJourneyConfig({journeyConfig: response, currentStep});
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<JourneyConfigState>,
    private mandateStore: Store<MandateState.MandateState>,
    private journeyConfigStore: Store<JourneyConfigState>,
    private journeyHistoryStore: Store<JourneyHistoryState>,
    private router: Router,
    private tenantService: TenantService,
    private journeyConfigService: JourneyConfigService,
  ) {}
}
