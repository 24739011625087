import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ProfileEffects } from '@app/state/profile/profile.effects';
import { ProfileState } from '@app/state/profile/index';
import { reducer } from '@app/state/profile/profile.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(ProfileState.featureKey, reducer),
    EffectsModule.forFeature([ProfileEffects])
  ],
  declarations: []
})

export class ProfileStateModule {
}
