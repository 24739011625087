import {Component, OnDestroy, OnInit} from '@angular/core';
import {webSocket} from 'rxjs/webSocket';
import {filter, map} from 'rxjs/operators';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {refreshToken, setupAutomaticSilentRefresh} from '@app/state/auth/auth.actions';
import {Store} from '@ngrx/store';
import {AuthState} from '@app/state';
import {OAuthStorageService} from '@app/auth/services/oauth-storage.service';
import {OAuthService} from 'angular-oauth2-oidc';
import {AuthOidcService} from '@app/auth/services/auth-oidc.service';
import {LoggerService} from '@app/services/logger.service';

const BITMEX_WS_URL = 'wss://ws.bitmex.com/realtime';

@Component({
  selector: 'abl-lab',
  templateUrl: './lab.component.html',
  styleUrls: ['./lab.component.scss'],
})
export class LabComponent implements OnInit, OnDestroy {
  formGroup = new UntypedFormGroup({
    input: new UntypedFormControl('', Validators.required),
    prefix: new UntypedFormControl('', Validators.required),
    suffix: new UntypedFormControl('', Validators.required),
  });

  private wsSubject$ = webSocket(BITMEX_WS_URL);
  trades$ = this.wsSubject$.pipe(
    filter((data: any) => data?.table === 'trade'),
    map(d => d.data),
  );
  chat$ = this.wsSubject$.pipe(
    filter((data: any) => data?.table === 'chat' && !!data.data.length),
    map(d => d.data),
  );

  constructor(
    private authStore: Store<AuthState.State>,
    private oauthStorageService: OAuthStorageService,
    private oauthService: OAuthService,
    private authService: AuthOidcService,
    public logger: LoggerService,
  ) {}

  ngOnInit() {
    this.wsSubject$.next({op: 'subscribe', args: ['chat', 'trade']});
  }

  ngOnDestroy() {
    this.wsSubject$.complete();
  }

  dispatchSetupAutomaticSilentRefresh() {
    this.authStore.dispatch(setupAutomaticSilentRefresh());
  }

  logRefreshToken() {
    console.log(this.oauthService.getRefreshToken());
  }

  logSessionStorageRefreshToken() {
    console.log(this.oauthStorageService.getItem('refresh_token'));
  }

  dispatchRefreshToken() {
    this.authStore.dispatch(refreshToken());
  }

  refreshToken() {
    console.log(this.oauthStorageService.getItem('refresh_token'));
    this.oauthService.refreshToken();
  }

  silentRefresh() {
    this.oauthService
      .silentRefresh()
      .then(info => console.debug('refresh ok', info))
      .catch(err => console.error('refresh error', err));
  }

  setTokenInSessionStorageAndLog() {
    this.oauthStorageService.setItem('refresh_token', 'FAKE_TOKEN_###');
    setTimeout(() => {
      console.log(this.oauthStorageService.getItem('refresh_token'));
    }, 500);
  }

  validateTokenForMobile() {
    this.authService.validateTokenForMobile().subscribe(res => console.log(res));
  }
}
