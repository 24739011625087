import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Mandate, MandateCreate} from '@app/shared/models/mandate.model';
import {environment} from '../../../environments/environment';
import {PatchDocument} from '@app/shared/models/patch-document.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class MandateService {
  headers: HttpHeaders;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders().set('Content-Type', 'application/json');
  }

  getMandates(): Observable<Mandate[]> {
    return this.http.get<Mandate[]>(`${environment.apiEndpoint}/mandates`);
  }

  getMandateById(referenceId: number): Observable<Mandate> {
    return this.http.get<Mandate>(`${environment.apiEndpoint}/mandates/${referenceId}`);
  }

  deleteMandate(mandateId: number): Observable<Mandate> {
    return this.http.delete<Mandate>(`${environment.apiEndpoint}/mandates/${mandateId}`);
  }

  updateMandate(mandateId: number, payload: PatchDocument): Observable<Mandate> {
    return this.http.patch<Mandate>(`${environment.apiEndpoint}/mandates/${mandateId}`, payload.patch, {
      headers: this.headers,
    });
  }

  finaliseMandate(mandateId: number): Observable<Mandate> {
    return this.http.post<Mandate>(`${environment.apiEndpoint}/mandates/finalise`, {mandateId});
  }

  createMandate(mandate: MandateCreate): Observable<Mandate> {
    return this.http.post<Mandate>(`${environment.apiEndpoint}/mandates`, mandate);
  }
}
