import { on, createReducer, Action } from '@ngrx/store';
import { initialState, MessageState } from './message.state';
import * as MessageActions from './message.actions';

const messageReducer = createReducer(
  initialState,

  on(MessageActions.LoadMessagesSuccess, (state, { payload }) => ({
    ...state,
    messages: payload
  })),

  on(MessageActions.createMessageSuccess, (state, { payload }) => ({
    ...state,
    message: payload,
    isSent: payload.isSent,
    stateChanged: true
  })),
  on(MessageActions.resetMessageSuccess, state => ({
    ...state,
    isSent: false,
    stateChanged: false
  })),

  on(MessageActions.getThreadById, state => ({
    ...state,
    loading: true,
  })),

  on(MessageActions.getThreadByIdSuccess, (state, { thread }) => ({
    ...state,
    thread: thread,
    loading: false,
  })),

  on(MessageActions.filter, (state, { selectedMessageType, filteredAccountOrEntityName }) => ({
    ...state,
    selectedMessageType: selectedMessageType,
    filteredAccountOrEntityName: filteredAccountOrEntityName
  })),

  on(MessageActions.setSelectedMessageId, (state, { messageId }) => ({
    ...state,
    currentMessageId: messageId
  })),

  on(MessageActions.LoadMessageTypesSuccess, (state, { payload }) => ({
    ...state,
    messageTypes: payload
  }))
);

export function reducer(state: MessageState, action: Action) {
  return messageReducer(state, action);
}
