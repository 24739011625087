import {createReducer, on} from '@ngrx/store';
import * as JourneyHistoryActions from './journey-history.actions';
import {adapter, initialState} from '@app/state/journey-history/journey-history.state';

const journeyHistoryReducer = createReducer(
  initialState,

  on(JourneyHistoryActions.fetchCurrentJourneyHistoryStepSuccess, (state, action) => ({
    ...state,
    currentJourneyHistoryStep: action.response,
  })),

  on(JourneyHistoryActions.createJourneyHistoryStepSuccess, (state, {response: journeyHistoryStep}) =>
    adapter.addOne(journeyHistoryStep, {
      ...state,
      lastLoaded: new Date().toUTCString(),
    }),
  ),

  on(JourneyHistoryActions.clearJourneyHistory, state =>
    adapter.removeAll({
      ...state,
      currentJourneyHistoryStep: null,
      lastLoaded: null,
    }),
  ),
);

export function reducer(state, action) {
  return journeyHistoryReducer(state, action);
}

export const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal,
} = adapter.getSelectors();
