import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromfinancialdependents from './financial-dependents.reducer';
import * as fromfinancialdependentsState from './financial-dependents.state';
import { FinancialDependentsEffects } from './financial-dependents.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromfinancialdependentsState.featureKey, fromfinancialdependents.reducer),
    EffectsModule.forFeature([FinancialDependentsEffects])
  ],
  declarations: []
})
export class FinancialDependentsStateModule {}
