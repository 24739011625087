import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromBanksState from './bank.state';

export const selectBankDetailsState = createFeatureSelector<fromBanksState.BankState>(fromBanksState.featureKey);

export const selectBankDetails = createSelector(selectBankDetailsState, state => state.bankDetails);

export const selectCreateBankDetailsSuccess = createSelector(
  selectBankDetailsState,
  state => state.createBankDetailsSuccess,
);

export const selectUpdateBankDetailsSuccess = createSelector(
  selectBankDetailsState,
  state => state.updateBankDetailsSuccess,
);
export const selectUpdateNonUkBankDetailsSuccess = createSelector(
  selectBankDetailsState,
  state => state.updateNonUkBankDetailsSuccess,
);

export const selectBankDetailsValidationResult = createSelector(
  selectBankDetailsState,
  state => state.loqateBankValidationResult,
);

export const selectBankDetailsIsLoading = createSelector(selectBankDetailsState, state => state.isLoading);
