import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import * as fromProduct from './product.reducer';
import * as fromProductState from './product.state';
import {EffectsModule} from '@ngrx/effects';
import {ProductEffects} from './product.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromProductState.featureKey, fromProduct.reducer),
    EffectsModule.forFeature([ProductEffects]),
  ],
})
export class ProductStateModule {
}
