import {Action, createReducer, on} from '@ngrx/store';
import * as OrganisationActions from './organisation.actions';
import {initialState, OrganisationState} from '@app/state/organisation/organisation.state';

export const organisationReducer = createReducer(
  initialState,

  on(OrganisationActions.fetchOrganisationsSuccess, (state, {response}) => ({
    ...state,
    organisations: response,
  })),
);

export function reducer(state: OrganisationState, action: Action) {
  return organisationReducer(state, action);
}
