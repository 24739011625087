<mat-toolbar color="primary" fxLayoutGap="10px">
  <mat-icon>science</mat-icon>
  <span>Lab</span>
  <div fxFlex></div>
  <button aria-label="home" mat-icon-button [routerLink]="['']" matTooltip="Go home">
    <mat-icon>home</mat-icon>
  </button>
</mat-toolbar>
<mat-tab-group class="padding" mat-align-tabs="start">
  <mat-tab label="Mobile Auth Testing" fxLayout="column">
    <h3>Auth Testing Buttons:</h3>
    <div fxLayout="row wrap" class="auth-buttons" fxLayoutGap="10px">
      <button mat-raised-button color="warn" (click)="dispatchSetupAutomaticSilentRefresh()">
        Auto silent refresh
      </button>
      <button mat-raised-button color="warn" (click)="refreshToken()">
        Refresh Token
      </button>
      <button mat-raised-button color="warn" (click)="silentRefresh()">
        Silent Refresh Token
      </button>
      <button mat-raised-button color="warn" (click)="dispatchRefreshToken()">
        Dispatch Refresh Token To Effects
      </button>
      <button mat-raised-button color="warn" (click)="logRefreshToken()">
        Log Refresh Token
      </button>
      <button mat-raised-button color="warn" (click)="logSessionStorageRefreshToken()">
        Log Session Storage Refresh Token
      </button>
      <button mat-raised-button color="warn" (click)="setTokenInSessionStorageAndLog()">
        setTokenInSessionStorageAndLog
      </button>
      <button mat-raised-button color="warn" (click)="validateTokenForMobile()">
        Validate token for mobile
      </button>
    </div>
    <div fxLayout="column" class="logs-container">
      <h3>Logs:</h3>
      <div *ngFor="let log of logger.logs$ | async">
        <span>{{log}}</span>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Style Library 'stylbrary'">
    <div fxLayout="column" fxLayoutGap="10px" fxFlex="50">
      <div fxLayoutGap="10px">
        <button mat-raised-button i18n color="primary">Continue</button>
        <button mat-stroked-button i18n>Cancel</button>
      </div>
      <form [formGroup]="formGroup" fxLayout="column">
        <mat-form-field>
          <mat-label>input control</mat-label>
          <input
            formControlName="input"
            matInput
            placeholder="input control"
            autocomplete="off"
          >
          <mat-error>
            Error
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>prefix</mat-label>
          <span matPrefix>£&nbsp;</span>
          <input
            formControlName="prefix"
            matInput
            placeholder="prefix"
            autocomplete="off"
          >
          <mat-error>
            Error
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>suffix</mat-label>
          <input
            formControlName="suffix"
            matInput
            placeholder="suffix"
            autocomplete="off"
          >
          <mat-error>
            Error
          </mat-error>
          <span matSuffix>years</span>
        </mat-form-field>
      </form>
      <mat-divider></mat-divider>
      <strong>Custom material card .custom-mat-card</strong>
      <p>For natural mat-card elements where we remove the border radius and box-shadow</p>
      <p>Must only appear on the back, never within another mat-card.</p>
      <div fxLayout="column" fxLayoutGap="10px">
        <mat-card *ngFor="let item of ['item1', 'item2']">
          <mat-card-header>
            <mat-card-title>
              <strong>Title</strong>
            </mat-card-title>
            <mat-card-subtitle>subtitle</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <p>Body text: Lorem ipsum dolor sit amet, consectetur adipiscing elit. In et dui semper, faucibus augue
              nec, finibus ligula. Nam in leo sed massa tempor mattis. Fusce.</p>
          </mat-card-content>
        </mat-card>
      </div>
      <mat-divider></mat-divider>
      <strong>Normal material card</strong>
      <p>For natural mat-card elements with custom box-shadow and border-radius</p>
      <p>Must only appear on the back, never within another mat-card.</p>
      <mat-card fxLayout="column" fxLayoutGap="15px" class="custom-mat-card">
        <strong>mat-card heading is strong</strong>
        <div class="custom-border-card" fxLayout="column">
          <span class="mat-subheading-2">Heading as mat-subheading-2</span>
          <strong>subtitle in strong</strong>
          <p>Body text: Lorem ipsum dolor sit amet, consectetur adipiscing elit. In et dui semper, faucibus augue nec,
            finibus ligula. Nam in leo sed massa tempor mattis. Fusce.</p>
        </div>
      </mat-card>
    </div>
  </mat-tab>
  <mat-tab label="Web Sockets">
    <ng-template matTabContent>
      <div *ngIf="chat$ | async as chats">
        <h2>Chat:</h2>
        <div *ngFor="let chat of chats" fxLayout="column">
          <span>{{chat.fromBot ? 'Bot' : 'Person'}}</span>
          <span>User: {{chat.user}}</span>
          <span>Message: {{chat.message}}</span>
        </div>
        <mat-divider></mat-divider>
      </div>

      <div *ngIf="trades$ | async as trades">
        <h2>Trades:</h2>
        <div fxLayoutGap="10px">
          <div fxLayoutGap="10px" fxLayout="column" *ngFor="let data of trades">
            <div fxLayout="column">
              <span>Symbol: {{data.symbol}}</span>
              <span>Side: {{data.side}}</span>
              <span>Price: ${{data.price}}</span>
            </div>
            <mat-divider></mat-divider>
          </div>
        </div>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>
