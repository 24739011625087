import {Injectable} from '@angular/core';

import {TenantService} from '@app/services/tenant.service';

@Injectable({
  providedIn: 'root',
})
export class LabGuard  {
  constructor(private tenantService: TenantService) {}

  canActivate(): boolean {
    return !this.tenantService.isEnvironmentProduction;
  }
}
