import {Principal} from './../../shared/models/principal.model';
// This file is for actions initiated by the api (not by the ui)
import {createAction, props} from '@ngrx/store';
import {AddressResponse, LoqateAddressFindRequest, V2Address} from '@app/shared/models/address.model';

import {DeletedItem} from '@app/shared/models/deleted-item.model';
import {HttpErrorResponse} from '@angular/common/http';
import {LoqateAddressFindItem, LoqateAddressRetrieveRequest} from '@app/shared/models/loqate.model';
import {AddressState} from '@app/state/address/address.state';

export const getAddressesForEntity = createAction(
  '[Address Details] Get Addresses For Entity',
  props<{entityId: number}>(),
);

export const getAddressesForIndividual = createAction(
  '[Address Details] Get Addresses For Individual',
  props<{individualId: number}>(),
);

export const loadAddressByIndividualId = createAction(
  '[Address Details] Load Addresses By Owner Id',
  props<{individualId: number}>(),
);

export const getLoqateAddress = createAction(
  '[Address Details] Get Loqate Address',
  props<{retrieveRequest: LoqateAddressRetrieveRequest}>(),
);

export const validateLoqateAddress = createAction(
  '[Address Details] Validate Loqate Address',
  props<{params: LoqateAddressFindRequest}>(),
);

/** V2 address actions. */

/** Fetch addresses. */
export const fetchAddressesByIndividualId = createAction('[Address Details] Fetch Addresses By Individual Id');

export const fetchAddressesSuccess = createAction(
  '[Address Details] Fetch Addresses Success',
  props<{addresses: AddressResponse[]}>(),
);

export const fetchAddressesFail = createAction(
  '[Address Details] Fetch Addresses Fail',
  props<{error: HttpErrorResponse}>(),
);

/** Fetch addresses for individual two. */
export const fetchPrincipalAddress = createAction(
  '[Principal Address Details] Fetch Principal Addresses By mandate Id',
  props<{addressTypeId: number}>(),
);

export const fetchPrincipalAddressSuccess = createAction(
  '[Principal Address Details] Fetch Principal Addresses By mandate Id Success',
  props<{principalAddress: AddressResponse}>(),
);

export const fetchPrincipalAddressFail = createAction(
  '[Principal Address Details] Fetch Principal Addresses By mandate Id Fail',
  props<{error: HttpErrorResponse}>(),
);

/** Add address. */
export const addAddress = createAction('[Address Details] Add Address', props<{address: V2Address}>());

export const addAddressSuccess = createAction(
  '[Address Details] Add Address Success',
  props<{response: AddressResponse}>(),
);

export const addAddressFail = createAction('[Address Details] Add Address Fail', props<{error: HttpErrorResponse}>());

/** Update address. */
export const updateAddress = createAction('[Address Details] Update Address', props<{address: V2Address}>());

export const updateAddressSuccess = createAction(
  '[Address Details] Update Address Success',
  props<{response: AddressResponse}>(),
);

/** Remove address. */
export const removeAddress = createAction('[Address Details] Remove Address', props<{id: number}>());

export const removeAddressSuccess = createAction(
  '[Address Details] Remove Address Success',
  props<{response: DeletedItem}>(),
);

export const removeAddressFail = createAction(
  '[Address Details] Remove Address Fail',
  props<{error: HttpErrorResponse}>(),
);

/** Find actions. */
export const findLoqateAddress = createAction(
  '[Address Details] Find Loqate Address',
  props<{request: LoqateAddressFindRequest}>(),
);

export const findLoqateAddressSuccess = createAction(
  '[Address Details] Find Loqate Address Success',
  props<{response: LoqateAddressFindItem[]}>(),
);

export const findLoqateAddressFail = createAction(
  '[Address Details] Find Loqate Address Fail',
  props<{error: HttpErrorResponse}>(),
);

/** Retrieve actions. */
export const retrieveLoqateAddress = createAction(
  '[Address Details] Retrieve Loqate Address',
  props<{request: LoqateAddressRetrieveRequest}>(),
);

export const retrieveLoqateAddressSuccess = createAction(
  '[Address Details] Retrieve Loqate Address Success',
  props<{response: V2Address}>(),
);

export const retrieveLoqateAddressFail = createAction(
  '[Address Details] Retrieve Loqate Address Fail',
  props<{error: HttpErrorResponse}>(),
);

export const updateAddressState = createAction(
  '[Address Details] Update Address State',
  props<Partial<AddressState>>(),
);
