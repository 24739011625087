import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {CreateIndividualChild, IndividualChild} from '@app/shared/models/individuals/individual-child.model';
import {map} from 'rxjs/operators';
import {Patch} from '@app/shared/models/patch.model';

@Injectable({
  providedIn: 'root',
})
export class IndividualChildService {
  constructor(private http: HttpClient) {}

  fetchIndividualChildByMandateId(individualId: number, mandateId: number): Observable<IndividualChild> {
    return this.http
      .get<IndividualChild[]>(`${environment.apiEndpoint}/individuals/${individualId}/children?mandateId=${mandateId}`)
      .pipe(map(children => children[0] ?? null));
  }

  createIndividualChild(
    individualId: number,
    mandateId: number,
    child: CreateIndividualChild,
  ): Observable<IndividualChild> {
    return this.http.post<IndividualChild>(`${environment.apiEndpoint}/individuals/${individualId}/children`, {
      mandateId,
      ...child,
    });
  }

  patchIndividualChild(individualId: number, childIndividualId: number, patch: Patch[]): Observable<IndividualChild> {
    return this.http.patch<IndividualChild>(
      `${environment.apiEndpoint}/individuals/${individualId}/children/${childIndividualId}`,
      patch,
    );
  }
}
