import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';
import {CreateIndividualChild, IndividualChild} from '@app/shared/models/individuals/individual-child.model';
import {Patch} from '@app/shared/models/patch.model';

/** Fetch child for mandate. */
export const fetchChildByMandateId = createAction('[IndividualChild] Fetch Child By Mandate Id');

export const fetchChildByMandateIdSuccess = createAction(
  '[IndividualChild] Fetch Child By Mandate Id Success',
  props<{response: IndividualChild}>(),
);

export const fetchChildByMandateIdFailure = createAction(
  '[IndividualChild] Fetch Child By Mandate Id Failure',
  props<{error: HttpErrorResponse}>(),
);

/** Create child. */
export const createChild = createAction('[IndividualChild] Create Child', props<{request: CreateIndividualChild}>());

export const createChildSuccess = createAction(
  '[IndividualChild] Create Child Success',
  props<{response: IndividualChild}>(),
);

export const createChildFailure = createAction(
  '[IndividualChild] Create Child Failure',
  props<{error: HttpErrorResponse}>(),
);

/** Update child. */
export const updateChild = createAction('[IndividualChild] Update Child', props<{request: Patch[]}>());

export const updateChildSuccess = createAction(
  '[IndividualChild] Update Child Success',
  props<{response: IndividualChild}>(),
);

export const updateChildFailure = createAction(
  '[IndividualChild] Update Child Failure',
  props<{error: HttpErrorResponse}>(),
);

export const resetIndividualChildState = createAction('[IndividualChild] Reset Child Individual State');
