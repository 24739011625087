import {Action, createReducer, on} from '@ngrx/store';
import {ErrorState, initialState} from './error.state';
import * as ErrorActions from './error.actions';

const errorReducer = createReducer(
  initialState,

  on(ErrorActions.newError, (state, {backEndError}) => ({
    ...state,
    errors: [...state.errors, backEndError],
  })),

  on(ErrorActions.removeError, (state, {index}) => ({
    ...state,
    errors: [...state.errors.filter((error, i) => index !== i)],
  })),
);

export function reducer(state: ErrorState, action: Action) {
  return errorReducer(state, action);
}
