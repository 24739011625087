import {Action, createReducer, on} from '@ngrx/store';
import {DashboardState, initialState} from './dashboard.state';
import {filterSubNavItems, setActiveSubNavItem, setPageData, setPageFilter, setPageTitle} from './dashboard.actions';

export const dashboardReducer = createReducer(
  initialState,

  on(setPageData, (state, {pageTitle, subNavItems}) => ({
    ...state,
    pageTitle,
    subNavItems: subNavItems ?? [],
  })),

  on(filterSubNavItems, (state, {path}) => ({
    ...state,
    subNavItems: state.subNavItems.filter(s => s.path !== path),
  })),

  on(setPageTitle, (state, {pageTitle}) => ({
    ...state,
    pageTitle,
  })),

  on(setActiveSubNavItem, (state, {item}) => ({
    ...state,
    activeSubNavItem: item,
  })),

  on(setPageFilter, (state, {filter}) => ({
    ...state,
    pageFilter: filter,
  })),
);

export function reducer(state: DashboardState, action: Action) {
  return dashboardReducer(state, action);
}
