import {Injectable} from '@angular/core';
import {StrategyService} from '@app/services/strategy/strategy.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import * as StrategyActions from '@app/state/strategy/strategy.actions';
import {Observable, of} from 'rxjs';
import {catchError, filter, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {ErrorActions} from '../error';
import {StrategyState} from '@app/state/strategy/strategy.state';
import {selectStrategies} from '@app/state/strategy/strategy.selectors';

@Injectable()
export class StrategyEffects {
  fetchStrategies$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(StrategyActions.fetchStrategies),
      withLatestFrom(this.strategyStore.select(selectStrategies)),
      filter(([, strategies]) => !strategies.length),
      switchMap(() =>
        this.strategyService.fetchStrategies().pipe(
          map(response => StrategyActions.fetchStrategiesSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private strategyService: StrategyService, private strategyStore: Store<StrategyState>) {}
}
