import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'environments/environment';
import {JourneyHistoryStep} from '@app/shared/models/journey-history-step';

@Injectable({
  providedIn: 'root',
})
export class JourneyHistoryService {
  constructor(
    private http: HttpClient,
  ) {
  }

  getJourneyHistoryStepsByMandate(mandateId: number): Observable<JourneyHistoryStep[]> {
    const params = new HttpParams().set('mandateId', mandateId.toString());
    return this.http.get<JourneyHistoryStep[]>(
      `${environment.apiEndpoint}/journeys/steps`,
      {params},
    );
  }

  getCurrentJourneyHistoryStep(mandateId: number): Observable<JourneyHistoryStep> {
    return this.http.get<JourneyHistoryStep>(
      `${environment.apiEndpoint}/journeys/steps/current/${mandateId}`,
    );
  }

  createJourneyHistoryStep(url: string): Observable<JourneyHistoryStep> {
    return this.http.post<JourneyHistoryStep>(
      `${environment.apiEndpoint}/journeys/steps`,
      {url},
    );
  }
}
