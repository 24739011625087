import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {JourneyConfig} from '@app/shared/models/journey-config.model';
import {environment} from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class JourneyConfigService {
  constructor(private http: HttpClient) {}

  fetchJourneyConfigs(): Observable<JourneyConfig[]> {
    return this.http.get<JourneyConfig[]>(`${environment.apiEndpoint.replace('v1', 'v2')}/journeys/configurations`);
  }

  fetchJourneyConfigByMandateId(id: number): Observable<JourneyConfig> {
    return this.http.get<JourneyConfig>(`${environment.apiEndpoint.replace('v1', 'v2')}/journeys/configurations/mandate/${id}`);
  }
}
