import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {catchError, filter, map, switchMap, withLatestFrom} from 'rxjs/operators';

import * as DocumentActions from './document.actions';
import {
  downloadDocument,
  loadDocuments,
  loadDocumentsSuccess,
  loadDocumentTypes,
  loadDocumentTypesSuccess,
  setLoadingFalse,
  setUploadProgress,
  uploadDocument,
  uploadDocumentSuccess,
} from './document.actions';
import {DocumentService, mapDocumentFromAPIToDocumentListItem} from '@app/services/document/document.service';
import {ErrorActions} from '@app/state/error';
import {HttpEventType} from '@angular/common/http';
import {AppState} from '@app/state/root-state';
import Utils from '@app/shared/util/utils';
import {Platform} from '@angular/cdk/platform';

@Injectable()
export class DocumentEffects {
  loadDocuments$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDocuments),
      withLatestFrom(this.store.select(state => state.profile)),
      switchMap(([action, state]) =>
        this.documentService
          .fetchDocuments(state.profile.individualId, action.objectTypeId, action.isIncludeArchived)
          .pipe(
            map(response => loadDocumentsSuccess({response})),
            catchError(error => {
              this.store.dispatch(setLoadingFalse());
              return of(ErrorActions.newError({backEndError: error}));
            }),
          ),
      ),
    ),
  );

  loadDocumentTypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDocumentTypes),
      switchMap(({id}) =>
        this.documentService.fetchDocumentTypesByObjectTypeId(id).pipe(
          map(response => loadDocumentTypesSuccess({response})),
          catchError(error => {
            this.store.dispatch(setLoadingFalse());
            return of(ErrorActions.newError({backEndError: error}));
          }),
        ),
      ),
    ),
  );

  uploadDocument$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadDocument),
      switchMap(({request}) =>
        this.documentService.uploadDocument(request).pipe(
          filter(
            response => response.type === HttpEventType.UploadProgress || response.type === HttpEventType.Response,
          ),
          map(response => {
            if (response.type === HttpEventType.UploadProgress) {
              return setUploadProgress({
                progress: getLoadProgressPercentage(response.loaded, response.total),
              });
            } else if (response.type === HttpEventType.Response) {
              return uploadDocumentSuccess({
                response: mapDocumentFromAPIToDocumentListItem(response.body.document),
              });
            }
          }),
          catchError(error => {
            this.store.dispatch(setLoadingFalse());
            return of(ErrorActions.newError({backEndError: error}));
          }),
        ),
      ),
    ),
  );

  downLoadDocument$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(downloadDocument),
      switchMap(({id, contentType, fileName}) =>
        this.documentService.downloadDocument(id).pipe(
          map(response => {
            Utils.handleDownloadFile(this.platform, id, response, contentType, fileName);
            return DocumentActions.downloadDocumentSuccess();
          }),
          catchError(error => {
            this.store.dispatch(setLoadingFalse());
            return of(ErrorActions.newError({backEndError: error}));
          }),
        ),
      ),
    ),
  );

  constructor(
    private platform: Platform,
    private actions$: Actions,
    private store: Store<AppState>,
    private documentService: DocumentService,
  ) {}
}

function getLoadProgressPercentage(loaded: number, total: number): number {
  return (100 / total) * loaded;
}
