import {BankAccountValidationResponse, BankDetails} from '@app/shared/models/bank-details.model';

export const featureKey = 'bank';

export interface BankState {
  bankDetails: BankDetails;
  createBankDetailsSuccess: BankDetails;
  updateBankDetailsSuccess: BankDetails;
  updateNonUkBankDetailsSuccess: BankDetails;
  isLoading: boolean;
  loqateBankValidationResult: BankAccountValidationResponse | null;
}

export const initialState: BankState = {
  bankDetails: null,
  createBankDetailsSuccess: null,
  updateBankDetailsSuccess: null,
  updateNonUkBankDetailsSuccess: null,
  isLoading: false,
  loqateBankValidationResult: null,
};
