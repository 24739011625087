import {Profile} from '@app/shared/models/profile.model';

export const featureKey = 'profile';

export interface ProfileState {
  profile: Profile | null;
  loading: boolean;
  IsGdprAccepted?: null | boolean;
  error: any;
  updateSuccess: boolean;
}

export const initialState: ProfileState = {
  profile: null,
  loading: false,
  IsGdprAccepted: null,
  error: null,
  updateSuccess: false,
};
