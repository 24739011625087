import { Action, createReducer, on } from '@ngrx/store';
import * as InviteActions from './invite.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { APInviteData, Invite } from '@app/shared/models/invite.model';
import { initialState, State } from './state';

export interface InviteState extends EntityState<APInviteData> {
  lastLoaded: string | null;
  invite: APInviteData | null;
  loading: boolean;
}

export const adapter: EntityAdapter<APInviteData> = createEntityAdapter<APInviteData>();

const InviteReducer = createReducer(
  initialState,

  on(InviteActions.loadInvite, (state) => ({
    ...state,
    loading: true
  })),

  on(InviteActions.loadInviteSuccess, (state, { payload: invite }) => ({
    ...state,
    invite: invite,
    loading: false,
    lastLoaded: new Date().toUTCString()
  })),

  on(InviteActions.createInvite, (state) => ({
    ...state,
    loading: true
  })),

  on(InviteActions.createInviteSuccess, (state, { payload: invite }) => ({
    ...state,
    invite: invite,
    loading: false,
    lastLoaded: new Date().toUTCString()
  })),

  on(InviteActions.loadInviteFail, (state, { error }) => ({
    ...state,
    loading: false
  })),

  on(InviteActions.createInviteFail, (state, { error }) => ({
    ...state,
    loading: false
  }))
);

export function reducer(state: State, action: Action) {
  return InviteReducer(state, action);
}
