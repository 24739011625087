import {Report} from '@app/shared/models/report.model';

export const featureKey = 'report';

export interface ReportState {
  reports: Report[];
}

export const initialState: ReportState = {
  reports: [],
};
