import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {catchError, exhaustMap, filter, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {
  fetchChildTitles,
  fetchChildTitlesSuccess,
  fetchIndividual,
  fetchIndividualRelationshipTypesSuccess,
  fetchIndividualSuccess,
  fetchRelationshipTypes,
  fetchTitles,
  fetchTitlesSuccess,
  saveAcceptance,
  saveAcceptanceFailure,
  saveAcceptanceSuccess,
  updateIndividual,
  updateIndividualFailure,
  updateIndividualSuccess,
  saveIndividualSpouseRelation,
  saveIndividualSpouseRelationSuccess,
  saveIndividualSpouseRelationFailure,
  fetchIndividualSpouseRelation,
  fetchIndividualSpouseRelationSuccess,
  createIndividualPowerOfAttorney,
  createIndividualPowerOfAttorneySuccess,
  createIndividualPowerOfAttorneyFailure,
  fetchIndividualPowerOfAttorney,
  fetchIndividualPowerOfAttorneySuccess,
  createIndividualInterestedParties,
  createIndividualInterestedPartiesFailure,
  createIndividualInterestedPartiesSuccess,
  fetchIndividualInterestedParties,
  fetchIndividualInterestedPartiesSuccess,
  deleteInterestedParty,
  deleteInterestedPartySuccess,
  createAccountInstructions,
  createAccountInstructionsFailure,
  createAccountInstructionsSuccess,
  fetchAccountInstructions,
  fetchAccountInstructionsSuccess,
  fetchIncomeDetails,
  fetchIncomeDetailsSuccess,
  createIncomeDetailsSuccess,
  createIncomeDetails,
  createIncomeDetailsFailure,
  removeIncomeDetails,
  removeIncomeDetailsSuccess,
  fetchTaxableInstructionsSuccess,
  fetchTaxableInstructions,
  createInvestmentIncome,
  createInvestmentIncomeSuccess,
  createInvestmentIncomeFailure,
  fetchInvestmentIncome,
  fetchInvestmentIncomeSuccess,
  fetchPaymentFrequencyTypes,
  fetchPaymentFrequencyTypesSuccess,
  updateInvestmentIncome,
  updateInvestmentIncomeFailure,
  updateInvestmentIncomeSuccess,
  fetchIncomeTypes,
  fetchIncomeTypesSuccess,
  createAnnualSpending,
  createAnnualSpendingFailure,
  createAnnualSpendingSuccess,
  fetchAnnualSpending,
  fetchAnnualSpendingFailure,
  fetchAnnualSpendingSuccess,
  updateAnnualSpending,
  updateAnnualSpendingFailure,
  updateAnnualSpendingSuccess,
  createAssets,
  createAssetsFailure,
  createAssetsSuccess,
  fetchAssetsSuccess,
  fetchAssets,
  removeAssets,
  removeAssetsSuccess,
  fetchTaxdetails,
  fetchTaxdetailsSuccess,
  createTaxdetails,
  createTaxdetailsFailure,
  createTaxdetailsSuccess,
  updateTaxdetails,
  updateTaxdetailsFailure,
  updateTaxdetailsSuccess,
  createIsaaccountdetails,
  createIsaaccountdetailsFailure,
  createIsaaccountdetailsSuccess,
  fetchIsaaccountdetails,
  fetchIsaaccountdetailsSuccess,
  deleteIsaaccount,
  deleteIsaaccountSuccess,
  fetchMonthendTypes,
  fetchMonthendTypesSuccess,
  fetchQuarterendTypes,
  fetchQuarterendTypesSuccess,
  fetchIndividualSummary,
  fetchIndividualSummarySuccess,
  fetchIndividualOverviewSuccess,
  fetchIndividualOverview,
  completeIndividualDma,
  completeIndividualDmaSuccess,
  completeIndividualDmaFailure,
} from './individual.actions';
import {IndividualsService} from '@app/services/individuals/individuals.service';
import {AppState} from '../root-state';
import {ErrorActions} from '@app/state/error';
import {selectProfile} from '@app/state/profile/profile.selectors';
import {fetchProfileSuccess} from '@app/state/profile/profile.actions';
import {IndividualState} from '@app/state/individual/individual.state';
import {selectRelationshipTypes} from '@app/state/individual/individual.selectors';
import {PowerOfAttorneyResponse} from '@app/shared/models/individuals/individual-powerofattorney.model';
import {InterestedPartiesResponse} from '@app/shared/models/individuals/individual-interestedparties';
import {DeletedItem} from '@app/shared/models/deleted-item.model';
import {IncomeResponse, InvestmentIncome, AnnualSpending} from '@app/shared/models/individuals/individual-income';
import {Assets} from '@app/shared/models/individuals/individual-assets.model';
import {Taxdetails} from '@app/shared/models/individuals/individual-taxdetails.model';
import {Isadetails} from '@app/shared/models/individuals/individual-isadetails';

@Injectable()
export class IndividualEffects {
  fetchIndividual$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchIndividual, fetchProfileSuccess),
      withLatestFrom(this.store.select(selectProfile)),
      switchMap(([, profile]) =>
        this.individualService.fetchIndividualById(profile?.individualId).pipe(
          map(response => fetchIndividualSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  fetchTitles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchTitles),
      exhaustMap(() =>
        this.individualService.fetchTitles().pipe(
          map(response => fetchTitlesSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  fetchIndividualSpouseRelation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchIndividualSpouseRelation),
      withLatestFrom(this.store.select(selectProfile)),
      switchMap(([{mandateId}, profile]) =>
        this.individualService.fetchIndividualSpouseRelation(profile.individualId, mandateId).pipe(
          map(response => fetchIndividualSpouseRelationSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  fetchChildTitles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchChildTitles),
      exhaustMap(() =>
        this.individualService.fetchChildTitles().pipe(
          map(response => fetchChildTitlesSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  fetchRelationships$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchRelationshipTypes),
      withLatestFrom(this.individualStore.select(selectRelationshipTypes)),
      filter(([, relationshipTypes]) => !relationshipTypes.length),
      exhaustMap(() =>
        this.individualService.fetchRelationshipTypes().pipe(
          map(response => fetchIndividualRelationshipTypesSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  updateIndividual$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateIndividual),
      withLatestFrom(this.store.select(selectProfile)),
      exhaustMap(([{request}, profile]) =>
        this.individualService.patchIndividual(Number(profile.individualId), request).pipe(
          map(response => updateIndividualSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}), updateIndividualFailure({error}))),
        ),
      ),
    ),
  );

  completeIndividualDma$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(completeIndividualDma),
      withLatestFrom(this.store.select(selectProfile)),
      exhaustMap(([, profile]) =>
        this.individualService.completeIndividualDma(Number(profile.individualId)).pipe(
          map(response => completeIndividualDmaSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}), completeIndividualDmaFailure({error}))),
        ),
      ),
    ),
  );

  // updateIndividualSpouseRelation$: Observable<Action> = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(updateIndividualSpouseRelation),
  //     withLatestFrom(this.individualStore.select(selectIndividualSpouseRelation), this.store.select(selectProfile)),
  //     map(([{request, mandateId, isJointPartner}, spouseRelation, profile]) => ({
  //       spouseRelationId: spouseRelation,
  //       profile: profile,
  //       request: request,
  //       mandateId: mandateId,
  //       isJointPartner: isJointPartner
  //     })),
  //     exhaustMap(({request, spouseRelationId, profile, mandateId, isJointPartner}) =>
  //       this.individualService
  //         .patchIndividualSpouseRelation(Number(profile.individualId), Number(spouseRelationId.id), request, mandateId, isJointPartner)
  //         .pipe(
  //           map(response => updateIndividualSpouseRelationSuccess({response})),
  //           catchError(error =>
  //             of(ErrorActions.newError({backEndError: error}), updateIndividualSpouseRelationFailure({error})),
  //           ),
  //         ),
  //     ),
  //   ),
  // );

  saveIndividualSpouseRelation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(saveIndividualSpouseRelation),
      withLatestFrom(this.store.select(selectProfile)),
      exhaustMap(([{request}, profile]) =>
        this.individualService.saveIndividualSpouseRelation(Number(profile.individualId), request).pipe(
          map(response => saveIndividualSpouseRelationSuccess({response})),
          catchError(error =>
            of(ErrorActions.newError({backEndError: error}), saveIndividualSpouseRelationFailure({error})),
          ),
        ),
      ),
    ),
  );

  // save power of attorney *post*
  createIndividualPowerOfAttorney$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(createIndividualPowerOfAttorney),
      withLatestFrom(this.store.select(selectProfile)),
      exhaustMap(([{request}, profile]) =>
        this.individualService.createIndividualPowerOfAttorney(Number(profile.individualId), request).pipe(
          map((response: PowerOfAttorneyResponse) => createIndividualPowerOfAttorneySuccess({response})),
          catchError(error =>
            of(ErrorActions.newError({backEndError: error}), createIndividualPowerOfAttorneyFailure({error})),
          ),
        ),
      ),
    ),
  );
  // end
  // save power of attorney *post*
  createIndividualInterestedParties$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(createIndividualInterestedParties),
      withLatestFrom(this.store.select(selectProfile)),
      exhaustMap(([{request}, profile]) =>
        this.individualService.createIndividualInterestedParties(Number(profile.individualId), request).pipe(
          map((response: InterestedPartiesResponse) => createIndividualInterestedPartiesSuccess({response})),
          catchError(error =>
            of(ErrorActions.newError({backEndError: error}), createIndividualInterestedPartiesFailure({error})),
          ),
        ),
      ),
    ),
  );
  // end

  // get power of attorney
  fetchIndividualPowerOfAttorney$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchIndividualPowerOfAttorney),
      withLatestFrom(this.store.select(selectProfile)),
      switchMap(([, profile]) =>
        this.individualService.fetchIndividualPowerOfAttorney(profile.individualId).pipe(
          map(response => fetchIndividualPowerOfAttorneySuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );
  // end

  // get InterestedParties
  fetchIndividualInterestedParties$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchIndividualInterestedParties),
      withLatestFrom(this.store.select(selectProfile)),
      switchMap(([, profile]) =>
        this.individualService.fetchIndividualInterestedParties(profile.individualId).pipe(
          map(response => fetchIndividualInterestedPartiesSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );
  // end

  deleteInterestedParty$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteInterestedParty),
      withLatestFrom(this.store.select(selectProfile)),
      exhaustMap(([{id}, profile]) =>
        this.individualService.deleteInterestedParty(id, profile.individualId).pipe(
          map(response => deleteInterestedPartySuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  saveAcceptance$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveAcceptance),
        withLatestFrom(this.store.select(selectProfile)),
        switchMap(([action, profile]) =>
          this.individualService.saveAcceptance(profile.individualId, action.acceptance).pipe(
            map(response => saveAcceptanceSuccess({response})),
            catchError(error => of(ErrorActions.newError({backEndError: error}), saveAcceptanceFailure({error}))),
          ),
        ),
      ),
    {dispatch: true},
  );

  // save account instructions **post**
  createAccountInstructions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(createAccountInstructions),
      withLatestFrom(this.store.select(selectProfile)),
      exhaustMap(([{request}, profile]) =>
        this.individualService.createAccountInstructions(Number(profile.individualId), request).pipe(
          map((response: any) => createAccountInstructionsSuccess({response})),
          catchError(error =>
            of(ErrorActions.newError({backEndError: error}), createAccountInstructionsFailure({error})),
          ),
        ),
      ),
    ),
  );
  // end

  // save income details **post**
  createIncomeDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(createIncomeDetails),
      withLatestFrom(this.store.select(selectProfile)),
      exhaustMap(([{request}, profile]) =>
        this.individualService.createIncomeDetails(Number(profile.individualId), request).pipe(
          map((response: IncomeResponse) => createIncomeDetailsSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}), createIncomeDetailsFailure({error}))),
        ),
      ),
    ),
  );
  // end

  /** Remove address. */
  removeIncomeDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(removeIncomeDetails),
      exhaustMap(({id}) =>
        this.individualService.removeIncomeDetails(id).pipe(
          map((item: DeletedItem) => removeIncomeDetailsSuccess({response: item})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  fetchAccountInstructions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAccountInstructions),
      withLatestFrom(this.store.select(selectProfile)),
      switchMap(([, profile]) =>
        this.individualService.fetchAccountInstructions(profile.individualId).pipe(
          map(response => fetchAccountInstructionsSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  // fetch income details
  fetchIncomeDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchIncomeDetails),
      withLatestFrom(this.store.select(selectProfile)),
      switchMap(([, profile]) =>
        this.individualService.fetchIncomeDetails(profile.individualId).pipe(
          map(response => fetchIncomeDetailsSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  fetchTaxableInstructions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchTaxableInstructions),
      switchMap(() =>
        this.individualService.fetchTaxableInstructions().pipe(
          map(response => fetchTaxableInstructionsSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );
  fetchPaymentFrequencyTypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchPaymentFrequencyTypes),
      switchMap(() =>
        this.individualService.fetchPaymentFrequencyTypes().pipe(
          map(response => fetchPaymentFrequencyTypesSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  fetchIncomeTypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchIncomeTypes),
      switchMap(() =>
        this.individualService.fetchIncomeTypes().pipe(
          map(response => fetchIncomeTypesSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  // save investment income **post**
  createInvestmentIncome$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(createInvestmentIncome),
      withLatestFrom(this.store.select(selectProfile)),
      exhaustMap(([{request}, profile]) =>
        this.individualService.createInvestmentIncome(Number(profile.individualId), request).pipe(
          map((response: InvestmentIncome) => createInvestmentIncomeSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}), createInvestmentIncomeFailure({error}))),
        ),
      ),
    ),
  );
  // end

  // save AnnualSpending **post**
  createAnnualSpending$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(createAnnualSpending),
      withLatestFrom(this.store.select(selectProfile)),
      exhaustMap(([{request}, profile]) =>
        this.individualService.createAnnualSpending(Number(profile.individualId), request).pipe(
          map((response: AnnualSpending) => createAnnualSpendingSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}), createAnnualSpendingFailure({error}))),
        ),
      ),
    ),
  );
  // end

  // save AnnualSpending **get**
  fetchAnnualSpending$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAnnualSpending),
      withLatestFrom(this.store.select(selectProfile)),
      exhaustMap(([, profile]) =>
        this.individualService.fetchAnnualSpending(Number(profile.individualId)).pipe(
          map((response: AnnualSpending) => fetchAnnualSpendingSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}), fetchAnnualSpendingFailure({error}))),
        ),
      ),
    ),
  );
  // end

  // save investment income **patch**
  updateInvestmentIncome$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateInvestmentIncome),
      withLatestFrom(this.store.select(selectProfile)),
      exhaustMap(([{request}, profile]) =>
        this.individualService.updateInvestmentIncome(Number(profile.individualId), request).pipe(
          map((response: InvestmentIncome) => updateInvestmentIncomeSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}), updateInvestmentIncomeFailure({error}))),
        ),
      ),
    ),
  );
  // end
  // updateAnnualSpending **patch**
  updateAnnualSpending$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAnnualSpending),
      withLatestFrom(this.store.select(selectProfile)),
      exhaustMap(([{request}, profile]) =>
        this.individualService.updateAnnualSpending(Number(profile.individualId), request).pipe(
          map((response: AnnualSpending) => updateAnnualSpendingSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}), updateAnnualSpendingFailure({error}))),
        ),
      ),
    ),
  );
  // end

  // get investment income **get**
  fetchInvestmentIncome$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchInvestmentIncome),
      withLatestFrom(this.store.select(selectProfile)),
      exhaustMap(([, profile]) =>
        this.individualService.fetchInvestmentIncome(Number(profile.individualId)).pipe(
          map((response: InvestmentIncome) => fetchInvestmentIncomeSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );
  // end

  createAssets$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(createAssets),
      withLatestFrom(this.store.select(selectProfile)),
      exhaustMap(([{request}, profile]) =>
        this.individualService.createAssets(Number(profile.individualId), request).pipe(
          map((response: Assets) => createAssetsSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}), createAssetsFailure({error}))),
        ),
      ),
    ),
  );

  fetchAssets$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAssets),
      withLatestFrom(this.store.select(selectProfile)),
      exhaustMap(([, profile]) =>
        this.individualService.fetchAssets(Number(profile.individualId)).pipe(
          map((response: Assets) => fetchAssetsSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  /** Remove Assets. */
  removeAssets$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(removeAssets),
      exhaustMap(({id}) =>
        this.individualService.removeAssets(id).pipe(
          map((item: DeletedItem) => removeAssetsSuccess({response: item})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  fetchTaxdetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchTaxdetails),
      withLatestFrom(this.store.select(selectProfile)),
      exhaustMap(([, profile]) =>
        this.individualService.fetchTaxdetails(Number(profile.individualId)).pipe(
          map((response: Taxdetails) => fetchTaxdetailsSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  createTaxdetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(createTaxdetails),
      withLatestFrom(this.store.select(selectProfile)),
      exhaustMap(([{request}, profile]) =>
        this.individualService.createTaxdetails(Number(profile.individualId), request).pipe(
          map((response: Taxdetails) => createTaxdetailsSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}), createTaxdetailsFailure({error}))),
        ),
      ),
    ),
  );

  updateTaxdetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTaxdetails),
      withLatestFrom(this.store.select(selectProfile)),
      exhaustMap(([{request}, profile]) =>
        this.individualService.updateTaxdetails(Number(profile.individualId), request).pipe(
          map((response: Taxdetails) => updateTaxdetailsSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}), updateTaxdetailsFailure({error}))),
        ),
      ),
    ),
  );

  createIsaaccountdetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(createIsaaccountdetails),
      withLatestFrom(this.store.select(selectProfile)),
      exhaustMap(([{request}, profile]) =>
        this.individualService.createIsaaccountdetails(Number(profile.individualId), request).pipe(
          map((response: Isadetails) => createIsaaccountdetailsSuccess({response})),
          catchError(error =>
            of(ErrorActions.newError({backEndError: error}), createIsaaccountdetailsFailure({error})),
          ),
        ),
      ),
    ),
  );

  fetchIsaaccountdetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchIsaaccountdetails),
      withLatestFrom(this.store.select(selectProfile)),
      exhaustMap(([, profile]) =>
        this.individualService.fetchIsaaccountdetails(Number(profile.individualId)).pipe(
          map((response: Isadetails[]) => fetchIsaaccountdetailsSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  deleteIsaaccount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteIsaaccount),
      withLatestFrom(this.store.select(selectProfile)),
      exhaustMap(([{id}, profile]) =>
        this.individualService.deleteIsaaccount(id, profile.individualId).pipe(
          map((item: DeletedItem) => deleteIsaaccountSuccess({response: item})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  fetchMonthendTypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchMonthendTypes),
      switchMap(() =>
        this.individualService.fetchMonthendtypes().pipe(
          map(response => fetchMonthendTypesSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  fetchQuarterendTypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchQuarterendTypes),
      switchMap(() =>
        this.individualService.fetchQuarterendtypes().pipe(
          map(response => fetchQuarterendTypesSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );
  // get individual summary
  fetchIndividualSummary$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchIndividualSummary),
      withLatestFrom(this.store.select(selectProfile)),
      switchMap(([, profile]) =>
        this.individualService.fetchIndividualSummary(profile.individualId).pipe(
          map(response => fetchIndividualSummarySuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  // get individual Overview
  fetchIndividualOverview$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchIndividualOverview),
      withLatestFrom(this.store.select(selectProfile)),
      switchMap(([, profile]) =>
        this.individualService.fetchIndividualOverview(profile.individualId).pipe(
          map(response => fetchIndividualOverviewSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );
  // end

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private individualService: IndividualsService,
    private individualStore: Store<IndividualState>,
  ) {}
}
