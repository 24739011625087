import * as fromGender from './gender.reducer';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {DropDownItem} from '@app/shared/models/dropdownitem.model';

export const selectGenderState = createFeatureSelector<fromGender.GenderState>(fromGender.featureKey);

export const selectGenders = createSelector(selectGenderState, fromGender.selectAll);

export const selectGendersLoading = createSelector(selectGenderState, state => state.loading);

export const selectGenderDropdownItems = createSelector(selectGenders, genders =>
  genders.map(
    gender =>
      <DropDownItem>{
        id: gender.id,
        label: gender.value,
        value: gender.value,
      },
  ),
);
