import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {AuthStateModule} from './auth';
import {AttitudeToRiskStateModule} from './attitude-to-risk';
import {JourneyConfigStateModule} from './journey-config';
import {TaxDetailStateModule} from './tax-details';
import {OnboardingStateModule} from './onboarding';
import {PhoneStateModule} from './phone';
import {EmailStateModule} from './email';
import {BankStateModule} from './bank';
import {IndividualStateModule} from './individual';
import {AddressStateModule} from './address';
import {CountriesStateModule} from './countries';
import {RelationshipStateModule} from './relationships';
import * as fromGender from './gender/gender.reducer';
import * as fromMaritalStatus from './marital-status/marital-status.reducer';
import * as fromEmploymentStatus from './employment-status/employment-status.reducer';
import {GenderEffects} from './gender/gender.effects';
import {MartialStatusEffects} from './marital-status/marital-status.effects';
import {EmploymentStatusEffects} from './employment-status/employment-status.effects';
import {DocumentStateModule} from '@app/state/document';
import {EidvStateModule} from '@app/state/eidv/eidv-state.module';
import {ProfileStateModule} from '@app/state/profile/profile-state.module';
import {MandateStateModule} from '@app/state/mandate/mandate-state.module';
import {InviteStateModule} from '@app/state/invite/invite-state.module';
import {MessageStateModule} from '@app/state/message/message-state.module';
import {ErrorStateModule} from '@app/state/error';
import {AlertStateModule} from '@app/state/alert/alert-state.module';
import {NotificationStateModule} from '@app/state/notification/notification-state.module';
import {ProductStateModule} from '@app/state/product';
import {PortfolioStateModule} from '@app/state/portfolio';
import {StrategyStateModule} from '@app/state/strategy';
import {ConfigurationStateModule} from '@app/state/configuration';
import {IsaTransferStateModule} from '@app/state/isa-transfer';
import {DashboardStateModule} from '@app/state/dashboard/dashboard-state.module';
import {JourneyHistoryStateModule} from '@app/state/journey-history';
import {OrganisationStateModule} from '@app/state/organisation';
import {ReportStateModule} from '@app/state/report';
import {IndividualChildStateModule} from '@app/state/individual-child/individual-child-state.module';
import {UserStateModule} from '@app/state/user';
import { OverseasEmploymentStateModule } from './overseas-employment/overseas-employment-state.module';
import { LiabilitiesStateModule } from './liabilities/liabilities-state.module';
import { RetirementProvisionStateModule } from './retirement-provision/retirement-provision-state.module';
import { CurrenciesStateModule } from './currencies';
import { SourceOfFundsStateModule } from './source-of-funds/source-of-funds-state.module';
import { FinancialDependentsStateModule } from './financial-dependents/financial-dependents-state.module';



// All state modules are imported
@NgModule({
  imports: [
    CommonModule,
    AddressStateModule,
    AlertStateModule,
    AttitudeToRiskStateModule,
    AuthStateModule,
    BankStateModule,
    ConfigurationStateModule,
    CountriesStateModule,
    DashboardStateModule,
    DocumentStateModule,
    EidvStateModule,
    EmailStateModule,
    ErrorStateModule,
    IndividualChildStateModule,
    IndividualStateModule,
    InviteStateModule,
    IsaTransferStateModule,
    JourneyConfigStateModule,
    JourneyHistoryStateModule,
    MandateStateModule,
    MessageStateModule,
    NotificationStateModule,
    OnboardingStateModule,
    OrganisationStateModule,
    PhoneStateModule,
    PortfolioStateModule,
    ProductStateModule,
    ProfileStateModule,
    RelationshipStateModule,
    ReportStateModule,
    StrategyStateModule,
    TaxDetailStateModule,
    UserStateModule,
    OverseasEmploymentStateModule,
    LiabilitiesStateModule,
    RetirementProvisionStateModule,
    CurrenciesStateModule,
    SourceOfFundsStateModule,
    FinancialDependentsStateModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreModule.forFeature(fromGender.featureKey, fromGender.reducer),
    StoreModule.forFeature(fromEmploymentStatus.featureKey, fromEmploymentStatus.reducer),
    StoreModule.forFeature(fromMaritalStatus.featureKey, fromMaritalStatus.reducer),
    EffectsModule.forFeature([GenderEffects, MartialStatusEffects, EmploymentStatusEffects]),
  ],
  declarations: [],
})
export class RootStateModule {}
