import { EidvLatest } from '@app/shared/models/eidv.model';

export const featureKey = 'eidv';

export interface EidvState {
  eidv: EidvLatest | null;
}

export const initialState: EidvState = {
  eidv: null,
};
