import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MessageEffects } from './message.effects';
import * as fromMessage from './message.reducer';
import * as fromMessageState from './message.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromMessageState.messageFeatureKey, fromMessage.reducer),
    EffectsModule.forFeature([MessageEffects])
  ],
  declarations: []
})
export class MessageStateModule {}
