import {
  LoqateAddressFindItem,
  LoqateFailError,
  RawLoqateAddressFindItem,
  RawLoqateFailError,
  RawLoqateResponse,
} from '@app/shared/models/loqate.model';
import {BankAccountValidationResponse, LoqateBankAccountValidationRequest} from '@app/shared/models/bank-details.model';
import {WxlOnboardAPIError} from '@app/shared/models/error.model';
import {V2Address} from '@app/shared/models/address.model';

export function mapFromRawLoqateFailError(rawError: RawLoqateFailError): LoqateFailError {
  return {
    cause: rawError.Cause,
    description: rawError.Description,
    errorId: rawError.Error,
    resolution: rawError.Resolution,
  };
}

/** Map the Loqate fail error to WxlOnboardAPIError. */
export function mapLoqateFailErrorToWxlOnboardAPIError(error: LoqateFailError): WxlOnboardAPIError {
  return {
    title: `Loqate: ${error.description}`,
    status: Number(error.errorId),
    detail: `${error.cause} ${error.resolution}`,
    instance: null,
    type: error.description,
    extensions: [],
    validationErrors: [],
  };
}

/** Bank account utils. */
export function mapRawLoqateBankValidationResponse(
  response: RawLoqateResponse,
): BankAccountValidationResponse | LoqateFailError {
  const {Items} = response;
  const [item] = Items;
  if (item && 'CorrectedAccountNumber' in item && 'BankBIC' in item && !('Description' in item) && !('Cause' in item)) {
    return {
      accountNumber: item.CorrectedAccountNumber,
      addressLine1: item.ContactAddressLine1,
      addressLine2: item.ContactAddressLine2,
      bank: item.Bank,
      bankBIC: item.BankBIC,
      branch: item.Branch,
      branchBIC: item.BranchBIC,
      iban: item.IBAN,
      isValid: item.IsCorrect,
      postcode: item.ContactPostcode,
      result: item.StatusInformation,
      serviceError: null,
      sortCode: item.CorrectedSortCode,
      town: item.ContactPostTown,
    };
  } else if (item && 'Description' in item && 'Cause' in item) {
    return mapFromRawLoqateFailError(item);
  }
}

export function mockRawLoqateResp(request: LoqateBankAccountValidationRequest): RawLoqateResponse {
  if (request.sortCode === '11-11-11' && request.accountNumber === '12345671') {
    return <RawLoqateResponse>{
      Items: [
        {
          Bank: 'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC)',
          BankBIC: 'HLFXGB21',
          Branch: 'Ruislip (111111)',
          BranchBIC: 'G95',
          CHAPSSupported: true,
          ContactAddressLine1: 'P O Box 722',
          ContactAddressLine2: '',
          ContactFax: '',
          ContactPhone: '345 7394959',
          ContactPostTown: 'Leeds',
          ContactPostcode: 'LS1 9GG',
          CorrectedAccountNumber: '12345671',
          CorrectedSortCode: '111111',
          FasterPaymentsSupported: true,
          IBAN: 'GB77HLFX11111112345671',
          IsCorrect: true,
          IsDirectDebitCapable: true,
          StatusInformation: 'OK',
        },
      ],
    };
  }
  if (
    request.sortCode === '11-11-22' &&
    (request.accountNumber === '12345671' || request.accountNumber === '12345677')
  ) {
    return <RawLoqateResponse>{
      Items: [
        {
          Bank: '',
          BankBIC: '',
          Branch: '',
          BranchBIC: '',
          CHAPSSupported: false,
          ContactAddressLine1: '',
          ContactAddressLine2: '',
          ContactFax: '',
          ContactPhone: '',
          ContactPostTown: '',
          ContactPostcode: '',
          CorrectedAccountNumber: '',
          CorrectedSortCode: '',
          FasterPaymentsSupported: false,
          IBAN: '',
          IsCorrect: false,
          IsDirectDebitCapable: false,
          StatusInformation: 'UnknownSortCode',
        },
      ],
    };
  } else {
    return <RawLoqateResponse>{
      Items: [
        {
          Bank: 'HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC)',
          BankBIC: 'HLFXGB21',
          Branch: 'Ruislip (111111)',
          BranchBIC: 'G95',
          CHAPSSupported: false,
          ContactAddressLine1: 'P O Box 722',
          ContactAddressLine2: '',
          ContactFax: '',
          ContactPhone: '345 7394959',
          ContactPostTown: 'Leeds',
          ContactPostcode: 'LS1 9GG',
          CorrectedAccountNumber: '',
          CorrectedSortCode: '',
          FasterPaymentsSupported: false,
          IBAN: '',
          IsCorrect: false,
          IsDirectDebitCapable: false,
          StatusInformation: 'InvalidAccountNumber',
        },
      ],
    };
  }
}

/** Addresses utils. */
export function mapRawLoqateAddressFindResponse(
  response: RawLoqateResponse,
): LoqateAddressFindItem[] | LoqateFailError {
  const {Items} = response;
  const [item] = Items;
  if (item && 'Description' in item && 'Cause' in item) {
    return mapFromRawLoqateFailError(item);
  }
  if (!Items.length) {
    return [];
  } else {
    return (Items as RawLoqateAddressFindItem[])
      .filter(address => address.Type !== 'Locality')
      .map((a: RawLoqateAddressFindItem) => {
        const fullAddress = `${a.Text} ${a.Description}`;
        const numOfAddress = fullAddress.match(/\d+\sAddresses$/);
        return <LoqateAddressFindItem>{
          id: a.Id,
          type: a.Type,
          fullAddress: fullAddress.replace(/\s*-?\s*\d+\sAddresses$/, ''),
          numberOfAddresses: numOfAddress ? numOfAddress[0] : '',
          highLight: '',
        };
      });
  }
}

export function mapRawLoqateAddressRetrieveResponse(response: RawLoqateResponse): V2Address | LoqateFailError {
  const {Items} = response;
  const [item] = Items;
  if (item && 'City' in item && 'PostalCode' in item && !('Description' in item) && !('Cause' in item)) {
    return {
      id: null,
      isCurrentAddress: false,
      addressTypeId: null,
      relatedObjectId: null,
      relatedObjectTypeId: null,
      address1: item.Line1,
      address2: item.Line2,
      address3: item.Line3,
      address4: item.City,
      address5: item.AdminAreaName,
      buildingNumber: String(item.BuildingNumber),
      buildingName: item.BuildingName,
      postZipCard: item.PostalCode,
      department: item.Department,
      company: item.Company,
      description: item.Label,
      city: item.City,
      country: item.CountryName,
      countryId: null,
      subBuilding: item.SubBuilding,
      secondaryStreet: item.SecondaryStreet,
      street: item.Street,
      block: item.Block,
      neighbourhood: item.Neighbourhood,
      district: item.District,
      adminAreaName: item.AdminAreaName,
      adminAreaCode: item.AdminAreaCode,
      province: item.Province,
      provinceName: item.ProvinceName,
      provinceCode: item.ProvinceCode,
      sortingNumber1: String(item.SortingNumber1),
      sortingNumber2: String(item.SortingNumber2),
      barcode: item.Barcode,
      poBoxNumber: item.PoBoxNumber,
      dateFrom: null,
      dateTo: null,
      line1: item.Line1,
      line2: item.Line2,
      line3: item.Line3,
      line4: item.Line4,
      line5: item.Line5,
      countryIso2: item.CountryIso2,
      countryIso3: item.CountryIso3,
      countryIsoNumber: String(item.CountryIsoNumber),
      dataLevel: item.DataLevel,
    };
  } else if (item && 'Description' in item && 'Cause' in item) {
    return mapFromRawLoqateFailError(item);
  }
}
