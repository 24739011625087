import {createAction, props} from '@ngrx/store';
import {
  ClosePortfolioRequest,
  ConfirmInvite,
  ConfirmInviteSuccess,
  ConnectedManagePortfolio,
  ConnectedWrapper,
  DeclineConnectedAccountResponse,
  InvitePayload,
  InviteRequestSuccess,
  Portfolio,
  PortfoliosCharges,
  RemoveConnectedAccountResponse,
} from '@app/shared/models/portfolio/portfolio.model';
import {Patch} from '@app/shared/models/patch.model';
import {PortfolioOverview} from '@app/shared/models/portfolio/overview.model';
import {
  Contribution,
  ContributionType,
  CreatePortfolioContributionRequest,
  DeleteDirectDebitContributionResponse,
  PaymentDay,
} from '@app/shared/models/portfolio/contribution.model';
import {InvestmentsOverview} from '@app/shared/models/portfolio/investments-overview.model';
import {Activity} from '@app/shared/models/portfolio/activity';
import {InvestableProportionOption, SourceOfFundsOption} from '@app/shared/models/portfolio/source-of-funds.model';
import {PortfolioState} from '@app/state/portfolio/portfolio.state';
import { HttpErrorResponse } from '@angular/common/http';
import { PaymentSupported } from '@app/shared/models/portfolio/paymentSupported.model';

/** Fetch Portfolios. */
export const fetchPortfolios = createAction(
  '[Portfolio] Fetch Portfolios',
  props<{includeConnectedAccounts?: boolean}>(),
);

export const fetchPortfoliosSuccess = createAction(
  '[Portfolio] Fetch Portfolios Success',
  props<{response: Portfolio[]}>(),
);

/** fetch connected portfolios */
export const fetchConnectedPortfolios = createAction('[Portfolio] Fetch Connected Portfolios');

export const fetchConnectedPortfoliosSuccess = createAction(
  '[Portfolio] Fetch Connected Portfolios Success',
  props<{response: ConnectedWrapper}>(),
);

/** fetch connected manage portfolios */
export const fetchConnectedManagePortfolios = createAction('[Portfolio] Fetch Connected Manage Portfolios');

export const fetchConnectedManagePortfoliosSuccess = createAction(
  '[Portfolio] Fetch Connected Manage Portfolios Success',
  props<{response: ConnectedManagePortfolio[]}>(),
);

/** create connected portfolio invite */
export const createInvite = createAction('[Portfolio] Create Invite', props<{payload: InvitePayload}>());

export const createInviteSuccess = createAction(
  '[Portfolio API] Create Invite Success',
  props<{response: InviteRequestSuccess}>(),
);

export const resetInviteRequestSuccess = createAction('[Portfolio] Reset Invite Request Flag On State');

/** confirm invite */
export const confirmInvite = createAction('[Portfolio] Confirm Invite', props<{payload: ConfirmInvite}>());

export const confirmInviteSuccess = createAction(
  '[Portfolio API] Confirm Invite Success',
  props<{response: ConfirmInviteSuccess}>(),
);

export const resetInviteSuccess = createAction('[Portfolio] Reset Invite success Flag On State');

/** remove connected account */
export const removeConnectedAccount = createAction('[Portfolio] Remove Account', props<{id: number}>());

export const removeConnectedAccountSuccess = createAction(
  '[Portfolio API] Remove account Success',
  props<{response: RemoveConnectedAccountResponse}>(),
);

export const resetRemoveSuccess = createAction('[Portfolio] Reset Remove Request Flag On State');

/** decline connected account */
export const declineConnectedAccount = createAction('[Portfolio] Decline Account', props<{id: number}>());

export const declineConnectedAccountSuccess = createAction(
  '[Portfolio API] Decline account Success',
  props<{response: DeclineConnectedAccountResponse}>(),
);

export const resetDeclineSuccess = createAction('[Portfolio] Reset Decline Request Flag On State');

/** accept connected account */
export const acceptConnectedAccount = createAction('[Portfolio] Accept Account', props<{id: number}>());

export const acceptConnectedAccountSuccess = createAction(
  '[Portfolio API] Accept account Success',
  props<{
    response: {
      isAccepted: boolean;
    };
  }>(),
);

export const resetAcceptSuccess = createAction('[Portfolio] Reset Accept Request Flag On State');

/** Fetch Portfolios charges */
export const fetchPortfoliosCharges = createAction('[Portfolio] Fetch Portfolios Charges');

export const fetchPortfoliosChargesSuccess = createAction(
  '[Portfolio] Fetch Portfolios Charges Success',
  props<{response: PortfoliosCharges}>(),
);

/** Fetch Portfolio. */
export const fetchPortfolio = createAction('[Portfolio] Fetch Portfolio');

export const fetchPortfolioSuccess = createAction(
  '[Portfolio] Fetch Portfolio Success',
  props<{response: Portfolio}>(),
);

/** Fetch Portfolio By Id */
export const fetchPortfolioById = createAction('[Portfolio] Fetch Portfolio By Id', props<{portfolioId?: number}>());

/** Fetch portfolio funding contribution. */
export const fetchPortfolioContributionsSuccess = createAction(
  '[Portfolio] Fetch Portfolio Contribution Success',
  props<{response: Contribution}>(),
);

export const fetchPortfolioContributionTypes = createAction('[Portfolio] Fetch Portfolio Contribution Types');

export const fetchPortfolioContributionTypesSuccess = createAction(
  '[Portfolio] Fetch Portfolio Contribution Types Success',
  props<{response: ContributionType[]}>(),
);

export const fetchPortfolioContribution = createAction('[Portfolio] Fetch Portfolio Contributions');

/** Fetch Portfolio Funding Payment Days */
export const fetchPortfolioPaymentDays = createAction('[Portfolio] Fetch Portfolio Payment Days');

export const fetchPortfolioPaymentDaysSuccess = createAction(
  '[Portfolio] Fetch Portfolio Payment Days Success',
  props<{response: PaymentDay[]}>(),
);

/** Fetch portfolio overview success. */
export const fetchPortfolioOverviewSuccess = createAction(
  '[Portfolio] Fetch Portfolio Overview Success',
  props<{response: PortfolioOverview}>(),
);

/** Fetch portfolios investments. */
// export const fetchPortfoliosInvestments = createAction('[Portfolio] Fetch Portfolios Investments');
export const fetchPortfoliosInvestments = createAction(
  '[Portfolio] Fetch Portfolios Investments',
  props<{includeConnectedAccounts: boolean}>(),
);

export const fetchPortfoliosInvestmentsSuccess = createAction(
  '[Portfolio] Fetch Portfolios Investments Success',
  props<{response: InvestmentsOverview}>(),
);

// set include connected accounts toggle
export const setIncludeConnectedAccountsToggle = createAction(
  '[Portfolio] Set include connected accounts toggle',
  props<{includeConnectedAccounts: boolean}>(),
);

/** Portfolio funding contribution. */
export const createPortfolioContribution = createAction(
  '[Portfolio] Create Portfolio Funding Contribution',
  props<{request: CreatePortfolioContributionRequest}>(),
);

export const createPortfolioContributionSuccess = createAction(
  '[Portfolio] Create Portfolio Funding Contribution',
  props<{response: Contribution}>(),
);

export const updatePortfolioContribution = createAction(
  '[Portfolio] Update Portfolio Funding Contribution',
  props<{request: Patch[]}>(),
);

export const updatePortfolioContributionSuccess = createAction(
  '[Portfolio] Update Portfolio Funding Contribution Success',
  props<{response: Contribution}>(),
);

export const deletePortfolioContribution = createAction('[Portfolio] Delete Portfolio Funding Contribution');

export const deletePortfolioDirectDebitContributionSuccess = createAction(
  '[Portfolio] Delete Portfolio Funding Contribution Success',
  props<{response: DeleteDirectDebitContributionResponse}>(),
);

/** Fetch all/selected portfolio activities. */
export const fetchAllPortfolioActivities = createAction('[Portfolio] Fetch All Portfolios Activities');

export const fetchSelectedPortfolioActivities = createAction('[Portfolio] Fetch Selected Portfolios Activities');

export const fetchPortfolioActivitiesSuccess = createAction(
  '[Portfolio] Fetch Portfolio Activities Success',
  props<{response: Activity[]}>(),
);

/** Update portfolio product ID. */
export const updatePortfolioProductId = createAction('[Portfolio] Update Portfolio Product ID');

/** Portfolio strategy ID. */
export const setSelectedStrategyId = createAction('[Portfolio] Set Selected Strategy ID', props<{id: number}>());

export const updatePortfolioStrategyId = createAction('[Portfolio] Update Portfolio Strategy ID');

export const resetPortfolioStrategyId = createAction('[Portfolio] Reset Portfolio Strategy ID');

/** Update Portfolio. */
export const updatePortfolio = createAction('[Portfolio] Update Portfolio', props<{request: Patch[]}>());

export const updatePortfolioSuccess = createAction(
  '[Portfolio] Update Portfolio Success',
  props<{response: Portfolio}>(),
);

/** Set selected Product ID. */
export const setSelectedProductId = createAction('[Portfolio] Set Selected Product ID', props<{id: number}>());

/** Set selected Portfolio ID. */
export const setSelectedPortfolioId = createAction(
  '[Portfolio] Set Selected Portfolio ID',
  props<{portfolioId: number}>(),
);

export const setRestrictedPortfolio = createAction(
  '[Portfolio] Set Restricted Portfolio',
  props<{isRestricted: boolean}>(),
);

/** Reset selected Portfolio ID. */
export const clearSelectedProductId = createAction('[Portfolio] Clear Selected Product ID');

/** Clear portfolio. */
export const clearPortfolio = createAction('[Portfolio] Clear Portfolio');

/** Clear portfolio state. */
export const clearPortfolioState = createAction('[Portfolio] Clear Portfolio State');

/** Delete portfolio. */
export const deletePortfolio = createAction('[Portfolio] Delete Portfolio');

/** Close portfolio. */
export const closePortfolio = createAction('[Portfolio] Close Portfolio');
export const setClosePortfolio = createAction(
  '[Portfolio] Set Close Portfolio',
  props<{closePortfolioRequest: ClosePortfolioRequest}>(),
);

export const deletePortfolioSuccess = createAction('[Portfolio] Delete Portfolio Success', props<{response: any}>());

export const closePortfolioSuccess = createAction(
  '[Portfolio] Close Portfolio Success',
  props<{response: Portfolio}>(),
);

export const closePortfolioFailure = createAction(
  '[Portfolio] Close Portfolio Failed',
  props<{response: any}>(),
);

/** Fetch source of funds. */
export const fetchSourceOfFundsOptions = createAction('[Portfolio] Fetch Portfolio Source Of Funds');

export const fetchSourceOfFundsOptionsSuccess = createAction(
  '[Portfolio] Fetch Portfolio Source Of Funds Success',
  props<{response: SourceOfFundsOption[]}>(),
);

/** Fetch investable proportions. */
export const fetchInvestableProportionsOptions = createAction('[Portfolio] Fetch Portfolio Investable Proportions');

export const fetchInvestableProportionsOptionsSuccess = createAction(
  '[Portfolio] Fetch Portfolio Investable Proportions Success',
  props<{response: InvestableProportionOption[]}>(),
);

/** Request withdrawal from portfolio. */
export const withdrawPortfolioFunds = createAction(
  '[Portfolio] Withdraw Funds From Portfolio',
  props<{withdrawalAmount: number}>(),
);

export const withdrawPortfolioFundsSuccess = createAction(
  '[Portfolio] Withdraw Funds From Portfolio Success',
  props<{response: any}>(),
);

export const resetWithdrawFundsSuccess = createAction('[Portfolio] Reset Withdraw Request Flag On State');

export const updatePortfolioState = createAction(
  '[Portfolio] Update Properties On Portfolio State',
  props<Partial<PortfolioState>>(),
);

export const initialisePaymentValue = createAction(
  '[Portfolio] payment initialistion',
  props<{addAmount: number, portfolioId : number}>(),
);

export const initialisePaymentSuccess = createAction(
  '[Portfolio] payment initialistion success',
  props<{response: any}>(),
)

export const fetchPaymentSupported = createAction(
  '[Portfolio] Fetch Payment Support',
  props<{portfolioId : number}>(),
);

export const fetchPaymentSupportedSuccess = createAction(
  '[Portfolio] Fetch PaymentSupported Success',
  props<{response: PaymentSupported}>()
  );

export const fetchPaymentSupportedFailure = createAction(
  '[Portfolio] Fetch PaymentSupported Failure',
  props<{error: HttpErrorResponse}>(),
);


export const fetchPaymentSuccessStatus = createAction(
  '[Portfolio] Fetch Payment Success Status',
  props<{portfolioId : number, id: string, status: string, message: string}>(),
);

export const fetchPaymentSuccessResponse = createAction(
  '[Portfolio] Fetch Payment Success Response',
  props<{response: any}>());

export const fetchPaymentFailedResponse = createAction(
  '[Portfolio] Fetch Payment Failed Response',
  props<{response: any}>());

export const fetchPayment = createAction(
  '[] Fetch Payment',
  props<{paymentId:any}> ());

export const fetchPaymentSuccess = createAction(
  '[Portfolio] Fetch Payment Success',
  props<{response: any}>());

export const fetchItemisedCharges = createAction(
  '[Portfolio] Fetch itemised Charges',
  props<{portfolioId?: any}> ());

export const fetchItemisedChargesResponse = createAction(
    '[Portfolio] Fetch itemised Charges success' ,
    props<{response: any}> ());
