import {createAction, props} from '@ngrx/store';
import {
  Email,
  EmailAddress,
  EmailChangeResponse,
  EmailTypes,
  EmailChangeWithCode,
  AdditionalEmailAddress,
} from '@app/shared/models/email-address.model';
import { HttpErrorResponse } from '@angular/common/http';
import { DeletedItem } from '@app/shared/models/deleted-item.model';

// Entity
export const loadEmailAddressesForEntity = createAction(
  '[Email Addresses List] Load Email Addresses for Entity',
  props<{entityId: number}>(),
);

export const loadEmailAddressesForEntitySuccess = createAction(
  '[Email Addresses List] Load Email Addresses',
  props<{payload: EmailAddress[]}>(),
);

export const loadEmailAddressesForEntityFail = createAction(
  '[EmailAddresses API] Load Email Addresses Success',
  props<{payload: any}>(),
);

// Individual
export const loadEmailAddressesForIndividual = createAction(
  '[Email Addresses List] Load Email Addresses for Individual',
);

export const loadEmailAddressesForIndividualSuccess = createAction(
  '[EmailAddresses API] Load Email Addresses Individual Success',
  props<{response: EmailAddress[]}>(),
);

export const loadEmailAddressesForIndividualFail = createAction(
  '[EmailAddresses API] Load Email Addresses Individual Fail',
  props<{payload: any}>(),
);

// Delete
export const deleteEmailAddress = createAction(
  '[Email Addresses List] Delete Email Adress',
  props<{id: number}>(),
);

export const deleteSuccess = createAction(
  '[EmailAddresses API] Delete Email Address Success',
  props<{response: DeletedItem}>(),
);

export const deleteFail = createAction('[EmailAddresses API] Delete Email Address Fail', props<{payload: any}>());

// update/change email address with verification code
export const createEmailAddress = createAction(
  '[Add Email Address] Update Email Address',
  props<{request: EmailChangeWithCode}>(),
);

export const createEmailSuccess = createAction(
  '[EmailAddresses API] Update Email Address Success',
  props<{response: EmailChangeResponse}>(),
);

// reset update email success
export const resetUpdateEmailAddressSuccess = createAction('[Email] Reset update email Flag On State');

export const createFail = createAction('[EmailAddresses API] Create Email Address Fail', props<{payload: any}>());

// Email types
export const loadEmailTypes = createAction('[Add Email Address] Load Email Types', props<{objectTypeId?: number}>());

export const loadEmailTypesSuccess = createAction(
  '[Add Email Address] Load Email Types Success',
  props<{payload: EmailTypes[]}>(),
);

export const loadEmailTypesFail = createAction('[Add Email Address] Load Email Types Fail', props<{payload: any}>());

export const SetSelectedEmailAddressId = createAction(
  '[Email Addresses Page] Set Selected Email Address',
  props<{emailAddressId: number}>(),
);

// change email address request
export const changeEmail = createAction('[EmailAddresses API] Change Email Address', props<{payload: Email}>());

export const changeEmailSuccess = createAction(
  '[EmailAddresses API] Change Email Address Success',
  props<{response: EmailChangeResponse}>(),
);

//reset chnage email success
export const resetChangeEmailSuccess = createAction('[Email] Reset email change Request Flag On State');

// load email for user
export const getEmailsForUser = createAction('[Email Addresses List] Load Email Addresses for user');

export const getEmailsForUserSuccess = createAction(
  '[EmailAddresses API] Load Email Addresses for user Success',
  props<{response: EmailAddress[]}>(),
);

//add additional email for mcInroy user

export const createAdditionalEmailAddress = createAction(
  '[Add Additional Email Address] Add Email Address',
  props<{email: string}>(),
);

export const createAdditionalEmailAddressSuccess = createAction(
  '[EmailAddresses API] Add Additional Email Address Success',
  props<{response: EmailAddress}>(),
);

export const createAdditionalEmailAddressFail = createAction(
  '[EmailAddresses API] Add additional Email Address Fail',
  props<{error: HttpErrorResponse}>(),
);
