import {Action, createReducer, on} from '@ngrx/store';
import {AlertState, initialState} from './alert.state';
import {fetchAlertsSuccess} from './alert.actions';

const alertReducer = createReducer(
  initialState,
  on(fetchAlertsSuccess, (state, {response}) => ({...state, alerts: response})),
);

export function reducer(state: AlertState, action: Action) {
  return alertReducer(state, action);
}
