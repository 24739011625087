import {AppState} from '../root-state';
import * as EmploymentStatusActions from './employment-status.actions';
import {selectEmploymentStatuses} from './employment-status.selectors';
import {Observable, EMPTY, of} from 'rxjs';
import {createEffect, ofType, Actions} from '@ngrx/effects';
import {withLatestFrom, switchMap, catchError, map} from 'rxjs/operators';
import {Action, Store} from '@ngrx/store';
import {EmploymentStatus} from '@app/shared/models/employment-status.model';
import {EmploymentStatusService} from '@app/services/employment-status.service';
import {Injectable} from '@angular/core';
import {ErrorActions} from '@app/state/error';

@Injectable()
export class EmploymentStatusEffects {
  getEmploymentStatuses$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(EmploymentStatusActions.getEmploymentStatuses),
      withLatestFrom(this.store.select(selectEmploymentStatuses), (action, employmentStatuses) => {
        if (employmentStatuses.length > 0) {
          return EmploymentStatusActions.loadEmploymentStatusesSuccess({
            employmentStatuses,
          });
        } else {
          return EmploymentStatusActions.loadEmploymentStatuses();
        }
      }),
    ),
  );

  loadEmploymentStatuses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EmploymentStatusActions.loadEmploymentStatuses),
      switchMap(() =>
        this.employmentStatusService.getEmploymentStatuses().pipe(
          map((employmentStatuses: EmploymentStatus[]) =>
            EmploymentStatusActions.loadEmploymentStatusesSuccess({employmentStatuses}),
          ),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    );
  });
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private employmentStatusService: EmploymentStatusService,
  ) {}
}
