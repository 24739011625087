import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {CountryService} from '@app/services/country-service/country.service';
import {Observable, of} from 'rxjs';
import {catchError, filter, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {Country, Nationality} from '@app/shared/models/country.model';
import * as CountriesState from '@app/state/countries/country.state';
import {ErrorActions} from '@app/state/error';
import {fetchCountries, fetchCountriesSuccess, fetchNationalities, fetchNationalitiesSuccess} from '@app/state/countries/country.actions';
import {selectCountries, selectNationalities} from '@app/state/countries/country.selectors';

@Injectable()
export class CountriesEffects {
  fetchCollection$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchCountries),
      withLatestFrom(this.countryStore.select(selectCountries)),
      filter(([, countries]) => !countries.length),
      switchMap(() => {
        return this.countryService.getCountries().pipe(
          map((countriesArray: Country[]) => fetchCountriesSuccess({payload: countriesArray})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        );
      }),
    ),
  );

  fetchNationalities$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchNationalities),
      withLatestFrom(this.countryStore.select(selectNationalities)),
      filter(([, nationalities]) => !nationalities.length),
      switchMap(() => {
        return this.countryService.getNationalities().pipe(
          map((nationalitiesArray: Nationality[]) => fetchNationalitiesSuccess({payload: nationalitiesArray})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private countryStore: Store<CountriesState.CountryState>,
    private countryService: CountryService,
  ) {}
}
