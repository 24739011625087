import {Strategy} from '@app/shared/models/portfolio/strategy.model';

export const featureKey = 'strategy';

export interface StrategyState {
  strategies: Strategy[];
}

export const initialState: StrategyState = {
  strategies: [],
};
