import {IndividualChild} from '@app/shared/models/individuals/individual-child.model';

export const individualChildFeatureKey = 'individual-Child';

export interface IndividualChildState {
  childForCurrentMandate: IndividualChild | null;
  loading: boolean;
}

export const initialState: IndividualChildState = {
  childForCurrentMandate: null,
  loading: false,
};
