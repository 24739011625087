import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromState from './eidv.state';

export const getEidvState = createFeatureSelector<fromState.EidvState>(fromState.featureKey);

export const getEidvLatest = createSelector(
  getEidvState,
  state => {
    return state.eidv;
  }
);

