import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable, of} from 'rxjs';
import {Action, Store} from '@ngrx/store';
import {
  createIsaTransfer,
  createIsaTransferSuccess,
  deleteIsaTransfer,
  deleteIsaTransferSuccess,
  fetchIsaProviders,
  fetchIsaProvidersSuccess,
  fetchIsaTransfersSuccess,
  fetchIsaTransferTypes,
  fetchIsaTransferTypesSuccess,
  fetchIsaTypes,
  fetchIsaTypesSuccess,
} from '@app/state/isa-transfer/isa-transfer.actions';
import {catchError, filter, map, switchMap, withLatestFrom,combineLatest} from 'rxjs/operators';
import {ErrorActions} from '@app/state/error';
import {PortfolioService} from '@app/services/portfolio/portfolio.service';
import {PortfolioState} from '@app/state/portfolio/portfolio.state';
import {selectPortfolio} from '@app/state/portfolio/portfolio.selectors';
import {fetchPortfolioSuccess} from '@app/state/portfolio/portfolio.actions';
import {IsaTransferState} from '@app/state/isa-transfer/isa-transfer.state';
import {selectIsaTransfers} from '@app/state/isa-transfer/isa-transfer.selectors';
import {RouterState, selectUrl} from '@app/state/root.selectors';
import {JourneyStepEnum} from '@app/shared/enums/journey-step.enum';

@Injectable()
export class IsaTransferEffects {
  fetchIsaTransfers$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchPortfolioSuccess),
      withLatestFrom(
        this.portfolioStore.select(selectPortfolio),
        this.isaTransferStore.select(selectIsaTransfers),
        this.routerState.select(selectUrl),
      ),
      filter(([, , isaTransfers, url]) => !isaTransfers.length && url.includes(JourneyStepEnum.IsaTransfer)),
      switchMap(([, {id}]) =>
        this.portfolioService.fetchIsaTransfers(id).pipe(
          map(response => fetchIsaTransfersSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  createIsaTransfer$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(createIsaTransfer),
      withLatestFrom(this.portfolioStore.select(selectPortfolio)),
      switchMap(([{request}, {id}]) =>
        this.portfolioService.createIsaTransfer(request, id).pipe(
          map(response => createIsaTransferSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  deleteIsaTransfer$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteIsaTransfer),
      withLatestFrom(this.portfolioStore.select(selectPortfolio)),
      filter(([, portfolio]) => Boolean(portfolio)),
      switchMap(([{id}, portfolio]) =>
        this.portfolioService.deleteIsaTransfer(id, portfolio.id).pipe(
          map(response => deleteIsaTransferSuccess({id: response.id})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  fetchIsaProviders$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchIsaProviders, fetchIsaTransfersSuccess),
      switchMap(() =>
        this.portfolioService.fetchIsaProviders().pipe(
          map(response => fetchIsaProvidersSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  fetchIsaTypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchIsaTypes, fetchIsaTransfersSuccess),
      combineLatest(this.portfolioStore.select(selectPortfolio)),
      switchMap(([, portfolio]) =>
        this.portfolioService.fetchIsaTypes(portfolio?.product?.id).pipe(
          map(response => fetchIsaTypesSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  fetchIsaTransferTypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchIsaTransferTypes, fetchIsaTransfersSuccess),
      withLatestFrom(this.portfolioStore.select(selectPortfolio)),
      switchMap(([, portfolio]) =>
        this.portfolioService.fetchIsaTransferTypes(portfolio?.product?.id).pipe(
          map(response => fetchIsaTransferTypesSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private routerState: Store<RouterState>,
    private isaTransferStore: Store<IsaTransferState>,
    private portfolioService: PortfolioService,
    private portfolioStore: Store<PortfolioState>,
  ) {}
}
