import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { APInviteData } from '@app/shared/models/invite.model';
import { InviteCreate } from '@app/shared/models/invite-create.model';

@Injectable({
  providedIn: 'root',
})
export class InviteService {
  constructor(
    private http: HttpClient
  ) {}

  loadInvite(): Observable<APInviteData> {
    return this.http.get<APInviteData>(
      `${environment.apiEndpoint}/invites`
    );
  }

  createInvite(invite: InviteCreate): Observable<APInviteData> {
    return this.http.post<APInviteData>(
      `${environment.apiEndpoint}/invites`, invite
    );
  }

}
