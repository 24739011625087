import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngrx/store';
import {AuthState} from '@app/state';
import {navigateToAuthSuccessUrl, setAuthCodeFlowMode, setAuthStorageData, setAuthStorageItem} from '@app/state/auth/auth.actions';
import { AuthAction, AuthFlowType } from '@app/shared/enums/auth.enum';
import { AuthOidcService } from '@app/auth/services/auth-oidc.service';

/** 'mobile-launch' is the first route after the app is authenticated for mobile. */
@Component({
  template:
    '<div fxFlexFill fxLayoutAlign="center center"><abl-custom-loading-spinner></abl-custom-loading-spinner></div>',
})
export class MobileLaunchComponent implements OnInit {
  constructor(
    private route: ActivatedRoute, 
    private authStore: Store<AuthState.State>,
    private authService: AuthOidcService,
  ) {}

  ngOnInit() {
    const mobileParams = this.route.snapshot.paramMap.get('config');
    const lm = '2';//this.route.snapshot.paramMap.get('launchMode');

    this.authStore.dispatch(setAuthStorageData({mobileParams}));

    this.authStore.dispatch(setAuthStorageItem({key: 'launchMode', data: lm}),);
    this.authStore.dispatch(setAuthCodeFlowMode({action: AuthAction.Login, launchMode: Number(lm), authMode: AuthFlowType.Password}));
    //this.authService.setupAutomaticSilentRefreshForMobile();
    this.authStore.dispatch(navigateToAuthSuccessUrl());
  }
}
