import {Action, createReducer, on} from '@ngrx/store';
import {ConfigurationState, initialState} from '@app/state/configuration/configuration.state';
import {fetchConfigurationsSuccess, fetchConfigurationSuccess} from '@app/state/configuration/configuration.actions';


export const configurationReducer = createReducer(
  initialState,

  on(fetchConfigurationsSuccess, (state, {response}) => ({
    ...state,
    configurations: response,
  })),

  on(fetchConfigurationSuccess, (state, {response}) => ({
    ...state,
    configuration: response,
  })),
);

export function reducer(state: ConfigurationState, action: Action) {
  return configurationReducer(state, action);
}
