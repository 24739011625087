import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {StorageDialogData} from '@app/shared/models/generic.dialog';

@Component({
  selector: 'abl-storage-dialog',
  templateUrl: './storage-dialog.component.html',
  styleUrls: ['./storage-dialog.component.css']
})
export class StorageDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: StorageDialogData) {}
}
