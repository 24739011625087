import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TaxDetailsEffects } from './tax-detail.effects';
import * as fromTaxDetails from './tax-detail.reducer';
import * as fromTaxDetailsState from './tax-detail.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromTaxDetailsState.featureKey, fromTaxDetails.reducer),
    EffectsModule.forFeature([TaxDetailsEffects])
  ],
  declarations: []
})
export class TaxDetailStateModule {
}
