import {createReducer, on} from '@ngrx/store';
import {initialState} from '@app/state/portfolio/portfolio.state';
import {
  acceptConnectedAccountSuccess,
  clearPortfolio,
  clearPortfolioState,
  clearSelectedProductId,
  closePortfolioSuccess,
  confirmInviteSuccess,
  createInviteSuccess,
  createPortfolioContributionSuccess,
  declineConnectedAccountSuccess,
  deletePortfolioDirectDebitContributionSuccess,
  deletePortfolioSuccess,
  fetchConnectedManagePortfoliosSuccess,
  fetchConnectedPortfoliosSuccess,
  fetchInvestableProportionsOptionsSuccess,
  fetchPortfolioActivitiesSuccess,
  fetchPortfolioContributionsSuccess,
  fetchPortfolioContributionTypesSuccess,
  fetchPortfolioOverviewSuccess,
  fetchPortfolioPaymentDaysSuccess,
  fetchPortfoliosChargesSuccess,
  fetchPortfoliosInvestmentsSuccess,
  fetchPortfoliosSuccess,
  fetchPortfolioSuccess,
  fetchSourceOfFundsOptionsSuccess,
  removeConnectedAccountSuccess,
  resetAcceptSuccess,
  resetDeclineSuccess,
  resetInviteRequestSuccess,
  resetInviteSuccess,
  resetPortfolioStrategyId,
  resetRemoveSuccess,
  resetWithdrawFundsSuccess,
  setClosePortfolio,
  setIncludeConnectedAccountsToggle,
  setSelectedPortfolioId,
  setSelectedProductId,
  setSelectedStrategyId,
  updatePortfolio,
  updatePortfolioContributionSuccess,
  updatePortfolioState,
  updatePortfolioSuccess,
  withdrawPortfolioFundsSuccess,
  initialisePaymentSuccess,
  fetchPaymentSupportedSuccess,
  fetchPaymentSupported,
  fetchPaymentSupportedFailure,
  fetchPaymentSuccessStatus,
  fetchPaymentSuccessResponse,
  fetchPaymentFailedResponse,
  fetchPaymentSuccess,
  fetchItemisedChargesResponse,
  closePortfolioFailure
} from '@app/state/portfolio/portfolio.actions';

export const reducer = createReducer(
  initialState,

  on(updatePortfolio, state => ({
    ...state,
    loading: true,
  })),

  on(fetchPortfoliosSuccess, (state, {response}) => ({
    ...state,
    portfolios: response,
    fetchPortfolioSuccess: !!response,
  })),

  on(fetchPortfoliosChargesSuccess, (state, {response}) => ({
    ...state,
    portfoliosCharges: response,
  })),

  on(fetchConnectedPortfoliosSuccess, (state, {response}) => ({
    ...state,
    connectedPortfolios: response,
  })),

  on(fetchConnectedManagePortfoliosSuccess, (state, {response}) => ({
    ...state,
    connectedManagePortfolios: response,
  })),

  // invite step 1
  on(createInviteSuccess, (state, {response}) => ({
    ...state,
    inviteRequestSuccess: response.isSuccess,
    message: response.message,
    inviteStateChanged: true,
  })),

  on(resetInviteRequestSuccess, state => ({
    ...state,
    inviteRequestSuccess: false,
    inviteStateChanged: false
  })),

  // invite step 2
  on(confirmInviteSuccess, (state, {response}) => ({
    ...state,
    isSuccess: response.isSuccess,
    stateChanged: true,
  })),

  on(resetInviteSuccess, state => ({
    ...state,
    isSuccess: false,
    stateChanged: false,
  })),

  // remove account
  on(removeConnectedAccountSuccess, (state, {response}) => ({
    ...state,
    removeConnectedAccountSuccess: response.isDeleted,
  })),

  on(resetRemoveSuccess, state => ({
    ...state,
    removeConnectedAccountSuccess: false,
  })),

  // decline account
  on(declineConnectedAccountSuccess, (state, {response}) => ({
    ...state,
    isDeclined: response.isDeclined,
  })),

  on(resetDeclineSuccess, state => ({
    ...state,
    isDeclined: false,
  })),

  // accept account
  on(acceptConnectedAccountSuccess, (state, {response}) => ({
    ...state,
    isAccepted: response.isAccepted,
  })),

  on(resetAcceptSuccess, state => ({
    ...state,
    isAccepted: false,
  })),

  on(fetchPortfolioSuccess, (state, {response}) => ({
    ...state,
    portfolio: response,
    selectedProductId: response?.product?.id ?? null,
    selectedStrategyId: response?.strategy?.id ?? null,
  })),

  on(clearPortfolio, state => ({
    ...state,
    portfolio: null,
    selectedPortfolioId: null,
  })),

  on(fetchPortfolioContributionsSuccess, (state, {response}) => ({
    ...state,
    directDebitContribution: response,
  })),

  on(createPortfolioContributionSuccess, (state, {response}) => ({
    ...state,
    portfolio: {
      ...state.portfolio,
      investmentOverview: {
        ...state.portfolio.investmentOverview,
        taxYearContributions: state.portfolio.investmentOverview.taxYearContributions + (response?.amount || 0),
      },
    },
    directDebitContribution: response?.directDebit ? response : state.directDebitContribution,
    lumpSumContribution: !response?.directDebit ? response : state.lumpSumContribution,
    createContributionSuccess: !!response,
  })),

  on(updatePortfolioContributionSuccess, (state, {response}) => ({
    ...state,
    directDebitContribution: response?.directDebit ? response : state.directDebitContribution,
    lumpSumContribution: !response?.directDebit ? response : state.lumpSumContribution,
    updateContributionSuccess: !!response,
  })),

  on(deletePortfolioDirectDebitContributionSuccess, state => ({
    ...state,
    directDebitContribution: null,
    deleteContributionSuccess: true,
  })),

  on(fetchPortfolioContributionTypesSuccess, (state, {response}) => ({
    ...state,
    contributionTypes: response,
  })),

  on(fetchPortfolioPaymentDaysSuccess, (state, {response}) => ({
    ...state,
    paymentDays: response,
  })),

  on(fetchPortfolioOverviewSuccess, (state, {response}) => ({
    ...state,
    portfolioOverview: response,
  })),

  on(fetchPortfoliosInvestmentsSuccess, (state, {response}) => ({
    ...state,
    investmentsOverview: response,
  })),

  on(setIncludeConnectedAccountsToggle, (state, {includeConnectedAccounts}) => ({
    ...state,
    includeConnectedAccountsToggle: includeConnectedAccounts,
  })),

  on(fetchPortfolioActivitiesSuccess, (state, {response}) => ({
    ...state,
    activities: response,
  })),

  on(fetchSourceOfFundsOptionsSuccess, (state, {response}) => ({
    ...state,
    sourceOfFundsOptions: response,
  })),

  on(fetchInvestableProportionsOptionsSuccess, (state, {response}) => ({
    ...state,
    investableProportionsOptions: response,
  })),

  on(setSelectedProductId, (state, {id}) => ({
    ...state,
    selectedProductId: id,
  })),

  on(clearSelectedProductId, state => ({
    ...state,
    selectedProductId: null,
  })),

  on(setSelectedStrategyId, (state, {id}) => ({
    ...state,
    selectedStrategyId: id,
  })),

  on(setSelectedPortfolioId, (state, {portfolioId}) => ({
    ...state,
    selectedPortfolioId: portfolioId,
  })),

  on(resetPortfolioStrategyId, state => ({
    ...state,
    selectedStrategyId: state.portfolio.strategy.id,
  })),

  on(updatePortfolioSuccess, (state, {response}) => ({
    ...state,
    portfolio: response,
    loading: false,
  })),

  on(deletePortfolioSuccess, state => ({
    ...state,
    // TODO(sa) update the state once the return data model is available.
  })),

  on(closePortfolioSuccess, (state, {response}) => ({
    ...state,
    portfolio: response,
    loading: false,
    portfolioClosure: {isClosureConfirmed: response.isClosed}
  })),

  on(closePortfolioFailure, (state, {response}) => ({
    ...state,
    portfolio: response,
    loading: false,
    portfolioClosure: {isClosureConfirmed: false}
  })),

  on(setClosePortfolio, (state, {closePortfolioRequest}) => ({
    ...state,
    portfolioClosure: closePortfolioRequest,
    loading: false,
  })),

  on(withdrawPortfolioFundsSuccess, (state, {response}) => ({
    ...state,
    withdrawRequestSuccess: true,
  })),

  on(initialisePaymentSuccess, (state, {response}) => ({
    ...state,
    paymentId: response.paymentId,
    resourceToken: response.resourceToken,
    return_uri: response.returnUri,
    initialisePaymentRequestSuccess: true,
  })),


  on(resetWithdrawFundsSuccess, state => ({
    ...state,
    withdrawRequestSuccess: false,
  })),

  /** Clear portfolio state. */
  on(clearPortfolioState, state => ({
    ...state,
    portfolios: [],
    portfolio: null,
    portfolioOverview: null,
    selectedProductId: null,
    selectedStrategyId: null,
    portfolioClosure: null,
    contributionTypes: [],
    paymentDays: [],
    loading: false,
  })),

  on(updatePortfolioState, (state, newState) => ({
    ...state,
    ...newState,
  })),

  on(fetchPaymentSupported, state => ({...state, loading: true})),

  on(fetchPaymentSuccessStatus, state =>({...state, loading: true})),

  on(fetchPaymentSupportedSuccess, (state, {response}) => ({
    ...state,
    paymentSupported: response,
    loading: false,
  })),

  on(fetchPaymentSupportedFailure, state => ({...state, loading: false})),


on(fetchPaymentSuccessResponse, (state, {response}) => ({
  ...state,
  isSuccess: response.isSuccess,
  message: response.message,
})),

on(fetchPaymentFailedResponse, (state, {response}) => ({
  ...state,
  isSuccess: false,
  message: response.message,
})),

on(fetchPaymentSuccess, (state, {response}) => ({
  ...state,
  Id: response.id,
  portfolioId: response.portfolioId,
  amount: response.amount,
  eventStatus: response.eventStatus
})),

on(fetchItemisedChargesResponse, (state, {response}) => ({
  ...state,
 ItemisedCharges: response
})),

);


