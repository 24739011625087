import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import * as AttitudeToRiskActions from '@app/state/attitude-to-risk/attitude-to-risk.actions';
import {ErrorActions} from '../error';
import {AttitudeToRiskService} from '@app/services/attitude-to-risk/attitude-to-risk.service';
import {catchError, filter, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {AttitudeToRiskState} from '@app/state/attitude-to-risk/attitude-to-risk.state';
import {selectAttitudeToRiskQuestions} from '@app/state/attitude-to-risk/attitude-to-risk.selectors';

@Injectable()
export class AttitudeToRiskEffects {
  fetchAttitudeToRiskQuestions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AttitudeToRiskActions.fetchAttitudeToRiskQuestions),
     // withLatestFrom(this.attitudeToRiskStore.select(selectAttitudeToRiskQuestions)),
     // filter(([, questions]) => !questions.length),
      switchMap(() =>
        this.attitudeToRiskService.fetchAttitudeToRiskQuestions().pipe(
          map(response => AttitudeToRiskActions.fetchAttitudeToRiskQuestionsSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  resetAttitudeToRiskQuestions$: Observable<Action> = createEffect(() =>
  this.actions$.pipe(
    ofType(AttitudeToRiskActions.resetAttitudeToRiskQuestions),
    switchMap(() =>
      this.attitudeToRiskService.resetAttitudeToRiskQuestions().pipe(
        map(response => AttitudeToRiskActions.resetAttitudeToRiskQuestionsSuccess({response})),
        catchError(error => of(ErrorActions.newError({backEndError: error}))),
      ),
    ),
  ),
);

fetchRiskquestionnaireScore$:Observable<Action> = createEffect(() =>
this.actions$.pipe(
  ofType(AttitudeToRiskActions.fetchRiskquestionnaireScore),
  switchMap(() =>
    this.attitudeToRiskService.fetchRiskquestionnaireScore().pipe(
      map(response => AttitudeToRiskActions.fetchRiskquestionnaireScoreResponse({response})),
      catchError(error => of(ErrorActions.newError({backEndError: error}))),
    ),
  ),
),
)

  fetchAttitudeToRiskScore$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AttitudeToRiskActions.fetchAttitudeToRiskScore),
      switchMap(params =>
        this.attitudeToRiskService.fetchAttitudeToRiskScore(params.isNewScore).pipe(
          map(response => AttitudeToRiskActions.fetchAttitudeToRiskScoreSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  fetchCurrentAttitudeToRiskScore$: Observable<Action> = createEffect(() =>
  this.actions$.pipe(
    ofType(AttitudeToRiskActions.fetchCurrentAttitudeToRiskScore),
    switchMap(() =>
      this.attitudeToRiskService.fetchCurrentAttitudeToRiskScore().pipe(
        map(response => AttitudeToRiskActions.fetchCurrentAttitudeToRiskScoreSuccess({response})),
        catchError(error => of(ErrorActions.newError({backEndError: error}))),
      ),
    ),
  ),
);

  setAttitudeToRiskQuestionAnswer$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AttitudeToRiskActions.setAttitudeToRiskQuestionAnswer),
      switchMap(({payload}) =>
        this.attitudeToRiskService.setAttitudeToRiskQuestionAnswer(payload).pipe(
          map(response => AttitudeToRiskActions.setAttitudeToRiskQuestionAnswerSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  setAttitudeToRiskScore$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AttitudeToRiskActions.setAttitudeToRiskScore),
      switchMap(() =>
        this.attitudeToRiskService.setAttitudeToRiskScore().pipe(
          map(response => AttitudeToRiskActions.setAttitudeToRiskScoreSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private attitudeToRiskService: AttitudeToRiskService, private attitudeToRiskStore: Store<AttitudeToRiskState>) {}
}
