import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromRetirementProvision from './retirement-provision.reducer';
import * as fromRetirementProvisionState from './retirement-provision.state';
import { RetirementProvisionEffects } from './retirement-provision.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromRetirementProvisionState.featureKey, fromRetirementProvision.reducer),
    EffectsModule.forFeature([RetirementProvisionEffects])
  ],
  declarations: []
})
export class RetirementProvisionStateModule {
}
