import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable, of} from 'rxjs';
import {Action, Store} from '@ngrx/store';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {
  fetchProducts,
  fetchProductsByMandateId,
  fetchProductsByMandateIdSuccess,
  fetchProductsComparisons,
  fetchProductsComparisonsSuccess,
  fetchProductsSuccess,
  getProductDeclaration,
  getProductDeclarationSuccess,
} from '@app/state/product/product.actions';
import {ErrorActions} from '@app/state/error';
import {ProductService} from '@app/services/product/product.service';
import {PortfolioState} from '@app/state/portfolio/portfolio.state';
import {selectPortfolio} from '@app/state/portfolio/portfolio.selectors';
import {JISA_DECLARATION} from '@app/state/product/jisa-statements/jisa-product-declaration';
import {ISA_DECLARATION} from '@app/state/product/isa-statements/isa-product-declaration';

@Injectable()
export class ProductEffects {
  fetchProducts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchProducts),
      switchMap(() =>
        this.productService.fetchProducts().pipe(
          map(response => fetchProductsSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  fetchProductsByMandateId$: Observable<Action> = createEffect(() =>
  this.actions$.pipe(
    ofType(fetchProductsByMandateId),
    switchMap(({mandateId}) =>
      this.productService.fetchProductsByMandateId(mandateId).pipe(
        map(response => fetchProductsByMandateIdSuccess({response})),
        catchError(error => of(ErrorActions.newError({backEndError: error}))),
      ),
    ),
  ),
);

  fetchProductsComparisons$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchProductsComparisons),
      switchMap(() =>
        this.productService.fetchProductsComparisons().pipe(
          map(response => fetchProductsComparisonsSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  getProductsDeclaration$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(getProductDeclaration),
      withLatestFrom(this.portfolioStore.select(selectPortfolio)),
      map(([, portfolio]) =>
        getProductDeclarationSuccess({
          response: [ISA_DECLARATION, JISA_DECLARATION].find(d => d.productId === portfolio?.product.id),
        }),
      ),
    ),
  );

  constructor(
    private portfolioStore: Store<PortfolioState>,
    private actions$: Actions,
    private productService: ProductService,
  ) {}
}
