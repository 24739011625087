import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {catchError, exhaustMap, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import * as OnboardingActions from './onboarding.actions';
import {
  continueApplication,
  createNewApplication,
  endOnboardingRedirect,
  endOnboardingState,
  initOnboardingState,
  setOnboardingStateForMandate,
  updateOnboardingStateFail,
  updateOnboardingStateSuccess,
} from './onboarding.actions';
import {OnboardingStateService} from '@app/services/onboarding.service';
import {ErrorActions} from '@app/state/error';
import {AppState} from '@app/state/root-state';
import {AppOnboardingState} from '@app/shared/enums/app-onboarding-state.enum';
import {MandateState} from '@app/state/mandate/mandate.state';
import {selectOnboardingMandateId} from '@app/state/onboarding/onboarding.selectors';
import {Router} from '@angular/router';
import {JourneyStepEnum} from '@app/shared/enums/journey-step.enum';
import {clearJourneyConfigs} from '@app/state/journey-config/journey-config.actions';
import {clearJourneyHistory, fetchCurrentJourneyHistoryStep} from '@app/state/journey-history/journey-history.actions';
import {clearPortfolioState} from '@app/state/portfolio/portfolio.actions';
import {WxlOnboardAPIError} from '@app/shared/models/error.model';
import {HttpErrorResponse} from '@angular/common/http';
import {createMandate} from '@app/state/mandate/mandate.actions';

@Injectable()
export class OnboardingEffects {
  createNewApplication$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(createNewApplication),
      switchMap(() => [
        clearPortfolioState(),
        clearJourneyConfigs(),
        clearJourneyHistory(),
        initOnboardingState(),
        createMandate(),
      ]),
      tap(() => this.router.navigate([`/onboarding/${JourneyStepEnum.ProductSelection}`])),
    ),
  );

  continueApplication: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(continueApplication),
      switchMap(({mandateId}) => [
        clearPortfolioState(),
        clearJourneyConfigs(),
        clearJourneyHistory(),
        initOnboardingState(),
        setOnboardingStateForMandate({mandateId}),
        fetchCurrentJourneyHistoryStep({mandateId}),
      ]),
    ),
  );

  endOnboardingState$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(endOnboardingState),
      withLatestFrom(this.mandateStore.select(selectOnboardingMandateId)),
      map(([, mandateId]) => ({
        onboardingMandateId: mandateId,
        appState: AppOnboardingState.Complete,
        startTime: new Date(),
      })),
      exhaustMap(newOnboardingState =>
        this.onboardingStateService.updateOnboardingState(newOnboardingState).pipe(
          map(response => updateOnboardingStateSuccess({response})),
          catchError(error => of(updateOnboardingStateFail(error))),
        ),
      ),
    ),
  );

  endOnboardingRedirect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(endOnboardingRedirect),
      exhaustMap(({request}) =>
        this.onboardingStateService.createDeepLink(request).pipe(
          map(response => OnboardingActions.endOnboardingRedirectSuccess({response})),
          catchError(error =>
            of(ErrorActions.newError({backEndError: mapFirebaseHttpErrorResponseToWxlOnboardAPIError(error)})),
          ),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private onboardingStateService: OnboardingStateService,
    private mandateStore: Store<MandateState>,
    private router: Router,
  ) {}
}

export function mapFirebaseHttpErrorResponseToWxlOnboardAPIError(httpError: HttpErrorResponse): WxlOnboardAPIError {
  const {error} = httpError.error;
  return {
    title: error.status,
    status: error.code,
    detail: error.message,
    instance: null,
    type: error.status,
    extensions: [],
    validationErrors: [],
  };
}
