import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {RiskQuestionsResponse, RiskQuestionsTakenResponse, TargetRiskQuestion, TargetRiskScore} from '@app/shared/models/portfolio/target-risk.model';

@Injectable({
  providedIn: 'root',
})
export class AttitudeToRiskService {
  constructor(private http: HttpClient) {}

  /** HTTP Requests */
  fetchAttitudeToRiskQuestions(): Observable<RiskQuestionsResponse> {
    return this.http.get<RiskQuestionsResponse>(`${environment.apiEndpoint}/risk/questionnaire`);
  }
 
  resetAttitudeToRiskQuestions(): Observable<RiskQuestionsResponse> {
    return this.http.post<RiskQuestionsResponse>(`${environment.apiEndpoint}/risk/questionnaire/reset`, null);
  }
  fetchRiskquestionnaireScore(): Observable<RiskQuestionsTakenResponse> {
    return this.http.get<RiskQuestionsTakenResponse>(`${environment.apiEndpoint}/risk/questionnaire/score/check`);
  }
  
  setAttitudeToRiskQuestionAnswer(payload: {
    questionId: number;
    responseId: number;
  }): Observable<TargetRiskQuestion> {
    return this.http.post<TargetRiskQuestion>(
      `${environment.apiEndpoint}/risk/questionnaire/answer`,
      payload,
    );
  }

  setAttitudeToRiskScore(): Observable<TargetRiskScore> {
    //No request payload. Post only used to confirm risk score saved on backend.
    return this.http.post<TargetRiskScore>(`${environment.apiEndpoint}/risk/questionnaire/score/confirm`, {});
  }

  fetchAttitudeToRiskScore(isNewScore: boolean): Observable<TargetRiskScore> {
    return this.http.get<TargetRiskScore>(
      `${environment.apiEndpoint}/risk/questionnaire/score?isNewScore=${isNewScore}`,
    );
  }

  fetchCurrentAttitudeToRiskScore(): Observable<TargetRiskScore> {
    return this.http.get<TargetRiskScore>(`${environment.apiEndpoint}/risk/questionnaire/score/current`,);
  }
  /** HTTP Requests End */
}
