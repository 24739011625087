import { DocumentListItem } from '@app/shared/models/document.model';
import { DropDownItem } from '@app/shared/models/dropdownitem.model';

export const featureKey = 'document';

export interface DocumentState {
  documents: DocumentListItem[] | null;
  documentTypes: DropDownItem[] | null;
  uploadProgress: number | null;
  isLoading: boolean;
  isUploading: boolean;
}

export const initialState: DocumentState = {
  documents: null,
  documentTypes: null,
  uploadProgress: null,
  isLoading: false,
  isUploading: false
};
