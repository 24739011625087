import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EmailEffects } from './email.effects';
import * as fromEmail from './email.reducer';
import * as fromEmailState from './email.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromEmailState.featureKey, fromEmail.reducer),
    EffectsModule.forFeature([EmailEffects])
  ],
  declarations: []
})
export class EmailStateModule {}
