import {createSelector} from '@ngrx/store';
import {DropDownItem} from '@app/shared/models/dropdownitem.model';
import {MandateCategoryIdEnum} from '@app/shared/enums/mandate.enum';
import {selectDashboardState, selectMandateState} from '@app/state/root.selectors';
import {Mandate} from '@app/shared/models/mandate.model';

export const selectAllMandates = createSelector(selectMandateState, state => state.mandates);

export const selectHasMandates = createSelector(selectAllMandates, mandates => !!mandates.length);

export const selectIsPrincipal = createSelector(selectAllMandates, mandates =>
  mandates.length === 0 ? true : mandates[0]?.isPrincipal ?? true,
);

export const selectIsGDPRCompleted = createSelector(selectAllMandates, mandates => mandates[0]?.isGdprComplete);

/** Select whether there are any in progress mandates. */
export const selectHasInProgressMandate = createSelector(selectMandateState, state =>
  state?.mandates.some(m => m.mandateCategoryId === MandateCategoryIdEnum.InProgress),
);

/** Select whether there are any complete mandates. */
export const selectHasCompleteMandates = createSelector(selectMandateState, state =>
  state.mandates.some(m => m.mandateCategoryId === MandateCategoryIdEnum.Complete),
);

/** Select if there are no mandates or all mandates are in progress. */
export const selectHasOnlyInProgressMandate = createSelector(selectMandateState, state =>
  mandatesListHasOnlyInProgressMandate(state.mandates),
);
export const selectMandateTypeId = createSelector(selectMandateState, state => state.mandates);

/** Filtered by the pageFilter value in the dashboard state. */
export const selectFilteredListOfMandates = createSelector(
  selectAllMandates,
  selectDashboardState,
  (mandates, dashboardState) =>
    dashboardState.pageFilter === MandateCategoryIdEnum.All
      ? mandates
      : mandates.filter(m => m.mandateCategoryId === dashboardState.pageFilter),
);

export const selectAccountDropDownValues = createSelector(selectMandateState, state =>
  state.mandates.map(
    item =>
      <DropDownItem>{
        id: +item.id,
        label: item.mandateName,
        value: item.mandateName,
      },
  ),
);

export const selectMandatesLoading = createSelector(selectMandateState, state => state.loading);

export const selectUpdateMandateSuccess = createSelector(selectMandateState, state => state.updateSuccess);
export const selectUpdatedMandateSuccess = createSelector(selectMandateState, state => state.mandates[0]);

export const selectFinaliseMandateSuccess = createSelector(selectMandateState, state => state.updateSuccess);

export function mandatesListHasOnlyInProgressMandate(mandates: Mandate[]): boolean {
  return !mandates.length || mandates.every(m => m.mandateCategoryId === MandateCategoryIdEnum.InProgress);
}
