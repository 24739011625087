import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {catchError, exhaustMap, map, switchMap, withLatestFrom} from 'rxjs/operators';

import {
  addAddress,
  addAddressSuccess,
  fetchAddressesByIndividualId,
  fetchAddressesSuccess,
  fetchPrincipalAddress,
  fetchPrincipalAddressSuccess,
  findLoqateAddress,
  findLoqateAddressSuccess,
  removeAddress,
  removeAddressSuccess,
  retrieveLoqateAddress,
  retrieveLoqateAddressSuccess,
  updateAddress,
  updateAddressSuccess,
} from './address.actions';
import {AddressService} from '@app/services/address-service/address.service';
import {DeletedItem} from '@app/shared/models/deleted-item.model';
import {LoqateAddressService} from '@app/services/loqate/loqate-address-validation.service';
import {ErrorActions} from '@app/state/error';
import {AppState} from '@app/state/root-state';
import {selectProfile} from '@app/state/profile/profile.selectors';
import {LoqateAddressFindItem, LoqateFailError} from '@app/shared/models/loqate.model';
import {newError} from '@app/state/error/error.actions';
import {mapLoqateFailErrorToWxlOnboardAPIError} from '@app/services/loqate/loqate-utils';
import {V2Address} from '@app/shared/models/address.model';
import {OnboardingState} from '../onboarding';
import {selectOnboardingMandateId} from '../onboarding/onboarding.selectors';

@Injectable()
export class AddressEffects {
  /** Fetch addresses. */
  fetchAddressByIndividualId$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAddressesByIndividualId),
      withLatestFrom(this.store.select(selectProfile)),
      switchMap(([, profile]) =>
        this.addressService.fetchAddressesForIndividual(profile.individualId).pipe(
          map(addresses => fetchAddressesSuccess({addresses})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  /** Fetch principal addresses. */
  fetchPrincipalAddress$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchPrincipalAddress),
      withLatestFrom(this.onboardingStore.select(selectOnboardingMandateId)),
      switchMap(([{addressTypeId}, mandateId]) =>
        this.addressService.fetchPrincipalAddress(mandateId, addressTypeId).pipe(
          map(principalAddress => fetchPrincipalAddressSuccess({principalAddress})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  /** Add address. */
  addAddress$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(addAddress),
      exhaustMap(({address}) =>
        this.addressService.addAddress(address).pipe(
          map(response => addAddressSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  /** Update address. */
  updateAddress$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAddress),
      exhaustMap(({address}) =>
        this.addressService.updateAddress(address).pipe(
          map(response => addAddressSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  /** Remove address. */
  removeAddress$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(removeAddress),
      exhaustMap(({id}) =>
        this.addressService.removeAddress(id).pipe(
          map((item: DeletedItem) => removeAddressSuccess({response: item})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  /** Retrieve effects. */
  retrieveLoqateAddress$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(retrieveLoqateAddress),
      switchMap(({request}) =>
        this.loqateService.v2RetrieveAddress(request).pipe(
          map((response: V2Address | LoqateFailError) => {
            if ('errorId' in response) {
              return newError({backEndError: mapLoqateFailErrorToWxlOnboardAPIError(response)});
            } else if ('postZipCard' in response && 'address1' in response) {
              return retrieveLoqateAddressSuccess({response});
            }
          }),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  /** Find effects. */
  findLoqateAddress$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(findLoqateAddress),
      switchMap(({request}) =>
        this.loqateService.findAddress(request).pipe(
          map((response: LoqateAddressFindItem[] | LoqateFailError) => {
            if ('errorId' in response) {
              return newError({backEndError: mapLoqateFailErrorToWxlOnboardAPIError(response)});
            } else {
              return findLoqateAddressSuccess({response});
            }
          }),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private addressService: AddressService,
    private loqateService: LoqateAddressService,
    protected onboardingStore: Store<OnboardingState.OnboardingState>,
  ) {}
}
