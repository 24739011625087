import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromDocumentsState from './document.state';

export const selectDocumentsState = createFeatureSelector<fromDocumentsState.DocumentState>(fromDocumentsState.featureKey);

export const selectDocuments = createSelector(
  selectDocumentsState,
  state => state.documents
);

export const selectDocumentTypes = createSelector(
  selectDocumentsState,
  state => state.documentTypes
);

export const selectUploadProgress = createSelector(
  selectDocumentsState,
  state => state.uploadProgress
);

export const selectIsLoading = createSelector(
  selectDocumentsState,
  state => state.isLoading
);

export const selectIsUploading = createSelector(
  selectDocumentsState,
  state => state.isUploading
);
