import {Configuration} from '@app/shared/models/configuration.model';

export const featureKey = 'configuration';

export interface ConfigurationState {
  configurations: Configuration[];
  configuration: Configuration[];
}

export const initialState: ConfigurationState = {
  configurations: [],
  configuration: [],
};
