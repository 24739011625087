import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, NavigationStart, Event } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

// This guard is to try to prevent navigations which have trigger === popstate.
// In FireFox, the browser emits a popstate event on page load, which is resulting in the following:
// 1. Redirect to Auth service to log in;
// 2. Redirect to /callback
// 3. Redirect to /home
// 4. Popstate event which somehow causes redirect back to /callback
// This guard is to cancel navigation when the trigger was popstate
@Injectable({
  providedIn: 'root'
})
export class PreventAdditionalPopstateGuard{
  constructor( private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.router.events.pipe(
        filter(e => e instanceof NavigationStart),
        map(navStart => {
          if ((navStart as NavigationStart).navigationTrigger === 'popstate') {
            console.log('NavigationStart with navigationTrigger = popstate');
            return false;
          }
          return true;
        })
      );
  }

}
