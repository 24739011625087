import {IsaProvider, IsaTransfer, IsaTransferType, IsaType} from '@app/shared/models/portfolio/isa.model';

export const featureKey = 'isaTransfer';

export interface IsaTransferState {
  isaTransfers: IsaTransfer[];
  isaProviders: IsaProvider[];
  isaTypes: IsaType[];
  isaTransferTypes: IsaTransferType[];
  combinedIsaTransferValue: number | null;
}

export const initialState: IsaTransferState = {
  isaTransfers: [],
  isaProviders: [],
  isaTypes: [],
  isaTransferTypes: [],
  combinedIsaTransferValue: null,
};
