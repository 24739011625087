import {Component} from '@angular/core';
import {LoaderService} from '@app/services/loader.service';

@Component({
  selector: 'abl-loader',
  template: `
    <div class="overlay" *ngIf="loaderService.isLoading$ | async">
      <abl-custom-loading-spinner class="spinner"></abl-custom-loading-spinner>
    </div>
  `,
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  constructor(public loaderService: LoaderService) {}
}
