import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, filter, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {
  checkLogin,
  displayLogoutDialog,
  displaySessionTimeoutDialog,
  login,
  loginFailure,
  loginSuccess,
  logout,
  logoutConfirmed,
  navigateToAuthSuccessUrl,
  refreshToken,
  renewSessionTimeout,
  setAuthCodeFlowMode,
  setAuthStorageData,
  setAuthStorageItem,
  setupAutomaticSilentRefresh,
} from './auth.actions';
import {AuthOidcService} from '@app/auth/services/auth-oidc.service';
import {Observable, of} from 'rxjs';
import {environment} from '@app/../environments/environment';
import {AuthCountdownService} from '@app/auth/services/auth-countdown.service';
import {DialogService} from '@app/services/dialog/dialog.service';
import {ErrorActions} from '@app/state/error';
import {OAuthStorageService} from '@app/auth/services/oauth-storage.service';
import {OAuthErrorEvent, OAuthService, OAuthSuccessEvent} from 'angular-oauth2-oidc';
import {Action} from '@ngrx/store';
import {JourneyHistoryService} from '@app/services/journey-service/journey-history.service';
import {JourneyHistoryState} from '@app/state/journey-history/journey-history.state';

@Injectable()
export class AuthEffects {
  /** Auth error event. */
  oauthErrorEvent$: Observable<Action> = createEffect(() =>
    this.oauthService.events.pipe(
      filter(event => event instanceof OAuthErrorEvent),
      map(event => {
        if (event.type === 'invalid_nonce_in_state') {
          window.location.hash = '';
          return loginSuccess();
        }
      }),
      catchError(error => of(ErrorActions.newError({backEndError: error}))),
    ),
  );

  /** Auth token received event. */
  oauthTokenReceivedEvent$: Observable<Action> = createEffect(() =>
    this.oauthService.events.pipe(
      filter(event => event instanceof OAuthSuccessEvent && event.type === 'token_received'),
      map(() => setAuthStorageItem({key: 'refresh_token', data: this.oauthService.getRefreshToken()})),
    ),
  );

  /** Login. */
  login$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(login),
        tap(() => this.authService.login()),
      ),
    {dispatch: false},
  );

  checkLogin$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(checkLogin),
      exhaustMap(() =>
        this.oauthTokenReceivedEvent$.pipe(
          map(() => {
            window.location.hash = '';
            return loginSuccess();
          }),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  loginRedirect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loginSuccess),
      filter(() => this.router.url === '/'), // Only redirect if the route url is / (i.e. logging in, not refreshing).
      map(() => navigateToAuthSuccessUrl()),
    ),
  );

  loginErrorRedirect$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginFailure),
        mergeMap(({payload}) => payload),
        tap((err: any) => {
          if (err.error_description) {
            console.error(`Error: ${err.error_description}`);
          } else {
            console.error(`Error: ${JSON.stringify(err)}`);
          }
          this.router.navigate([this.authService.authFailureUrl]);
        }),
      ),
    {dispatch: false},
  );

  /** Logout. */
  logoutConfirmation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(logout),
      mergeMap(() => of(logoutConfirmed())),
    ),
  );

  logoutWithCustomRedirect$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logoutConfirmed),
        filter(() => this.authService.logout()),
        tap(() => this.router.navigate([this.authService.authFailureUrl])),
      ),
    {dispatch: false},
  );

  displayLogoutDialogDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(displayLogoutDialog),
        map(() => this.dialogService.openLogoutDialog()),
      ),
    {dispatch: false},
  );

  /** Session timeout. */
  renewSessionTimeout$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(renewSessionTimeout),
      map(() => this.countdownService.resetTimer()), // Reset the countdown timer.
      switchMap(() =>
        this.countdownService.timeUntilSessionTimeoutDialog$.pipe(
          filter(time => time <= 0),
          map(() => displaySessionTimeoutDialog()),
        ),
      ),
    ),
  );

  displaySessionTimeoutDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(displaySessionTimeoutDialog),
        map(() => {
          this.dialogService.openSessionTimeoutDialog();
          this.countdownService.startTimer(environment.sessionExtendTimeInSeconds);
        }),
      ),
    {dispatch: false},
  );

  /** Logout when the session dialog timer reaches 0, this happens when the user has not clicked continue. */
  logoutOnSessionTimeoutDialogCountdown$: Observable<Action> = createEffect(() =>
    this.countdownService.sessionTimeoutDialogCountdown$.pipe(
      filter(remainingSeconds => remainingSeconds <= 0),
      map(() => logoutConfirmed()),
    ),
  );

  /** Auto silent refresh. */
  setupAutomaticSilentRefresh$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setupAutomaticSilentRefresh),
        tap(() => this.oauthService.setupAutomaticSilentRefresh()), // Reset auto silent refresh timer.
      ),
    {dispatch: false},
  );

  /** Refresh token. */
  refreshToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(refreshToken),
        tap(() => this.oauthService.refreshToken()),
      ),
    {dispatch: false},
  );

  navigateAuthSuccessUrl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigateToAuthSuccessUrl),
        map(() => {
          if (environment.onBoardingOnly) {
            this.router.navigate([`/`]);
          } else {
            this.router.navigate([`/${environment.startingURL}`]);
          }
        }),
      ),
    {dispatch: false},
  );

  setAuthStorageData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setAuthStorageData),
        map(({mobileParams}) => this.oauthStorageService.setAuthData(mobileParams)),
      ),
    {dispatch: false},
  );

  setAuthStorageItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setAuthStorageItem),
        map(({key, data}) => this.oauthStorageService.setItem(key, data)),
      ),
    {dispatch: false},
  );

  setAuthCodeFlowMode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setAuthCodeFlowMode),
        map(({action, launchMode, authMode}) => this.authService.setMode(action, launchMode, authMode)),
      ),
    {dispatch: false},
  );

  constructor(
    private actions$: Actions,
    private oauthService: OAuthService,
    private authService: AuthOidcService,
    private oauthStorageService: OAuthStorageService,
    private dialogService: DialogService,
    private router: Router,
    private countdownService: AuthCountdownService,
    private journeyHistoryService: JourneyHistoryService
  ) {}
}
