import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {catchError, exhaustMap, map, mapTo, startWith, switchMap} from 'rxjs/operators';
import {interval, Observable, of} from 'rxjs';
import {ErrorActions} from '@app/state/error';
import {NotificationService} from '@app/services/notification/notification.service';
import {NotificationActions} from '@app/state/notification/index';

const SIXTY_SECONDS = 60 * 1000;

@Injectable()
export class NotificationEffects {
  loadAlerts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.loadNotifications),
      switchMap(action$ => interval(SIXTY_SECONDS).pipe(startWith(0), mapTo(action$))),
      exhaustMap(() =>
        this.notificationService.getNotificationSummary().pipe(
          map(response => NotificationActions.loadNotificationsSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private notificationService: NotificationService) {}
}
