import {Alert} from '@app/shared/models/alert.model';

export const alertFeatureKey = 'alert';

export interface AlertState {
  alerts: Alert[];
}

export const initialState: AlertState = {
  alerts: [],
};
