import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { Patch } from '@app/shared/models/patch.model';
import { Liabilities, LiabilitiesResponse } from '@app/shared/models/liabilities.model';
import { DeletedItem } from '@app/shared/models/deleted-item.model';

export const saveLiabilitiesDetails = createAction(
  '[Liabilities/API] Save Liabilities Details',
  props<{request: Liabilities}>());

export const saveLiabilitiesSuccess = createAction(
  '[Liabilities/API] Save Liabilities Details Success',
  props<{response : any }>()
);
export const saveLiabilitiesFail = createAction(
  '[Liabilities/API] Save Liabilities Details Fail',
  props<{ error: any }>()
);
export const fetchLiabilitiesDetails = createAction('[Liabilities Details] Fetch Liabilities Details');

export const fetchLiabilitiesSuccess = createAction(
  '[Liabilities Details] Fetch Liabilities Success',
  props<{response: any}>(),
);

export const fetchLiabilitiesFail = createAction(
  '[Liabilities Details] Fetch Liabilities Fail',
  props<{error: HttpErrorResponse}>(),
);

export const deleteLiabilities = createAction('[Liabilities Details] Update Liabilities Details', props<{id: number}>());

export const deleteLiabilitiesSuccess = createAction(
  '[Liabilities Details] Update Liabilities Details Success',
  props<{response: DeletedItem}>(),
);

export const deleteLiabilitiesFailure = createAction(
  '[Liabilities Details] Update Liabilities Details Failure',
  props<{error: HttpErrorResponse}>(),
);

export const resetDeleteLiabilitiesSuccess = createAction(
  '[Delete Liabilities] Reset Delete Liabilities Success',
);

