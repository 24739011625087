import {Injectable} from '@angular/core';
import {BehaviorSubject, interval, Subject, timer} from 'rxjs';
import {filter, map, switchMap, take, takeUntil, takeWhile} from 'rxjs/operators';
import {environment} from '@app/../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthCountdownService {
  private oneSecondDelay = 1000;
  private originalCountdownDurationSeconds: number;

  private started$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private reset$: Subject<void> = new Subject<void>();

  /** Remaining seconds to be shown in the session timeout dialog. */
  private remainingSeconds$ = timer(0, this.oneSecondDelay).pipe(
    map(timerSeconds => this.calculateRemainingSeconds(timerSeconds)),
    takeWhile(remainingSeconds => remainingSeconds >= 0),
    takeUntil(this.reset$),
  );

  /** Time until session timeout dialog Timer, e.g the amount of time we want to pass before the dialog is shown. */
  timeUntilSessionTimeoutDialog$ = interval(this.oneSecondDelay).pipe(
    take(environment.sessionTimeoutInSeconds),
    map(time => environment.sessionTimeoutInSeconds - 1 - time),
  );

  /** Session timeout dialog countdown - seconds. */
  sessionTimeoutDialogCountdown$ = this.started$.pipe(
    filter(started => started),
    switchMap(() => this.remainingSeconds$),
  );

  startTimer(seconds: number) {
    this.originalCountdownDurationSeconds = seconds;
    this.started$.next(true);
  }

  resetTimer() {
    this.started$.next(false);
    this.reset$.next();
  }

  private calculateRemainingSeconds(seconds: number): number {
    return this.originalCountdownDurationSeconds - seconds;
  }
}
