import {Directive, HostListener} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
  selector: '[ablUppercaseFormCtrl]',
})
export class UppercaseFormCtrlDirective {
  constructor(public control: NgControl) {}

  @HostListener('input', ['$event.target'])
  private onInput(input: HTMLInputElement) {
    const cursorPosition = input.selectionStart;
    this.control.control.setValue(input.value.toUpperCase());
    input.setSelectionRange(cursorPosition, cursorPosition);
  }
}
