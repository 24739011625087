import * as PhoneActions from './phone.actions';
import * as PhoneState from './phone.state';
import * as PhoneSelectors from './phone.selectors';

export * from './phone-state.module';

export {
  PhoneActions,
  PhoneState,
  PhoneSelectors
};
