import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';

import * as EmailActions from './email.actions';
import { EmailAddressService } from '@app/services/email-address.service';
import { EmailAddress, EmailTypes } from '@app/shared/models/email-address.model';
import { ErrorActions } from '@app/state/error';
import { IndividualState } from '@app/state/individual/individual.state';
import { selectIndividual } from '@app/state/individual/individual.selectors';
import { AppState } from '../root-state';
import { selectProfile } from '../profile/profile.selectors';

@Injectable()
export class EmailEffects {
  loadCollectionForEntity$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(EmailActions.loadEmailAddressesForEntity),
      exhaustMap(() =>
        this.emailAddressService.getEmailAddressesEntities().pipe(
          map((emailAddresses: EmailAddress[]) =>
            EmailActions.loadEmailAddressesForEntitySuccess({ payload: emailAddresses }),
          ),
          catchError(error => of(ErrorActions.newError({ backEndError: error }))),
        ),
      ),
    ),
  );

  loadCollectionForIndividual$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(EmailActions.loadEmailAddressesForIndividual),
      withLatestFrom(this.individualStore.select(selectIndividual)),
      exhaustMap(([, individual]) =>
        this.emailAddressService.getEmailAddressesForIndividual(individual.id).pipe(
          map((emailAddresses: EmailAddress[]) =>
            EmailActions.loadEmailAddressesForIndividualSuccess({ response: emailAddresses }),
          ),
          catchError(error => of(ErrorActions.newError({ backEndError: error }))),
        ),
      ),
    ),
  );

  getEmailsForUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(EmailActions.getEmailsForUser),
      exhaustMap(() =>
        this.emailAddressService.getEmailsForUser().pipe(
          map((emailAddresses: EmailAddress[]) =>
            EmailActions.getEmailsForUserSuccess({ response: emailAddresses }),
          ),
          catchError(error => of(ErrorActions.newError({ backEndError: error }))),
        ),
      ),
    ),
  );

  deleteEmailAddress$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(EmailActions.deleteEmailAddress),
      exhaustMap(({id}) =>
        this.emailAddressService.deleteEmailAddress(id).pipe(
          map(deletedEmailAddress => EmailActions.deleteSuccess({response : deletedEmailAddress })),
          catchError(error => of(ErrorActions.newError({ backEndError: error }))),
        ),
      ),
    ),
  );

  createEmailAddress$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(EmailActions.createEmailAddress),
      exhaustMap(({ request }) =>
        this.emailAddressService.confirmEmailAddress(request).pipe(
          map(EmailChangeResponse => EmailActions.createEmailSuccess({ response: EmailChangeResponse })),
          catchError(error => of(ErrorActions.newError({ backEndError: error }))),
        ),
      ),
    ),
  );

  changeEmail$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(EmailActions.changeEmail),
      exhaustMap(({ payload }) =>
        this.emailAddressService.changeEmailAddress(payload).pipe(
          map(EmailChangeResponse => EmailActions.changeEmailSuccess({ response: EmailChangeResponse })),
          catchError(error => of(ErrorActions.newError({ backEndError: error }))),
        ),
      ),
    ),
  );

  loadEmailTypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(EmailActions.loadEmailTypes),
      exhaustMap(({ objectTypeId }) =>
        this.emailAddressService.getEmailTypes(objectTypeId).pipe(
          map((emailTypes: EmailTypes[]) => EmailActions.loadEmailTypesSuccess({ payload: emailTypes })),
          catchError(error => of(ErrorActions.newError({ backEndError: error }))),
        ),
      ),
    ),
  );
  createAdditionalEmailAddress$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(EmailActions.createAdditionalEmailAddress),
      withLatestFrom(
        this.store.select(selectProfile),
      ),
      exhaustMap(([{email }, profile]) =>
        this.emailAddressService.createAdditionalEmailAddress({emailAddress: email, relatedObjectId: profile.individualId, emailAddressTypeId: 5, relatedObjectTypeId: 1 }).pipe(
          map(response => EmailActions.createAdditionalEmailAddressSuccess({response})),
          catchError(error => of(ErrorActions.newError({ backEndError: error }))),
        ),
      ),
    ),
  );
  constructor(
    private actions$: Actions,
    private emailAddressService: EmailAddressService,
    private individualStore: Store<IndividualState>,
    private store: Store<AppState>
  ) { }
}
