import {createAction, props} from '@ngrx/store';
import {Onboarding, ViewWrapperData} from '@app/shared/models/onboarding-state.model';
import {DeepLink} from '@app/shared/models/deeplink-model';
import {DeepLinkCreateRequest} from '@app/shared/models/deeplink-create.model';
import {HttpErrorResponse} from '@angular/common/http';

export const initOnboardingState = createAction('[Onboarding IndividualState UI] Init Onboarding IndividualState');

export const setOnboardingStateForMandate = createAction(
  '[Onboarding IndividualState UI] Set Onboarding IndividualState',
  props<{mandateId: number}>(),
);

export const reinitialiseOnboardingState = createAction(
  '[Onboarding IndividualState UI] Reinitialise Onboarding IndividualState',
);

export const endOnboardingState = createAction('[Onboarding IndividualState UI] End Onboarding IndividualState');

export const updateOnboardingStateFail = createAction(
  '[Onboarding IndividualState API] Update Onboarding IndividualState Fail',
  props<{error: HttpErrorResponse}>(),
);

export const updateOnboardingStateSuccess = createAction(
  '[Onboarding IndividualState API] Update Onboarding IndividualState Success',
  props<{response: Onboarding}>(),
);

export const endOnboardingRedirect = createAction(
  '[Onboarding IndividualState UI] End Onboarding Redirect',
  props<{request: DeepLinkCreateRequest}>(),
);

export const endOnboardingRedirectFail = createAction(
  '[Onboarding IndividualState API] End Onboarding Redirect Fail',
  props<{error: HttpErrorResponse}>(),
);

export const endOnboardingRedirectSuccess = createAction(
  '[Onboarding] End Onboarding Redirect Success',
  props<{response: DeepLink}>(),
);

/** Set is next disabled. */
export const setIsNextDisabled = createAction('[Onboarding] Set Is Next Disabled', props<{isNextDisabled: boolean}>());

/** Set is previous disabled. */
export const setIsPreviousDisabled= createAction('[Onboarding] Set Is Previous Disabled', props<{isPreviousDisabled: boolean}>());

/** View wrapper data. */
export const setViewWrapperData = createAction(
  '[Onboarding] Set View Heading And Description',
  props<{viewWrapperData: ViewWrapperData}>(),
);

/** Create a new application. */
export const createNewApplication = createAction('[Onboarding] Create New Application');

/** Continue an existing application. */
export const continueApplication = createAction('[Onboarding] Continue Application', props<{mandateId: number}>());
