import {ProductDeclaration} from '@app/shared/models/portfolio/product.model';
import {ProductCodeEnum, ProductIdEnum} from '@app/shared/enums/product.enum';

const jisaDeclarationTemplate = `
<p>Our custodian, Pershing Securities Limited, manages the MWise Stocks & Shares JISA. Please confirm the declaration below.
  <br>
  <a href="https://mattioliwoods.com/MWise-FAQs#Regulatory" target="_blank">Find out more here</a>.
</p>

<strong>I authorise Pershing Securities Limited to act as the Junior ISA Plan Manager and I declare that:</strong>

<ul>
  <li>The child on whose behalf I am opening the Junior ISA will be the beneficial owner.</li>
  <li>I am 18 years of age or over.</li>
  <li>I have parental responsibility for the child.</li>
  <li>I will be the registered contact for the JISA.</li>
  <li>The child is resident in the UK, or is a UK Crown servant, a dependent of a UK Crown servant or is married to/in a civil partnership with a UK Crown servant.</li>
  <li>I have not subscribed and will not subscribe to another JISA of this type for this child.</li>
  <li>I am not aware that this child has another JISA of this type.</li>
  <li>I am not aware of other JISA subscriptions that will result in this child exceeding the annual limit.</li>
  <li>I will not knowingly make subscriptions to JISAs for this child that will result in the subscription limit being exceeded.</li>
</ul>
<strong>I authorise Pershing Securities Limited:</strong>
<ul>
  <li>To hold the child’s subscriptions, JISA investments, interest, dividends and any other rights or proceeds in respect of those investments and cash, and to make on the child’s behalf any claims to relief from tax in respect of JISA investments.</li>
</ul>`;

export const JISA_DECLARATION: ProductDeclaration = {
  productId: ProductIdEnum.Jisa,
  productCode: ProductCodeEnum.Jisa,
  body: jisaDeclarationTemplate,
};
