import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Profile} from '@app/shared/models/profile.model';
import {environment} from 'environments/environment';
import {Patch} from '@app/shared/models/patch.model';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private http: HttpClient) {}

  fetchProfile(): Observable<Profile> {
    return this.http.get<Profile>(`${environment.apiEndpoint}/profiles`);
  }

  updateProfile(request: Patch[]): Observable<Profile> {
    return this.http.patch<Profile>(`${environment.apiEndpoint}/profiles`, request);
  }
}
