import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromStrategyState from '@app/state/strategy/strategy.state';
import {DropDownItem} from '@app/shared/models/dropdownitem.model';

export const getStrategyState = createFeatureSelector<fromStrategyState.StrategyState>(fromStrategyState.featureKey);

/** Select strategies. */
export const selectStrategies = createSelector(getStrategyState, state => state.strategies);

/** Select strategies dropdown items. */
export const selectStrategiesDropdownItems = createSelector(selectStrategies, strategies =>
  strategies.map(
    strategy =>
      <DropDownItem>{
        id: strategy.id,
        label: strategy.strategyTitle,
        value: strategy.strategyCode,
      },
  ),
);
