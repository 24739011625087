import { createAction, props } from '@ngrx/store';
import { MaritalStatus } from '@app/shared/models/marital-status.model';
export const getMaritalStatuses = createAction(
  '[MaritalStatus/API] Get MaritalStatuses'
);
export const loadMaritalStatuses = createAction(
  '[MaritalStatus/API] Load MaritalStatuses'
);
export const loadMaritalStatusesSuccess = createAction(
  '[MaritalStatus/API] Load MaritalStatuses Success',
  props<{ maritalStatuses: MaritalStatus[] }>()
);
export const loadMaritalStatusesFail = createAction(
  '[MaritalStatus/API] Load MaritalStatuses Fail',
  props<{ error: any }>()
);
export const clearMaritalStatuses = createAction(
  '[MaritalStatus/API] Clear MaritalStatuss'
);
