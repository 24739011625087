import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ErrorEffects } from './error.effects';
import * as fromError from './error.reducer';
import * as fromErrorState from './error.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromErrorState.featureKey, fromError.reducer),
    EffectsModule.forFeature([ErrorEffects])
  ],
  declarations: []
})
export class ErrorStateModule {
}
