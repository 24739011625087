import {Country} from '@app/shared/models/country.model';
import {Currency} from '@app/shared/models/portfolio/currency.model';

export interface CurrenciesState {
  currencies: Currency[];
  loading: boolean;
}

export const featureKey = 'currencies';

export const initialState: CurrenciesState = {
  currencies: [],
  loading: true,
};
