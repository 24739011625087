import {createSelector} from '@ngrx/store';
import {DashboardPagesEnum} from '@app/shared/enums/dashboard-pages.enum';
import {selectDashboardState, selectMandateState} from '@app/state/root.selectors';
import {mandatesListHasOnlyInProgressMandate} from '@app/state/mandate/mandate.selectors';

/** Select root nav items. */
export const selectRootNavItems = createSelector(selectDashboardState, selectMandateState, (state, mandateState) =>
  mandatesListHasOnlyInProgressMandate(mandateState.mandates)
    ? state.rootNavItems.filter(
        n => n.path === DashboardPagesEnum.Applications || n.path === DashboardPagesEnum.Support,
      )
    : state.rootNavItems,
);

/** Select page title. */
export const selectPageTitle = createSelector(selectDashboardState, state => state.pageTitle);

/** Select sub nav items. */
export const selectSubNavItems = createSelector(selectDashboardState, state => state.subNavItems);

/** Select active sub nav item. */
export const selectActiveSubNavItem = createSelector(selectDashboardState, state => state.activeSubNavItem);

/** Select page filter. */
export const selectPageFilter = createSelector(selectDashboardState, state => state.pageFilter);
