import {Injectable} from '@angular/core';
import {Actions, ofType, createEffect} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {catchError, map, exhaustMap, withLatestFrom} from 'rxjs/operators';

import * as PhoneActions from './phone.actions';
import {PhoneService} from '@app/services/phone-services/phone.service';
import {ErrorActions} from '@app/state/error';
import {AppState} from '@app/state/root-state';
import {selectProfile} from '@app/state/profile/profile.selectors';

@Injectable()
export class PhoneEffects {
  listPhoneNumbersByIndividualId: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PhoneActions.listPhoneNumbersByIndividualId),
      withLatestFrom(this.store.select(selectProfile)),
      exhaustMap(([, profile]) =>
        this.phoneService.listPhoneNumbersByIndividualId(profile.individualId).pipe(
          map(
            phoneNumbers => PhoneActions.listPhoneNumbersByIndividualIdSuccess({response: phoneNumbers}),
            catchError(error => of(ErrorActions.newError({backEndError: error}))),
          ),
        ),
      ),
    ),
  );

  listPhoneNumberTypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PhoneActions.listPhoneNumberTypes),
      exhaustMap(({id}) =>
        this.phoneService.listPhoneNumberTypes(id).pipe(
          map(phoneNumberTypes => PhoneActions.listPhoneNumberTypesSuccess({response: phoneNumberTypes})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  createPhoneNumber$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PhoneActions.createPhoneNumber),
      withLatestFrom(this.store.select(selectProfile)),
      exhaustMap(([{request}, profile]) => {
        return this.phoneService.createPhoneNumber({...request, relatedObjectId: profile.individualId}).pipe(
          map(phoneNumber => PhoneActions.createPhoneNumberSuccess({response: phoneNumber})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        );
      }),
    ),
  );

  deletePhoneNumber$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PhoneActions.deletePhoneNumber),
      exhaustMap(({id}) =>
        this.phoneService.deletePhoneNumber(id).pipe(
          map(deletedPhoneNumber => PhoneActions.deletePhoneNumberSuccess({response: deletedPhoneNumber})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private store: Store<AppState>, private phoneService: PhoneService) {}
}
