import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {catchError, exhaustMap, map, withLatestFrom} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import * as TaxDetailsActions from './tax-details.actions';
import {TaxDetailService} from '@app/services/tax-details-service/tax-details.service';
import {ErrorActions} from '@app/state/error';
import {selectProfile} from '@app/state/profile/profile.selectors';
import {AppState} from '@app/state/root-state';

@Injectable()
export class TaxDetailsEffects {
  loadCollectionIndividual$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TaxDetailsActions.fetchTaxDetailsByIndividualId),
      withLatestFrom(this.store.select(selectProfile)),
      exhaustMap(([, profile]) =>
        this.taxDetailService.fetchTaxDetailsByIndividualId(profile.individualId).pipe(
          map(taxDetails => TaxDetailsActions.fetchTaxDetailsByIndividualIdSuccess({response: taxDetails})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  removeTaxDetail$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TaxDetailsActions.removeTaxDetail),
      exhaustMap(({id}) =>
        this.taxDetailService.removeTaxDetail(id).pipe(
          map(response => TaxDetailsActions.removeTaxDetailSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  createTaxDetail$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TaxDetailsActions.createTaxDetail),
      withLatestFrom(this.store.select(selectProfile)),
      exhaustMap(([{request}, profile]) => {
        return this.taxDetailService.createTaxDetail({...request, relatedObjectId: profile.individualId}).pipe(
          map(response => TaxDetailsActions.createTaxDetailSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        );
      }),
    ),
  );

  constructor(private actions$: Actions, private store: Store<AppState>, private taxDetailService: TaxDetailService) {}
}
