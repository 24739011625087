import { createAction, props } from '@ngrx/store';
import { APInviteData } from '@app/shared/models/invite.model';
import { InviteCreate } from '@app/shared/models/invite-create.model';

export const loadInviteSuccess = createAction(
  '[Invite] Load Invite Success',
  props<{ payload: APInviteData }>()
);

export const loadInviteFail = createAction(
  '[Invite] Load Invite Fail',
  props<{ error: any }>()
);

export const createInviteSuccess = createAction(
  '[Invite] Create Invite Success',
  props<{ payload: APInviteData }>()
);

export const createInviteFail = createAction(
  '[Invite] Create Invite Fail',
  props<{ error: any }>()
);

export const loadInvite = createAction(
  '[Invite] Load Latest Invite'
);

export const createInvite = createAction(
  '[Invite] Create Invite',
  props<{ invite: InviteCreate }>()
);
