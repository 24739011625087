import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import * as fromIsaTransferState from './isa-transfer.state';
import {EffectsModule} from '@ngrx/effects';
import {IsaTransferEffects} from './isa-transfer.effects';
import * as fromIsaTransfer from './isa-transfer.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromIsaTransferState.featureKey, fromIsaTransfer.reducer),
    EffectsModule.forFeature([IsaTransferEffects]),
  ],
})
export class IsaTransferStateModule {}
