import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {GenericDialogData} from '@app/shared/models/generic.dialog';

@Component({
  selector: 'abl-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.css']
})
export class GenericDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: GenericDialogData) {}
}
