import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {DashboardEffects} from './dashboard.effects';
import * as fromDashboard from './dashboard.reducer';
import * as fromDashboardState from './dashboard.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromDashboardState.featureKey, fromDashboard.reducer),
    EffectsModule.forFeature([DashboardEffects]),
  ],
  declarations: [],
})
export class DashboardStateModule {}
