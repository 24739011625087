import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {TenantService} from '@app/services/tenant.service';
import {Title} from '@angular/platform-browser';
import {environment} from '../environments/environment';
import {DOCUMENT, Location} from '@angular/common';
import {AuthState} from '@app/state';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {checkLogin, renewSessionTimeout} from '@app/state/auth/auth.actions';
import {filter, takeUntil, tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

/** Root app component. */
@Component({
  selector: 'abl-root',
  templateUrl: './app.component.html',
  styleUrls: ['app.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  isProduction = this.tenantService.isEnvironmentProduction;
  private destroy$ = new Subject();
  private setPageTitle$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    tap(() => {
      let route: ActivatedRoute = this.router.routerState.root;
      while (route!.firstChild) {
        route = route.firstChild;
      }
      const title = route!.snapshot.data['title'];
      this.titleService.setTitle(title ? `${environment.applicationName} - ${title}` : environment.applicationName);
    }),
    takeUntil(this.destroy$),
  );

  constructor(
    @Inject(DOCUMENT) private document: HTMLDocument,
    private titleService: Title,
    private router: Router,
    private tenantService: TenantService,
    private authStore: Store<AuthState.State>,
    private location: Location,
    public translate: TranslateService,
  ) {
    translate.addLangs(['en', 'fr']);
    translate.setDefaultLang('en');
    translate.currentLang = 'en';
  }

  @HostListener('click')
  clickEvent() {
    this.handleRenewSessionTimeout();
  }

  @HostListener('window:keyup')
  keyupEvent() {
    this.handleRenewSessionTimeout();
  }

  ngOnInit() {
    this.setPageTitle$.subscribe();
    /** Kick off the session timeout. */
    this.authStore.dispatch(renewSessionTimeout());

    this.authStore.dispatch(checkLogin());

    this.document
      .getElementById('favicon')
      .setAttribute('href', `../assets/${this.tenantService.getTenant}/favicon/favicon.ico`);
  }

  ngOnDestroy() {
    // Trigger unsubscribe from all subscriptions when component is destroyed.
    this.destroy$.next();
    this.destroy$.complete();
  }

  private handleRenewSessionTimeout() {
    if (this.location.path() !== '/launch') {
      this.authStore.dispatch(renewSessionTimeout());
    }
  }
}
