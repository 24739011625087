import { on, createReducer, Action } from '@ngrx/store';
import { initialState, TaxDetailState } from './tax-detail.state';
import * as TaxDetailActions from './tax-details.actions';

const taxDetailReducer = createReducer(
  initialState,

  on(TaxDetailActions.fetchTaxDetailsByIndividualIdSuccess, (state, {response}) => ({
    ...state,
    taxDetails: response
  })),

  on(TaxDetailActions.removeTaxDetail, (state, {id}) => ({
      ...state,
      taxDetails: state.taxDetails.filter(x => x.id !== id)
  })),

  on(TaxDetailActions.createTaxDetailSuccess, (state, {response}) => ({
    ...state,
    taxDetails: state.taxDetails.concat(response),
  }))

);

export function reducer(state: TaxDetailState, action: Action) {
  return taxDetailReducer(state, action);
}
