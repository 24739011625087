export enum RootPagesEnum {
  Onboarding = 'onboarding',
  Risk = 'risk-assessment',
  Lab = 'lab',
  Payment = 'payments',
  Start = 'start',
}

export enum DashboardPagesEnum {
  Accounts = 'accounts',
  Applications = 'applications',
  Documents = 'documents',
  Messages = 'messages',
  Notifications = 'notifications',
  Profile = 'profile',
  Support = 'help-support',
}

export enum AccountsPagesEnum {
  Activity = 'activity',
  InvestMore = 'invest-more',
  Manage = 'manage',
  Portfolio = 'portfolio',
  PortfolioDetail = 'portfolio-detail',
  Portfolios = 'portfolios',
  RegularSavings = 'regular-savings',
  Reports = 'reports',
  Transfer = 'transfer',
  Withdraw = 'withdraw',
}

export enum pageTitlesEnum {
  'invest-more' = 'Add money',
  'transfer' = 'Transfer',
  'regular-savings' = 'Regular savings',
  'withdraw' = 'Withdraw Money',
  'activity' = 'Activity',
  'reports' = 'Reports',
  'manage' = 'Manage portfolio',
}

export enum MessagesPagesEnum {
  Compose = 'compose',
  Inbox = 'inbox',
  Open = 'open',
  Sent = 'sent',
  Unread = 'unread',
}

export enum PortfolioManagementPagesEnum {
  ChangeRiskLevel = 'change-risk-level',
  ChangeStrategy = 'change-strategy',
  ClosePortfolio = 'close-portfolio',
}

export enum mcInRoyPagesPrimaryUserEnum {
  'Personal details' = 'personal-information',
  'Tax and employment' = 'tax-information',
  'Third parties' = 'power-of-attorney-and-interested-parties',
  'Income' = 'financial-details',
  'Assets and liabilities' = 'assets',
  'Dependants' = 'financial-dependents',
  'Source of funds' = 'source-of-funds',
  'Account information' = 'investment-amount', 
  'Application summary' = 'account-summary',
}

export enum mcInRoyPagesSecondUserEnum {
  'Application overview'= 'application-overview',
  'Personal details' = 'personal-information',
  'Tax and employment' = 'tax-information',
  'Income' = 'investment-income',
  'Account information' = 'bank-account', 
  'Application summary' = 'account-summary',
}
