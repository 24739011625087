import { LiabilitiesResponse } from '@app/shared/models/liabilities.model';

export const featureKey = 'liabilities';

export interface LiabilitiesState {
    liabilities: LiabilitiesResponse;
    updateSuccess:boolean;
    isDeleted: boolean;
}
export const initialState: LiabilitiesState = {
    liabilities: null,
    updateSuccess: false,
    isDeleted: false
};


