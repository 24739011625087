import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromJourneyConfigState from './journey-config.state';

const selectJourneyConfigState = createFeatureSelector<fromJourneyConfigState.JourneyConfigState>(fromJourneyConfigState.journeyConfigFeatureKey);

export const selectJourneyConfigs = createSelector(selectJourneyConfigState, state => state.journeyConfigs);

export const selectJourneyConfig = createSelector(selectJourneyConfigState, state => state.currentJourneyConfig);

export const selectBreadcrumbJourneyParts = createSelector(selectJourneyConfigState, state => state.breadcrumbJourneyParts);

export const selectCurrentJourneyStep = createSelector(selectJourneyConfigState, state => state.currentStep);

export const selectCurrentJourneyConfigSuccess = createSelector(selectJourneyConfigState, state => state.isSuccess);
