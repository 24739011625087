import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {CreateReportRequest, Report} from '@app/shared/models/report.model';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(private http: HttpClient) {}

  fetchReports(): Observable<Report[]> {
    return this.http.get<Report[]>(`${environment.apiEndpoint}/reports`);
  }

  createReport(request: CreateReportRequest): Observable<Report> {
    return this.http.post<Report>(`${environment.apiEndpoint}/reports`, request);
  }

  downloadReport(id: string): Observable<ArrayBuffer> {
    return this.http.get(`${environment.apiEndpoint}/reports/download/${id}`, {
      responseType: 'arraybuffer',
    });
  }
}
