import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'environments/environment';
import {BankDetails, BankDetailsCreateRequest} from '@app/shared/models/bank-details.model';
import {map} from 'rxjs/operators';
import {Patch} from '@app/shared/models/patch.model';

@Injectable({
  providedIn: 'root',
})
export class BankDetailsService {
  constructor(private http: HttpClient) {}

  fetchBankDetailsByIndividualId(individualId: number): Observable<BankDetails> {
    return (
      this.http
        .get<BankDetails[]>(`${environment.apiEndpoint}/banks/individuals/${individualId}`)
        // Although the API returns a list of bank details, we are only interested in the first one.
        .pipe(map(([bankDetails]) => bankDetails))
    );
  }

  createBankDetails(request: BankDetailsCreateRequest): Observable<BankDetails> {
    return this.http.post<BankDetails>(`${environment.apiEndpoint}/banks`, request);
  }

  updateBankDetails(request: Patch[], bankDetailsId: number): Observable<BankDetails> {
    return this.http.patch<BankDetails>(`${environment.apiEndpoint}/banks/${bankDetailsId}`, request);
  }
}
