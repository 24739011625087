import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  constructor() {}

  get getTenant(): string {
    return environment.tenant;
  }

  get getAppName(): string {
    return environment.applicationName;
  }

  get isEnvironmentProduction(): boolean {
    return environment.production;
  }

  getImagePath(imageName: string, format = 'png'): string {
    return `/assets/${this.getTenant}/img/${imageName}.${format}`;
  }
}
