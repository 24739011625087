import {Action, createReducer, on} from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { saveSourceOfFundsSuccess, fetchSourceOfFundsSuccess, updateSourceOfFunds, updateSourceOfFundsSuccess, updateSourceOfFundsFailure, fetchsourceOfFundsTransferTypes, fetchsourceOfFundsTransferTypesSuccess, fetchsourceOfFundsTransferTypesFail } from './source-of-funds.actions';
import { SourceOfFundsState, initialState } from './source-of-funds.state';
import { SourceOfFunds } from '@app/shared/models/source-of-funds.model';

export const adapter: EntityAdapter<SourceOfFunds> = createEntityAdapter<SourceOfFunds>();
const sourceOfFundsReducer = createReducer(
  initialState,

  on(saveSourceOfFundsSuccess, (state, {response}) => ({
    ...state,
    updateSuccess:true,
    sourceOfFunds: response
  })),

  on(fetchSourceOfFundsSuccess, (state, {response}) => ({
    ...state,
    sourceOfFunds: response
  })),


on(updateSourceOfFunds, state => ({
    ...state,
    updateSuccess: false,
  })),

  on(updateSourceOfFundsSuccess, (state, {response}) => ({
    ...state,
    updateSuccess: true,
    sourceOfFunds: response,
  })),

  on(updateSourceOfFundsFailure, state => ({
    ...state,
    updateSuccess: false,
  })),
  on(fetchsourceOfFundsTransferTypes, state => ({
    ...state,
    updateSuccess: false,
  })),

  on(fetchsourceOfFundsTransferTypesSuccess, (state, {response}) => ({
    ...state,
    updateSuccess: true,
    sourceOfFundsTransferTypes: response,
  })),

  on(fetchsourceOfFundsTransferTypesFail, state => ({
    ...state,
    updateSuccess: false,
  })),
);
export function reducer(state: SourceOfFundsState, action: Action) {
  return sourceOfFundsReducer(state, action);
}
