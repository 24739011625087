import { Injectable } from '@angular/core';
import { AppState } from '../root-state';
import { Observable, of } from 'rxjs';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { switchMap, catchError, map, withLatestFrom, exhaustMap } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { ErrorActions } from '../error';
//import { deleteLiabilities, deleteLiabilitiesSuccess, fetchLiabilitiesDetails, fetchLiabilitiesSuccess, saveLiabilitiesDetails, saveLiabilitiesSuccess } from './liabilities.actions';
import { selectProfile } from '../profile/profile.selectors';
import { LiabilitiesService } from '@app/services/liabilities-service/liabilities.service';
import { deleteFinancialDependents, deleteFinancialDependentsSuccess, fetchFinancialDependentsDetails, fetchFinancialDependentsSuccess, saveFinancialDependentsDetails, saveFinancialDependentsSuccess } from './financial-dependents.actions';
import { FinancialDependentsService } from '@app/services/financialDependents-service/financialDependents.service';


@Injectable()
export class FinancialDependentsEffects {

  saveFinancialDependents$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(saveFinancialDependentsDetails),
      withLatestFrom(this.store.select(selectProfile)),
      switchMap(([{ request }, profile]) =>
        this.financialdependentsService.createFinancialDependents(request).pipe(
          map(response => saveFinancialDependentsSuccess({ response })),
          catchError(error => of(ErrorActions.newError({ backEndError: error }))),
        ),
      ),
    ));
  fetchFinancialDependentsDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchFinancialDependentsDetails),
      withLatestFrom(this.store.select(selectProfile)),
      switchMap(([, profile]) =>
        this.financialdependentsService.fetchFinancialDependents(profile.individualId).pipe(
          map(response => fetchFinancialDependentsSuccess({ response })),
          catchError(error => of(ErrorActions.newError({ backEndError: error }))),
        ),
      ),
    ),
  );
  deleteFinancialDependents$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteFinancialDependents),
      withLatestFrom(this.store.select(selectProfile)),
      exhaustMap(([{id}, profile]) =>
        this.financialdependentsService.deleteFinancialDependents(profile.individualId, id).pipe(
          map(response => deleteFinancialDependentsSuccess({ response })),
          catchError(error => of(ErrorActions.newError({ backEndError: error }))),
        ),
      ),
    ),
  );
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private financialdependentsService: FinancialDependentsService,
  ) { }
}

