import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import * as fromOrganisation from './organisation.reducer';
import * as fromOrganisationState from './organisation.state';
import {EffectsModule} from '@ngrx/effects';
import {OrganisationEffects} from './organisation.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromOrganisationState.featureKey, fromOrganisation.reducer),
    EffectsModule.forFeature([OrganisationEffects]),
  ],
})
export class OrganisationStateModule {}
