import { on, createReducer, Action } from '@ngrx/store';
import { initialState, PhoneState } from './phone.state';
import * as PhoneActions from './phone.actions';

const phoneReducer = createReducer(
  initialState,

  on(PhoneActions.listPhoneNumbersByIndividualIdSuccess, (state, {response}) => ({
    ...state,
    phoneNumbers: response
  })),

  on(PhoneActions.listPhoneNumberTypesSuccess, (state, {response}) => ({
    ...state,
    phoneTypes: response
  })),

  on(PhoneActions.createPhoneNumberSuccess, (state, {response}) => ({
    ...state,
    phoneNumbers: state.phoneNumbers.concat(response),
    addPhoneNumberSuccess: true,
    deletePhoneNumberSuccess: false,
  })),

  on(PhoneActions.deletePhoneNumberSuccess, (state, {response}) => ({
    ...state,
    phoneNumbers: state.phoneNumbers.filter(phoneNumber => phoneNumber.id !== response?.id),
    addPhoneNumberSuccess: false,
    deletePhoneNumberSuccess: true,
  })),
);

export function reducer(state: PhoneState, action: Action) {
  return phoneReducer(state, action);
}
