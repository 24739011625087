import {createAction, props} from '@ngrx/store';
import {RelationshipCreate} from '@app/shared/models/relationship-create.model';
import {RelationshipType} from '@app/shared/models/relationship-type.model';
import {Relationship} from '@app/shared/models/relationship.model';
import {ErrorModel} from '@app/shared/models/error.model';
import {HttpErrorResponse} from '@angular/common/http';

export const loadRelationshipTypes = createAction('[Relationships API] Load Relationship Types');

export const loadRelationshipTypesByEntityType = createAction(
  '[Relationships API] Load Relationship Types By Entity Type',
  props<{entityTypeId: number}>(),
);

export const loadRelationshipTypesSuccess = createAction(
  '[Relationships API] Load Relationship Types Success',
  props<{response: RelationshipType[]}>(),
);

export const loadRelationshipTypesFail = createAction('[Relationships API] Load Relationship Types Fail', props<{error: HttpErrorResponse}>());

export const createRelationship = createAction('[Relationships API] Create Relationship', props<{request: RelationshipCreate}>());

export const createRelationshipSuccess = createAction('[Relationships API] Create Relationship Success', props<{response: Relationship}>());

export const createRelationshipFail = createAction('[Relationships API] Create Relationship Fail', props<{error: ErrorModel}>());
