import * as fromMaritalStatus from './marital-status.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DropDownItem } from '@app/shared/models/dropdownitem.model';
export const selectMaritalStatusState = createFeatureSelector<
  fromMaritalStatus.MaritalStatusState
>(fromMaritalStatus.featureKey);

export const selectMaritalStatuses = createSelector(
  selectMaritalStatusState,
  fromMaritalStatus.selectAll
);
export const selectMaritalStatusesLoading = createSelector(
  selectMaritalStatusState,
  (state) => state.loading
);
export const selectMaritalStatusDropdownItems = createSelector(
  selectMaritalStatuses,
  (maritalStatuses) =>
    maritalStatuses.map(
      (item) =>
        <DropDownItem>{
          id: item.id,
          label: item.value,
          value: item.value,
        }
    )
);
