import {Action, createReducer, on} from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { deleteFinancialDependents, deleteFinancialDependentsFailure, deleteFinancialDependentsSuccess, fetchFinancialDependentsSuccess, resetDeleteFinancialDependentsSuccess, saveFinancialDependentsDetails, saveFinancialDependentsSuccess } from './financial-dependents.actions';
import { financialdependentsState, initialState } from './financial-dependents.state';
import { FinancialDependents } from '@app/shared/models/individuals/individual-financial-dependents.model';

export const adapter: EntityAdapter<FinancialDependents> = createEntityAdapter<FinancialDependents>();
const financialDependentsReducer = createReducer(
  initialState,

  on(saveFinancialDependentsSuccess, (state, {response}) => ({
    ...state,
    updateSuccess:true,
  })),

  on(saveFinancialDependentsDetails, (state) => ({
    ...state,
    updateSuccess:false,
  })),

  on(fetchFinancialDependentsSuccess, (state, {response}) => ({
    ...state,
    financialDependents: response
  })),

  on(deleteFinancialDependents, state => ({
    ...state,
    updateSuccess: false,
    isDeleted: false,
  })),

  on(deleteFinancialDependentsSuccess, (state) => ({
    ...state,
    isDeleted: true,
  })),

  on(deleteFinancialDependentsFailure, state => ({
    ...state,
    isDeleted: false,
  })),

  on(resetDeleteFinancialDependentsSuccess, state => ({
    ...state,
    isDeleted: false,
  })),
);

export function reducer(state: financialdependentsState, action: Action) {
  return financialDependentsReducer(state, action);
}
