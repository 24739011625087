import {Action, createReducer, on} from '@ngrx/store';
import * as ReportActions from './report.actions';
import {initialState, ReportState} from '@app/state/report/report.state';

export const reportReducer = createReducer(
  initialState,

  on(ReportActions.fetchReportsSuccess, (state, {response}) => ({
    ...state,
    reports: response,
  })),

  on(ReportActions.createReportSuccess, (state, {response}) => ({
    ...state,
    reports: [...state.reports, response],
  })),
);

export function reducer(state: ReportState, action: Action) {
  return reportReducer(state, action);
}
