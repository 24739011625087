import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromState from './currencies.state';
import {DropDownItem} from '@app/shared/models/dropdownitem.model';

export const getCurrenciesState = createFeatureSelector<fromState.CurrenciesState>(fromState.featureKey);

export const selectCurrencies = createSelector(getCurrenciesState, state => state.currencies);

export const getCurrenciesLoading = createSelector(getCurrenciesState, state => state.loading);

export const selectCurrenciesDropDownValues = createSelector(selectCurrencies, currencies =>
  currencies.map(
    c =>
      <DropDownItem>{
        id: c.id,
        label: c.name,
        value: c.code,
      },
  ),
);
