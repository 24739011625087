import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import { SessionTimeoutDialogComponent } from './session-timeout-dialog.component';
import {MaterialModule} from '@app/shared/material';


@NgModule({
  declarations: [SessionTimeoutDialogComponent],
  exports: [SessionTimeoutDialogComponent],
  imports: [TranslateModule,CommonModule,MaterialModule]
})
export class SessionTimeoutModule {}