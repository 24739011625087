import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromAttitudeToRiskState from '@app/state/attitude-to-risk/attitude-to-risk.state';

export const getAttitudeToRiskState = createFeatureSelector<fromAttitudeToRiskState.AttitudeToRiskState>(
  fromAttitudeToRiskState.attitudeToRiskFeatureKey,
);

/** Select attitude to risk questions */
export const selectAttitudeToRiskQuestions = createSelector(getAttitudeToRiskState, state => state.questions);

/** Select current question number in display */
export const selectCurrentAttitudeToRiskQuestion = createSelector(
  getAttitudeToRiskState,
  state => state.currentQuestion,
);

/** Select most recent fetched score from risk assessment questions */
export const selectAttitudeToRiskScore = createSelector(getAttitudeToRiskState, state => state.score);

/** Select user saved and confirmed risk score */
export const selectConfirmedRiskTarget = createSelector(getAttitudeToRiskState, state => state.confirmedCurrentRisk);

/** Select advised risk target */
export const selectAdvisedRiskTarget = createSelector(
  getAttitudeToRiskState,
  state => state.score?.riskTargets?.find(target => target.isRecommended) ?? null,
);

/** Select confirm final score saved success */
export const selectConfirmScoreUpdateSuccess = createSelector(
  getAttitudeToRiskState,
  state => state.confirmScoreUpdateSuccess,
);

/** Select fetch score update sucess */
export const selectFetchScoreUpdateSuccess = createSelector(
  getAttitudeToRiskState,
  state => state.fetchScoreUpdateSuccess,
);

/** Select questionnaire all questions answered */
export const selectQuestionnaireComplete = createSelector(
  getAttitudeToRiskState,
  state => !state.questions.length || !state.questions.some(question => !question.selectedResponseId),
);

/** Select number of questionnaire attempts */
export const selectNumberOfAttempts = createSelector(getAttitudeToRiskState, state => state.score?.numberOfAttempts);

/** Select if questionnaire attempt limit has been exceeded */
export const selectExceededAttemptLimit = createSelector(
  getAttitudeToRiskState,
  state => state.numberOfAttempts > state.maximumAttempts,
);

/** Select if answers an inconsistent */
export const selectInconsistentAnswers = createSelector(
  getAttitudeToRiskState,
  state => state.score?.inconsistentAnswers,
);

export const fetchRiskquestionnaireScoreResponse = createSelector(
  getAttitudeToRiskState, state => ({
    isChangeRequired: state.isChangeRequired,
    lastUpdatedDate: state.lastUpdatedDate,
    lastChangedMonth: state.lastChangedMonth,
    isChangeMandatory: state.isChangeMandatory,
    directDebitClearDownDate: state.directDebitClearDownDate,
  }));
