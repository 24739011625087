import { on, createReducer, Action } from '@ngrx/store';
import { initialState, EidvState } from './eidv.state';
import * as EidvActions from './eidv.actions';

const eidvReducer = createReducer(
  initialState,

  on(EidvActions.loadEidvLatestSuccess, (state, { payload }) => ({
    ...state,
    eidv: payload
  })),

);

export function reducer(state: EidvState, action: Action) {
  return eidvReducer(state, action);
}
