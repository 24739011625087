import {createAction, props} from '@ngrx/store';
import {Product, ProductComparison, ProductDeclaration} from '@app/shared/models/portfolio/product.model';
import {IsaTransferTypeIdEnum, IsaTypeIdEnum} from '@app/shared/enums/product.enum';

export const fetchProducts = createAction('[Product] Fetch Products');

export const fetchProductsSuccess = createAction('[Product] Fetch Products Success', props<{response: Product[]}>());

export const fetchProductsByMandateId = createAction('[Product] Fetch Product by id', props<{mandateId?: number}>());

export const fetchProductsByMandateIdSuccess = createAction(
  '[Product] Fetch Product by id Success',
  props<{response: Product[]}>(),
);

export const fetchProductsComparisons = createAction('[Product] Fetch Product Comparison');

export const fetchProductsComparisonsSuccess = createAction(
  '[Product] Fetch Product Comparison Success',
  props<{response: ProductComparison[]}>(),
);

export const getProductDeclaration = createAction('[Product] Fetch Product Declaration');

export const getProductDeclarationSuccess = createAction(
  '[Product] Fetch Product Declaration Success',
  props<{response: ProductDeclaration}>(),
);

export const setTransferTypeAuthStatement = createAction(
  '[Product] Set Transfer Type AuthStatement',
  props<{isaTypeId?: IsaTypeIdEnum; isaTransferTypeId?: IsaTransferTypeIdEnum}>(),
);
