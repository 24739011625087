import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  logs$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private currentLog: string[] = ['~~New Log~~'];

  writeToLog(text: string) {
    this.currentLog.push(`${text} :${moment()}`);
    this.logs$.next(this.currentLog);
  }
}
