import {Injectable} from '@angular/core';
import {AppState} from '../root-state';
import {
  fetchProfile,
  fetchProfileFail,
  fetchProfileSuccess,
  updateProfile,
  updateProfileSuccess,
} from './profile.actions';
import {updateIndividualSuccess} from '@app/state/individual/individual.actions';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {ProfileService} from '@app/services/profile.service';
import {Profile} from '@app/shared/models/profile.model';
import {ErrorActions} from '@app/state/error';

@Injectable()
export class ProfileEffects {
  fetchProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchProfile),
      switchMap(() =>
        this.profileService.fetchProfile().pipe(
          map((profile: Profile) => fetchProfileSuccess({profile})),
          catchError(error => of(ErrorActions.newError({backEndError: error}), fetchProfileFail(error))),
        ),
      ),
    ),
  );

  updateProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProfile),
      switchMap(({request}) =>
        this.profileService.updateProfile(request).pipe(
          map(response => updateProfileSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private store: Store<AppState>, private profileService: ProfileService) {}
}
