import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { CreateMessagePayload, Message, MessageThread, MessageType } from '@app/shared/models/message.model';
import { environment } from '../../../environments/environment';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class MessageService {
  headers: HttpHeaders;
  private tabValue = new BehaviorSubject<number>(null);
  tabValueData = this.tabValue.asObservable()
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;

  constructor(private http: HttpClient,public router : Router) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });

  }
  public getPreviousUrl(){
    return this.previousUrl;
  }  

  getMessages(): Observable<Message[]> {
    return this.http.get<Message[]>(`${environment.apiEndpoint}/messages/threads?restrictToHeaders=true&showRead=true&showActioned=true`);
  }

  getMessage(messageId: number): Observable<Message> {
    return this.http.get<Message>(`${environment.apiEndpoint}/messages/${messageId}`);
  }

  getThread(threadId: string): Observable<MessageThread[]> {
    return this.http.get<MessageThread[]>(`${environment.apiEndpoint}/messages/threads?threadId=${threadId}&restrictToHeaders=false&showRead=true&showActioned=true`);
  }

  getMessageTypes(): Observable<MessageType[]> {
    return this.http.get<MessageType[]>(`${environment.apiEndpoint}/messages/references/types`);
  }

  postMessage(message: CreateMessagePayload): Observable<Message> {
    return this.http.post<Message>(`${environment.apiEndpoint}/messages`, message);
  }

  upDateMessageReadStatus(messageId: number): Observable<Message> {
    const patchPayload = [{
      op: 'replace',
      path: '/isRead',
      value: true,
    }];

    return this.http.patch<Message>(`${environment.apiEndpoint}/messages/${messageId}`, patchPayload);
  }
  setTabValue(tabValue: number){
    this.tabValue.next(tabValue);
  }

}
