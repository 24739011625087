import {Action, createReducer, on} from '@ngrx/store';
import {initialState, MandateState} from './mandate.state';
import {
  createMandateSuccess,
  deleteMandateSuccess,
  fetchMandates,
  fetchMandatesFailure,
  fetchMandatesSuccess,
  fetchMandateSuccess,
  finaliseMandateFailure,
  finaliseMandateSuccess,
  updateMandate,
  updateMandateFailure,
  updateMandateSuccess,
} from './mandate.actions';
import {Mandate} from '@app/shared/models/mandate.model';

const mandateReducer = createReducer(
  initialState,

  on(fetchMandates, state => ({...state, loading: true})),

  on(fetchMandatesSuccess, (state, {response}) => ({
    ...state,
    mandates: response,
    loading: false,
  })),

  on(fetchMandatesFailure, state => ({...state, loading: false})),

  on(fetchMandateSuccess, (state, {response}) => {
    let mandates: Mandate[];
    if (state.mandates.findIndex(x => x.id === response.id) > -1) {
      mandates = state.mandates.map(item => (response.id === item.id ? {...item, ...response} : item));
    } else {
      mandates = [...state.mandates, response];
    }
    return {...state, mandates: mandates, mandateLoading: false};
  }),

  on(createMandateSuccess, (state, {response}) => ({
    ...state,
    mandates: [...state.mandates, response],
  })),

  on(updateMandate, state => ({...state, updateSuccess: false})),


  on(updateMandateSuccess, (state, {response}) => ({
    ...state,
    mandates: state.mandates.map(mandate => (response.id === mandate.id ? {...mandate, ...response} : mandate)),
    updateSuccess: true,
  })),

  on(updateMandateFailure, state => ({...state, updateSuccess: false})),

  on(finaliseMandateSuccess, (state, {response}) => ({
    ...state,
    mandates: state.mandates.map(mandate => (response.id === mandate.id ? {...mandate, ...response} : mandate)),
    updateSuccess: true,
  })),

  on(finaliseMandateFailure, state => ({...state, updateSuccess: false})),

  on(deleteMandateSuccess, (state, {id}) => ({
    ...state,
    mandates: [...state.mandates.filter(mandate => mandate.id !== id)],
  })),
);

export function reducer(state: MandateState, action: Action) {
  return mandateReducer(state, action);
}
