import {Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {selectError} from '@app/state/error/error.selectors';
import {ErrorState} from '@app/state/error/error.state';
import {ErrorActions} from '@app/state/error';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'abl-error-list-overlay',
  templateUrl: './error-list-overlay.component.html',
  styleUrls: ['./error-list-overlay.component.scss'],
  animations: [
    trigger('fadeInAndGrow', [
      state('in', style({height: '*', opacity: 1})),
      transition(':enter', [style({height: 0, opacity: 0}), animate(200)]),
      transition(':leave', animate(400, style({height: 0, opacity: 0}))),
    ]),
  ],
})
export class ErrorListOverlayComponent {
  errors$ = this.errorStore.select(selectError);

  constructor(private errorStore: Store<ErrorState>) {}

  close(index: number) {
    this.errorStore.dispatch(ErrorActions.removeError({index}));
  }
}
