import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UppercaseFormCtrlDirective} from './uppercase-form-ctrl.directive';
import {TrimWhitespaceDirective} from './trim-whitespace.directive';
import {IntegerOnlyDirective} from './integer-only.directive';
import {TitleCaseFormCtrlDirective} from './title-case-form-ctrl.directive';
import {DebounceClickDirective} from './double-click.directive';
import { AutocompleteOffDirective } from './auto-complete-off';
import { MonthYearDirectiveDirective } from './month-year-directive.directive';

@NgModule({
  declarations: [UppercaseFormCtrlDirective, TrimWhitespaceDirective, IntegerOnlyDirective, TitleCaseFormCtrlDirective, DebounceClickDirective, AutocompleteOffDirective, MonthYearDirectiveDirective],
  exports: [UppercaseFormCtrlDirective, TrimWhitespaceDirective, IntegerOnlyDirective, TitleCaseFormCtrlDirective, DebounceClickDirective, AutocompleteOffDirective, MonthYearDirectiveDirective],
  imports: [CommonModule],
})
export class DirectivesModule {}
