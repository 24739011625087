import {Injectable} from '@angular/core';
import {AppState} from '../root-state';
import * as MaritalStatusActions from './marital-status.actions';
import {selectMaritalStatuses} from './marital-status.selectors';
import {Observable, EMPTY, of} from 'rxjs';
import {createEffect, ofType, Actions} from '@ngrx/effects';
import {withLatestFrom, switchMap, catchError, map} from 'rxjs/operators';
import {Action, Store} from '@ngrx/store';
import {Gender} from '@app/shared/models/gender.model';
import {MaritalStatusService} from '@app/services/marital-status.service';
import {MaritalStatus} from '@app/shared/models/marital-status.model';
import {ErrorActions} from '@app/state/error';

@Injectable()
export class MartialStatusEffects {
  getMaritalStatuses$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MaritalStatusActions.getMaritalStatuses),
      withLatestFrom(this.store.select(selectMaritalStatuses), (action, maritalStatuses) => {
        if (maritalStatuses.length > 0) {
          return MaritalStatusActions.loadMaritalStatusesSuccess({
            maritalStatuses,
          });
        } else {
          return MaritalStatusActions.loadMaritalStatuses();
        }
      }),
    ),
  );

  loadMaritalStatuses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaritalStatusActions.loadMaritalStatuses),
      switchMap(() =>
        this.maritalStatusService.getMaritalStatuses().pipe(
          map((maritalStatuses: MaritalStatus[]) => MaritalStatusActions.loadMaritalStatusesSuccess({maritalStatuses})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private maritalStatusService: MaritalStatusService,
  ) {}
}
