import {JourneyStepEnum} from '@app/shared/enums/journey-step.enum';
import {Injectable} from '@angular/core';
import { Router } from '@angular/router';
import {Observable} from 'rxjs';
import {DialogService} from '@app/services/dialog/dialog.service';
import {first} from 'rxjs/operators';
import { environment } from 'environments/environment';
import { EnvironmentsEnum } from './shared/enums/environments.enum';


@Injectable({providedIn: 'root'})
export class CanDeactivateOnboardingGuard  {
  env = environment.tenant;
  envEnum = EnvironmentsEnum.mcInroy;
  
  constructor(private dialogService: DialogService, private router: Router) {}

  canDeactivate(): Observable<boolean> {
    if (this.router.url.includes(JourneyStepEnum.Finalise) ||
  this.router.url.includes(JourneyStepEnum.Gdpr)) {
      return;
    }
    if(this.router.url.includes('Logout')) {
      return;
    }
      return this.dialogService.openNavigateAwayDialog().pipe(first());

  }
}
