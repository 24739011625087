import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CountriesEffects } from './country.effects';
import * as fromReducer from './country.reducer';
import * as fromState from './country.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromState.featureKey, fromReducer.reducer),
    EffectsModule.forFeature([CountriesEffects])
  ],
  declarations: []
})
export class CountriesStateModule {}
