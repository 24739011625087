import {createAction, props} from '@ngrx/store';
import {Title} from '@app/shared/models/title.model';
import {RelationshipType} from '@app/shared/models/relationship-type.model';
import {
  Individual,
  IndividualOverview,
  IndividualSummary,
  SpouseRelationCreateResponse,
} from '@app/shared/models/individuals/individual.model';
import {Patch} from '@app/shared/models/patch.model';
import {HttpErrorResponse} from '@angular/common/http';
import {SpouseRelationCreate} from '@app/shared/models/individuals/individual-participant.model';
import {
  PowerOfAttorney,
  PowerOfAttorneyResponse,
} from '@app/shared/models/individuals/individual-powerofattorney.model';
import {
  DeletedInterestedParty,
  InterestedPartiesResponse,
  InterestedParty,
} from '@app/shared/models/individuals/individual-interestedparties';
import {Instructions, InstructionsResponse} from '@app/shared/models/individuals/individual-instructions';

import {DeletedItem} from '@app/shared/models/deleted-item.model';
import {
  Income,
  IncomeResponse,
  TaxableInstructions,
  PaymentFrequencyTypes,
  IncomeTypes,
  InvestmentIncomePyaload,
  InvestmentIncome,
  AnnualSpendingPayload,
  AnnualSpending,
  MonthendTypes,
  QuarterendTypes,
} from '@app/shared/models/individuals/individual-income';
import {Assets, AssetsPayload} from '@app/shared/models/individuals/individual-assets.model';
import {Taxdetails, TaxdetailsPayload} from '@app/shared/models/individuals/individual-taxdetails.model';
import {Isadetails, IsadetailsPayload} from '@app/shared/models/individuals/individual-isadetails';
import {AccountInformationSummary} from '@app/shared/models/individual-summary.model';

/** Fetches a fresh individual from the API by individual ID. */
export const fetchIndividual = createAction('[Individual Details] Fetch Individual');

export const fetchIndividualSuccess = createAction(
  '[Individual Details] Fetch Individual Success',
  props<{response: Individual}>(),
);

export const fetchIndividualFail = createAction(
  '[Individual Details] Fetch Individual Fail',
  props<{error: HttpErrorResponse}>(),
);

/** Load titles, e.g Mr, Mrs, Sir. */
export const fetchTitles = createAction('[Individual Details] Fetch Individuals Titles');

export const fetchTitlesSuccess = createAction(
  '[Individual Details] Fetch Individual Titles Success',
  props<{response: Title[]}>(),
);

/** Load titles for children, e.g Mr, Ms, Mater. */
export const fetchChildTitles = createAction('[Individual Details] Fetch Individuals Titles');

export const fetchChildTitlesSuccess = createAction(
  '[Individual Details] Fetch Individual Titles Success',
  props<{response: Title[]}>(),
);

/** Load relationship types. */
export const fetchRelationshipTypes = createAction('[Individual Details] Fetch Individuals Relationships');

export const fetchIndividualRelationshipTypesSuccess = createAction(
  '[Individual Details] Fetch Individual Relationship Types Success',
  props<{response: RelationshipType[]}>(),
);

export const fetchIndividualRelationshipTypesFail = createAction(
  '[Individual Details] Fetch Individual Relationship Types Fail',
  props<{error: HttpErrorResponse}>(),
);

/** Update individual. */
export const updateIndividual = createAction('[Individual Details] Update Individual', props<{request: Patch[]}>());

export const updateIndividualSuccess = createAction(
  '[Individual Details] Update Individual Success',
  props<{response: Individual}>(),
);

export const updateIndividualFailure = createAction(
  '[Individual Details] Update Individual Failure',
  props<{error: HttpErrorResponse}>(),
);

export const saveIndividualSpouseRelation = createAction(
  '[Individual Spouse Details] save Individual Spouse',
  props<{request: SpouseRelationCreate}>(),
);

export const saveIndividualSpouseRelationSuccess = createAction(
  '[Individual Spouse Details] save Individual Spouse Success',
  props<{response: SpouseRelationCreateResponse}>(),
);

export const saveIndividualSpouseRelationFailure = createAction(
  '[Individual Spouse Details] save Individual Spouse Failure',
  props<{error: HttpErrorResponse}>(),
);

//  save power of attorney *post*
export const createIndividualPowerOfAttorney = createAction(
  '[Individual power of attorney] create Individual power of attorney',
  props<{request: PowerOfAttorney}>(),
);

export const createIndividualPowerOfAttorneySuccess = createAction(
  '[Individual power of attorney]  create Individual power of attorney Success',
  props<{response: PowerOfAttorneyResponse}>(),
);

export const createIndividualPowerOfAttorneyFailure = createAction(
  '[Individual power of attorney]  create Individual power of attorney Failure',
  props<{error: HttpErrorResponse}>(),
);
// end

//save interested party **post**
export const createIndividualInterestedParties = createAction(
  '[Individual Interested parties] create Individual Interested parties',
  props<{request: InterestedParty}>(),
);

export const createIndividualInterestedPartiesSuccess = createAction(
  '[Individual Interested parties]  create Individual Interested parties Success',
  props<{response: InterestedPartiesResponse}>(),
);

export const createIndividualInterestedPartiesFailure = createAction(
  '[Individual Interested parties]  create Individual Interested parties Failure',
  props<{error: HttpErrorResponse}>(),
);
//end

// power of attorney get
export const fetchIndividualPowerOfAttorney = createAction(
  '[Individual  power of attorney Details] Fetch  power of attorney Detail',
);

export const fetchIndividualPowerOfAttorneySuccess = createAction(
  '[ power of attorney Details] Fetch  power of attorney Detail Success',
  props<{response: any}>(),
);

export const fetchIndividualPowerOfAttorneyFailure = createAction(
  '[ power of attorney Details] Fetch  power of attorney Detail Failure',
  props<{error: HttpErrorResponse}>(),
);
// end

// interested party get
export const fetchIndividualInterestedParties = createAction(
  '[Individual  Interested parties Details] Fetch  Interested parties Detail',
);

export const fetchIndividualInterestedPartiesSuccess = createAction(
  '[ Interested parties Details] Fetch  Interested parties Detail Success',
  props<{response: InterestedPartiesResponse[]}>(),
);

export const fetchIndividualInterestedPartiesFailure = createAction(
  '[ Interested parties Details] Fetch  Interested parties Detail Failure',
  props<{error: HttpErrorResponse}>(),
);
// end

/** Delete InterestedParty. */
export const deleteInterestedParty = createAction(
  '[Delete InterestedParty] Delete InterestedParty',
  props<{id: number}>(),
);

export const deleteInterestedPartySuccess = createAction(
  '[ Delete InterestedParty] Delete InterestedParty Success',
  props<{response: DeletedInterestedParty}>(),
);

// reset delete success
export const resetDeleteInterestedPartySuccess = createAction(
  '[Delete InterestedParty] Reset Delete InterestedParty Success',
);

export const deleteInterestedPartyFail = createAction(
  '[Delete InterestedParty] Delete InterestedParty Fail',
  props<{error: HttpErrorResponse}>(),
);
// end

export const updateIndividualSpouseRelation = createAction(
  '[Individual Spouse Details] Update Individual Spouse',
  props<{request: Patch[]; mandateId: number; isJointPartner: boolean}>(),
);

export const updateIndividualSpouseRelationSuccess = createAction(
  '[Individual Spouse Details] Update Individual Spouse Success',
  props<{response: SpouseRelationCreateResponse}>(),
);

export const updateIndividualSpouseRelationFailure = createAction(
  '[Individual Spouse Details] Update Individual Spouse Failure',
  props<{error: HttpErrorResponse}>(),
);

export const fetchIndividualSpouseRelation = createAction(
  '[Individual Spouse Details] Fetch Spouse Detail',
  props<{mandateId?: number; isJointPartner?: boolean}>(),
);

export const fetchIndividualSpouseRelationSuccess = createAction(
  '[Spouse Details] Fetch Spouse Detail Success',
  props<{response: SpouseRelationCreateResponse}>(),
);

export const fetchIndividualSpouseRelationFail = createAction(
  '[Spouse Details] Fetch Spouse Detail Fail',
  props<{error: HttpErrorResponse}>(),
);

/** Update acceptance. */
export const saveAcceptance = createAction(
  '[Individual Details] Save Acceptance Selection By Individual ID',
  props<{acceptance: boolean}>(),
);

export const saveAcceptanceSuccess = createAction(
  '[Individual Details] Save Acceptance Selection By Individual ID Success',
  props<{response: Individual}>(),
);

export const saveAcceptanceFailure = createAction(
  '[Individual Details] Save Acceptance Failure',
  props<{error: HttpErrorResponse}>(),
);

// account instructions get
export const fetchAccountInstructions = createAction('[Individual  account instructions] Fetch  account instructions');

export const fetchAccountInstructionsSuccess = createAction(
  '[ account instructions] Fetch  account instructions Success',
  props<{response: InstructionsResponse[]}>(),
);

export const fetchAccountInstructionsFailure = createAction(
  '[ account instructions] Fetch  account instructions Failure',
  props<{error: HttpErrorResponse}>(),
);
// end

// get income details
export const fetchIncomeDetails = createAction('[Individual income details] Fetch  income details');

export const fetchIncomeDetailsSuccess = createAction(
  '[income details] Fetch income details Success',
  props<{response: any}>(),
);

export const fetchIncomeDetailsFailure = createAction(
  '[income details] Fetch income details Failure',
  props<{error: HttpErrorResponse}>(),
);
// end

//save income details **post**
export const createIncomeDetails = createAction(
  '[Individual income details] create Individual income details',
  props<{request: Income}>(),
);

export const createIncomeDetailsSuccess = createAction(
  '[Individual income details]  create Individual income details Success',
  props<{response: IncomeResponse}>(),
);

export const createIncomeDetailsFailure = createAction(
  '[Individual income details]  create Individual income details Failure',
  props<{error: HttpErrorResponse}>(),
);
//end

/** Remove income details. */
export const removeIncomeDetails = createAction('[income details] Remove income details', props<{id: number}>());

export const removeIncomeDetailsSuccess = createAction(
  '[income details] Remove income details Success',
  props<{response: DeletedItem}>(),
);

export const removeIncomeDetailsFail = createAction(
  '[income details] Remove income details Fail',
  props<{error: HttpErrorResponse}>(),
);

// reset delete success
export const resetDeleteIncomeDetailsSuccess = createAction(
  '[Delete income details] Reset Delete income details Success',
);

//save account instructions **post**
export const createAccountInstructions = createAction(
  '[Individual account instructions] create Individual account instructions',
  props<{request: Instructions}>(),
);

export const createAccountInstructionsSuccess = createAction(
  '[Individual account instructions]  create Individual account instructions Success',
  props<{response: any}>(),
);

export const createAccountInstructionsFailure = createAction(
  '[Individual account instructions]  create Individual Interested parties Failure',
  props<{error: HttpErrorResponse}>(),
);
//end

//fetch taxableinstructions
export const fetchTaxableInstructions = createAction(
  '[Individuals taxableinstructions] fetch Individuals taxableinstructions',
);

export const fetchTaxableInstructionsSuccess = createAction(
  '[Individuals taxableinstructions] fetch Individuals taxableinstructions Success',
  props<{response: TaxableInstructions[]}>(),
);

export const fetchTaxableInstructionsFailure = createAction(
  '[Individuals taxableinstructions] fetch Individuals taxableinstructions Failure',
  props<{error: HttpErrorResponse}>(),
);
//end

//fetch PaymentFrequencyTypes
export const fetchPaymentFrequencyTypes = createAction(
  '[Individuals paymentFrequencyTypes] fetch Individuals paymentFrequencyTypes',
);

export const fetchPaymentFrequencyTypesSuccess = createAction(
  '[Individuals paymentFrequencyTypes] fetch Individuals paymentFrequencyTypes Success',
  props<{response: PaymentFrequencyTypes[]}>(),
);

export const fetchPaymentFrequencyTypesFailure = createAction(
  '[Individuals paymentFrequencyTypes] fetch Individuals paymentFrequencyTypes Failure',
  props<{error: HttpErrorResponse}>(),
);
//end

//fetch incomeTypes
export const fetchIncomeTypes = createAction('[Individuals incomeTypes] fetch Individuals incomeTypes');

export const fetchIncomeTypesSuccess = createAction(
  '[Individuals incomeTypes] fetch Individuals incomeTypes Success',
  props<{response: IncomeTypes[]}>(),
);

export const fetchIncomeTypesFailure = createAction(
  '[Individuals incomeTypes] fetch Individuals incomeTypes Failure',
  props<{error: HttpErrorResponse}>(),
);
//end

// createInvestmentIncome post
export const createInvestmentIncome = createAction(
  '[Individual investment income] create Individual investment income',
  props<{request: InvestmentIncomePyaload}>(),
);

export const createInvestmentIncomeSuccess = createAction(
  '[Individual investment income]  create Individual investment income Success',
  props<{response: InvestmentIncome}>(),
);

export const createInvestmentIncomeFailure = createAction(
  '[Individual investment income]  create Individual investment income Failure',
  props<{error: HttpErrorResponse}>(),
);
//end

// createAnnualSpending post
export const createAnnualSpending = createAction(
  '[Individual AnnualSpending] create Individual AnnualSpending',
  props<{request: AnnualSpendingPayload}>(),
);

export const createAnnualSpendingSuccess = createAction(
  '[Individual AnnualSpending]  create Individual AnnualSpending Success',
  props<{response: AnnualSpending}>(),
);

export const createAnnualSpendingFailure = createAction(
  '[Individual AnnualSpending]  create Individual AnnualSpending Failure',
  props<{error: HttpErrorResponse}>(),
);
//end

//fetch fetchAnnualSpending
export const fetchAnnualSpending = createAction('[Individuals AnnualSpending] fetch Individuals AnnualSpending');

export const fetchAnnualSpendingSuccess = createAction(
  '[Individuals AnnualSpending] fetch Individuals AnnualSpending Success',
  props<{response: AnnualSpending}>(),
);

export const fetchAnnualSpendingFailure = createAction(
  '[Individuals AnnualSpending] fetch Individuals AnnualSpending Failure',
  props<{error: HttpErrorResponse}>(),
);
//end

// updateInvestmentIncome patch
export const updateInvestmentIncome = createAction(
  '[Individual investment income] update Individual investment income',
  props<{request: Patch[]}>(),
);

export const updateInvestmentIncomeSuccess = createAction(
  '[Individual investment income]  update Individual investment income Success',
  props<{response: InvestmentIncome}>(),
);

export const updateInvestmentIncomeFailure = createAction(
  '[Individual investment income]  update Individual investment income Failure',
  props<{error: HttpErrorResponse}>(),
);
//end
// updateAnnualSpending patch
export const updateAnnualSpending = createAction(
  '[Individual AnnualSpending] update Individual AnnualSpending',
  props<{request: Patch[]}>(),
);

export const updateAnnualSpendingSuccess = createAction(
  '[Individual AnnualSpending]  update Individual AnnualSpending Success',
  props<{response: AnnualSpending}>(),
);

export const updateAnnualSpendingFailure = createAction(
  '[Individual AnnualSpending]  update Individual AnnualSpending Failure',
  props<{error: HttpErrorResponse}>(),
);
//end

// get InvestmentIncome get
export const fetchInvestmentIncome = createAction('[Individual investment income] get Individual investment income');

export const fetchInvestmentIncomeSuccess = createAction(
  '[Individual investment income]  get Individual investment income Success',
  props<{response: InvestmentIncome}>(),
);

export const fetchInvestmentIncomeFailure = createAction(
  '[Individual investment income]  get Individual investment income Failure',
  props<{error: HttpErrorResponse}>(),
);
//end

// create assets post
export const createAssets = createAction(
  '[Individual Assets] create Individual Assets',
  props<{request: AssetsPayload}>(),
);

export const createAssetsSuccess = createAction(
  '[Individual Assets]  create Individual Assets Success',
  props<{response: Assets}>(),
);

export const createAssetsFailure = createAction(
  '[Individual Assets]  create Individual Assets Failure',
  props<{error: HttpErrorResponse}>(),
);
// ennd

// fetch assets get
export const fetchAssets = createAction('[Individual Assets] get Individual Assets');

export const fetchAssetsSuccess = createAction(
  '[Individual Assets]  get Individual Assets Success',
  props<{response: Assets}>(),
);

export const fetchAssetsFailure = createAction(
  '[Individual Assets]  get Individual Assets Failure',
  props<{error: HttpErrorResponse}>(),
);

/** Remove Assets. */
export const removeAssets = createAction('[Assets] Remove Assets', props<{id: number}>());

export const removeAssetsSuccess = createAction('[Assets] Remove Assets Success', props<{response: DeletedItem}>());

export const removeAssetsFail = createAction('[Assets] Remove Assets Fail', props<{error: HttpErrorResponse}>());
// reset delete success
export const resetDeleteAssetsSuccess = createAction('[Delete Assets] Reset Delete Assets Success');

/** Fetches a tax details from the API by individual ID. */
export const fetchTaxdetails = createAction('[Individual Taxdetails] Fetch Taxdetails');

export const fetchTaxdetailsSuccess = createAction(
  '[Individual Taxdetails] Fetch Taxdetails Success',
  props<{response: Taxdetails}>(),
);

export const fetchTaxdetailsFail = createAction(
  '[Individual Taxdetails] Fetch Taxdetails Fail',
  props<{error: HttpErrorResponse}>(),
);

/** create Taxdetails - post */
export const createTaxdetails = createAction(
  '[Individual Taxdetails] create Individual Taxdetails',
  props<{request: TaxdetailsPayload}>(),
);

export const createTaxdetailsSuccess = createAction(
  '[Individual Taxdetails]  create Individual Taxdetails Success',
  props<{response: Taxdetails}>(),
);

export const createTaxdetailsFailure = createAction(
  '[Individual Taxdetails]  create Individual Taxdetails Failure',
  props<{error: HttpErrorResponse}>(),
);

/** patch taxdetails */

export const updateTaxdetails = createAction(
  '[Individual Taxdetails] update Individual Taxdetails',
  props<{request: Patch[]}>(),
);

export const updateTaxdetailsSuccess = createAction(
  '[Individual Taxdetails]  update Individual Taxdetails Success',
  props<{response: Taxdetails}>(),
);

export const updateTaxdetailsFailure = createAction(
  '[Individual Taxdetails]  update Individual Taxdetails Failure',
  props<{error: HttpErrorResponse}>(),
);

/** create Isaaccountdetails - post */
export const createIsaaccountdetails = createAction(
  '[Individual Isaaccountdetails] create Individual Isaaccountdetails',
  props<{request: IsadetailsPayload}>(),
);

export const createIsaaccountdetailsSuccess = createAction(
  '[Individual Isaaccountdetails]  create Individual Isaaccountdetails Success',
  props<{response: Isadetails}>(),
);

export const createIsaaccountdetailsFailure = createAction(
  '[Individual Isaaccountdetails]  create Individual Isaaccountdetails Failure',
  props<{error: HttpErrorResponse}>(),
);

/** fetchIsaaccountdetails from the API by individual ID. */
export const fetchIsaaccountdetails = createAction('[Individual Isaaccountdetails] Fetch Isaaccountdetails');

export const fetchIsaaccountdetailsSuccess = createAction(
  '[Individual Isaaccountdetails] Fetch Isaaccountdetails Success',
  props<{response: Isadetails[]}>(),
);

export const fetchIsaaccountdetailsFail = createAction(
  '[Individual Isaaccountdetails] Fetch Isaaccountdetails Fail',
  props<{error: HttpErrorResponse}>(),
);

/** Remove isa account. */
export const deleteIsaaccount = createAction('[Individual Isaaccountdetails] Remove isaaccount', props<{id: number}>());

export const deleteIsaaccountSuccess = createAction(
  '[Individual Isaaccountdetails] Remove isaaccount Success',
  props<{response: DeletedItem}>(),
);

export const deleteIsaaccountFail = createAction(
  '[Individual Isaaccountdetails] Remove isaaccount Fail',
  props<{error: HttpErrorResponse}>(),
);

// reset delete success
export const resetDeleteIsaaccountSuccess = createAction(
  '[Individual Isaaccountdetails] Reset Delete isaaccount Success',
);

//fetch MonthendTypes
export const fetchMonthendTypes = createAction('[Individuals monthendTypes] fetch Individuals monthendTypes');

export const fetchMonthendTypesSuccess = createAction(
  '[Individuals monthendTypes] fetch Individuals monthendTypes Success',
  props<{response: MonthendTypes[]}>(),
);

export const fetchMonthendTypesFailure = createAction(
  '[Individuals monthendTypes] fetch Individuals monthendTypes Failure',
  props<{error: HttpErrorResponse}>(),
);
//end

//fetch QuarterendTypes
export const fetchQuarterendTypes = createAction('[Individuals QuarterendTypes] fetch Individuals QuarterendTypes');

export const fetchQuarterendTypesSuccess = createAction(
  '[Individuals QuarterendTypes] fetch Individuals QuarterendTypes Success',
  props<{response: MonthendTypes[]}>(),
);

export const fetchQuarterendTypesFailure = createAction(
  '[Individuals QuarterendTypes] fetch Individuals QuarterendTypes Failure',
  props<{error: HttpErrorResponse}>(),
);
//end

// get individual summary
export const fetchIndividualSummary = createAction('[Individual summary] get Individual summary');

export const fetchIndividualSummarySuccess = createAction(
  '[Individual summary]  get Individual summary Success',
  props<{response: IndividualSummary}>(),
);

export const fetchIndividualSummaryFailure = createAction(
  '[Individual summary]  get Individual summary Failure',
  props<{error: HttpErrorResponse}>(),
);

// get individual overview
export const fetchIndividualOverview = createAction('[Individual Overview] get Individual Overview');

export const fetchIndividualOverviewSuccess = createAction(
  '[Individual Overview]  get Individual Overview Success',
  props<{response: IndividualOverview}>(),
);

export const fetchIndividualOverviewFailure = createAction(
  '[Individual Overview]  get Individual Overview Failure',
  props<{error: HttpErrorResponse}>(),
);

export const completeIndividualDma = createAction('[Individual completeIndividualDma] Complete Individual Dma');

export const completeIndividualDmaFailure = createAction(
  '[Individual completeIndividualDma] Complete Individual Dma Fail',
  props<{error: HttpErrorResponse}>(),
);

export const completeIndividualDmaSuccess = createAction(
  '[Individual completeIndividualDma] Complete Individual Dma Success',
  props<{response: {success: boolean}}>(),
);
