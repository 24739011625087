import {EnvironmentConfigurationModel} from '@app/shared/models/environment-configuration.model';

export const environment: EnvironmentConfigurationModel = {
  // TODO: Configure QA environment for Matiolli Woods
  applicationName: 'McInroy & Wood',
  startingURL: 'applications',
  onBoardingOnly: true,
  version: '1',
  tenant: 'mcInroy',
  production: true,
  enableBankValidationLookup: true,
  loqateAccountValidationKey: 'YZ53-TY93-FY32-EY54',
  loqateAddressValidationKey: 'KZ69-MA93-TH88-WD97',
  loqateServiceBankValidationUrl:
    'https://services.postcodeanywhere.co.uk/BankAccountValidation/Interactive/Validate/v2.00/json3.ws?',
  loqateServiceAddressValidationUrl: 'https://api.addressy.com/Capture/Interactive/Find/v1.10/json3.ws',
  loqateServiceRetrieveAddressUrl: 'https://api.addressy.com/Capture/Interactive/Retrieve/v1.00/json3.ws',
  linkToFAQ: 'https://www.mcinroy-wood.co.uk/',
  apiEndpoint: 'https://newclient-api.mcinroy-wood.co.uk/api/v1',
  verifyEmailEndpoint: 'Account/EnterEmailVerificationCode',
  sessionTimeoutInSeconds: 570,
  sessionExtendTimeInSeconds: 30,

  allowedUrls: ['https://newclient-api.mcinroy-wood.co.uk'],
  sendAccessToken: true,
  codeFlowAuthConfig: {
    issuer: 'https://newclient-auth.mcinroy-wood.co.uk',
    redirectUri: window.location.origin + '/callback',
    clientId: 'wxlclient',
    responseType: 'code',
    scope: 'openid profile email offline_access WXLAPI IdentityServerApi',
    postLogoutRedirectUri: 'https://newclient-app.mcinroy-wood.co.uk/launch/',
  },
  pwdFlowAuthConfig: {
    issuer: 'https://newclient-auth.mcinroy-wood.co.uk',
    redirectUri: window.location.origin + '/callback',
    clientId: 'wxlapp-mobile',
    dummyClientSecret: 'secret',
    scope: 'openid profile email offline_access WXLAPI IdentityServerApi',
    oidc: false,
  },

  registrationRoute: '/Account/Register',
  loginRoute: '/Account/Login',
  returnUrlRoute: '/connect/authorize/callback',
  deepLink: {
    firebaseLink:
      'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBlXrOCdRtX6C7_InFSVlfaC9PtdvsKk9s',
    dynamicLinkInfo: {
      domainUriPrefix: 'https://tillermcinroyprod.page.link',
      endpoint: '/eidv',
      iosInfo: {
        iosBundleId: 'uk.co.mcinroywood.wxl',
        iosCustomScheme: 'uk.co.mcinroywood.wxl',
        iosAppStoreId: '6476776997',
      },
      androidInfo: {
        androidPackageName: 'uk.co.mcinroywood.wxl.prod',
      },
      navigationInfo: {
        enableForcedRedirect: '1',
      },
    },
    suffix: {
      option: 'UNGUESSABLE',
    },
  },

  appStore: {
    appStoreUrl: 'https://apps.apple.com/gb/app/tiller-wealthxcel-onboard/id6476776997',
    playStoreUrl: 'https://play.google.com/store/apps/details?id=uk.co.mcinroywood.wxl.prod',
  },
  zeroMandateRouteEnabled: true,
};
