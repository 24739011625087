import {Component, Input} from '@angular/core';

@Component({
  selector: 'abl-colour-marker',
  templateUrl: './colour-marker.component.html',
  styleUrls: ['./colour-marker.component.scss'],
})
export class ColourMarkerComponent {
  @Input() hexCode: string;
  @Input() square = false;
  @Input() circle = false;
}
