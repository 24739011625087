import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {environment} from '../../../environments/environment';
import {BankAccountValidationResponse, LoqateBankAccountValidationRequest} from '@app/shared/models/bank-details.model';
import {debounceTime, map} from 'rxjs/operators';
import {LoqateFailError, RawLoqateResponse} from '@app/shared/models/loqate.model';
import {mapRawLoqateBankValidationResponse, mockRawLoqateResp} from '@app/services/loqate/loqate-utils';

@Injectable({
  providedIn: 'root',
})
export class LoqateBankValidationService {
  constructor(private http: HttpClient) {}

  validateBankAccount(
    request: LoqateBankAccountValidationRequest,
  ): Observable<BankAccountValidationResponse | LoqateFailError> {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const params = new HttpParams()
      .set('Key', environment.loqateAccountValidationKey)
      .set('AccountNumber', request.accountNumber)
      .set('SortCode', request.sortCode);
    const source = environment.enableBankValidationLookup
      ? this.http.get<RawLoqateResponse>(`${environment.loqateServiceBankValidationUrl}`, {
          headers,
          params,
        })
      : of(mockRawLoqateResp(request)).pipe(debounceTime(1000));
    return source.pipe(map(rawResponse => mapRawLoqateBankValidationResponse(rawResponse)));
  }
}
