import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomLoadingSpinnerComponent} from './custom-loading-spinner.component';

@NgModule({
  declarations: [CustomLoadingSpinnerComponent],
  exports: [CustomLoadingSpinnerComponent],
  imports: [CommonModule],
})
export class CustomLoadingSpinnerModule {}
