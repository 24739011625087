import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable, of} from 'rxjs';
import {Action, Store} from '@ngrx/store';
import {catchError, exhaustMap, filter, map, switchMap, withLatestFrom, tap} from 'rxjs/operators';
import {ErrorActions} from '@app/state/error';
import {
  createPortfolioContribution,
  createPortfolioContributionSuccess,
  closePortfolio,
  deletePortfolio,
  deletePortfolioContribution,
  deletePortfolioDirectDebitContributionSuccess,
  deletePortfolioSuccess,
  fetchAllPortfolioActivities,
  fetchInvestableProportionsOptions,
  fetchInvestableProportionsOptionsSuccess,
  fetchPortfolio,
  fetchPortfolioActivitiesSuccess,
  fetchPortfolioById,
  fetchPortfolioContribution,
  fetchPortfolioContributionsSuccess,
  fetchPortfolioContributionTypes,
  fetchPortfolioContributionTypesSuccess,
  fetchPortfolioOverviewSuccess,
  fetchPortfolioPaymentDays,
  fetchPortfolioPaymentDaysSuccess,
  fetchPortfolios,
  fetchPortfoliosInvestments,
  fetchPortfoliosInvestmentsSuccess,
  fetchPortfoliosSuccess,
  fetchPortfolioSuccess,
  fetchSelectedPortfolioActivities,
  fetchSourceOfFundsOptions,
  fetchSourceOfFundsOptionsSuccess,
  updatePortfolio,
  updatePortfolioContribution,
  updatePortfolioContributionSuccess,
  updatePortfolioProductId,
  updatePortfolioStrategyId,
  updatePortfolioSuccess,
  withdrawPortfolioFunds,
  withdrawPortfolioFundsSuccess,
  closePortfolioSuccess,
  fetchPortfoliosCharges,
  fetchPortfoliosChargesSuccess,
  fetchConnectedPortfolios,
  fetchConnectedPortfoliosSuccess,
  createInvite,
  createInviteSuccess,
  confirmInvite,
  confirmInviteSuccess,
  fetchConnectedManagePortfolios,
  fetchConnectedManagePortfoliosSuccess,
  removeConnectedAccount,
  removeConnectedAccountSuccess,
  acceptConnectedAccount,
  acceptConnectedAccountSuccess,
  declineConnectedAccount,
  declineConnectedAccountSuccess,
  initialisePaymentSuccess,
  initialisePaymentValue,
  fetchPaymentSupportedSuccess,
  fetchPaymentSupported,
  fetchPaymentSupportedFailure,
  fetchPaymentSuccessStatus,
  fetchPaymentSuccessResponse,
  fetchPaymentFailedResponse,
  fetchPayment,
  fetchPaymentSuccess,
  fetchItemisedCharges,
  fetchItemisedChargesResponse
} from '@app/state/portfolio/portfolio.actions';
import {PortfolioService} from '@app/services/portfolio/portfolio.service';
import {
  PaymentResponse,
  selectDirectDebitContribution,
  selectInvestableProportionsOptions,
  selectPaymentAddMoney,
  selectPaymentIsSupported,
  selectPortfolio,
  selectPortfolioClosure,
  selectProductId,
  selectSelectedPortfolioId,
  selectSourceOfFundsOptions,
  selectStrategyId,
  selectPaymentIsSupportedSuccess
} from '@app/state/portfolio/portfolio.selectors';
import {PortfolioState} from '@app/state/portfolio/portfolio.state';
import {DashboardState, MandateState} from '@app/state';
import {selectOnboardingMandateId} from '@app/state/onboarding/onboarding.selectors';
import {createMandateSuccess} from '@app/state/mandate/mandate.actions';
import {RouterState, selectUrl} from '@app/state/root.selectors';
import {routerNavigationAction} from '@ngrx/router-store';
import {JourneyStepEnum} from '@app/shared/enums/journey-step.enum';
import {AccountsPagesEnum, RootPagesEnum} from '@app/shared/enums/dashboard-pages.enum';

@Injectable()
export class PortfolioEffects {
  fetchPortfolios$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchPortfolios),
      switchMap((action) =>
        this.portfolioService.fetchPortfolios(action.includeConnectedAccounts).pipe(
          map(response => fetchPortfoliosSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  fetchConnectedPortfolios$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchConnectedPortfolios),
      switchMap(() =>
        this.portfolioService.fetchConnectedPortfolios().pipe(
          map(response => fetchConnectedPortfoliosSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  fetchConnectedManagePortfolios$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchConnectedManagePortfolios),
      switchMap(() =>
        this.portfolioService.fetchConnectedManagePortfolios().pipe(
          map(response => fetchConnectedManagePortfoliosSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  createInvite$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(createInvite),
      switchMap(action => {
        return this.portfolioService.createInvite(action.payload).pipe(
          map(response => createInviteSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        );
      }),
    ),
  );
  confirmInvite$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(confirmInvite),
      switchMap(action => {
        return this.portfolioService.confirmInvite(action.payload).pipe(
          map(response => confirmInviteSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        );
      }),
    ),
  );

  removeConnectedAccount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(removeConnectedAccount),
      switchMap(action => {
        return this.portfolioService.removeConnectedAccount(action.id).pipe(
          map(response => removeConnectedAccountSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        );
      }),
    ),
  );

  declineConnectedAccount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(declineConnectedAccount),
      switchMap(action => {
        return this.portfolioService.declineConnectedAccount(action.id).pipe(
          map(response => declineConnectedAccountSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        );
      }),
    ),
  );

  acceptConnectedAccount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(acceptConnectedAccount),
      switchMap(action => {
        return this.portfolioService.acceptConnectedAccount(action.id).pipe(
          map(response => acceptConnectedAccountSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        );
      }),
    ),
  );

  fetchPortfoliosCharges$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchPortfoliosCharges),
      switchMap(() =>
        this.portfolioService.fetchPortfoliosCharges().pipe(
          map(response => fetchPortfoliosChargesSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  routeChangeFetchPortfolio$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigationAction),
      filter(
        navigation =>
          navigation.payload.event.url.includes(RootPagesEnum.Onboarding) &&
          !navigation.payload.event.url.includes(JourneyStepEnum.Gdpr),
      ),
      map(() => fetchPortfolio()),
    ),
  );

  fetchPortfolio$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchPortfolio, createMandateSuccess),
      withLatestFrom(this.mandateStore.select(selectOnboardingMandateId)),
      filter(([, mandateId]) => !!mandateId),
      switchMap(([, mandateId]) =>
        this.portfolioService.fetchPortfolioByMandateId(mandateId).pipe(
          map(response => fetchPortfolioSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  fetchPortfolioById$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchPortfolioById),
      withLatestFrom(this.portfolioStore.select(selectSelectedPortfolioId)),
      switchMap(([{portfolioId}, id]) =>
        this.portfolioService.fetchPortfolioById(portfolioId ? portfolioId : id).pipe(
          map(response => fetchPortfolioSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  fetchPortfolioOverview$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchPortfolioSuccess, updatePortfolioSuccess),
      withLatestFrom(this.routerState.select(selectUrl)),
      filter(([, currentUrl]) =>
        // Fetch Portfolio when on selected routes navigated.
        [JourneyStepEnum.InvestmentReview, AccountsPagesEnum.Portfolio].some(url => currentUrl.includes(url)),
      ),
      switchMap(([portfolioResp]) =>
        this.portfolioService.fetchPortfolioOverviewByPortfolioId(portfolioResp.response.id).pipe(
          map(response => fetchPortfolioOverviewSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  fetchPortfolioContributions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchPortfolioContribution),
      withLatestFrom(this.portfolioStore.select(selectSelectedPortfolioId), this.routerState.select(selectUrl)),
      filter(([, , url]) => url.includes(AccountsPagesEnum.RegularSavings)),
      switchMap(([, id]) =>
        this.portfolioService.fetchPortfolioContribution(id).pipe(
          map(response => fetchPortfolioContributionsSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  fetchPortfolioContributionTypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchPortfolioContributionTypes),
      switchMap(() =>
        this.portfolioService.fetchPortfolioContributionTypes().pipe(
          map(response => fetchPortfolioContributionTypesSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  fetchPortfolioPaymentDays$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchPortfolioPaymentDays),
      switchMap(() =>
        this.portfolioService.fetchPortfolioPaymentDays().pipe(
          map(response => fetchPortfolioPaymentDaysSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  fetchPortfoliosInvestments$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchPortfoliosInvestments),
      switchMap((action) =>
        this.portfolioService.fetchInvestments(action.includeConnectedAccounts).pipe(
          map(response => fetchPortfoliosInvestmentsSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  fetchSourceOfFunds$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchSourceOfFundsOptions),
      withLatestFrom(this.portfolioStore.select(selectSourceOfFundsOptions)),
      filter(([, sourceOfFundsOptions]) => !sourceOfFundsOptions.length),
      switchMap(() =>
        this.portfolioService.fetchSourceOfFundsOptions().pipe(
          map(response => fetchSourceOfFundsOptionsSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  fetchInvestableProportions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchInvestableProportionsOptions),
      withLatestFrom(this.portfolioStore.select(selectInvestableProportionsOptions)),
      filter(([, investableProportionOptions]) => !investableProportionOptions.length),
      switchMap(() =>
        this.portfolioService.fetchInvestableProportions().pipe(
          map(response => fetchInvestableProportionsOptionsSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  createPortfolioContribution$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(createPortfolioContribution),
      withLatestFrom(this.portfolioStore.select(selectPortfolio)),
      exhaustMap(([{request}, portfolio]) =>
        this.portfolioService.createPortfolioContribution(request, portfolio.id).pipe(
          map(response => createPortfolioContributionSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  fetchAllPortfolioActivities$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAllPortfolioActivities),
      switchMap(() =>
        this.portfolioService.fetchActivities().pipe(
          map(response => fetchPortfolioActivitiesSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  fetchPortfolioActivitiesById$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchSelectedPortfolioActivities),
      withLatestFrom(this.portfolioStore.select(selectSelectedPortfolioId)),
      switchMap(([, id]) =>
        this.portfolioService.fetchActivitiesById(id).pipe(
          map(response => fetchPortfolioActivitiesSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  updatePortfolioContribution$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePortfolioContribution),
      withLatestFrom(
        this.portfolioStore.select(selectPortfolio),
        this.portfolioStore.select(selectDirectDebitContribution),
      ),
      exhaustMap(([{request}, portfolio, contribution]) =>
        this.portfolioService.updatePortfolioContribution(request, portfolio.id, contribution.id).pipe(
          map(response => updatePortfolioContributionSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  deletePortfolioContribution$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(deletePortfolioContribution),
      withLatestFrom(
        this.portfolioStore.select(selectPortfolio),
        this.portfolioStore.select(selectDirectDebitContribution),
      ),
      exhaustMap(([, portfolio, contribution]) =>
        this.portfolioService.deletePortfolioContribution(portfolio.id, contribution.id).pipe(
          map(response => deletePortfolioDirectDebitContributionSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  updatePortfolio$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePortfolio),
      withLatestFrom(this.portfolioStore.select(selectPortfolio)),
      exhaustMap(([{request}, portfolio]) =>
        this.portfolioService.updatePortfolio(request, portfolio.id).pipe(
          map(response => updatePortfolioSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  updatePortfolioProductId$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePortfolioProductId),
      withLatestFrom(this.portfolioStore.select(selectProductId), this.portfolioStore.select(selectPortfolio)),
      map(([, productId, portfolio]) => ({
        patch: [{op: 'replace', path: '/productId', value: productId}],
        portfolioId: portfolio.id,
      })),
      exhaustMap(({patch, portfolioId}) =>
        this.portfolioService.updatePortfolio(patch, portfolioId).pipe(
          map(response => updatePortfolioSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  updatePortfolioStrategyId$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePortfolioStrategyId),
      withLatestFrom(this.portfolioStore.select(selectStrategyId), this.portfolioStore.select(selectPortfolio)),
      map(([, strategyId, portfolio]) => ({
        patch: [{op: 'replace', path: '/strategyId', value: strategyId}],
        portfolioId: portfolio.id,
      })),
      exhaustMap(({patch, portfolioId}) =>
        this.portfolioService.updatePortfolio(patch, portfolioId).pipe(
          map(response => updatePortfolioSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  deletePortfolio$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(deletePortfolio),
      withLatestFrom(this.portfolioStore.select(selectPortfolio)),
      exhaustMap(([, portfolio]) =>
        this.portfolioService.deletePortfolio(portfolio.id).pipe(
          map(response => deletePortfolioSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  closePortfolio$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(closePortfolio),
      withLatestFrom(this.portfolioStore.select(selectPortfolio), this.portfolioStore.select(selectPortfolioClosure)),
      exhaustMap(([, portfolio, closePortfolioRequest]) =>
        this.portfolioService.closePortfolio(portfolio.id, closePortfolioRequest).pipe(
          map(response => closePortfolioSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  withdrawFunds$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(withdrawPortfolioFunds),
      withLatestFrom(this.portfolioStore.select(selectPortfolio)),
      switchMap(([{withdrawalAmount}, portfolio]) =>
        this.portfolioService.withdrawPortfolioFunds(withdrawalAmount, portfolio.id).pipe(
          map(response => withdrawPortfolioFundsSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  initialisePayment$: Observable<Action> = createEffect(() =>
  this.actions$.pipe(
    ofType(initialisePaymentValue),
    withLatestFrom(this.portfolioStore.select(selectPaymentAddMoney)),
    switchMap(([{addAmount,portfolioId}]) =>
      this.portfolioService.initialisePayment(addAmount, portfolioId).pipe(
        map(response => initialisePaymentSuccess({response})),
        catchError(error => of(ErrorActions.newError({backEndError: error}))),
      ),
    ),
  ),
);

fetchPaymentSupported$: Observable<Action> = createEffect(() =>
  this.actions$.pipe(
    ofType(fetchPaymentSupported),
    withLatestFrom(this.portfolioStore.select(selectSelectedPortfolioId)),
    switchMap(([, portfolioId]) =>
      this.portfolioService.getPaymentSupported(portfolioId).pipe(
        map(response => fetchPaymentSupportedSuccess({response})),
        catchError(error => of(ErrorActions.newError({backEndError: error}), fetchPaymentSupportedFailure({error}))),
      ),
    ),
  ),
);
fetchPaymentSuccessStatus$: Observable<Action> = createEffect(() =>
this.actions$.pipe(
  ofType(fetchPaymentSuccessStatus),
  withLatestFrom(this.portfolioStore.select(selectPortfolio)),
  switchMap(([{portfolioId, id, status, message}]) =>
    this.portfolioService.getPaymentSuccessStatus(portfolioId, id, status, message).pipe(
      map(response => fetchPaymentSuccessResponse({response})),
      catchError(error => of(ErrorActions.newError({backEndError: error}))),
    ),
  ),
),
);

fetchPayment$:Observable<Action> = createEffect(() =>
this.actions$.pipe(
  ofType(fetchPayment),
  switchMap(({paymentId}) =>
    this.portfolioService.getPayment(paymentId).pipe(
      map(response => fetchPaymentSuccess({response})),
      catchError(error => of(ErrorActions.newError({backEndError: error}))),
    ),
  ),
),
);

getItemisedChargeDetails$:Observable<Action> = createEffect(() =>
this.actions$.pipe(
  ofType(fetchItemisedCharges),
  withLatestFrom(this.portfolioStore.select(selectPortfolio)),
  switchMap(([{}, portfolio]) =>
    this.portfolioService.getItemisedCharge(portfolio).pipe(
      map(response => fetchItemisedChargesResponse({response})),
      catchError(error => of(ErrorActions.newError({backEndError: error}))),
    ),
  ),
),
)

  constructor(
    private actions$: Actions,
    private portfolioStore: Store<PortfolioState>,
    private mandateStore: Store<MandateState.MandateState>,
    private dashboardStore: Store<DashboardState.DashboardState>,
    private routerState: Store<RouterState>,
    private portfolioService: PortfolioService,
  ) {}
}
