import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromProductState from '@app/state/product/product.state';

export const getProductState = createFeatureSelector<fromProductState.ProductState>(fromProductState.featureKey);

/** Select products. */
export const selectProducts = createSelector(getProductState, state => state.products);

/** Select products by mandateId. */
export const selectProductsByMandateId = createSelector(getProductState, state => state.products);

/** Select product comparison. */
export const selectProductsComparisons = createSelector(getProductState, state => state.comparison);

/** Select product declaration. */
export const selectProductDeclaration = createSelector(getProductState, state => state.declaration?.body);

/** Select product transfer type auth statement. */
export const selectTransferTypeAuthStatement = createSelector(
  getProductState,
  state => state.transferTypeAuthStatement,
);
