import { createAction, props } from '@ngrx/store';
import { OverseasEmployment, OverseasEmploymentResponse } from '@app/shared/models/overseas-employment.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Patch } from '@app/shared/models/patch.model';

export const saveOverseasEmploymentDetails = createAction(
  '[OverseasEmployment/API] Save OverseasEmployment Details',
  props<{request: OverseasEmployment}>());

export const saveOverseasEmploymentDetailsSuccess = createAction(
  '[OverseasEmployment/API] Save OverseasEmployment Details Success',
  props<{response : OverseasEmploymentResponse }>()
);
export const saveOverseasEmploymentDetailsFail = createAction(
  '[OverseasEmployment/API] Save OverseasEmployment Details Fail',
  props<{ error: any }>()
);
export const fetchOverseasEmploymentDetails = createAction('[Overseas Employment Details] Fetch Employment Details');

export const fetchOverseasEmploymentDetailsSuccess = createAction(
  '[Overseas Employment Details] Fetch Overseas Employment Success',
  props<{response: OverseasEmploymentResponse}>(),
);

export const fetchOverseasEmploymentDetailsFail = createAction(
  '[Overseas Employment Details] Fetch Overseas Employment Fail',
  props<{error: HttpErrorResponse}>(),
);

export const updateOverseasEmployment = createAction('[Overseas Employment Details] Update Overseas Employment Details', props<{request: Patch[]}>());

export const updateOverseasEmploymentSuccess = createAction(
  '[Overseas Employment Details] Update Overseas Employment Details Success',
  props<{response: OverseasEmploymentResponse}>(),
);

export const updateOverseasEmploymentFailure = createAction(
  '[Overseas Employment Details] Update Overseas Employment Details Failure',
  props<{error: HttpErrorResponse}>(),
);

