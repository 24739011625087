import {createAction, props} from '@ngrx/store';
import {TaxDetail, TaxDetailCreateRequest} from '@app/shared/models/tax-detail.model';
import {DeletedItem} from '@app/shared/models/deleted-item.model';
import {HttpErrorResponse} from '@angular/common/http';

/** Fetch tax details by individual ID. */
export const fetchTaxDetailsByIndividualId = createAction(
  '[Tax Details] Fetch Tax Details By Individual ID',
);

export const fetchTaxDetailsByIndividualIdSuccess = createAction(
  '[Tax Details] Fetch Tax Details By Individual ID Success',
  props<{response: TaxDetail[]}>(),
);

export const fetchTaxDetailsByIndividualIdFail = createAction(
  '[Tax Details] Fetch Tax Details By Individual ID Fail',
  props<{error: HttpErrorResponse}>(),
);

/** Create tax detail. */
export const createTaxDetail = createAction(
  '[Tax Detail] Create Tax Detail',
  props<{request: TaxDetailCreateRequest}>(),
);

export const createTaxDetailSuccess = createAction(
  '[Tax Details] Create Tax Detail Success',
  props<{response: TaxDetail}>(),
);

export const createTaxDetailFail = createAction(
  '[Tax Details] Create Tax Detail Fail',
  props<{error: HttpErrorResponse}>(),
);

/** Remove tax detail. */
export const removeTaxDetail = createAction(
  '[Tax Details] Remove Tax Detail',
  props<{id: number}>(),
);

export const removeTaxDetailSuccess = createAction(
  '[Tax Details] Remove Tax Success',
  props<{response: DeletedItem}>(),
);

export const removeTaxDetailFail = createAction(
  '[Tax Details] Remove Tax Fail',
  props<{error: HttpErrorResponse}>(),
);





