import {WxlOnboardAPIError} from '@app/shared/models/error.model';

export const featureKey = 'error';

export interface ErrorState {
  errors: WxlOnboardAPIError[];
  isLoading: boolean;
}

export const initialState: ErrorState = {
  errors: [],
  isLoading: false,
};
