import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {environment} from 'environments/environment';
import {Address, AddressResponse, V2Address} from '@app/shared/models/address.model';
import {DeletedItem} from '@app/shared/models/deleted-item.model';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  constructor(private http: HttpClient) {}

  getAddressesForIndividual(individualId: number): Observable<Address[]> {
    return this.http.get<Address[]>(`${environment.apiEndpoint}/addresses/individuals/${individualId}`);
  }

  /** Remove address only takes an address ID and returns the deleted item. */
  removeAddress(id: number): Observable<DeletedItem> {
    return this.http.delete<DeletedItem>(`${environment.apiEndpoint}/addresses/${id}`);
  }

  addAddress(address: V2Address): Observable<AddressResponse> {
    return this.http.post<AddressResponse>(`${environment.apiEndpoint}/addresses`, {...address, id: null});
  }

  updateAddress(address: V2Address): Observable<AddressResponse> {
    return this.http.put<AddressResponse>(`${environment.apiEndpoint}/addresses/${address.id}`, address);
  }

  fetchAddressesForIndividual(individualId: number): Observable<AddressResponse[]> {
    return this.http.get<AddressResponse[]>(`${environment.apiEndpoint}/addresses/individuals/${individualId}`);
  }

  fetchPrincipalAddress(mandateId: number, addressTypeId: number): Observable<AddressResponse> {
    return this.http.get<AddressResponse>(
      `${environment.apiEndpoint}/addresses/principal?mandateId=${mandateId}&addressTypeId=${addressTypeId}`,
    );
  }
}
