import {Injectable} from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import {AuthOidcService} from './auth-oidc.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(private authService: AuthOidcService, private router: Router) {}

  canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> {
    if (this.authService.isMobileLaunchMode) {
      return this.authService
        .validateTokenForMobile()
        .pipe(map(res => (res.active ? res.active : this.router.parseUrl('/launch'))));
    } else {
      return this.authService.hasValidToken || this.router.parseUrl('/launch');
    }
  }
}
