export enum LinksEnum {
  ClientAgreement = 'https://mattioliwoods.com/sites/default/files/2022-05/MWise%20Client%20Agreement.pdf',
  PershingTermsOfBusiness = 'https://mattioliwoods.com/sites/default/files/2022-05/Pershing%20Terms%20of%20Business_0.pdf',
  FAQ = 'https://mattioliwoods.com/MWise-FAQs',
  Products = 'https://mattioliwoods.com/MWise-FAQs#Products',
  Investment = 'https://mattioliwoods.com/MWise-FAQs#2636',
  PaymentsWithdrawal = 'https://mattioliwoods.com/MWise-FAQs#PaymentsAndWithdrawals',
  Onboarding = 'https://mattioliwoods.com/MWise-FAQs#Onboarding',
  Funding = 'https://mattioliwoods.com/MWise-FAQs#2633',
  InvestmentRiskFAQ = 'https://mattioliwoods.com/MWise-FAQs#2614',
  DiscretionaryPortfolio = 'https://mattioliwoods.com/MWise-FAQs#2639',
  SourceOfFundsFAQ = 'https://mattioliwoods.com/MWise-FAQs#2618',
  RiskFAQ = 'https://mattioliwoods.com/MWise-FAQs#Risk',
  ProjectionCalculation = 'https://mattioliwoods.com/MWise-FAQs#2654',
  Calculator = 'https://mattioliwoods.com/MWise#Calculator',
  Contacts = 'https://mattioliwoods.com/contacts',
  MobileFAQ = 'https://mattioliwoods.com/MWise-FAQs#2727',
  Funds = 'https://mattioliwoods.com/MWise-FAQs#Funds',
  StrategyFAQ = 'https://mattioliwoods.com/MWise-FAQs#InvestmentStrategies',
  InvestmentFeeFAQ = 'https://mattioliwoods.com/MWise-FAQs#Fees',
  privacyPolicy = 'https://mattioliwoods.com/privacy',
  TrueLayerPrivacyPolicy = 'https://truelayer.com/legal/privacy/',
  TrueLayerTermsOfBusiness = 'https://truelayer.com/legal/enduser_tos/',
}
export enum mcInroyLinks {
  Contacts = 'https://www.mcinroy-wood.co.uk/contact-us',
}
