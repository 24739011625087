import {RiskTarget, TargetRiskQuestion, TargetRiskScore} from '@app/shared/models/portfolio/target-risk.model';

export const attitudeToRiskFeatureKey = 'attitude-to-risk';

export interface AttitudeToRiskState {
  questions: TargetRiskQuestion[];
  currentQuestion: number | null;
  score: TargetRiskScore | null;
  confirmedCurrentRisk: RiskTarget | null;
  numberOfAttempts: number;
  fetchScoreUpdateSuccess: boolean;
  confirmScoreUpdateSuccess: boolean;
  maximumAttempts: number;
  isChangeRequired: boolean;
  lastUpdatedDate: string;
  lastChangedMonth: number;
  isChangeMandatory: boolean;
  directDebitClearDownDate: Date | null;
}

export const initialState: AttitudeToRiskState = {
  questions: [],
  currentQuestion: 1,
  score: null,
  confirmedCurrentRisk: null,
  numberOfAttempts: 0,
  fetchScoreUpdateSuccess: false,
  confirmScoreUpdateSuccess: false,
  maximumAttempts: 3,
  isChangeRequired: false,
  lastUpdatedDate: null,
  lastChangedMonth: 0,
  isChangeMandatory: false,
  directDebitClearDownDate: null
};
