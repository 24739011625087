import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromSourceOfFunds from './source-of-funds.reducer';
import * as fromSourceOfFundsState from './source-of-funds.state';
import { SourceOfFundsEffects } from './source-of-funds.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromSourceOfFundsState.featureKey, fromSourceOfFunds.reducer),
    EffectsModule.forFeature([SourceOfFundsEffects])
  ],
  declarations: []
})
export class SourceOfFundsStateModule {
}
