import {ProductDeclaration} from '@app/shared/models/portfolio/product.model';
import {ProductCodeEnum, ProductIdEnum} from '@app/shared/enums/product.enum';

const isaDeclarationTemplate = `
<p>Our custodian, Pershing Securities Limited, manages the MWise Stocks & Shares ISA. Please confirm the declaration below.
  <br>
  <a href="https://mattioliwoods.com/MWise-FAQs#2627" target="_blank">Find out more here</a>.
</p>

<strong>I authorise Pershing Securities Limited to act as my ISA Plan Manager and I declare that:</strong>

<ul>
  <li>All subscriptions made and to be made, belong to me, and any future subscriptions will be drawn on an account belonging to me.</li>
  <li>I am 18 years of age or over.</li>
  <li>I have not subscribed and will not subscribe more than the overall subscription limit in total to any combination of permitted ISAs in the same tax year. </li>
  <li>I have not subscribed and will not subscribe to another Stocks & Shares ISA in the same year that I subscribe to this Stocks & Shares ISA.</li>
  <li>Any transfer instructions also constitute an application to subscribe to a Pershing Stocks and Share ISA for the current tax year, and for each subsequent tax year, unless I notify you otherwise.</li>
  <li>I am resident and ordinarily resident in the UK for tax purposes, or if not so resident, either perform duties which, by virtue of Section 28 of the Income Tax (Earnings & Pensions) Act 2003 (Crown employees serving overseas), are treated as being performed in the United Kingdom, or I am married to or in a civil partnership with a person who performs such duties.  I will inform Pershing Securities Limited if I cease to be resident and ordinarily resident or to perform such duties or to be married to a person who performs such duties or in a civil partnership with a person who performs such duties.</li>
  <li>The information given within this form has been completed to the best of my knowledge.</li>
</ul>
<strong>I authorise Pershing Securities Limited:</strong>
<ul>
  <li>To hold my cash subscriptions, ISA Investments, interest, dividends and any other rights or proceeds in respect of these investments and any other cash.</li>
  <li>To make on my behalf any claims to relief from tax in respect of ISA Investments.</li>
</ul>
<p>I understand that Pershing Securities Limited will notify me if by reason of failing to meet the provisions of the ISA rules my account is or will become void.</p>
<p>I will inform Pershing Securities Limited of any change of circumstances affecting the information given in this form without delay.</p>
`;

export const ISA_DECLARATION: ProductDeclaration = {
  productId: ProductIdEnum.Isa,
  productCode: ProductCodeEnum.Isa,
  body: isaDeclarationTemplate,
};
