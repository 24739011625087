import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable, of} from 'rxjs';
import {Action} from '@ngrx/store';
import * as ReportActions from './report.actions';
import {createReport, downloadReport, downloadReportSuccess, fetchReports} from './report.actions';
import {catchError, exhaustMap, map, switchMap} from 'rxjs/operators';
import {ReportService} from '@app/services/report/report.service';
import {ErrorActions} from '@app/state/error';
import Utils from '@app/shared/util/utils';
import {Platform} from '@angular/cdk/platform';

@Injectable()
export class ReportEffects {
  fetchReports$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchReports),
      switchMap(() =>
        this.reportService.fetchReports().pipe(
          map(response => ReportActions.fetchReportsSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    );
  });

  createReport: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(createReport),
      exhaustMap(({request}) =>
        this.reportService.createReport(request).pipe(
          map(response => ReportActions.createReportSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  downloadReport: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(downloadReport),
      switchMap(({id, contentType, fileName}) => {
        return this.reportService.downloadReport(id).pipe(
          map(response => {
            Utils.handleDownloadFile(this.platform, id, response, contentType, fileName);
            return downloadReportSuccess();
          }),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        );
      }),
    ),
  );

  constructor(private platform: Platform, private actions$: Actions, private reportService: ReportService) {}
}
