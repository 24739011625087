import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {catchError, exhaustMap, map, withLatestFrom} from 'rxjs/operators';
import {
  createRelationship,
  createRelationshipSuccess,
  loadRelationshipTypes,
  loadRelationshipTypesByEntityType,
  loadRelationshipTypesSuccess,
} from './relationship.actions';
import {RelationshipService} from '@app/services/relationship-service/relationship.service';
import {RelationshipState} from '.';
import {getRelationshipTypes} from './relationship.selectors';
import {ErrorActions} from '@app/state/error';

@Injectable()
export class RelationshipEffects {
  loadRelationshipTypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRelationshipTypes),
      exhaustMap(() =>
        this.relationshipService.getRelationshipTypes().pipe(
          map(response => loadRelationshipTypesSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  loadRelationshipTypesByEntityType$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRelationshipTypesByEntityType),
      withLatestFrom(this.store.select(getRelationshipTypes)),
      exhaustMap(([action, relationshipTypes]) => {
          return this.relationshipService.getRelationshipTypesByEntityType(action.entityTypeId).pipe(
            map(response => loadRelationshipTypesSuccess({response})),
            catchError(error => of(ErrorActions.newError({backEndError: error}))),
          );
      }),
    ),
  );

  createRelationship$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(createRelationship),
      exhaustMap(({request}) =>
        this.relationshipService.createRelationship(request).pipe(
          map(response => createRelationshipSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private relationshipService: RelationshipService,
    private store: Store<RelationshipState.RelationshipState>,
  ) {}
}
