import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Product, ProductComparison} from '@app/shared/models/portfolio/product.model';
import {environment} from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private http: HttpClient) {}

  fetchProducts(): Observable<Product[]> {
    return this.http.get<Product[]>(`${environment.apiEndpoint}/products`);
  }

  fetchProductsByMandateId(mandateId: number): Observable<Product[]> {
    return this.http.get<Product[]>(`${environment.apiEndpoint}/products?mandateId=` + mandateId);
  }

  fetchProductsComparisons(): Observable<ProductComparison[]> {
    return this.http.get<ProductComparison[]>(`${environment.apiEndpoint}/products/comparisons`);
  }
}
