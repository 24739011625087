import { createAction, props } from '@ngrx/store';
import { NotificationSummary } from '@app/shared/models/notification.model';

export const loadNotifications = createAction(
  '[Notifications] Load Notification Summary'
);

export const loadNotificationsSuccess = createAction(
  '[Notifications] Load Notification Summary Success',
  props<{ response: NotificationSummary }>()
);
