import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'environments/environment';
import {
  Email,
  EmailAddress,
  EmailChangeResponse,
  EmailTypes,
  EmailChangeWithCode,
  AdditionalEmailAddress,
} from '@app/shared/models/email-address.model';
import {ActivatedRoute} from '@angular/router';
import {DeletedItem} from '@app/shared/models/deleted-item.model';

@Injectable({
  providedIn: 'root',
})
export class EmailAddressService {
  constructor(private http: HttpClient, private route: ActivatedRoute) {}

  getEmailAddressesEntities(): Observable<EmailAddress[]> {
    return this.http.get<EmailAddress[]>(
      `${environment.apiEndpoint}/emails/entities/${this.route.snapshot.queryParams['e']}`,
    );
  }

  getEmailAddressesForIndividual(individualId: number): Observable<EmailAddress[]> {
    return this.http.get<EmailAddress[]>(`${environment.apiEndpoint}/emails/individuals/${individualId}`);
  }

  getEmailAddressesForEntity(entityId: number): Observable<EmailAddress[]> {
    return this.http.get<EmailAddress[]>(`${environment.apiEndpoint}/entities/${entityId}/emailAddresses`);
  }

  deleteEmailAddress(emailId: number): Observable<DeletedItem> {
    return this.http.delete<DeletedItem>(`${environment.apiEndpoint}/emails/${emailId}`);
  }

  confirmEmailAddress(request: EmailChangeWithCode): Observable<EmailChangeResponse> {
    return this.http.post<EmailChangeResponse>(`${environment.apiEndpoint}/emails/users/validate`, request);
  }

  changeEmailAddress(payload: Email) {
    return this.http.put<EmailChangeResponse>(`${environment.apiEndpoint}/emails/users`, payload);
  }

  getEmailsForUser(): Observable<EmailAddress[]> {
    return this.http.get<EmailAddress[]>(`${environment.apiEndpoint}/emails/user`);
  }

  getEmailTypes(objectTypeId?: number): Observable<EmailTypes[]> {
    return this.http.get<EmailTypes[]>(
      `${environment.apiEndpoint}/emails/references/types${!!objectTypeId ? `?objectTypeId=${objectTypeId}` : ''}`,
    );
  }
  createAdditionalEmailAddress(email: AdditionalEmailAddress): Observable<EmailAddress> {
    return this.http.post<EmailAddress>(`${environment.apiEndpoint}/emails/`, email);
  }
}
