import {Organisation} from '@app/shared/models/organisation.model';

export const featureKey = 'organisation';

export interface OrganisationState {
  organisations: Organisation[];
}

export const initialState: OrganisationState = {
  organisations: [],
};
