import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {JourneyHistoryStep} from '@app/shared/models/journey-history-step';

export const JourneyHistoryFeatureKey = 'journey-history';

export const adapter: EntityAdapter<JourneyHistoryStep> = createEntityAdapter<JourneyHistoryStep>();

export interface JourneyHistoryState extends EntityState<JourneyHistoryStep> {
  currentJourneyHistoryStep: JourneyHistoryStep;
  lastLoaded: string|null;
}

export const initialState: JourneyHistoryState = adapter.getInitialState({
  currentJourneyHistoryStep: null,
  lastLoaded: null,
  journeyInstanceId: 0,
});
