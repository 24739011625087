import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {
  Portfolio,
  ClosePortfolioRequest,
  PortfoliosCharges,
  ConnectedPortfolio,
  InvitePayload,
  ConfirmInvite,
  ConnectedManagePortfolio,
  ConnectedWrapper,
  ConfirmInviteSuccess,
  RemoveConnectedAccountResponse,
  InviteRequestSuccess,
  PaymentResponse,
  selectPaymentIsSupportedSuccess
} from '@app/shared/models/portfolio/portfolio.model';
import {Patch} from '@app/shared/models/patch.model';
import {map} from 'rxjs/operators';
import {
  IsaProvider,
  IsaTransfer,
  IsaTransferCreateRequest,
  IsaTransferType,
  IsaType,
} from '@app/shared/models/portfolio/isa.model';
import {
  Contribution,
  ContributionType,
  CreatePortfolioContributionRequest,
  DeleteDirectDebitContributionResponse,
  PaymentDay,
} from '@app/shared/models/portfolio/contribution.model';
import {PortfolioOverview} from '@app/shared/models/portfolio/overview.model';
import {InvestmentsOverview} from '@app/shared/models/portfolio/investments-overview.model';
import {Activity} from '@app/shared/models/portfolio/activity';
import {InvestableProportionOption, SourceOfFundsOption} from '@app/shared/models/portfolio/source-of-funds.model';
import { Payment } from '@app/shared/models/payment.model';
import { PaymentSupported } from '@app/shared/models/portfolio/paymentSupported.model';

@Injectable({
  providedIn: 'root',
})
export class PortfolioService {
  constructor(private http: HttpClient) {}

  fetchPortfolios(includeConnectedAccounts: boolean): Observable<Portfolio[]> {
    return this.http.get<Portfolio[]>(
      `${environment.apiEndpoint}/portfolios?includeConnectedAccounts=${includeConnectedAccounts}`,
    );
  }

  fetchConnectedPortfolios(): Observable<ConnectedWrapper> {
    return this.http.get<ConnectedWrapper>(`${environment.apiEndpoint}/portfolios/connected`);
  }

  fetchConnectedManagePortfolios(): Observable<ConnectedManagePortfolio[]> {
    return this.http.get<ConnectedManagePortfolio[]>(`${environment.apiEndpoint}/portfolios/connected/manage`);
  }

  createInvite(invite: InvitePayload): Observable<InviteRequestSuccess> {
    return this.http.post<InviteRequestSuccess>(`${environment.apiEndpoint}/portfolios/connected/invite`, invite);
  }
  confirmInvite(inviteCode: ConfirmInvite): Observable<ConfirmInviteSuccess> {
    return this.http.post<ConfirmInviteSuccess>(
      `${environment.apiEndpoint}/portfolios/connected/invite/confirm`,
      inviteCode,
    );
  }

  removeConnectedAccount(id: number): Observable<RemoveConnectedAccountResponse> {
    return this.http.delete<RemoveConnectedAccountResponse>(`${environment.apiEndpoint}/portfolios/connected/${id}`);
  }

  acceptConnectedAccount(id: number): Observable<{isAccepted: boolean}> {
    return this.http.post<{isAccepted: boolean}>(`${environment.apiEndpoint}/portfolios/connected/accept/${id}`, {});
  }

  declineConnectedAccount(id: number): Observable<{isDeclined: boolean}> {
    return this.http.post<{isDeclined: boolean}>(`${environment.apiEndpoint}/portfolios/connected/decline/${id}`, {});
  }

  fetchPortfoliosCharges(): Observable<PortfoliosCharges> {
    return this.http.get<PortfoliosCharges>(`${environment.apiEndpoint}/portfolios/charges`);
  }

  fetchPortfolioByMandateId(mandateId: number): Observable<Portfolio> {
    return this.http
      .get<Portfolio[]>(`${environment.apiEndpoint}/portfolios?mandateId=${mandateId}`)
      .pipe(map(portfolios => portfolios[0]));
  }

  fetchPortfolioById(portfolioId: number): Observable<Portfolio> {
    return this.http.get<Portfolio>(`${environment.apiEndpoint}/portfolios/${portfolioId}`);
  }

  fetchPortfolioContribution(portfolioId: number): Observable<Contribution> {
    // Although the API returns a list of contributions, we are only interested in the first one.
    return this.http
      .get<Contribution>(
        `${environment.apiEndpoint}/portfolios/${portfolioId}/funding/contribution?portfolioContributionTypeId=2`,
      )
      .pipe(map(contributions => contributions[0]));
  }

  fetchPortfolioContributionTypes(): Observable<ContributionType[]> {
    return this.http.get<ContributionType[]>(`${environment.apiEndpoint}/portfolios/references/contributiontypes`);
  }

  fetchPortfolioPaymentDays(): Observable<PaymentDay[]> {
    return this.http.get<PaymentDay[]>(`${environment.apiEndpoint}/portfolios/references/paymentdays`);
  }

  fetchPortfolioOverviewByPortfolioId(portfolioId: number): Observable<PortfolioOverview> {
    return this.http.get<PortfolioOverview>(`${environment.apiEndpoint}/portfolios/${portfolioId}/overview`);
  }

  fetchIsaTransfers(portfolioId: number): Observable<IsaTransfer[]> {
    return this.http.get<IsaTransfer[]>(`${environment.apiEndpoint}/portfolios/${portfolioId}/isa/transfer`);
  }

  fetchIsaProviders(): Observable<IsaProvider[]> {
    return this.http.get<IsaProvider[]>(`${environment.apiEndpoint}/portfolios/references/isaproviders`);
  }

  fetchIsaTypes(productId: number | null): Observable<IsaType[]> {
    return this.http.get<IsaType[]>(
      `${environment.apiEndpoint}/portfolios/references/isatypes` + (!!productId ? `?productId=${productId}` : ''),
    );
  }

  fetchIsaTransferTypes(productId: number | null): Observable<IsaTransferType[]> {
    return this.http.get<IsaTransferType[]>(
      `${environment.apiEndpoint}/portfolios/references/isatransfertypes` +
        (!!productId ? `?productId=${productId}` : ''),
    );
  }

  fetchInvestments(includeConnectedAccounts: boolean): Observable<InvestmentsOverview> {
    return this.http.get<InvestmentsOverview>(
      `${environment.apiEndpoint}/portfolios/investments?includeConnectedAccounts=${includeConnectedAccounts}`,
    );
  }

  fetchActivities(): Observable<Activity[]> {
    return this.http.get<Activity[]>(`${environment.apiEndpoint}/portfolios/activities`);
  }

  fetchActivitiesById(portfolioId: number): Observable<Activity[]> {
    return this.http.get<Activity[]>(`${environment.apiEndpoint}/portfolios/${portfolioId}/activities`);
  }

  fetchSourceOfFundsOptions(): Observable<SourceOfFundsOption[]> {
    return this.http.get<any>(`${environment.apiEndpoint}/portfolios/references/sourceoffunds`);
  }

  fetchInvestableProportions(): Observable<InvestableProportionOption[]> {
    return this.http.get<any>(`${environment.apiEndpoint}/portfolios/references/investableproportions`);
  }

  createIsaTransfer(request: IsaTransferCreateRequest, portfolioId: number): Observable<IsaTransfer> {
    return this.http.post<IsaTransfer>(`${environment.apiEndpoint}/portfolios/${portfolioId}/isa/transfer`, request);
  }

  createPortfolioContribution(
    request: CreatePortfolioContributionRequest,
    portfolioId: number,
  ): Observable<Contribution> {
    return this.http.post<Contribution>(
      `${environment.apiEndpoint}/portfolios/${portfolioId}/funding/contribution`,
      request,
    );
  }

  updatePortfolioContribution(request: Patch[], portfolioId: number, contributionId: number): Observable<Contribution> {
    return this.http.patch<Contribution>(
      `${environment.apiEndpoint}/portfolios/${portfolioId}/funding/contribution/${contributionId}`,
      request,
    );
  }

  deletePortfolioContribution(
    portfolioId: number,
    contributionId: number,
  ): Observable<DeleteDirectDebitContributionResponse> {
    return this.http.delete<DeleteDirectDebitContributionResponse>(
      `${environment.apiEndpoint}/portfolios/${portfolioId}/funding/contribution/${contributionId}`,
    );
  }

  updatePortfolio(request: Patch[], portfolioId): Observable<Portfolio> {
    return this.http.patch<Portfolio>(`${environment.apiEndpoint}/portfolios/${portfolioId}`, request);
  }

  deleteIsaTransfer(isaTransferId: number, portfolioId: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiEndpoint}/portfolios/${portfolioId}/isa/${isaTransferId}`);
  }

  deletePortfolio(portfolioId: number): Observable<any> {
    return this.http.post<any>(`${environment.apiEndpoint}/portfolios/${portfolioId}/closure`, null);
  }

  closePortfolio(portfolioId: number, closePortfolioRequest: ClosePortfolioRequest): Observable<any> {
    return this.http.post<any>(`${environment.apiEndpoint}/portfolios/${portfolioId}/closure`, closePortfolioRequest);
  }

  withdrawPortfolioFunds(withdrawalAmount: number, portfolioId: number): Observable<any> {
    return this.http.post<any>(`${environment.apiEndpoint}/portfolios/${portfolioId}/funding/withdrawal`, {
      amount: withdrawalAmount,
    });
  }

initialisePayment(request: number, portfolioId:number ): Observable<any> {
    return this.http.post<any>(`${environment.apiEndpoint}/portfolios/${portfolioId}/payments/initialise`, {
      amount: request,
    });
  }

  getPaymentSupported(portfolioId: number):Observable<PaymentSupported> {
    return this.http.get<PaymentSupported>(`${environment.apiEndpoint}/portfolios/${portfolioId}/payments/supported`);
  }

  getPaymentSuccessStatus(portfolioId : number, id: string, status: string, message: string): Observable<any> {
    return this.http.post<any>(`${environment.apiEndpoint}/portfolios/${portfolioId}/payments/complete` , {
      id: id,
      status: status,
      message: message
    });
  }
  // getPaymentFailedStatus(portfolioId : number, id: string, status: string, message: string): Observable<any> {
  //   return this.http.post<any>(`${environment.apiEndpoint}/portfolios/${portfolioId}/payments/complete` , {
  //     id: id,
  //     status: status,
  //     message: message
  //   });
//}

getPayment(paymentId:string) : Observable<any> {
  return this.http.get<Payment>(`${environment.apiEndpoint}/portfolios/payments/${paymentId}`);
}
getItemisedCharge(portfolioId): Observable<any> {
  return this.http.get<any>(`${environment.apiEndpoint}/portfolios/charges/itemised`)
}
}
