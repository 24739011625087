import { Country, Nationality } from '@app/shared/models/country.model';

export interface CountryState {
  countries: Country[];
  nationalities: Nationality[];
  loading: boolean;
}

export const featureKey = 'country';

export const initialState: CountryState = {
  countries: [],
  nationalities: [],
  loading: true,
};
