import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';


/** Form control for editing a single value of an item. */
@Component({
  selector: 'abl-edit-single-value',
  templateUrl: './edit-single-value.component.html',
  styleUrls: ['./edit-single-value.component.scss'],
})
export class EditSingleValueComponent implements OnInit, OnChanges {
  @Input() value = '';
  @Input() placeholder: string;
  @Input() loading = false;
  @Input() formFlexSize = 100;
  @Output() save = new EventEmitter<string | number>();
  ctrl: UntypedFormControl;
  isEdit = false;

  ngOnInit() {
    this.ctrl = new UntypedFormControl(this.value, Validators.required);
  }

  ngOnChanges() {
    if (this.isEdit) {
      this.isEdit = this.loading;
    }
  }
  constructor( private translate : TranslateService){}
}
