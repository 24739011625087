import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EffectsModule} from '@ngrx/effects';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {GenericDialogComponent} from '@app/shared/controls/dialog/generic-dialog.component';
import {AuthStateModule} from '@app/state/auth';
import {AuthEffects} from '@app/state/auth/auth.effects';
import {RouterModule} from '@angular/router';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {FormValidationMessageComponent} from '@app/shared/controls/form-validation-message/form-validation-message.component';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {OverlayModule} from '@angular/cdk/overlay';
import {MaterialModule} from '@app/shared/material';
import {ColourMarkerComponent} from '@app/shared/controls/colour-marker/colour-marker.component';
import {EditSingleValueComponent} from '@app/shared/controls/edit-single-value/edit-single-value.component';
import {TranslateModule} from '@ngx-translate/core';
import {SessionTimeoutModule} from './dialog/session-timeout-dialog.module';
import {EditSingleValueModule} from './edit-single-value/edit-single-value.module';
import { DirectivesModule } from '../directives/directives.module';
import { StorageDialogComponent } from './dialog/storage-dialog.component';

export const Components = [
  BreadcrumbComponent,
  GenericDialogComponent,
  StorageDialogComponent,
  FormValidationMessageComponent,
  ColourMarkerComponent,
  EditSingleValueComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FlexModule,
    FormsModule,
    ReactiveFormsModule,
    AuthStateModule,
    EffectsModule.forFeature([AuthEffects]),
    RouterModule,
    MaterialModule,
    FlexLayoutModule,
    OverlayModule,
    TranslateModule,
    SessionTimeoutModule,
    EditSingleValueModule,
    DirectivesModule,
  ],
  declarations: [
    BreadcrumbComponent,
    GenericDialogComponent,
    FormValidationMessageComponent,
    ColourMarkerComponent,
    StorageDialogComponent
    // EditSingleValueComponent,
  ],
  exports: [
    BreadcrumbComponent,
    GenericDialogComponent,
    StorageDialogComponent,
    FormValidationMessageComponent,
    ColourMarkerComponent,
    CommonModule,
    FlexModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule,
    SessionTimeoutModule,
    EditSingleValueModule,
    RouterModule,
    FlexLayoutModule,
    OverlayModule,

    // EditSingleValueComponent,
  ],
  providers: [{provide: MAT_DATE_LOCALE, useValue: 'en-GB'}],
})
export class SharedModule {}
