import {createAction, props} from '@ngrx/store';
import {IsaProvider, IsaTransfer, IsaTransferCreateRequest, IsaTransferType, IsaType} from '@app/shared/models/portfolio/isa.model';

/** Fetch ISA transfers. */
export const fetchIsaTransfersSuccess = createAction(
  '[IsaTransfer] Fetch ISA Transfers Success',
  props<{response: IsaTransfer[]}>(),
);

/** Delete ISA transfer. */
export const deleteIsaTransfer = createAction('[IsaTransfer] Delete ISA Transfer', props<{id: number}>());

export const deleteIsaTransferSuccess = createAction(
  '[IsaTransfer] Delete ISA Transfer Success',
  props<{id: number}>(),
);

/** Create ISA transfer. */
export const createIsaTransfer = createAction(
  '[IsaTransfer] Create ISA Transfer',
  props<{request: IsaTransferCreateRequest}>(),
);

export const createIsaTransferSuccess = createAction(
  '[IsaTransfer] Create ISA Transfer Success',
  props<{response: IsaTransfer}>(),
);

/** Fetch ISA providers. */
export const fetchIsaProviders = createAction('[IsaTransfer] Fetch ISA Providers');

export const fetchIsaProvidersSuccess = createAction(
  '[IsaTransfer] Fetch ISA Providers Success',
  props<{response: IsaProvider[]}>(),
);

/** Fetch ISA types. */
export const fetchIsaTypes = createAction('[IsaTransfer] Fetch ISA Types');

export const fetchIsaTypesSuccess = createAction(
  '[IsaTransfer] Fetch ISA Types Success',
  props<{response: IsaType[]}>(),
);

/** Fetch ISA transfer types. */
export const fetchIsaTransferTypes = createAction('[IsaTransfer] Fetch ISA Transfer Types');

export const fetchIsaTransferTypesSuccess = createAction(
  '[IsaTransfer] Fetch ISA Transfer Types Success',
  props<{response: IsaTransferType[]}>(),
);
