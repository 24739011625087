export enum AddressTypeEnum {
  Registered = 'Registered',
  Postal = 'Postal',
  Residential = 'Residential',
}

export enum AddressTypeIdEnum {
  Registered = 1,
  Postal,
  Residential,
}

// Loqate
export enum LoqateAddressTypeEnum {
  Address = 'Address',
  Postcode = 'Postcode',
}
