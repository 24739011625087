import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MaterialModule} from '@app/shared/material';
import { EditSingleValueComponent } from './edit-single-value.component';


@NgModule({
  declarations: [EditSingleValueComponent],
  exports: [EditSingleValueComponent],
  imports: [TranslateModule,CommonModule,MaterialModule]
})
export class EditSingleValueModule {}