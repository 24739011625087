import { Injectable } from '@angular/core';
import { AppState } from '../root-state';
import { Observable, of } from 'rxjs';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { switchMap, catchError, map, withLatestFrom, exhaustMap } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { ErrorActions } from '../error';
import { OverseasEmploymentState } from './overseas-employment.state';
import { fetchOverseasEmploymentDetails, fetchOverseasEmploymentDetailsSuccess, saveOverseasEmploymentDetails, saveOverseasEmploymentDetailsSuccess, updateOverseasEmployment, updateOverseasEmploymentFailure, updateOverseasEmploymentSuccess } from './overseas-employment.actions';
import { OverseasEmploymentService } from '@app/services/overseas-employment/overseas-employment.service';
import { selectProfile } from '../profile/profile.selectors';


@Injectable()
export class OverseasEmploymentEffects {

    saveOverseasEmployment$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(saveOverseasEmploymentDetails),
            exhaustMap(({request} ) =>
                this.overseasEmploymentService.saveOverseasEmploymentDetails(request).pipe(
                    map(response => saveOverseasEmploymentDetailsSuccess({ response })),
                    catchError(error => of(ErrorActions.newError({ backEndError: error }))),
                ),
            ),
        ));
        fetchOverseasEmploymentDetails$:Observable<Action> = createEffect(() =>
        this.actions$.pipe(
          ofType(fetchOverseasEmploymentDetails),
          withLatestFrom(this.store.select(selectProfile)),
          switchMap(([, profile]) =>
            this.overseasEmploymentService.fetchOverseasEmploymentDetails(profile.individualId).pipe(
              map(response => fetchOverseasEmploymentDetailsSuccess({response})),
              catchError(error => of(ErrorActions.newError({backEndError: error}))),
            ),
          ),
        ),
      );
      updateOverseasEmployment$: Observable<Action> = createEffect(() =>
      this.actions$.pipe(
        ofType(updateOverseasEmployment),
        withLatestFrom(this.store.select(selectProfile)),
        exhaustMap(([{request}, profile]) =>
          this.overseasEmploymentService.patchOverseasEmploymentDetails(Number(profile.individualId), request).pipe(
            map(response => updateOverseasEmploymentSuccess({response})),
            catchError(error => of(ErrorActions.newError({backEndError: error}), updateOverseasEmploymentFailure({error}))),
          ),
        ),
      ),
    );
        constructor(
            private actions$: Actions,
            private store: Store<AppState>,
            private overseasEmploymentService: OverseasEmploymentService,
        ) { }
}

