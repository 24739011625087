import * as BankActions from './bank.actions';

export * from './bank-state.module';
import * as BankState from './bank.state';
import * as BankSelectors from './bank.selectors';

export {
  BankActions,
  BankState,
  BankSelectors
};
