import {createAction, props} from '@ngrx/store';
import {Profile} from '@app/shared/models/profile.model';
import {HttpErrorResponse} from '@angular/common/http';
import {Patch} from '@app/shared/models/patch.model';

/** Fetch profile. */
export const fetchProfile = createAction('[Profile] Fetch Profile');

export const fetchProfileSuccess = createAction('[Profile] Fetch Profile Success', props<{profile: Profile}>());

export const fetchProfileFail = createAction('[Profile] Fetch Profile Fail', props<{error: HttpErrorResponse}>());

/** Clear. */
export const clearProfiles = createAction('[Profile] Clear Profiles');

/** Update profile. */
export const updateProfile = createAction('[Profile] Update Profile', props<{request: Patch[]}>());

export const updateProfileSuccess = createAction('[Profile] Update Profile Success', props<{response: Profile}>());
