import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AuthGuard} from './auth/services/auth.guard';
import {OnboardingResolver} from './onboarding/onboarding.resolver';
import {CanDeactivateOnboardingGuard} from '@app/can-deactivate-onboarding.guard';
import {CanActivateDashboardGuard} from '@app/can-activate-dashboard.guard';
import {LabComponent} from '@app/lab/lab.component';
import {LabGuard} from '@app/lab/lab.guard';
import {RootPagesEnum} from '@app/shared/enums/dashboard-pages.enum';
import { PaymentComponent } from './payment/payment.component';
import { StartComponent } from './dashboard/pages/start/start.component';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          loadChildren: () => import('@app/dashboard/dashboard.module').then(m => m.DashboardModule),
          canActivate: [AuthGuard, CanActivateDashboardGuard],
        },
        {
          path: RootPagesEnum.Onboarding,
          canDeactivate: [CanDeactivateOnboardingGuard],
          loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingModule),
          canActivate: [AuthGuard],
          resolve: {journeyData: OnboardingResolver},
        },
        {
          path: RootPagesEnum.Risk,
          loadChildren: () => import('@app/risk-assessment/risk-assessment.module').then(m => m.RiskAssessmentModule),
          canActivate: [AuthGuard],
        },
         {path: '', pathMatch: 'full', redirectTo: '/launch'},
         {
          path: RootPagesEnum.Start,
          component: StartComponent,
          canActivate: [AuthGuard],
          data: {title: 'Starting Application'},
        },
        {
          path: RootPagesEnum.Lab,
          component: LabComponent,
          canActivate: [LabGuard],
          loadChildren: () => import('@app/lab/lab.module').then(m => m.LabModule),
        },
        {
          path: RootPagesEnum.Payment,
          component: PaymentComponent,
          canActivate: [AuthGuard],
          loadChildren: () => import('@app/payment/payment.module').then(m => m.PaymentsModule),
        },
       // {path: '**',  redirectTo: '/launch'},
      ],

      {
    onSameUrlNavigation: 'reload',
    enableTracing: false,
    scrollPositionRestoration: 'top'
},
    ),
  ],

  exports: [RouterModule],
})
export class AppRoutingModule {}
