import {createAction, props} from '@ngrx/store';
import {RiskQuestionsResponse, RiskQuestionsTakenResponse, TargetRiskQuestion, TargetRiskScore} from '@app/shared/models/portfolio/target-risk.model';

export const fetchAttitudeToRiskQuestions = createAction(
  '[AttitudeToRisk] Fetch Attitude To Risk Questions',
);

export const fetchAttitudeToRiskQuestionsSuccess = createAction(
  '[AttitudeToRisk] Fetch Attitude To Risk Questions Success',
  props<{response: RiskQuestionsResponse}>(),
);

export const setCurrentAttitudeToRiskQuestionIndex = createAction(
  '[AttitudeToRisk] Set Current Attitude To Risk Question Index',
  props<{index: number}>(),
);

export const setAttitudeToRiskQuestionAnswer = createAction(
  '[AttitudeToRisk] Set Answer for Specified Attitude To Risk Question',
  props<{payload: {questionId: number; responseId: number}}>(),
);

export const setAttitudeToRiskQuestionAnswerSuccess = createAction(
  '[AttitudeToRisk] Set Answer for Attitude To Risk Question Success',
  props<{response: TargetRiskQuestion}>(),
);

export const fetchAttitudeToRiskScore = createAction(
  '[AttitudeToRisk] Fetch Attitude To Risk Score',
  props<{isNewScore: boolean}>(),
);

export const fetchCurrentAttitudeToRiskScore = createAction(
  '[AttitudeToRisk] Fetch Current Attitude To Risk Score',
);

export const fetchAttitudeToRiskScoreSuccess = createAction(
  '[AttitudeToRisk] Fetch Attitude To Risk Score Success',
  props<{response: TargetRiskScore}>(),
);

export const fetchCurrentAttitudeToRiskScoreSuccess = createAction(
  '[AttitudeToRisk] Fetch Current Attitude To Risk Score Success',
  props<{response: TargetRiskScore}>(),
);

export const setAttitudeToRiskScore = createAction(
  '[AttitudeToRisk] Set Attitude To Risk Score',
);

export const setAttitudeToRiskScoreSuccess = createAction(
  '[AttitudeToRisk] Set Attitude To Risk Score Success',
  props<{response: TargetRiskScore}>(),
);

export const resetAttitudeToRiskQuestions = createAction(
  '[AttitudeToRisk] Reset Attitude To Risk Questions',
);

export const resetAttitudeToRiskQuestionsSuccess = createAction(
  '[AttitudeToRisk] Reset Attitude To Risk Questions Success',
  props<{response: RiskQuestionsResponse}>(),
);

export const fetchRiskquestionnaireScoreResponse = createAction(
  '[AttitudeToRisk] Reset Attitude To Risk Questions Success',
  props<{response: RiskQuestionsTakenResponse}>(),
);

export const setFetchRiskScoreSuccess = createAction(
  '[AttitudeToRisk] Set Attitude to Risk Score Success',
  props<{fetchScoreSuccess: boolean}>(),
);

export const fetchRiskquestionnaireScore = createAction(
  '[AttitudeRisk] Get Last taken Risk questionnaire time',
);