import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {LoggerService} from '@app/services/logger.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantService } from '@app/services/tenant.service';
import { PortfolioState } from '@app/state/portfolio/portfolio.state';
import { fetchPayment, fetchPaymentSuccessStatus } from '@app/state/portfolio/portfolio.actions';
import { Observable } from 'rxjs';
import { fetchPaymentSuccess } from '@app/state/portfolio/portfolio.selectors';
import { filter, first, skip, tap } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { OAuthStorageService } from '@app/auth/services/oauth-storage.service';

@Component({
  selector: 'abl-payments',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})

export class PaymentComponent implements OnInit {

  portfolioId : number = null;
  paymentId: string = null;
  paymentStatus: string = null;

  constructor(
    private route: ActivatedRoute,
    private portfolioStore: Store<PortfolioState>,
    public logger: LoggerService,
    public tenantService: TenantService,
    private dialog: MatDialog,
    private router: Router,
    private oauthStorageService: OAuthStorageService
  ) {}
  ngOnInit(): void {
    if (window.location.href.indexOf("payment_id")!= -1) {
      this.paymentId = this.route.snapshot.queryParamMap.get('payment_id');
      this.portfolioStore.dispatch(fetchPayment({paymentId: this.paymentId}));
      this.oauthStorageService.setItem('paymentId', this.paymentId)
      this.fetchPaymentDetails$.subscribe();

    } else {
      console.error('Not handling callback.');
    }
  }

  fetchPaymentDetails$: Observable<{id: string, portfolioId: number, amount: number, eventStatus: string }> =
  this.portfolioStore.pipe(
   select(fetchPaymentSuccess),
   skip(1),
   filter(success => {
     return !!success;
   }),
   tap(res => {
     if (res) {
      this.portfolioId = res.portfolioId;
      this.paymentStatus = res.eventStatus;

      this.oauthStorageService.setItem('paymentStatus', this.paymentStatus)

      this.portfolioStore.dispatch(fetchPaymentSuccessStatus({portfolioId: this.portfolioId, id: this.paymentId, message: this.paymentStatus, status: this.paymentStatus}));

      if (res.eventStatus ) {
        res.eventStatus = null;
        this.router.navigate([`/accounts/portfolio/${this.portfolioId}/invest-more`]);
       }
     }
   }),
   first(),
 );
}
