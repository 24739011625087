import {Component, OnInit} from '@angular/core';
import {login, setAuthCodeFlowMode} from '@app/state/auth/auth.actions';
import {Store} from '@ngrx/store';
import {AuthState} from '@app/state';
import {AuthAction} from '@app/shared/enums/auth.enum';

@Component({
  selector: 'web-register-component',
  template:
    '<div fxFlexFill fxLayoutAlign="center center"><abl-custom-loading-spinner></abl-custom-loading-spinner></div>',
})
export class WebRegisterComponent implements OnInit {
  constructor(private authStore: Store<AuthState.State>) {}

  ngOnInit() {
    this.authStore.dispatch(setAuthCodeFlowMode({action: AuthAction.Register, launchMode: null, authMode: null}));
    this.authStore.dispatch(login());
  }
}
