import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Gender } from '@app/shared/models/gender.model';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GenderService {
  constructor(private http: HttpClient) {}

  getGenders(): Observable<Gender[]> {
    return this.http.get<Gender[]>(
      `${environment.apiEndpoint}/individuals/references/genders`
    );
  }
}
