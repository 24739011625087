import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevelopmentNavigationCardComponent } from '@app/shared/dev-controls/development-navigation-card.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@app/shared/material';
import {RouterModule} from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [DevelopmentNavigationCardComponent],
  exports: [DevelopmentNavigationCardComponent],
  imports: [CommonModule, MaterialModule, FlexLayoutModule, RouterModule, TranslateModule],
})
export class DevelopmentNavigationCardModule {}
