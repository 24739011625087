import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {GenericDialogComponent} from '@app/shared/controls/dialog/generic-dialog.component';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {SessionTimeoutDialogComponent} from '@app/shared/controls/dialog/session-timeout-dialog.component';
import {logout, refreshToken, renewSessionTimeout} from '@app/state/auth/auth.actions';
import {AuthState, MandateState} from '@app/state';
import {Store} from '@ngrx/store';
import {DialogCloseEnum} from '@app/shared/enums/dialog.enum';
import {filter, map, take, tap} from 'rxjs/operators';
import {deleteMandate} from '@app/state/mandate/mandate.actions';
import {OnboardingState} from '@app/state/onboarding/onboarding.state';
import {createNewApplication} from '@app/state/onboarding/onboarding.actions';

@Injectable({providedIn: 'root'})
export class DialogService {
  updatePayloadForIndividual: Subject<any> = new Subject<any>();
  submitForm: Subject<any> = new Subject<any>();

  constructor(
    private dialog: MatDialog,
    readonly authStore: Store<AuthState.State>,
    readonly mandateStore: Store<MandateState.MandateState>,
    readonly onboardingStore: Store<OnboardingState>,
  ) {}

  /** Open http error 422 dialog. */
  openErrorDialog(error: any) {
    const isError60002 = error.errors[0].number === 60002;
    const primaryButtonText = isError60002 ? 'Ok' : 'Confirm';
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      ariaLabel: 'http error',
      data: {
        title: error.message,
        text: error.errors[0].message,
        secondaryButton: isError60002 ? null : {text: 'Decline'},
        primaryButton: {
          text: primaryButtonText,
          close: isError60002 ? null : DialogCloseEnum.Confirm,
        },
      },
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result === DialogCloseEnum.Confirm) {
          this.updatePayloadForIndividual.next(true);
          this.submitForm.next(true);
        }
      });
  }

  /** Open logout dialog. */
  openLogoutDialog() {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      ariaLabel: 'log out',
      data: {
        title: 'Logout?',
        text: 'Are you sure you want to logout?',
        secondaryButton: {
          text: 'Cancel',
        },
        primaryButton: {
          text: 'Logout',
          close: DialogCloseEnum.Logout,
        },
      },
    });
    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        filter(result => result === DialogCloseEnum.Logout),
        tap(() => this.logout()),
      )
      .subscribe();
  }

  /** Open session timout dialog. */
  openSessionTimeoutDialog() {
    const dialogRef = this.dialog.open(SessionTimeoutDialogComponent, {
      ariaLabel: 'session timeout',
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result === DialogCloseEnum.Logout) {
          this.logout();
          return;
        }
        this.authStore.dispatch(renewSessionTimeout());
        this.authStore.dispatch(refreshToken());
      });
  }

  /** Open navigate away dialog. */
  openNavigateAwayDialog(): Observable<boolean> {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      ariaLabel: 'navigate away',
      data: {
        title: 'Navigate Away?',
        text: 'Are you sure you want to exit your current account opening journey?',
        secondaryButton: {
          text: 'Cancel',
        },
        primaryButton: {
          text: 'Confirm',
          close: DialogCloseEnum.Confirm,
        },
      },
    });
    return dialogRef.afterClosed().pipe(
      take(1),
      map(result => result === DialogCloseEnum.Confirm),
    );
  }

  /** Open delete application dialog. */
  openDeleteApplicationDialog(id: any) {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      ariaLabel: 'delete application',
      restoreFocus: false,
      data: {
        title: 'Delete?',
        text: 'Are you sure you want to delete the application?',
        secondaryButton: {
          text: 'Cancel',
        },
        primaryButton: {
          text: 'Confirm',
          close: DialogCloseEnum.Confirm,
        },
      },
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result === DialogCloseEnum.Confirm) {
          this.mandateStore.dispatch(deleteMandate({id: id.id}));
        }
      });
  }

  /** Open personal details edit confirm dialog. */
  openEditConfirmDialog(title: string, text: string, onlyConfirm = false): MatDialogRef<any> {
    return this.dialog.open(GenericDialogComponent, {
      ariaLabel: 'edit personal details',
      data: {
        title,
        text,
        secondaryButton: onlyConfirm
          ? null
          : {
              text: 'Cancel',
            },
        primaryButton: {
          text: 'Confirm',
          close: DialogCloseEnum.Confirm,
        },
      },
    });
  }

  /** Open confirm create new mandate dialog. */
  openNewMandateConfirmDialog() {
    this.dialog
      .open(GenericDialogComponent, {
        ariaLabel: 'create new mandate',
        data: {
          title: 'Create new mandate',
          text: 'Would you like to create a new mandate?',
          primaryButton: {
            text: 'Create Mandate',
            close: DialogCloseEnum.Confirm,
          },
          secondaryButton: {text: 'Decline'},
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (result === DialogCloseEnum.Confirm) {
          this.onboardingStore.dispatch(createNewApplication());
        }
      });
  }

  private logout() {
    this.authStore.dispatch(logout());
  }
}
