import {Action, createReducer, on} from '@ngrx/store';
import * as StrategyActions from '@app/state/strategy/strategy.actions';
import {initialState, StrategyState} from '@app/state/strategy/strategy.state';

export const strategyReducer = createReducer(
  initialState,

  on(StrategyActions.fetchStrategiesSuccess, (state, {response}) => ({
    ...state,
    strategies: response,
  }))
);

export function reducer(state: StrategyState, action: Action) {
  return strategyReducer(state, action);
}
