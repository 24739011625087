import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { RelationshipEffects } from './relationship.effects';
import * as fromRelationship from './relationship.reducer';
import * as fromRelationshipState from './relationship.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromRelationshipState.featureKey, fromRelationship.reducer),
    EffectsModule.forFeature([RelationshipEffects])
  ],
  declarations: []
})
export class RelationshipStateModule {}
