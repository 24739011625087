import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {select, Store} from '@ngrx/store';
import {combineLatest, Observable} from 'rxjs';
import {filter, first} from 'rxjs/operators';
import {JourneyConfigState, OnboardingState} from '../state';
import {selectCurrentJourneyStep, selectJourneyConfig} from '../state/journey-config/journey-config.selectors';
import {MandateState} from '@app/state/mandate/mandate.state';
import {fetchJourneyConfigForCurrentMandate} from '@app/state/journey-config/journey-config.actions';
import {setOnboardingStateForMandate} from '@app/state/onboarding/onboarding.actions';
import {JourneyHistoryState} from '@app/state/journey-history/journey-history.state';
import {selectCurrentJourneyHistoryStep} from '@app/state/journey-history/journey-history.selectors';
import {fetchCurrentJourneyHistoryStep} from '@app/state/journey-history/journey-history.actions';
import {JourneyHistoryStep} from '@app/shared/models/journey-history-step';
import {JourneyStepEnum} from '@app/shared/enums/journey-step.enum';
import {JourneyConfig} from '@app/shared/models/journey-config.model';
import {fetchProfile} from '@app/state/profile/profile.actions';
import {fetchOrganisations} from '@app/state/organisation/organisation.actions';
import {ProfileState} from '@app/state/profile';
import {OrganisationState} from '@app/state/organisation/organisation.state';
import {IndividualState} from '@app/state/individual/individual.state';
import {selectProfile} from '@app/state/profile/profile.selectors';
import {selectIndividual} from '@app/state/individual/individual.selectors';
import {selectOrganisations} from '@app/state/organisation/organisation.selectors';
import {Profile} from '@app/shared/models/profile.model';
import {Individual} from '@app/shared/models/individuals/individual.model';
import {Organisation} from '@app/shared/models/organisation.model';
import {IndividualChildState} from '@app/state/individual-child/individual-child.state';
import {resetIndividualChildState} from '@app/state/individual-child/individual-child.actions';

/**
 * When creating a new mandate, only navigating to GDPR page.
 * On Next we need to make a new mandate and navigate to product selection page.
 *
 * On application and continue or F5(reload page):
 * Fetch all mandates.
 * Set journey history step.
 * Set onboarding state for mandate ID.
 * Fetch journey config for this mandate ID.
 */
@Injectable()
export class OnboardingResolver  {
  private currentJourneyHistoryStep$ = this.journeyConfigStore.pipe(
    select(selectCurrentJourneyHistoryStep),
    filter(currentJourneyHistoryStep => !!currentJourneyHistoryStep),
  );
  private currentJourneyStep$ = this.journeyConfigStore.pipe(
    select(selectCurrentJourneyStep),
    filter(currentJourneyStep => !!currentJourneyStep),
  );
  private currentJourneyConfig$ = this.journeyConfigStore.pipe(
    select(selectJourneyConfig),
    filter(config => !!config),
  );
  private profile$ = this.profileStore.pipe(
    select(selectProfile),
    filter(profile => !!profile),
  );
  private individual$ = this.individualStore.pipe(
    select(selectIndividual),
    filter(individual => !!individual),
  );
  private organisations$ = this.organisationStore.pipe(
    select(selectOrganisations),
    filter(organisations => !!organisations),
  );

  constructor(
    private journeyConfigStore: Store<JourneyConfigState.JourneyConfigState>,
    private journeyHistoryStore: Store<JourneyHistoryState>,
    private mandateStore: Store<MandateState>,
    private onboardingStore: Store<OnboardingState.OnboardingState>,
    private profileStore: Store<ProfileState.ProfileState>,
    private individualStore: Store<IndividualState>,
    private individualChildStore: Store<IndividualChildState>,
    private organisationStore: Store<OrganisationState>,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
  ):
    | Observable<[JourneyHistoryStep, JourneyConfig, JourneyStepEnum, Profile, Individual, Organisation[]] | unknown[]>
    | boolean {
    const mandateId = Number(route.queryParamMap.get('m'));

    this.profileStore.dispatch(fetchProfile());
    this.organisationStore.dispatch(fetchOrganisations());
    this.individualChildStore.dispatch(resetIndividualChildState());

    if (!mandateId) {
      return true;
    }

    this.journeyHistoryStore.dispatch(fetchCurrentJourneyHistoryStep({mandateId}));
    this.onboardingStore.dispatch(setOnboardingStateForMandate({mandateId}));
    this.journeyConfigStore.dispatch(fetchJourneyConfigForCurrentMandate());

    return combineLatest([
      this.currentJourneyHistoryStep$,
      this.currentJourneyStep$,
      this.currentJourneyConfig$,
      this.profile$,
      this.individual$,
      this.organisations$,
    ]).pipe(first());
  }
}
