import {Component, OnDestroy} from '@angular/core';
import {AuthActions, AuthState, OnboardingState} from '@app/state';
import {Store} from '@ngrx/store';
import {ErrorActions, ErrorState} from '@app/state/error';
import {Subject} from 'rxjs';
import {login, logout, setAuthCodeFlowMode} from '@app/state/auth/auth.actions';
import {LoggerService} from '@app/services/logger.service';
import {createNewApplication} from '@app/state/onboarding/onboarding.actions';
import {RootPagesEnum} from '@app/shared/enums/dashboard-pages.enum';
import {AuthAction, AuthFlowType, AuthLaunchMode} from '@app/shared/enums/auth.enum';
import { TranslateService } from '@ngx-translate/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { GenericDialogComponent } from '../controls/dialog/generic-dialog.component';
import { DialogCloseEnum } from '../enums/dialog.enum';
import { take } from 'rxjs/operators';
import { StorageDialogComponent } from '../controls/dialog/storage-dialog.component';
import { OAuthStorageService } from '@app/auth/services/oauth-storage.service';
import { AuthOidcService } from '@app/auth/services/auth-oidc.service';


@Component({
  selector: 'abl-development-navigation-card',
  templateUrl: './development-navigation-card.component.html',
  styleUrls: ['./development-navigation-card.component.scss'],
})
export class DevelopmentNavigationCardComponent implements OnDestroy {
  isShowDeveloperButtonCard = false;
  values = '';
  labUrl = RootPagesEnum.Lab;

  private destroy$ = new Subject();

  constructor(
    private onboardingStore: Store<OnboardingState.OnboardingState>,
    private authStore: Store<AuthState.State>,
    private errorStore: Store<ErrorState.ErrorState>,
    private authService: AuthOidcService,
    private storageService: OAuthStorageService,
    public logger: LoggerService,
    private translate : TranslateService,
    private dialog: MatDialog,
  ) {}

  ngOnDestroy() {
    // Trigger unsubscribe from all subscriptions when component is destroyed.
    this.destroy$.next();
    this.destroy$.complete();
  }

  doRefresh(): void {
    window.location.reload();
  }

  logOut() {
    this.authStore.dispatch(logout());
  }

  logIn1() {
    this.logger.writeToLog('test trylogin 1');
    this.authStore.dispatch(AuthActions.navigateToAuthSuccessUrl());
  }

  logIn2() {
    this.logger.writeToLog('test trylogin2');
    this.authStore.dispatch(AuthActions.login());
  }

  logIn3() {
    this.logger.writeToLog('test trylogin3');
    window.location.href = '/mobile-launch';
  }

  logIn4() {
    this.logger.writeToLog('test trylogin4');

    this.authStore.dispatch(setAuthCodeFlowMode({action: AuthAction.Login, launchMode: AuthLaunchMode.LaunchCodeFlow, authMode: AuthFlowType.Code}));
    this.authStore.dispatch(login());
  }

  onKey(event: KeyboardEvent) {
    this.values += (event.target as HTMLInputElement).value;
  }

  onNav() {
    window.location.href = this.values;
  }

  tryNavigate() {
    this.onboardingStore.dispatch(createNewApplication());
  }

  showError() {
    this.errorStore.dispatch(
      ErrorActions.newError({
        backEndError: {
          title: 'An error occured while processing your request.',
          status: 500,
          detail: 'err',
          instance: 'urn:tillertechonboard:error:c42213fb-d584-4665-a1b8-d4ea86f42944',
          type: null,
          extensions: [],
          validationErrors: [],
        },
      }),
    );
  }
  onShowValuesClick() {
    // onclick of close portfolio display a modal confirming action
    this.dialog
      .open(StorageDialogComponent, {
        ariaLabel: 'stored values in session storage',
        data: {
          title: 'Data',
          text: sessionStorage.getItem('principal_value'),
          text1: sessionStorage.getItem('access_token'),
          text2: sessionStorage.getItem('expires_at'),
          text3: sessionStorage.getItem('id_token'),
          text4: sessionStorage.getItem('clientid'),
          text5: this.storageService.getItem('clientid'),
          text6: this.authService.clientId, 
          text7: sessionStorage.getItem('refresh_token'),
          text8: this.storageService.getItem('refresh_token'),
          text9: this.authService.flowString,
          text10: this.storageService.getItem('last_refreshed'),
          text11: this.storageService.getItem('refresh_timer_started'),
          text12: this.storageService.getItem('refresh_timer_stopped'),
          secondaryButton: {
            text: 'Cancel',
          },
          primaryButton: {
            text: 'Confirm',
            close: DialogCloseEnum.Confirm,
          },
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        // on confirm of close portfolio dispatch action
        if (result === DialogCloseEnum.Confirm) {
        }
      });
  }
  Sessionvalues() {
    sessionStorage.getItem('principal_value'),
    sessionStorage.getItem('access_token');

  }
}
