import {Component} from '@angular/core';
import {animate, keyframes, style, transition, trigger} from '@angular/animations';
import {interval} from 'rxjs';
import {map} from 'rxjs/operators';

const TRIGGER_KEYS = Array(8)
  .fill('scale')
  .map((t, i) => (t = `${t}${i + 1}`));

const TRANSFORM_SCALES: string[] = [
  'scale(0)',
  'scale(0.25)',
  'scale(0.5)',
  'scale(0.75)',
  'scale(1)',
  'scale(0.75)',
  'scale(0.5)',
  'scale(0.25)',
  'scale(0)',
];

export const TRIGGERS = TRIGGER_KEYS.map((k, i) => {
  const copy = TRANSFORM_SCALES.slice();
  const kf = [...copy.splice(i), ...copy].map(s => style({transform: s}));
  return trigger(k, [transition('* <=> *', animate('1s linear', keyframes(kf)))]);
});

/** Change this component based on tenant preference on loading spinner. */
@Component({
  selector: 'abl-custom-loading-spinner',
  templateUrl: './custom-loading-spinner.component.html',
  styleUrls: ['./custom-loading-spinner.component.scss'],
  animations: TRIGGERS,
})
export class CustomLoadingSpinnerComponent {
  state$ = interval(1000).pipe(map(value => value % 2));
}
