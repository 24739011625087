import {Action, ActionReducer, createReducer, on} from '@ngrx/store';
import {initialState, JourneyConfigState} from './journey-config.state';
import {clearJourneyConfigs, fetchJourneyConfigForCurrentMandateSuccess, setCurrentJourneyConfig, setPrevJourneyStep, updateJourneyConfig} from './journey-config.actions';
import {JourneyConfig} from '@app/shared/models/journey-config.model';
import {BreadcrumbJourneyPart, BreadcrumbJourneyStage, BreadcrumbJourneyStep} from '@app/shared/models/breadcrumb.model';
import {JourneyStepEnum} from '@app/shared/enums/journey-step.enum';

let journeyConfigReducer: ActionReducer<JourneyConfigState, Action>;

journeyConfigReducer = createReducer(
  initialState,

  /** Set/update journey config. */
  on(setCurrentJourneyConfig, (state, {journeyConfig, currentStep}) => {
    const flatBreadcrumbJourneySteps = createFlatBreadcrumbJourneySteps(journeyConfig);
    return {
      ...state,
      currentJourneyConfig: journeyConfig,
      flatBreadcrumbJourneySteps,
      breadcrumbJourneyParts: createBreadcrumbJourneyJourney(journeyConfig, flatBreadcrumbJourneySteps, currentStep),
      currentStep,
    };
  }),

  on(updateJourneyConfig, (state, {journeyConfig}) => {
    const flatBreadcrumbJourneySteps = createFlatBreadcrumbJourneySteps(journeyConfig);
    const disabledJourneySteps = createDisabledJourneySteps(journeyConfig);
    const nextStep = getNextStep(state.currentStep, Array.from(flatBreadcrumbJourneySteps), Array.from(disabledJourneySteps));
    return {
      ...state,
      currentJourneyConfig: journeyConfig,
      flatBreadcrumbJourneySteps,
      disabledJourneySteps,
      currentStep: nextStep,
      breadcrumbJourneyParts: createBreadcrumbJourneyJourney(journeyConfig, flatBreadcrumbJourneySteps, nextStep),
    };
  }),

  on(setPrevJourneyStep, state => {
    const flatBreadcrumbJourneySteps = createFlatBreadcrumbJourneySteps(state.currentJourneyConfig);
    const disabledJourneySteps = createDisabledJourneySteps(state.currentJourneyConfig);
    const prevStep = getPreviousStep(state.currentStep, Array.from(flatBreadcrumbJourneySteps), Array.from(disabledJourneySteps));
    return {
      ...state,
      flatBreadcrumbJourneySteps,
      disabledJourneySteps,
      currentStep: prevStep,
      breadcrumbJourneyParts: createBreadcrumbJourneyJourney(state.currentJourneyConfig, flatBreadcrumbJourneySteps, prevStep),
    };
  }),

  on(fetchJourneyConfigForCurrentMandateSuccess, (state, {response}) => ({
    ...state,
    ///currentJourneyConfig: response,
    isSuccess: true,
  })),


  /** clear journey configs. */
  on(clearJourneyConfigs, state => ({
    ...state,
    currentJourneyConfig: null,
    breadcrumbJourneyParts: [],
    currentStep: null,
  })),
);

/** Flattens the provided journeyConfig and returns a Set of JourneyStepEnums. */
function createFlatBreadcrumbJourneySteps(journeyConfig: JourneyConfig): Set<JourneyStepEnum> | null {
  if (!journeyConfig) {
    return null;
  }
  const flattenedSteps = new Set<JourneyStepEnum>();
  for (const part of journeyConfig.parts) {
    for (const stage of part.stages) {
      for (const step of stage.steps) {
        flattenedSteps.add(step.url);
      }
    }
  }
  return flattenedSteps;
}

function createDisabledJourneySteps(journeyConfig: JourneyConfig): Set<JourneyStepEnum> | null {
  if (!journeyConfig) {
    return null;
  }
  const disabledSteps = new Set<JourneyStepEnum>();
  for (const part of journeyConfig.parts) {
    for (const stage of part.stages) {
      for (const step of stage.steps) {
        if(step.isDisabled)
          disabledSteps.add(step.url);
      }
    }
  }
  return disabledSteps;
}

/** Creates a breadcrumb journey and sets active and complete flags. */
function createBreadcrumbJourneyJourney(
  journeyConfig: JourneyConfig,
  flatJourneySteps: Set<JourneyStepEnum>,
  url: JourneyStepEnum,
): BreadcrumbJourneyPart[] | null {
  if (!journeyConfig) {
    return null;
  }
  let stepSequence = 0;
  return journeyConfig.parts.map(part => {
    const stages: BreadcrumbJourneyStage[] = part.stages.map(stage => {
      const steps: BreadcrumbJourneyStep[] = stage.steps.map(step => {
        stepSequence++;
        return {
          url: step.url as JourneyStepEnum,
          seq: stepSequence,
          isDisabled: step.isDisabled,
          isActive: step.url === url,
          isComplete: stepSequence < Array.from(flatJourneySteps).indexOf(url) + 1,
        };
      });
      return {
        isActive: steps.some(step => step.isActive),
        isComplete: steps.every(step => step.isComplete),
        displayName: stage.breadcrumbDisplayName,
        isDisabled: stage.isDisabled,
        steps,
      };
    });
    return {id: part.id, displayName: part.name, stages};
  });
}

function getPreviousStep(current: JourneyStepEnum, flatSteps: JourneyStepEnum[], disabledSteps: JourneyStepEnum[]): JourneyStepEnum | null {
  // const index  = flatSteps.indexOf(current) - 1;
  // const step = index < 0 ?  0 : index;
  // return flatSteps[step] ?? null;

  const index = flatSteps.indexOf(current) -1;
  const nextStepIndex = index < 0 ? 0 : index;
  let newStep = null;
  let nextStepUrl = null;

  for (let step = nextStepIndex; step < flatSteps.length -1; step--) {
    nextStepUrl = flatSteps[step];
    let di = disabledSteps.indexOf(nextStepUrl);

    if(di >= 0)
      continue;
    else
    {
      newStep = flatSteps[step];
      break;
    }
  }

  // return flatSteps[flatSteps.indexOf(current) + 1] ?? null;
  return newStep ?? null;  
}

function getNextStep(current: JourneyStepEnum, flatSteps: JourneyStepEnum[], disabledSteps: JourneyStepEnum[]): JourneyStepEnum | null {
  const index = flatSteps.indexOf(current) +1;
  const nextStepIndex = index < 0 ? 0 : index;
  let newStep = null;
  let nextStepUrl = null;

  for (let step = nextStepIndex; step < flatSteps.length +1; step++) {
    nextStepUrl = flatSteps[step];
    let di = disabledSteps.indexOf(nextStepUrl);

    if(di >= 0)
      continue;
    else
    {
      newStep = flatSteps[step];
      break;
    }
  }

  // return flatSteps[flatSteps.indexOf(current) + 1] ?? null;
  return newStep ?? null;
}

export function reducer(state: JourneyConfigState, action: Action) {
  return journeyConfigReducer(state, action);
}
