import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {catchError, exhaustMap, map, mapTo, startWith, switchMap} from 'rxjs/operators';
import {interval, Observable, of} from 'rxjs';
import {AlertService} from '@app/services/alert/alert.service';
import {ErrorActions} from '@app/state/error';
import {fetchAlerts, fetchAlertsSuccess} from '@app/state/alert/alert.actions';

const SIXTY_SECONDS = 60 * 1000;

@Injectable()
export class AlertEffects {
  loadAlerts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAlerts),
      switchMap(action$ => interval(SIXTY_SECONDS).pipe(startWith(0), mapTo(action$))),
      exhaustMap(() =>
        this.alertService.getAlerts().pipe(
          map(response => fetchAlertsSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private alertService: AlertService) {}
}
