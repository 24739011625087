import { createAction, props } from '@ngrx/store';
import { DocumentCreateRequest, DocumentListItem } from '@app/shared/models/document.model';
import { RelatedObjectType } from '@app/shared/enums/related-object-type.enum';

/** Load documents. */
export const loadDocuments = createAction(
  '[Documents] Load Documents',
  props<{ objectTypeId: number, isIncludeArchived: boolean }>()
);

export const loadDocumentsSuccess = createAction(
  '[Documents] Load Documents Success',
  props<{ response: DocumentListItem[] }>()
);

/** Load document types. */
export const loadDocumentTypes = createAction(
  '[Documents] Load Document Types',
  props<{ id: RelatedObjectType | null }>()
);

export const loadDocumentTypesSuccess = createAction(
  '[Documents] Load Document Types Success',
  props<{ response: any }>()
);

/** Upload document. */
export const uploadDocument = createAction(
  '[Documents] Upload Document',
  props<{ request: DocumentCreateRequest }>()
);

export const uploadDocumentSuccess = createAction(
  '[Documents] Upload Document Success',
  props<{response: DocumentListItem}>()
);

/** Upload progress. */
export const setUploadProgress = createAction(
  '[Documents] Upload Progress',
  props<{progress: number}>()
);

/** Download document. */
export const downloadDocument = createAction(
  '[Documents] Download Document',
  props<{id: string, contentType: string, fileName: string}>()
);

export const downloadDocumentSuccess = createAction(
  '[Documents] Download Document Success'
);

/** Reset all loading flags. */
export const setLoadingFalse = createAction(
  '[Documents] Set Loading False'
);
