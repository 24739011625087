import * as fromEmploymentStatus from './employment-status.reducer';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {DropDownItem} from '@app/shared/models/dropdownitem.model';

export const selectEmploymentStatusState = createFeatureSelector<fromEmploymentStatus.EmploymentStatusState>(fromEmploymentStatus.featureKey);

export const selectEmploymentStatuses = createSelector(selectEmploymentStatusState, fromEmploymentStatus.selectAll);

export const selectEmploymentStatusesLoading = createSelector(selectEmploymentStatusState, state => state.loading);

export const selectEmploymentStatusDropdownItems = createSelector(selectEmploymentStatuses, employmentStatuses =>
  employmentStatuses.map(
    employmentStatus =>
      <DropDownItem>{
        id: employmentStatus.id,
        label: employmentStatus.value,
        value: employmentStatus.value,
      },
  ),
);
