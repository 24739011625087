import {RelationshipType} from '@app/shared/models/relationship-type.model';
import {Title} from '@app/shared/models/title.model';
import {
  Individual,
  IndividualSummary,
  SpouseRelationCreateResponse,
} from '@app/shared/models/individuals/individual.model';
import {PowerOfAttorneyResponse} from '@app/shared/models/individuals/individual-powerofattorney.model';
import {InterestedPartiesResponse} from '@app/shared/models/individuals/individual-interestedparties';
import {InstructionsResponse} from '@app/shared/models/individuals/individual-instructions';
import {
  AnnualSpending,
  IncomeResponse,
  IncomeTypes,
  InvestmentIncome,
  MonthendTypes,
  QuarterendTypes,
  PaymentFrequencyTypes,
  TaxableInstructions,
} from '@app/shared/models/individuals/individual-income';
import {Assets} from '@app/shared/models/individuals/individual-assets.model';
import {Taxdetails} from '@app/shared/models/individuals/individual-taxdetails.model';
import {Isadetails} from '@app/shared/models/individuals/individual-isadetails';
import {AccountInformationSummary} from '@app/shared/models/individual-summary.model';

export const featureKey = 'Individual';

export interface IndividualState {
  individual: Individual;
  lastLoaded: string | null;
  loading: boolean;
  relationshipTypes: RelationshipType[];
  titles: Title[];
  titlesLoading: boolean;
  updateSuccess: boolean;
  spouseRelation: SpouseRelationCreateResponse;
  createPowerOfAttorneySuccess: boolean;
  powerOfAttorneyPostResponse: PowerOfAttorneyResponse;
  powerOfAttorneyGetResponse: PowerOfAttorneyResponse[];
  InterestedPartiesPostResponse: InterestedPartiesResponse;
  InterestedPartiesGetResponse: InterestedPartiesResponse[];
  createInterestedPartiesSuccess: boolean;
  isDeleted: boolean;
  createAccountInstructionsSuccess: boolean;
  AccountInstructions: InstructionsResponse[];
  isLoadingAccountInstructions: boolean;
  incomeDetails: IncomeResponse;
  incomeDetailsSuccess: boolean;
  removeIncomeDetailsSuccess: boolean;
  investmentIncomeSuccess: boolean;
  taxableInstructions: TaxableInstructions[];
  paymentFrequencyTypes: PaymentFrequencyTypes[];
  incomeTypes: IncomeTypes[];
  investmentIncome: InvestmentIncome;
  annualSpending: AnnualSpending;
  updateSpouseSuccess: boolean;
  updateInvestmentIncomeSuccess: boolean;
  updateAnnualSpendingSuccess: boolean;
  annualSpendingSuccess: boolean;
  createAssetsSuccess: boolean;
  assets: Assets;
  removeAssetsSuccess: boolean;
  taxdetails: Taxdetails;
  createTaxdetailsSuccess: boolean;
  updateTaxdetailsSuccess: boolean;
  createIsaaccountdetailsSuccess: boolean;
  isadetails: Isadetails[];
  removeIsaaccountSuccess: boolean;
  monthendTypes: MonthendTypes[];
  monthendTypesSuccess: boolean;
  quarterendTypes: QuarterendTypes[];
  quarterendTypesSuccess: boolean;
  individualSummary: IndividualSummary;
  individualOverview: IndividualSummary;
  updateSummarySuccess: boolean;
  updateOverviewSuccess: boolean;
  saveSpouseSuccess: boolean;
  completeIndividualDmaSuccess: boolean;
}

export const initialState: IndividualState = {
  individual: null,
  lastLoaded: null,
  loading: false,
  relationshipTypes: [],
  titles: [],
  titlesLoading: false,
  updateSuccess: false,
  spouseRelation: null,
  createPowerOfAttorneySuccess: false,
  powerOfAttorneyPostResponse: null,
  powerOfAttorneyGetResponse: null,
  InterestedPartiesPostResponse: null,
  InterestedPartiesGetResponse: null,
  createInterestedPartiesSuccess: false,
  isDeleted: false,
  createAccountInstructionsSuccess: false,
  AccountInstructions: null,
  isLoadingAccountInstructions: false,
  incomeDetails: null,
  incomeDetailsSuccess: false,
  removeIncomeDetailsSuccess: false,
  taxableInstructions: null,
  investmentIncomeSuccess: false,
  investmentIncome: null,
  updateSpouseSuccess: false,
  paymentFrequencyTypes: null,
  updateInvestmentIncomeSuccess: false,
  updateAnnualSpendingSuccess: false,
  incomeTypes: null,
  annualSpending: null,
  annualSpendingSuccess: false,
  createAssetsSuccess: false,
  assets: null,
  removeAssetsSuccess: false,
  taxdetails: null,
  createTaxdetailsSuccess: false,
  updateTaxdetailsSuccess: false,
  createIsaaccountdetailsSuccess: false,
  isadetails: null,
  removeIsaaccountSuccess: false,
  monthendTypes: null,
  monthendTypesSuccess: false,
  quarterendTypes: null,
  quarterendTypesSuccess: false,
  individualSummary: null,
  updateSummarySuccess: false,
  saveSpouseSuccess: false,
  individualOverview: null,
  updateOverviewSuccess: false,
  completeIndividualDmaSuccess: false,
};
