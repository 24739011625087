import {Product, ProductComparison, ProductDeclaration} from '@app/shared/models/portfolio/product.model';

export const featureKey = 'product';

export interface ProductState {
  products: Product[];
  comparison: ProductComparison[];
  declaration: ProductDeclaration | null;
  transferTypeAuthStatement: string | null;
}

export const initialState: ProductState = {
  products: [],
  comparison: [],
  declaration: null,
  transferTypeAuthStatement: null,
};
