import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { OverseasEmploymentEffects } from './overseas-employment.effects';
import * as fromOverseasEmployment from './overseas-employment.reducer';
import * as fromOverseasEmploymentState from './overseas-employment.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromOverseasEmploymentState.featureKey, fromOverseasEmployment.reducer),
    EffectsModule.forFeature([OverseasEmploymentEffects])
  ],
  declarations: []
})
export class OverseasEmploymentStateModule {
}
