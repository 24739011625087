import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import * as fromPortfolioState from './portfolio.state';
import {EffectsModule} from '@ngrx/effects';
import {PortfolioEffects} from './portfolio.effects';
import * as fromPortfolio from './portfolio.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromPortfolioState.featureKey, fromPortfolio.reducer),
    EffectsModule.forFeature([PortfolioEffects]),
  ],
})
export class PortfolioStateModule {
}
