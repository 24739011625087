import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {
  ConfirmEmailVerificationCode,
  EmailVerificationCodeResponse,
  PasswordChangeRequest,
  PasswordChangeResponse,
  SecurityQuestionAnswerList,
  SecurityQuestionList,
  UpdateSecurityQuestionAnswersRequest,
  UpdateSecurityQuestionAnswersResponse,
  resendEmailVerificationCodeResponse,
} from '@app/shared/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}
  private language = new BehaviorSubject<string>(null);
  languageData = this.language.asObservable()

  updatePassword(request: PasswordChangeRequest): Observable<PasswordChangeResponse> {
    return this.http.post<PasswordChangeResponse>(`${environment.apiEndpoint}/users/security/changepassword`, request);
  }
  langUpdate(lang: string) {
    this.language.next(lang);
  }

  fetchSecurityQuestions(): Observable<SecurityQuestionList> {
    return this.http.get<SecurityQuestionList>(`${environment.apiEndpoint}/users/security/questions`);
  }

  fetchSecurityQuestionAnswers(): Observable<SecurityQuestionAnswerList> {
    return this.http.get<SecurityQuestionAnswerList>(`${environment.apiEndpoint}/users/security/answers`);
  }

  updateSecurityQuestionAnswers(
    request: UpdateSecurityQuestionAnswersRequest,
  ): Observable<UpdateSecurityQuestionAnswersResponse> {
    return this.http.post<UpdateSecurityQuestionAnswersResponse>(
      `${environment.apiEndpoint}/users/security/changequestions`,
      request,
    );
  }
  EmailCodeVerification(request: ConfirmEmailVerificationCode) : Observable<EmailVerificationCodeResponse> {
    return this.http.post<EmailVerificationCodeResponse> (`${environment.apiEndpoint}/users/security/email/confirm`, request);
  }

  resendEmailVerificationCode(): Observable<resendEmailVerificationCodeResponse> {
    return this.http.post<resendEmailVerificationCodeResponse>(`${environment.apiEndpoint}/users/security/email/reconfirm`, null);
  }

}
