import {createAction, props} from '@ngrx/store';
import {CreateReportRequest, Report} from '@app/shared/models/report.model';

export const fetchReports = createAction('[Report] Fetch Reports');

export const fetchReportsSuccess = createAction('[Report] Fetch Reports Success', props<{response: Report[]}>());

export const createReport = createAction('[Report] Create Report', props<{request: CreateReportRequest}>());

export const createReportSuccess = createAction('[Report] Create Report Success', props<{response: Report}>());

export const downloadReport = createAction(
  '[Report] Download Report',
  props<{id: string; contentType: string; fileName: string}>(),
);

export const downloadReportSuccess = createAction('[Report] Download Report Success');
