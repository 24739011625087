export enum AuthFlowType {
  Code = 0,
  Password, // Using password flow see https://manfredsteyer.github.io/angular-oauth2-oidc/docs/additional-documentation/using-password-flow.html
}

export enum AuthLaunchMode {
  LaunchCodeFlow = 1,
  LaunchPasswordFlow, // Using password flow see https://manfredsteyer.github.io/angular-oauth2-oidc/docs/additional-documentation/using-password-flow.html
}

/** Auth action is to tell the mobile app weather to show the login or register screen. */
export enum AuthAction {
  Login = 'login',
  Register = 'register',
  Invite = 'invite',
}