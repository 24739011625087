<button
  aria-label="open card"
  tabindex="-1"
  mat-stroked-button *ngIf="!isShowDeveloperButtonCard" (click)="isShowDeveloperButtonCard = !isShowDeveloperButtonCard"
>
 {{'open_card' | translate}}
</button>
<mat-card *ngIf="isShowDeveloperButtonCard" fxLayout fxLayout.lt-sm="column">
  <div>
    <mat-card-header fxLayoutAlign="space-between">
      <mat-card-subtitle>{{'Not_visible_in_production' | translate}}</mat-card-subtitle>
      <button mat-stroked-button (click)="isShowDeveloperButtonCard = !isShowDeveloperButtonCard" data-cy="closeButton">
        {{'close' | translate}}
      </button>
    </mat-card-header>

    <mat-card-content fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="row wrap" fxLayoutGap="5px">
        <button
          color="warn"
          [routerLink]="[labUrl]"
          mat-raised-button
          (click)="isShowDeveloperButtonCard = false"
        >{{'Lab' | translate}}
          <mat-icon>science</mat-icon>
        </button>
        <button color="primary" mat-raised-button (click)="onShowValuesClick()">{{'StoredValues' | translate}}</button>
      </div>
      <div fxLayout="row wrap" fxLayoutGap="5px">
        <button color="primary" mat-raised-button>{{'Button_primary' | translate}}</button>
        <button color="accent" mat-raised-button>{{'Button_accent' | translate}}</button>
        <button color="warn" mat-raised-button>{{'Button_warn' | translate}}</button>
      </div>
      <div fxLayout="row wrap" fxLayoutGap="5px">
        <button color="primary" mat-raised-button (click)="doRefresh()">
          {{'F5_Refresh' | translate}}
        </button>

        <button mat-raised-button color="warn" (click)="logOut()">
         {{'Log_Out' | translate}}
        </button>
        <button mat-raised-button color="warn" (click)="logIn1()">
          {{'Log_in_1' | translate}}
        </button>
        <button mat-raised-button color="warn" (click)="logIn2()">
          {{'Log_in_2' | translate}}
        </button>
        <button mat-raised-button color="warn" (click)="logIn3()">
          {{'Log_in_3' | translate}}
        </button>
        <button mat-raised-button color="warn" (click)="logIn4()">
          {{'Log_in_4' | translate}}
        </button>
        <button mat-raised-button color="warn" (click)="tryNavigate()">
          {{'New_Application' | translate}}
        </button>
        <button mat-raised-button color="warn" (click)="showError()">
          {{'Show_Error' | translate}}
        </button>
        <input (keyup)="onKey($event)">
        <button mat-raised-button color="warn" (click)="onNav()">
          {{'GO' | translate}}
        </button>
      </div>
    </mat-card-content>
  </div>
</mat-card>
