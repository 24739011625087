import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from 'environments/environment';
import {PhoneNumber, PhoneNumberCreateRequest} from '@app/shared/models/phone-details.model';
import {PhoneType} from '@app/shared/models/phone-type.model';
import {DeletedItem} from '@app/shared/models/deleted-item.model';
import {DropDownItem} from '@app/shared/models/dropdownitem.model';

@Injectable({
  providedIn: 'root',
})
export class PhoneService {
  constructor(private http: HttpClient) {
  }

  listPhoneNumbersByIndividualId(id: number): Observable<PhoneNumber[]> {
    return this.http.get<PhoneNumber[]>(`${environment.apiEndpoint}/phonenumbers/individuals/${id}`);
  }

  listPhoneNumberTypes(id: number): Observable<DropDownItem[]> {
    return this.http.get<PhoneType[]>(`${environment.apiEndpoint}/phonenumbers/references/types?objecttypeid=${id}`)
      .pipe(
        map(phoneTypes => phoneTypes.map(phoneType => mapPhoneTypeToDropDownItem(phoneType))),
      );
  }

  createPhoneNumber(phoneNumber: PhoneNumberCreateRequest): Observable<PhoneNumber> {
    return this.http.post<PhoneNumber>(`${environment.apiEndpoint}/phonenumbers`, phoneNumber);
  }

  deletePhoneNumber(id: number): Observable<DeletedItem> {
    return this.http.delete<DeletedItem>(`${environment.apiEndpoint}/phonenumbers/${id}`);
  }
}

function mapPhoneTypeToDropDownItem(resp: PhoneType): DropDownItem {
  return {
    id: resp.id,
    label: resp.phoneNumberTypeName,
    value: resp.id.toString(),
  };
}
