import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { Individual, SpouseRelationCreateResponse } from '@app/shared/models/individuals/individual.model';
import { Title } from '@app/shared/models/title.model';
import { RelationshipType } from '@app/shared/models/relationship-type.model';
import { Patch } from '@app/shared/models/patch.model';
import { SpouseRelationCreate } from '@app/shared/models/individuals/individual-participant.model';
import { OverseasEmployment, OverseasEmploymentResponse } from '@app/shared/models/overseas-employment.model';

@Injectable({
    providedIn: 'root',
})
export class OverseasEmploymentService {
    constructor(private http: HttpClient) { }

    saveOverseasEmploymentDetails(employment: OverseasEmployment): Observable<OverseasEmploymentResponse> {
        return this.http.post<OverseasEmploymentResponse>(`${environment.apiEndpoint}/employment`, employment);
    }
    patchOverseasEmploymentDetails(individualId: number, patch: Patch[]): Observable<OverseasEmploymentResponse> {
        return this.http.patch<OverseasEmploymentResponse>(`${environment.apiEndpoint}/employment/${individualId}`, patch);
    }
    fetchOverseasEmploymentDetails(individualId: number): Observable<OverseasEmploymentResponse> {
        return this.http.get<OverseasEmploymentResponse>(`${environment.apiEndpoint}/employment/${individualId}`);
    }

}