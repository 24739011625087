export enum JourneyStepEnum {
  Acceptance = 'acceptance',
  AccountName = 'account-name',
  AccountType = 'account-type',
  AdditionalAddresses = 'additional-addresses',
  AttitudeToRisk = 'attitude-to-risk',
  AttitudeToRiskSummary = 'attitude-to-risk-summary',
  BankAccount = 'bank-account',
  BankDetails = 'bank-details',
  ChildPersonalInformation = 'child-details',
  Submission = 'submission',
  Finalise = 'finalise',
  ContactNumbers = 'contact-numbers',
  Documents = 'documents',
  EmailDetails = 'email-details',
  Employment = 'employment',
  EntityDetails = 'entity-details',
  EntityLei = 'entity-lei',
  Funding = 'funding',
  Gdpr = 'gdpr',
  IndividualDetails = 'individual-details',
  IndividualName = 'individual-name',
  InvestmentReview = 'investment-review',
  InvestmentSummary = 'investment-summary',
  IsaTransfer = 'isa-transfer',
  Nationality = 'nationality',
  PersonalDetails = 'personal-details',
  PersonalInformation = 'personal-information',
  PersonalParticipants = 'personal-participants',
  PhoneDetails = 'phone-details',
  ProductDeclaration = 'product-declaration',
  ProductSelection = 'product-selection',
  ResidentialAddresses = 'residential-addresses',
  Services = 'services',
  SourceOfFunds = 'source-of-funds',
  StrategySelection = 'strategy-selection',
  TaxInformation = 'tax-information',
  SpouseInformation = 'partners-details',
  PostalAddress = 'postal-addresses',
  SpouseDetails = 'spouse/partners-details',
  SpouseDetailInformation = 'partners-detail-information',
  TaxationResidency = 'taxation-residency',
  OverseasEmployment = 'overseas-employment',
  DirectorshipsExposure = 'polictical-exposure',
  PowerOfAttorneyInterestedParties = 'power-of-attorney-and-interested-parties',
  PowerOfAttorney = 'power-of-attorney',
  InterestedParties = 'interested-parties',
  AccountInstructions = 'account-instructions',
  FinancialDetails = 'financial-details',
  Income = 'income',
  InvestmentIncome = 'investment-income',
  AnnualSpendingRequirement = 'annual-spending-requirement',
  Assets = 'assets',
  Liabilities = 'liabilities',
  RetirementProvision = 'retirement-provision',
  FinancialDependents = 'financial-dependents',
  InvestmentAmount = 'investment-amount',
  YearEndTaxCaptialGainsTax = 'tax-year-end-capital-gains',
  ISARequirements = 'isa-requirements',
  ISADeclaration = 'isa-declaration',
  ISADetails = 'isa-details',
  ApplicationSummary = 'account-summary',
  ApplicationOverview = 'application-overview',
  DMADocuments = 'your-documents',
  DMAAgreement = 'your-agreement',
  DMAComplete = 'final-submission',
  ReportsStatements = 'reports-statements',
  PrncipalResidentialAddress = 'principal-residential-address',
  PrincipalPostalAddress = 'principal-postal-address',
  CompletedJourney = 'completed-journey',
  CompletedApplication = 'completed-application',
  CompletedVerification = 'completed-verification',
  EmailVerification = 'email-verification',
  SecurityQuestion ='security-questions'
}

export enum JourneyShortNameEnum {
  Default = 'ltdco',
  Entity = 'addep',
  Personal = 'perso',
  Joint = 'joint',
  Start = 'opacc',
  New = 'new',
}
