import {PhoneNumber} from '@app/shared/models/phone-details.model';
import {DropDownItem} from '@app/shared/models/dropdownitem.model';

export const featureKey = 'phone';

export interface PhoneState {
  phoneNumbers: PhoneNumber[];
  phoneTypes: DropDownItem[];
  currentPhoneDetailId: number | null;
  addPhoneNumberSuccess: boolean;
  deletePhoneNumberSuccess: boolean;
}

export const initialState: PhoneState = {
  phoneNumbers: [],
  phoneTypes: [],
  currentPhoneDetailId: null,
  addPhoneNumberSuccess: false,
  deletePhoneNumberSuccess: false,
};
