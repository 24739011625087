import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {MandateCategoryEnum, MandateCategoryIdEnum} from '@app/shared/enums/mandate.enum';
import {Mandate} from '@app/shared/models/mandate.model';
import {MandateState} from '@app/state/mandate/mandate.state';
import {selectAllMandates, selectHasMandates, selectMandatesLoading} from '@app/state/mandate/mandate.selectors';
import {filter, first, map, skipWhile, switchMap} from 'rxjs/operators';

import {combineLatest, Observable, Subject} from 'rxjs';

import {JourneyStepEnum} from '@app/shared/enums/journey-step.enum';
import {ProfileState} from '@app/state/profile/profile.state';
import {AuthOidcService} from '@app/auth/services/auth-oidc.service';
import {fetchMandates} from '@app/state/mandate/mandate.actions';
import {fetchProfile} from '@app/state/profile/profile.actions';
import {DashboardPagesEnum, RootPagesEnum} from '@app/shared/enums/dashboard-pages.enum';
import {PortfolioState} from '@app/state/portfolio/portfolio.state';
import {fetchPortfolios} from '@app/state/portfolio/portfolio.actions';
import {OAuthStorageService} from '@app/auth/services/oauth-storage.service';
import { OnboardingState } from '@app/state';
import { continueApplication } from '@app/state/onboarding/onboarding.actions';
import { environment } from 'environments/environment';


@Component({
  selector: 'abl-start',
  templateUrl: './start.component.html',
  styles: [],
})
export class StartComponent implements OnInit {
  onBoardingOnly = environment.onBoardingOnly;
  private mandatesLoading$ = this.mandateStore.select(selectMandatesLoading);
  private hasMandates$ = this.mandateStore.select(selectHasMandates);
  private mandates$ = this.mandateStore.select(selectAllMandates);

  navigate$ = combineLatest([this.hasMandates$, this.mandatesLoading$]).pipe(
    skipWhile(([, loading]) => loading),
    map(([hasMandates]) => {
      if (hasMandates && this.onBoardingOnly) {
        this.mandates$.subscribe(mandates => {
          this.onboardingStore.dispatch(continueApplication({mandateId: mandates[0].id}));
        });
      }
      return hasMandates;
    }),
  );

   constructor(
    private mandateStore: Store<MandateState>,
    private onboardingStore: Store<OnboardingState.OnboardingState>,
    private profileStore: Store<ProfileState>,
  ) {}

  ngOnInit() {
    this.mandateStore.dispatch(fetchMandates());
    this.profileStore.dispatch(fetchProfile());
    this.navigate$.subscribe();
  }

  continueApplication(mandateId: number) {
    this.onboardingStore.dispatch(continueApplication({mandateId}));
  }
}
