import {Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {logout} from '@app/state/auth/auth.actions';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap({
        error: (err: HttpErrorResponse) => this.handleError(err),
      }),
    );
  }

  handleError(err: HttpErrorResponse) {
    if (err.status === 401 || err.status === 403) {
      this.injector.get(Store).dispatch(logout());
    }
  }
}
