import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromJourneyHistory from './journey-history.reducer';
import * as fromJourneyHistoryState from './journey-history.state';

export const selectJourneyHistoryState = createFeatureSelector<fromJourneyHistoryState.JourneyHistoryState>(fromJourneyHistoryState.JourneyHistoryFeatureKey);

export const selectJourneyHistorySteps = createSelector(
  selectJourneyHistoryState,
  fromJourneyHistory.selectAll,
);

export const getJourneyHistorySteps = createSelector(
  selectJourneyHistoryState,
  selectJourneyHistorySteps,
  (state, journeyHistorySteps) =>
    state.lastLoaded ? journeyHistorySteps : null,
);

export const selectLastLoaded = createSelector(
  selectJourneyHistoryState,
  (JourneyHistoryState) => JourneyHistoryState.lastLoaded,
);

export const selectCurrentJourneyHistoryStep = createSelector(
  selectJourneyHistoryState,
  (state) => state.currentJourneyHistoryStep,
);

// TODO this needs to us breadcrumb helper service for get latest
export const getLatestBreadcrumbHistory = createSelector(
  selectJourneyHistorySteps,
  (journeyHistorySteps) => {
    let latest = [];
    const latestFinal = [];

    const distinctBreadcrumbIds = new Set(
      journeyHistorySteps.map((x) => x.parentId),
    );

    distinctBreadcrumbIds.forEach((id) => {
      latest.push(
        journeyHistorySteps
          .filter((x) => x.parentId === id)
          .sort(
            (a, b) =>
              new Date(b.writtenDate).getTime() -
              new Date(a.writtenDate).getTime(),
          )[0],
      );
    });

    latest = latest.sort((a, b) => a.parentId - b.parentId);
    let writtenDate;
    latest.forEach((journeyHistoryStep, index) => {
      if (
        index === 0 ||
        new Date(journeyHistoryStep.writtenDate).getTime() >
        new Date(writtenDate).getTime()
      ) {
        latestFinal.push(journeyHistoryStep);
        writtenDate = journeyHistoryStep.writtenDate;
      }
    });

    return latestFinal;
  },
);
