export enum PortfolioFundingContributionTypeEnum {
  LumpSum = 1,
  DirectDebit = 2,
}

export enum PortfolioTypeEnum {
  GIA = 1,
  ISA = 2,
  JISA = 3,
}

export enum PortfolioActivityEventStatusId {
  New = 1,
  Accepted = 2,
  Rejected = 3,
  Locked = 4,
  Void = 5,
  Processing = 6,
  PendingApproval = 7,
  Complete = 8,
}

export enum HistoricPerformanceTypeEnum {
  MarketValue = 'marketValue',
  TotalReturn = 'totalReturn',
}

export enum ActionsEnumOnManageConnectedAccounts {
  Accept = 'accept',
  Remove = 'remove',
  Decline = 'decline',
  Cancel = 'cancel',
}

export enum PortfolioConnectionTypeEnum {
  Viewing = 1,
  Permissioned = 2,
}
