import {IsaTransferTypeIdEnum, IsaTypeIdEnum} from '@app/shared/enums/product.enum';
import {ProductTransferAuthStatement} from '@app/shared/models/portfolio/product.model';

const ctrFullTransferBody = `
<p>
  <strong>Full transfer</strong>: I authorise Pershing Securities plc to transfer the full balance of the existing
  Child Trust Fund with immediate
  effect. Any holdings are to be sold and transferred as cash with immediate effect.
</p>
<p>
  The following stakeholder CTF features may not be included in a Junior ISA:
</p>
<ul>
  <li>Lifestyling from age 15</li>
  <li>Minimum subscriptions of £10 allowed</li>
  <li>Annual charge cap of 1.5%</li>
</ul>
<p>
  If the transfer from the CTF is not successful, any Junior ISA that has been opened on a provisional basis to
  accept the transfer will be invalid, and any subscriptions to the provisional Junior ISA will be returned. The CTF
  will remain intact. If you are applying to transfer a stakeholder CTF, please confirm that you understand this and
  wish to proceed.
</p>
<p>
  I authorise Pershing Securities Limited, the ISA Manager, to transfer my Child Trust Fund to my Junior ISA. The
  child named in this application will be the beneficial owner of the account investments.
</p>
<p>
  I declare that:
</p>
<ul>
  <li>I am the registered contact for the CTF</li>
  <li>I will be the registered contact for the JISA</li>
</ul>
<p>
  I authorise Pershing Securities Limited:
</p>
<ul>
  <li>To hold the child's subscriptions, investments, interest, dividends, and any other rights or proceeds in
    respect of those investments and cash and to make on the child's behalf, any claims to relief from tax in
    respect of JISA investments.
  </li>
  <li>I agree to the JISA terms and conditions.</li>
  <li>I have read, understood and agree to the 'Guide to Pershing's Services' Document and the provisions applicable
    to the Pershing Junior ISA as set out in the Terms of Business and agree that my account will be operated in
    accordance with the provisions of these documents.
  </li>
  <li>I confirm that to the best of my belief the information in this form is true.</li>
</ul>`;

export const transferAuthStatements: ProductTransferAuthStatement[] = [
  {
    isaTypeId: IsaTypeIdEnum.StocksSharesIsa,
    isaTransferTypeId: IsaTransferTypeIdEnum.Full,
    body: '<strong>Full transfer:</strong> I authorise Pershing Securities plc to transfer the full balance of my existing Stocks & Shares ISA. All holdings are to be sold and transferred as cash with immediate effect.',
  },
  {
    isaTypeId: IsaTypeIdEnum.StocksSharesIsa,
    isaTransferTypeId: IsaTransferTypeIdEnum.Partial,
    body: '<strong>Partial transfer:</strong> I authorise Pershing Securities plc to transfer the stated transfer value from my existing ISA. I will ensure there is sufficient cash available for the transfer.',
  },
  {
    isaTypeId: IsaTypeIdEnum.CashIsa,
    isaTransferTypeId: IsaTransferTypeIdEnum.Full,
    body: '<strong>Full transfer:</strong> I authorise Pershing Securities plc to transfer the full balance of my existing Cash ISA with immediate effect.',
  },
  {
    isaTypeId: IsaTypeIdEnum.CashIsa,
    isaTransferTypeId: IsaTransferTypeIdEnum.Partial,
    body: '<strong>Partial transfer:</strong> I authorise Pershing Securities plc to transfer the stated transfer value from my existing Cash ISA with immediate effect.',
  },
  {
    isaTypeId: IsaTypeIdEnum.StocksSharesJisa,
    isaTransferTypeId: IsaTransferTypeIdEnum.Full,
    body: '<strong>Full transfer:</strong> I authorise Pershing Securities plc to transfer the full balance of the existing S&S JISA with immediate effect. All holdings are to be sold and transferred as cash with immediate effect.',
  },
  {
    isaTypeId: IsaTypeIdEnum.CashJisa,
    isaTransferTypeId: IsaTransferTypeIdEnum.Full,
    body: '<strong>Full transfer:</strong> I authorise Pershing Securities plc to transfer the full balance of the existing Cash JISA with immediate effect.',
  },
  {
    isaTypeId: IsaTypeIdEnum.CashJisa,
    isaTransferTypeId: IsaTransferTypeIdEnum.Partial,
    body: '<strong>Partial transfer:</strong> I authorise Pershing Securities plc to transfer the stated transfer amount from the existing cash JISA with immediate effect.',
  },
  {
    isaTypeId: IsaTypeIdEnum.CTF,
    isaTransferTypeId: IsaTransferTypeIdEnum.Full,
    body: ctrFullTransferBody,
  },
];
