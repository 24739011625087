import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BankEffects } from './bank.effects';
import * as fromBank from './bank.reducer';
import * as fromBankState from './bank.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromBankState.featureKey, fromBank.reducer),
    EffectsModule.forFeature([BankEffects])
  ],
  declarations: []
})
export class BankStateModule {}
