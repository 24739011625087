<div class="error-container" ngClass.gt-md="border-radius" fxLayout fxLayoutAlign="space-between center" fxFlex>
  <div fxLayout="column">
    <span>{{ 'Unable_to_complete_your_request' | translate }}</span>
    <span>
      {{ 'Try_again_later' | translate }}
      <a [href]="FAQ" target="_blank">{{ text }}</a>.
    </span>
    <span>
      {{ 'Please_quote_the_reference' | translate }} "<b>{{
        error?.referenceNumber ? error?.referenceNumber : error?.error?.referenceNumber
                               ? error?.error?.referenceNumber : error?.status == '429' ? 'TN'+error?.status : error
      }}</b
      >"
    </span>
  </div>
  <button aria-label="close" mat-icon-button (click)="closeError.emit()">
    <mat-icon>highlight_off</mat-icon>
  </button>
</div>
