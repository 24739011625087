import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {CountryService} from '@app/services/country-service/country.service';
import {Observable, of} from 'rxjs';
import {catchError, filter, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {Country} from '@app/shared/models/country.model';
import * as CountriesState from '@app/state/countries/country.state';
import {ErrorActions} from '@app/state/error';
import {fetchCountries, fetchCountriesSuccess} from '@app/state/countries/country.actions';
import {selectCountries} from '@app/state/countries/country.selectors';
import {CurrenciesService} from '@app/services/currencies/currencies.service';
import {CurrenciesState} from './currencies.state';
import {Currency} from '@app/shared/models/portfolio/currency.model';
import {fetchCurrencies, fetchCurrenciesSuccess} from './currencies.actions';
import {selectCurrencies} from './currencies.selectors';

@Injectable()
export class CurrenciesEffects {
  fetchCurrencies$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchCurrencies),
      withLatestFrom(this.currenciesStore.select(selectCurrencies)),
      filter(([, currencies]) => !currencies.length),
      switchMap(() => {
        return this.currenciesService.getCurrencies().pipe(
          map((currenciesArray: Currency[]) => fetchCurrenciesSuccess({payload: currenciesArray})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private currenciesStore: Store<CurrenciesState>,
    private currenciesService: CurrenciesService,
  ) {}
}
