import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {IndividualChildEffects} from './individual-child.effects';
import * as fromIndividualChild from './individual-child.reducer';
import * as fromIndividualChildState from './individual-child.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromIndividualChildState.individualChildFeatureKey, fromIndividualChild.reducer),
    EffectsModule.forFeature([IndividualChildEffects]),
  ],
})
export class IndividualChildStateModule {}
