import {PowerOfAttorney} from './../../shared/models/individuals/individual-powerofattorney.model';
import {Action, createReducer, on} from '@ngrx/store';
import {
  createAccountInstructions,
  createAccountInstructionsFailure,
  createAccountInstructionsSuccess,
  createAnnualSpending,
  createAnnualSpendingFailure,
  createAnnualSpendingSuccess,
  createAssets,
  createAssetsFailure,
  createAssetsSuccess,
  createIncomeDetails,
  createIncomeDetailsFailure,
  createIncomeDetailsSuccess,
  createIndividualInterestedParties,
  createIndividualInterestedPartiesFailure,
  createIndividualInterestedPartiesSuccess,
  createIndividualPowerOfAttorney,
  createIndividualPowerOfAttorneyFailure,
  createIndividualPowerOfAttorneySuccess,
  createInvestmentIncome,
  createInvestmentIncomeFailure,
  createInvestmentIncomeSuccess,
  createIsaaccountdetails,
  createIsaaccountdetailsFailure,
  createIsaaccountdetailsSuccess,
  createTaxdetails,
  createTaxdetailsFailure,
  createTaxdetailsSuccess,
  deleteInterestedPartySuccess,
  deleteIsaaccountSuccess,
  fetchAccountInstructions,
  fetchAccountInstructionsFailure,
  fetchAccountInstructionsSuccess,
  fetchAnnualSpending,
  fetchAnnualSpendingFailure,
  fetchAnnualSpendingSuccess,
  fetchAssets,
  fetchAssetsFailure,
  fetchAssetsSuccess,
  fetchChildTitles,
  fetchChildTitlesSuccess,
  fetchIncomeDetails,
  fetchIncomeDetailsFailure,
  fetchIncomeDetailsSuccess,
  fetchIncomeTypes,
  fetchIncomeTypesFailure,
  fetchIncomeTypesSuccess,
  fetchIndividual,
  fetchIndividualFail,
  fetchIndividualInterestedParties,
  fetchIndividualInterestedPartiesFailure,
  fetchIndividualInterestedPartiesSuccess,
  fetchIndividualPowerOfAttorney,
  fetchIndividualPowerOfAttorneyFailure,
  fetchIndividualPowerOfAttorneySuccess,
  fetchIndividualRelationshipTypesSuccess,
  fetchIndividualSpouseRelation,
  fetchIndividualSpouseRelationFail,
  fetchIndividualSpouseRelationSuccess,
  fetchIndividualSuccess,
  fetchInvestmentIncome,
  fetchInvestmentIncomeFailure,
  fetchInvestmentIncomeSuccess,
  fetchIsaaccountdetails,
  fetchIsaaccountdetailsFail,
  fetchIsaaccountdetailsSuccess,
  fetchPaymentFrequencyTypes,
  fetchPaymentFrequencyTypesFailure,
  fetchPaymentFrequencyTypesSuccess,
  fetchTaxableInstructions,
  fetchTaxableInstructionsFailure,
  fetchTaxableInstructionsSuccess,
  fetchTaxdetails,
  fetchTaxdetailsFail,
  fetchTaxdetailsSuccess,
  fetchTitles,
  fetchTitlesSuccess,
  removeAssetsSuccess,
  removeIncomeDetailsSuccess,
  resetDeleteAssetsSuccess,
  resetDeleteIncomeDetailsSuccess,
  resetDeleteInterestedPartySuccess,
  resetDeleteIsaaccountSuccess,
  saveAcceptance,
  saveAcceptanceFailure,
  saveAcceptanceSuccess,
  saveIndividualSpouseRelation,
  saveIndividualSpouseRelationFailure,
  saveIndividualSpouseRelationSuccess,
  updateAnnualSpending,
  updateAnnualSpendingFailure,
  updateAnnualSpendingSuccess,
  updateIndividual,
  updateIndividualFailure,
  updateIndividualSpouseRelation,
  updateIndividualSpouseRelationFailure,
  updateIndividualSpouseRelationSuccess,
  updateIndividualSuccess,
  updateInvestmentIncome,
  updateInvestmentIncomeFailure,
  updateInvestmentIncomeSuccess,
  updateTaxdetails,
  updateTaxdetailsFailure,
  updateTaxdetailsSuccess,
  fetchMonthendTypes,
  fetchMonthendTypesFailure,
  fetchMonthendTypesSuccess,
  fetchQuarterendTypes,
  fetchQuarterendTypesFailure,
  fetchQuarterendTypesSuccess,
  fetchIndividualSummary,
  fetchIndividualSummaryFailure,
  fetchIndividualSummarySuccess,
  fetchIndividualOverview,
  fetchIndividualOverviewFailure,
  fetchIndividualOverviewSuccess,
  completeIndividualDma,
  completeIndividualDmaFailure,
  completeIndividualDmaSuccess,
} from './individual.actions';
import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';
import {Individual} from '@app/shared/models/individuals/individual.model';
import {initialState} from '@app/state/individual/individual.state';

export const adapter: EntityAdapter<Individual> = createEntityAdapter<Individual>();

const IndividualReducer = createReducer(
  initialState,

  /** Fetch individual. */
  on(fetchIndividual, state => ({
    ...state,
    loading: true,
    updateSuccess: false,
  })),

  on(fetchIndividualSuccess, (state, {response}) => ({
    ...state,
    individual: response,
    loading: false,
    updateSuccess: false,
    lastLoaded: new Date().toUTCString(),
  })),

  on(fetchIndividualFail, state => ({
    ...state,
    loading: false,
    updateSuccess: false,
  })),

  /** Fetch relationship types. */
  on(fetchIndividualRelationshipTypesSuccess, (state, {response}) => ({
    ...state,
    relationshipTypes: response,
  })),

  on(fetchTitles, state => ({
    ...state,
    titlesLoading: true,
  })),

  on(fetchTitlesSuccess, (state, {response}) => ({
    ...state,
    titles: response,
    titlesLoading: false,
  })),

  on(fetchChildTitles, state => ({
    ...state,
    titlesLoading: true,
  })),

  on(fetchChildTitlesSuccess, (state, {response}) => ({
    ...state,
    titles: response,
    titlesLoading: false,
  })),

  on(updateIndividual, state => ({
    ...state,
    updateSuccess: false,
  })),

  on(updateIndividualSuccess, (state, {response}) => ({
    ...state,
    updateSuccess: true,
    individual: response,
  })),

  on(updateIndividualFailure, state => ({
    ...state,
    updateSuccess: false,
  })),
  on(updateIndividualSpouseRelation, state => ({
    ...state,
    updateSpouseSuccess: false,
  })),

  on(updateIndividualSpouseRelationSuccess, (state, {response}) => ({
    ...state,
    updateSpouseSuccess: true,
    spouseRelation: response,
  })),

  on(updateIndividualSpouseRelationFailure, state => ({
    ...state,
    updateSpouseSuccess: false,
  })),

  on(saveIndividualSpouseRelation, state => ({
    ...state,
    saveSpouseSuccess: false,
  })),

  on(saveIndividualSpouseRelationSuccess, (state, {response}) => ({
    ...state,
    saveSpouseSuccess: true,
    spouseRelation: response,
  })),

  on(saveIndividualSpouseRelationFailure, state => ({
    ...state,
    saveSpouseSuccess: false,
  })),

  // PowerOfAttorney post
  on(createIndividualPowerOfAttorney, state => ({
    ...state,
    createPowerOfAttorneySuccess: false,
    powerOfAttorneyLoading: true,
  })),

  on(createIndividualPowerOfAttorneySuccess, (state, {response}) => ({
    ...state,
    createPowerOfAttorneySuccess: true,
    powerOfAttorneyPostResponse: response,
    powerOfAttorneyLoading: false,
  })),

  on(createIndividualPowerOfAttorneyFailure, state => ({
    ...state,
    createPowerOfAttorneySuccess: false,
    powerOfAttorneyLoading: false,
  })),
  // end

  // InterestedParties post
  on(createIndividualInterestedParties, state => ({
    ...state,
    createInterestedPartiesSuccess: false,
    // powerOfAttorneyLoading: true,
  })),

  on(createIndividualInterestedPartiesSuccess, (state, {response}) => ({
    ...state,
    createInterestedPartiesSuccess: true,
    InterestedPartiesPostResponse: response,
    // powerOfAttorneyLoading: false,
  })),

  on(createIndividualInterestedPartiesFailure, state => ({
    ...state,
    createInterestedPartiesSuccess: false,
    // powerOfAttorneyLoading: false,
  })),
  // end

  on(saveAcceptance, state => ({
    ...state,
    updateSuccess: false,
  })),

  on(saveAcceptanceSuccess, (state, {response}) => ({
    ...state,
    updateSuccess: true,
    individual: response,
  })),

  on(saveAcceptanceFailure, state => ({
    ...state,
    updateSuccess: false,
  })),

  on(fetchIndividualSpouseRelation, state => ({
    ...state,
    updateSpouseSuccess: false,
  })),

  on(fetchIndividualSpouseRelationSuccess, (state, {response}) => ({
    ...state,
    updateSpouseSuccess: true,
    spouseRelation: response,
  })),

  on(fetchIndividualSpouseRelationFail, state => ({
    ...state,
    updateSpouseSuccess: false,
  })),

  on(fetchIndividualPowerOfAttorney, state => ({
    ...state,
    createPowerOfAttorneySuccess: false,
  })),

  on(fetchIndividualPowerOfAttorneySuccess, (state, {response}) => ({
    ...state,
    createPowerOfAttorneySuccess: true,
    powerOfAttorneyGetResponse: response,
  })),

  on(fetchIndividualPowerOfAttorneyFailure, state => ({
    ...state,
    createPowerOfAttorneySuccess: false,
  })),
  on(fetchIndividualInterestedParties, state => ({
    ...state,
    createInterestedPartiesSuccess: false,
  })),

  on(fetchIndividualInterestedPartiesSuccess, (state, {response}) => ({
    ...state,
    createInterestedPartiesSuccess: true,
    InterestedPartiesGetResponse: response,
  })),

  on(fetchIndividualInterestedPartiesFailure, state => ({
    ...state,
    createInterestedPartiesSuccess: false,
  })),

  on(deleteInterestedPartySuccess, (state, {response}) => ({
    ...state,
    isDeleted: response.isDeleted,
  })),

  on(resetDeleteInterestedPartySuccess, state => ({
    ...state,
    isDeleted: false,
  })),

  //account instructions post
  on(createAccountInstructions, state => ({
    ...state,
    createAccountInstructionsSuccess: false,
  })),

  on(createAccountInstructionsSuccess, (state, {response}) => ({
    ...state,
    createAccountInstructionsSuccess: true,
    InstructionsPostResponse: response,
  })),

  on(createAccountInstructionsFailure, state => ({
    ...state,
    createAccountInstructionsSuccess: false,
  })),
  // end

  on(fetchAccountInstructions, state => ({
    ...state,
    createAccountInstructionsSuccess: false,
    isLoadingAccountInstructions: true,
  })),

  on(fetchAccountInstructionsSuccess, (state, {response}) => ({
    ...state,
    createAccountInstructionsSuccess: true,
    AccountInstructions: response,
    isLoadingAccountInstructions: false,
  })),

  on(fetchAccountInstructionsFailure, state => ({
    ...state,
    createAccountInstructionsSuccess: false,
    isLoadingAccountInstructions: false,
  })),

  on(fetchIncomeDetails, state => ({
    ...state,
    incomeDetailsSuccess: false,
  })),

  on(fetchIncomeDetailsSuccess, (state, {response}) => ({
    ...state,
    incomeDetailsSuccess: true,
    incomeDetails: response,
  })),

  on(fetchIncomeDetailsFailure, state => ({
    ...state,
    incomeDetailsSuccess: false,
  })),

  //income details post
  on(createIncomeDetails, state => ({
    ...state,
    incomeDetailsSuccess: false,
  })),

  on(createIncomeDetailsSuccess, (state, {response}) => ({
    ...state,
    incomeDetailsSuccess: true,
    incomeDetails: response,
  })),

  on(createIncomeDetailsFailure, state => ({
    ...state,
    incomeDetailsSuccess: false,
  })),
  // end

  // remove account
  on(removeIncomeDetailsSuccess, (state, {response}) => ({
    ...state,
    removeIncomeDetailsSuccess: response.isDeleted,
  })),

  on(resetDeleteIncomeDetailsSuccess, state => ({
    ...state,
    removeIncomeDetailsSuccess: false,
  })),

  on(fetchTaxableInstructions, state => ({
    ...state,
    getTaxableInstructions: false,
  })),

  on(fetchTaxableInstructionsSuccess, (state, {response}) => ({
    ...state,
    getTaxableInstructions: true,
    taxableInstructions: response,
  })),

  on(fetchTaxableInstructionsFailure, state => ({
    ...state,
    getTaxableInstructions: false,
  })),

  on(fetchPaymentFrequencyTypes, state => ({
    ...state,
    fetchPaymentFrequencyTypes: false,
  })),

  on(fetchPaymentFrequencyTypesSuccess, (state, {response}) => ({
    ...state,
    fetchPaymentFrequencyTypes: true,
    paymentFrequencyTypes: response,
  })),

  on(fetchPaymentFrequencyTypesFailure, state => ({
    ...state,
    fetchPaymentFrequencyTypes: false,
  })),

  //investment income post
  on(createInvestmentIncome, state => ({
    ...state,
    investmentIncomeSuccess: false,
  })),

  on(createInvestmentIncomeSuccess, (state, {response}) => ({
    ...state,
    investmentIncomeSuccess: true,
    investmentIncome: response,
  })),

  on(createInvestmentIncomeFailure, state => ({
    ...state,
    investmentIncomeSuccess: false,
  })),
  // end

  //investment income patch
  on(updateInvestmentIncome, state => ({
    ...state,
    updateInvestmentIncomeSuccess: false,
  })),

  on(updateInvestmentIncomeSuccess, (state, {response}) => ({
    ...state,
    updateInvestmentIncomeSuccess: true,
    investmentIncome: response,
  })),

  on(updateInvestmentIncomeFailure, state => ({
    ...state,
    updateInvestmentIncomeSuccess: false,
  })),
  // end

  on(fetchInvestmentIncome, state => ({
    ...state,
    investmentIncomeSuccess: false,
  })),

  on(fetchInvestmentIncomeSuccess, (state, {response}) => ({
    ...state,
    investmentIncomeSuccess: true,
    investmentIncome: response,
  })),

  on(fetchInvestmentIncomeFailure, state => ({
    ...state,
    investmentIncomeSuccess: false,
  })),

  on(fetchIncomeTypes, state => ({
    ...state,
    fetchIncomeTypes: false,
  })),

  on(fetchIncomeTypesSuccess, (state, {response}) => ({
    ...state,
    fetchIncomeTypes: true,
    incomeTypes: response,
  })),

  on(fetchIncomeTypesFailure, state => ({
    ...state,
    fetchIncomeTypes: false,
  })),

  //createAnnualSpending post
  on(createAnnualSpending, state => ({
    ...state,
    annualSpendingSuccess: false,
  })),

  on(createAnnualSpendingSuccess, (state, {response}) => ({
    ...state,
    annualSpendingSuccess: true,
    annualSpending: response,
  })),

  on(createAnnualSpendingFailure, state => ({
    ...state,
    annualSpendingSuccess: false,
  })),
  // end

  //fetchAnnualSpending get
  on(fetchAnnualSpending, state => ({
    ...state,
    fetchAnnualSpendingSuccess: false,
  })),

  on(fetchAnnualSpendingSuccess, (state, {response}) => ({
    ...state,
    fetchAnnualSpendingSuccess: true,
    annualSpending: response,
  })),

  on(fetchAnnualSpendingFailure, state => ({
    ...state,
    fetchAnnualSpendingSuccess: false,
  })),
  // end

  //investment income patch
  on(updateAnnualSpending, state => ({
    ...state,
    updateAnnualSpendingSuccess: false,
  })),

  on(updateAnnualSpendingSuccess, (state, {response}) => ({
    ...state,
    updateAnnualSpendingSuccess: true,
    annualSpending: response,
  })),

  on(updateAnnualSpendingFailure, state => ({
    ...state,
    updateAnnualSpendingSuccess: false,
  })),
  // end

  // create assests
  on(createAssets, state => ({
    ...state,
    createAssetsSuccess: false,
  })),

  on(createAssetsSuccess, (state, {response}) => ({
    ...state,
    createAssetsSuccess: true,
    assets: response,
  })),

  on(createAssetsFailure, state => ({
    ...state,
    createAssetsSuccess: false,
  })),

  // fetch assests
  on(fetchAssets, state => ({
    ...state,
    fetchAssetsSuccess: false,
  })),

  on(fetchAssetsSuccess, (state, {response}) => ({
    ...state,
    fetchAssetsSuccess: true,
    assets: response,
  })),

  on(fetchAssetsFailure, state => ({
    ...state,
    fetchAssetsSuccess: false,
  })),

  // remove account
  on(removeAssetsSuccess, (state, {response}) => ({
    ...state,
    removeAssetsSuccess: response.isDeleted,
  })),

  on(resetDeleteAssetsSuccess, state => ({
    ...state,
    removeAssetsSuccess: false,
  })),

  /** fetchTaxdetails */
  on(fetchTaxdetails, state => ({
    ...state,
    fetchTaxdetailsSuccess: false,
  })),

  on(fetchTaxdetailsSuccess, (state, {response}) => ({
    ...state,
    fetchTaxdetailsSuccess: true,
    taxdetails: response,
  })),

  on(fetchTaxdetailsFail, state => ({
    ...state,
    fetchTaxdetailsSuccess: false,
  })),

  /** create taxdetails - post */
  on(createTaxdetails, state => ({
    ...state,
    createTaxdetailsSuccess: false,
  })),

  on(createTaxdetailsSuccess, (state, {response}) => ({
    ...state,
    createTaxdetailsSuccess: true,
    taxdetails: response,
  })),

  on(createTaxdetailsFailure, state => ({
    ...state,
    createTaxdetailsSuccess: false,
  })),

  /** update taxdetails */
  on(updateTaxdetails, state => ({
    ...state,
    updateTaxdetailsSuccess: false,
  })),

  on(updateTaxdetailsSuccess, (state, {response}) => ({
    ...state,
    updateTaxdetailsSuccess: true,
    taxdetails: response,
  })),

  on(updateTaxdetailsFailure, state => ({
    ...state,
    updateTaxdetailsSuccess: false,
  })),

  /** create Isaaccountdetails - post */
  on(createIsaaccountdetails, state => ({
    ...state,
    createIsaaccountdetailsSuccess: false,
  })),

  on(createIsaaccountdetailsSuccess, (state, {response}) => ({
    ...state,
    createIsaaccountdetailsSuccess: true,
    isadetailsResponse: response,
  })),

  on(createIsaaccountdetailsFailure, state => ({
    ...state,
    createIsaaccountdetailsSuccess: false,
  })),

  /** fetch Isaaccountdetails */
  on(fetchIsaaccountdetails, state => ({
    ...state,
    fetchIsaaccountdetailsSuccess: false,
  })),

  on(fetchIsaaccountdetailsSuccess, (state, {response}) => ({
    ...state,
    fetchIsaaccountdetailsSuccess: true,
    isadetails: response,
  })),

  on(fetchIsaaccountdetailsFail, state => ({
    ...state,
    fetchIsaaccountdetailsSuccess: false,
  })),

  // remove account
  on(deleteIsaaccountSuccess, (state, {response}) => ({
    ...state,
    removeIsaaccountSuccess: response.isDeleted,
  })),

  on(resetDeleteIsaaccountSuccess, state => ({
    ...state,
    removeIsaaccountSuccess: false,
  })),

  on(fetchMonthendTypes, state => ({
    ...state,
    monthendTypesSuccess: false,
  })),

  on(fetchMonthendTypesSuccess, (state, {response}) => ({
    ...state,
    monthendTypesSuccess: true,
    monthendTypes: response,
  })),

  on(fetchMonthendTypesFailure, state => ({
    ...state,
    monthendTypesSuccess: false,
  })),

  on(fetchQuarterendTypes, state => ({
    ...state,
    quarterendTypesSuccess: false,
  })),

  on(fetchQuarterendTypesSuccess, (state, {response}) => ({
    ...state,
    quarterendTypesSuccess: true,
    quarterendTypes: response,
  })),

  on(fetchQuarterendTypesFailure, state => ({
    ...state,
    quarterendTypesSuccess: false,
  })),

  on(fetchIndividualSummary, state => ({
    ...state,
    updateSummarySuccess: false,
  })),

  on(fetchIndividualSummarySuccess, (state, {response}) => ({
    ...state,
    individualSummary: response,
    updateSummarySuccess: true,
  })),

  on(fetchIndividualSummaryFailure, state => ({
    ...state,
    updateSummarySuccess: false,
  })),

  on(fetchIndividualOverview, state => ({
    ...state,
    updateOverviewSuccess: false,
  })),

  on(fetchIndividualOverviewSuccess, (state, {response}) => ({
    ...state,
    individualOverview: response,
    updateOverviewSuccess: true,
  })),

  on(fetchIndividualOverviewFailure, state => ({
    ...state,
    updateOverviewSuccess: false,
  })),

  on(completeIndividualDma, state => ({
    ...state,
    completeIndividualDmaSuccess: false,
  })),

  on(completeIndividualDmaSuccess, (state, {response}) => ({
    ...state,
    completeIndividualDmaSuccess: response.success,
  })),

  on(completeIndividualDmaFailure, state => ({
    ...state,
    completeIndividualDmaSuccess: false,
  })),


);

export function reducer(state, action: Action) {
  return IndividualReducer(state, action);
}
