import {Action, createReducer, on} from '@ngrx/store';
import {initialState, ProductState} from '@app/state/product/product.state';
import {
  fetchProductsByMandateIdSuccess,
  fetchProductsComparisonsSuccess,
  fetchProductsSuccess,
  getProductDeclarationSuccess,
  setTransferTypeAuthStatement,
} from '@app/state/product/product.actions';
import {transferAuthStatements} from '@app/state/product/transfer-auth-statments';

export const productReducer = createReducer(
  initialState,

  on(fetchProductsSuccess, (state, {response}) => ({
    ...state,
    products: response,
  })),

  on(fetchProductsByMandateIdSuccess, (state, {response}) => ({
    ...state,
    products: response,
  })),

  on(fetchProductsComparisonsSuccess, (state, {response}) => ({
    ...state,
    comparison: response,
  })),

  on(getProductDeclarationSuccess, (state, {response}) => ({
    ...state,
    declaration: response,
  })),

  on(setTransferTypeAuthStatement, (state, {isaTypeId, isaTransferTypeId}) => ({
    ...state,
    transferTypeAuthStatement:
      transferAuthStatements.find(ts => ts.isaTypeId === isaTypeId && ts.isaTransferTypeId === isaTransferTypeId)
        ?.body ?? '',
  })),
);

export function reducer(state: ProductState, action: Action) {
  return productReducer(state, action);
}
