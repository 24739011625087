import {createAction, props} from '@ngrx/store';
import {Configuration} from '@app/shared/models/configuration.model';
import {ConfigurationGroupEnum} from '@app/shared/enums/configuration.enum';

/** Fetch all configurations. */
export const fetchConfigurations = createAction(
  '[ConfigurationModel] Fetch All Configurations',
);

export const fetchConfigurationsSuccess = createAction(
  '[ConfigurationModel] Fetch All Configurations Success',
  props<{response: Configuration[]}>(),
);

/** Fetch configuration by group ID. */
export const fetchConfiguration = createAction(
  '[ConfigurationModel] Fetch Configuration',
  props<{request: ConfigurationGroupEnum}>(),
);

export const fetchConfigurationSuccess = createAction(
  '[ConfigurationModel] Fetch Configuration Success',
  props<{response: Configuration[]}>(),
);
