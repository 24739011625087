import {on, createReducer, Action} from '@ngrx/store';
import {initialState, EmailState} from './email.state';
import * as EmailActions from './email.actions';

const emailReducer = createReducer(
  initialState,

  on(EmailActions.loadEmailAddressesForEntitySuccess, (state, {payload}) => ({...state, emailAddresses: payload})),

  on(EmailActions.loadEmailAddressesForIndividualSuccess, (state, {response}) => ({
    ...state,
    emailAddresses: response,
  })),

  on(EmailActions.getEmailsForUserSuccess, (state, {response}) => ({
    ...state,
    userEmailAddresses: response,
  })),

  on(EmailActions.SetSelectedEmailAddressId, (state, {emailAddressId: addressId}) => ({
    ...state,
    currentEmailAddressId: addressId,
  })),

  on(EmailActions.deleteSuccess, (state, {response}) => ({
    ...state,
    emailAddresses: state.emailAddresses.filter(x => x.id !== response.id),
  })),

  // on(EmailActions.createEmailAddress, state => ({
  //   ...state,
  //   addEmailAddressSuccess: false,
  // })),

  on(EmailActions.createEmailSuccess, (state, {response}) => ({
    ...state,
    email: response.email,
    message: response.message,
    addEmailAddressSuccess: response.success,
    isStateChanged: true,
  })),

  on(EmailActions.resetUpdateEmailAddressSuccess, state => ({
    ...state,
    message: null,
    addEmailAddressSuccess: false,
    isStateChanged: false,
  })),

  on(EmailActions.loadEmailTypesSuccess, (state, {payload}) => ({
    ...state,
    emailTypes: payload,
  })),

  on(EmailActions.changeEmailSuccess, (state, {response}) => ({
    ...state,
    email: response.email,
    changeEmailSuccess: response.success,
    changeEmailMessage: response.message,
    stateChanged: true,
  })),

  on(EmailActions.resetChangeEmailSuccess, state => ({
    ...state,
    changeEmailSuccess: false,
    changeEmailMessage: null,
    stateChanged: false,
  })),

  on(EmailActions.createAdditionalEmailAddressSuccess, (state, {response}) => ({
    ...state,
    emailAddresses: response.statusCode === 409 ? state.emailAddresses : state.emailAddresses.concat(response),
    addEmailAddressSuccess: response.statusCode === 409 ? false : true,
    emailErrorMessage: response.statusCode === 409 ? response.statusMessage : null,
  })),
);

export function reducer(state: EmailState, action: Action) {
  return emailReducer(state, action);
}
