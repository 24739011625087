import {EmailAddress, EmailTypes} from '@app/shared/models/email-address.model';

export const featureKey = 'email';

export interface EmailState {
  emailAddresses: EmailAddress[];
  userEmailAddresses: EmailAddress[];
  emailTypes: EmailTypes[];
  currentEmailAddressId: number | null;
  addEmailAddressSuccess: boolean;
  changeEmailSuccess: boolean;
  message: string | null;
  changeEmailMessage: string | null;
  stateChanged: boolean;
  isStateChanged: boolean;
  emailErrorMessage: string | null;
}

export const initialState: EmailState = {
  emailAddresses: [],
  userEmailAddresses: [],
  emailTypes: [],
  currentEmailAddressId: null,
  addEmailAddressSuccess: false,
  changeEmailSuccess: false,
  message: null,
  changeEmailMessage: null,
  stateChanged: false,
  isStateChanged: false,
  emailErrorMessage: null,
};
