import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Strategy} from '@app/shared/models/portfolio/strategy.model';

@Injectable({
  providedIn: 'root',
})
export class StrategyService {
  constructor(private http: HttpClient) {}

  fetchStrategies(): Observable<Strategy[]> {
    return this.http.get<Strategy[]>(`${environment.apiEndpoint}/strategies`);
  }
}
