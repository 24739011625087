import {createAction, props} from '@ngrx/store';
import {AuthAction, AuthFlowType, AuthLaunchMode} from '@app/shared/enums/auth.enum';

export const setAuthCodeFlowMode = createAction(
  '[Auth] Set Auth Code Flow Mode',
  props<{action: AuthAction | null; launchMode: AuthLaunchMode | null; authMode: AuthFlowType | null}>(),
);

/** Login actions. */
export const login = createAction('[Auth] Login');

export const checkLogin = createAction('[Auth] Check Login');

export const loginComplete = createAction('[Auth] Login Complete');

export const loginSuccess = createAction('[Auth] Login Success');

export const loginFailure = createAction('[Auth] Login Failure', props<{payload: any}>());

/** Logout actions. */
export const logout = createAction('[Auth] Confirm Logout');

export const logoutConfirmed = createAction('[Auth] Logout Confirmed');

export const displayLogoutDialog = createAction('[Auth] Display Logout Dialog');

/** Session actions. */
export const renewSessionTimeout = createAction('[Auth] Renew Session Timeout');

export const displaySessionTimeoutDialog = createAction('[Auth] Display Session Timeout Dialog');

export const setupAutomaticSilentRefresh = createAction('[Auth] Set Auto Silent Refresh');

export const refreshToken = createAction('[Auth] Refresh Token');

export const endSessionCountdown = createAction('[Auth] End Session Countdown');

/** Navigation actions. */
export const navigateToAuthSuccessUrl = createAction('[Auth] Navigate To Auth Success URL');

/** Auth storage actions. */
export const setAuthStorageData = createAction('[Auth] Set Auth Storage Data', props<{mobileParams: string}>());

export const setAuthStorageItem = createAction('[Auth] Set Auth Storage Item', props<{key: string; data: string}>());

