import {OAuthStorage} from 'angular-oauth2-oidc';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OAuthStorageService extends OAuthStorage {
  setAuthData(encodedAuthData: string) {
    // Decode the base64 string
    const decodedAuthData = atob(encodedAuthData);
    this.storeAuthData(decodedAuthData);
  }

  private storeAuthData(authorizationData: string) {
    const authorizationDataItems = JSON.parse(authorizationData);
    const token = authorizationDataItems['token'];
    if (token) {
      this.setItem('access_token', token);
      console.log('setting access token');
    } else {
      console.error('no token found');
    }
    this.setItem('access_token_stored_at', '' + Date.now());
    const expiresIn = authorizationDataItems['expiresIn'];
    // We only expect one of expiresIn or expiryDate to be set
    if (expiresIn) {
      const expiresInMilliSeconds = expiresIn * 1000;
      const now = new Date();
      const expiresAt = now.getTime() + expiresInMilliSeconds;
      this.setItem('expires_at', '' + expiresAt);
    }
    const expiryDate = authorizationDataItems['expiryDate'];
    if (expiryDate) {
      const expiresAt = new Date(expiryDate);
      this.setItem('expires_at', '' + expiresAt);
    }
    const refreshToken = authorizationDataItems['refreshToken'];
    if (refreshToken) {
      this.setItem('refresh_token', refreshToken);
      console.log('setting refresh token');
    } else {
      console.error('no refresh token found');
    }
    const clientId = authorizationDataItems['client_id'];
    if (clientId) {
      this.setItem('clientid', clientId);
      console.log('setting clientid');
    } else {
      console.error('no clientid found');
    }
  }

  getItem(key: string): string {
    return sessionStorage.getItem(key);
  }

  removeItem(key: string): void {
    sessionStorage.removeItem(key);
  }

  setItem(key: string, data: string): void {
    sessionStorage.setItem(key, data);
  }

  truncate(input: string) {
    if (input) {
      return input.length > 50 ? `${input.substring(0, 50)}...` : input;
    } else {
      return null;
    }
  }
}
