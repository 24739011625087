import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { JourneyConfigEffects } from './journey-config.effects';
import * as fromJourneyConfig from './journey-config.reducer';
import * as fromJourneyConfigState from './journey-config.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromJourneyConfigState.journeyConfigFeatureKey, fromJourneyConfig.reducer),
    EffectsModule.forFeature([JourneyConfigEffects])
  ],
  declarations: []
})
export class JourneyConfigStateModule {}
