<div
  fxFlexFill
  fxLayoutAlign="center"
  class="padding overflow-y background"
  [style.backgroundColor]="getColor()"
  [style.backgroundImage]="'url(' + tenantService.getImagePath('breadcrumb-background', 'svg') + ')'"
>
  <div
    *ngIf="(breadcrumbJourneyParts$ | async) as breadcrumbJourneyParts"
    fxLayout="column"
    fxLayoutGap="15px"
  >
    <ng-container *ngFor="let part of breadcrumbJourneyParts; let i = index">
      <div class="stage-name" fxLayoutAlign=" center" fxLayoutGap="10px">
        <div fxFlex="30px" fxLayoutAlign="center center">
          <b>{{i + 1}}</b>
        </div>
        <strong>{{part.displayName | uppercase}}</strong>
      </div>

      <div
        *ngFor="let stage of part.stages; let j = index"
        [class.active]="stage.isComplete"
        class="journey-step"
        fxLayoutAlign=" center"
        fxLayoutGap="10px"
      >
        <div fxFlex="30px" fxLayoutAlign="center center">
          <mat-icon *ngIf="stage.isComplete">check_circle</mat-icon>
        </div>
        <span *ngIf= "breadcrumbClickOptions" (click)="navigateFromBreadcrumb(stage)"  [ngClass]="{
          'stageDisplay': stage.isComplete,
          }" >{{stage.displayName}}</span>
          <span *ngIf= "!breadcrumbClickOptions" >{{stage.displayName}}</span>
      </div>
    </ng-container>
  </div>
</div>
