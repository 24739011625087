import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromState from './tax-detail.state';

export const getTaxDetailsState = createFeatureSelector<fromState.TaxDetailState>(fromState.featureKey);

export const getAllTaxDetails = createSelector(
  getTaxDetailsState,
  state => {
    return state.taxDetails;
  }
);

