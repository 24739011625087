import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {JourneyHistoryEffects} from './journey-history.effects';
import * as fromJourneyHistory from './journey-history.reducer';
import * as fromJourneyHistoryState from './journey-history.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromJourneyHistoryState.JourneyHistoryFeatureKey, fromJourneyHistory.reducer),
    EffectsModule.forFeature([JourneyHistoryEffects]),
  ],
  declarations: [],
})
export class JourneyHistoryStateModule {
}
