import {createAction, props} from '@ngrx/store';
import {Country, Nationality} from '@app/shared/models/country.model';

export const fetchCountries = createAction('[Countries API] Fetch Countries');

export const fetchCountriesSuccess = createAction(
  '[Countries API] Fetch Countries Success',
  props<{payload: Country[]}>(),
);

export const fetchCountriesFail = createAction('[Countries API] Fetch Countries Fail', props<{payload: any}>());


export const fetchNationalities = createAction('[Nationalities API] Fetch Countries');

export const fetchNationalitiesSuccess = createAction(
  '[Nationalities API] Fetch Countries Success',
  props<{payload: Nationality[]}>(),
);

export const fetchNationalitiesFail = createAction('[Nationalities API] Fetch Countries Fail', props<{payload: any}>());
