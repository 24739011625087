import { createAction, props } from '@ngrx/store';
import { EmploymentStatus } from '@app/shared/models/employment-status.model';
export const getEmploymentStatuses = createAction(
  '[EmploymentStatus/API] Get EmploymentStatuses'
);
export const loadEmploymentStatuses = createAction(
  '[EmploymentStatus/API] Load EmploymentStatuses'
);
export const loadEmploymentStatusesSuccess = createAction(
  '[EmploymentStatus/API] Load EmploymentStatuses Success',
  props<{ employmentStatuses: EmploymentStatus[] }>()
);
export const loadEmploymentStatusesFail = createAction(
  '[EmploymentStatus/API] Load EmploymentStatuses Fail',
  props<{ error: any }>()
);
export const clearEmploymentStatuses = createAction(
  '[EmploymentStatus/API] Clear EmploymentStatuss'
);
