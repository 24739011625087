import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { DocumentCreateRequest, DocumentFromAPI, DocumentListAPI, DocumentListItem, DocumentRefFromAPI, DocumentTypeFromAPI } from '@app/shared/models/document.model';
import { RelatedObjectType } from '@app/shared/enums/related-object-type.enum';
import { DropDownItem } from '@app/shared/models/dropdownitem.model';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class DocumentService {
  constructor(private http: HttpClient) {}

  fetchDocuments(ObjectId: number, objectTypeId: number, isIncludeArchived: boolean = false): Observable<DocumentListItem[]> {
    return this.http.get<DocumentListAPI>(`${environment.apiEndpoint}/documents/byObject?objectId=${ObjectId}&objectTypeId=${objectTypeId}&includeArchive=${isIncludeArchived}`)
      .pipe(
        map(documents => documents.documentReference.map(documentRef => mapDocumentFromAPIToDocumentListItem(documentRef.document)))
      );
  }

  fetchDocumentTypesByObjectTypeId(typeId?: RelatedObjectType): Observable<DropDownItem[]> {
    return this.http.get<DocumentTypeFromAPI[]>(`${environment.apiEndpoint}/documents/references/types${typeId ? `?objecttypeId=${typeId}` : ''}`)
      .pipe(
        map(types => types.map(type => mapDocumentTypeToDropDownItem(type))) // Map the types to drop down items.
      );
  }

  uploadDocument(document: DocumentCreateRequest): Observable<HttpEvent<DocumentRefFromAPI>> {
    return this.http.post<DocumentRefFromAPI>(`${environment.apiEndpoint}/documents`, document, {reportProgress: true, observe: 'events'});
  }

  downloadDocument(id: string): Observable<ArrayBuffer> {
    return this.http.get(`${environment.apiEndpoint}/documents/download/${id}`, {
      responseType: 'arraybuffer',
    });
  }
}

export function mapDocumentFromAPIToDocumentListItem(document: DocumentFromAPI): DocumentListItem {
  return {
    id: document.documentId,
    documentName: document.documentName,
    fileName: document.fileName,
    contentType: document.contentType,
    documentType: document.documentType.documentTypeName,
    uploadDate: document.writtenDate
  };
}

function mapDocumentTypeToDropDownItem(resp: DocumentTypeFromAPI): DropDownItem {
  return {
    id: resp.id,
    label: resp.documentTypeName,
    value: resp.type
  };
}
