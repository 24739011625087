import { createAction, props } from '@ngrx/store';
import { Gender } from '@app/shared/models/gender.model';
export const getGenders = createAction('[Gender/API] Get Genders');
export const loadGenders = createAction('[Gender/API] Load Genders');
export const loadGendersSuccess = createAction(
  '[Gender/API] Load Genders Success',
  props<{ genders: Gender[] }>()
);
export const loadGendersFail = createAction(
  '[Gender/API] Load Genders Fail',
  props<{ error: any }>()
);
export const clearGenders = createAction('[Gender/API] Clear Genders');
