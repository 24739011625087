import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { EmploymentStatus } from '@app/shared/models/employment-status.model';
import * as EmploymentStatusActions from './employment-status.actions';

export const featureKey = 'employmentStatus';
export interface EmploymentStatusState extends EntityState<EmploymentStatus> {
  loading: boolean;
}

export const adapter: EntityAdapter<EmploymentStatus> = createEntityAdapter<
  EmploymentStatus
>();

export const initialState: EmploymentStatusState = adapter.getInitialState({
  loading: true,
});

const genderReducer = createReducer(
  initialState,
  on(EmploymentStatusActions.loadEmploymentStatuses, (state, action) => ({
    ...state,
    loading: true,
  })),
  on(EmploymentStatusActions.loadEmploymentStatusesSuccess, (state, action) =>
    adapter.addMany(action.employmentStatuses, { ...state, loading: false })
  ),
  on(EmploymentStatusActions.loadEmploymentStatusesFail, (state, action) => ({
    ...state,
    loading: false,
  })),
  on(EmploymentStatusActions.clearEmploymentStatuses, (state) =>
    adapter.removeAll(state)
  )
);

export function reducer(state: EmploymentStatusState | undefined, action: Action) {
  return genderReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
