import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AuthState} from '@app/state';
import {checkLogin} from '@app/state/auth/auth.actions';

@Component({
  selector: 'callback-component',
  template:
    '<div fxFlexFill fxLayoutAlign="center center"><abl-custom-loading-spinner></abl-custom-loading-spinner></div>',
})
export class CallbackComponent implements OnInit {
  constructor(private store: Store<AuthState.State>) {}

  ngOnInit() {
    if (window.location.href) {
      this.store.dispatch(checkLogin());
    } else {
      console.error('Not handling callback.');
    }
  }
}
