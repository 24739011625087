<h2 matDialogTitle>{{'Attention' | translate}}</h2>
<mat-dialog-content>
  <p>{{'Inactivity_logged_out' | translate}}</p>
  <p>{{countdownService.sessionTimeoutDialogCountdown$ | async}} second(s)</p>
  <p>{{'Click_continue_to_remain_logged_in' | translate}}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button aria-label="log out" [mat-dialog-close]="dialogCloseEnum.Logout" mat-stroked-button>
    {{'Logout' | translate}}
  </button>
  <button aria-label="continue" mat-dialog-close mat-raised-button color="primary" cdkFocusInitial>
    {{'Continue' | translate}}
  </button>
</mat-dialog-actions>
