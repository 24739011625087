import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Injector, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app.routing';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../environments/environment';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthModule} from './auth/auth.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RootStateModule} from '@app/state';
import {OAuthStorage} from 'angular-oauth2-oidc';
import {OAuthStorageService} from './auth/services/oauth-storage.service';
import {OnboardingResolver} from './onboarding/onboarding.resolver';
import {CanDeactivateOnboardingGuard} from '@app/can-deactivate-onboarding.guard';
// Angular material.
import {DevelopmentNavigationCardModule} from '@app/shared/dev-controls/development-navigation-card.module';
import {ErrorModule} from '@app/error/error.module';
import {IConfig} from 'ngx-mask';
import {LoaderModule} from '@app/shared/controls/loader/loader.module';
import {LoaderService} from '@app/services/loader.service';
import {LoaderInterceptor} from '@app/interceptors/loader.interceptor';
import {StoreModule} from '@ngrx/store';
import {routerReducer, StoreRouterConnectingModule} from '@ngrx/router-store';
import {LoggerService} from '@app/services/logger.service';
import {UnauthorizedInterceptor} from '@app/interceptors/unauthorized.interceptor';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';
import { HeadersInterceptor } from './headers.interceptor';
import { WebpackTranslateLoader } from './webpackTranslateLoader';
import { SharedModule } from './shared/controls/shared.module';

// We need a factory, since localStorage is not available during AOT build time.
export function storageFactory(): OAuthStorage {
  return new OAuthStorageService();
}

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    RootStateModule,
    StoreModule.forRoot({
      router: routerReducer,
    }),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      name: environment.applicationName,
      logOnly: environment.production,
    }),
    AuthModule,
    SharedModule,
    DevelopmentNavigationCardModule,
    ErrorModule,
    LoaderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: WebpackTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  bootstrap: [AppComponent],
  providers: [
    Title,
    OnboardingResolver,
    CanDeactivateOnboardingGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: (injector: Injector) => {
        return new UnauthorizedInterceptor(injector);
      },
      useClass: UnauthorizedInterceptor,
      multi: true,
      deps: [Injector],
    },
    {
      provide: OAuthStorage,
      useFactory: storageFactory,
    },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HeadersInterceptor,
    multi: true
  },
    LoaderService,
    LoggerService,
  ],
})
export class AppModule {}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
