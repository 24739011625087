import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { DeletedItem } from '@app/shared/models/deleted-item.model';
import { FinancialDependents, FinancialDependentsResponse } from '@app/shared/models/individuals/individual-financial-dependents.model';

@Injectable({
  providedIn: 'root'
})

export class FinancialDependentsService {
  headers: HttpHeaders;

  constructor(private http: HttpClient,public router : Router) {
  }
 
  fetchFinancialDependents(individualId: number): Observable<FinancialDependentsResponse> {
    return this.http.get<FinancialDependentsResponse>(
      `${environment.apiEndpoint}/individuals/${individualId}/financialdependents`,
    );
  }

  deleteFinancialDependents(individualId: number, financialDependentId: number): Observable<DeletedItem> {
    return this.http.delete<DeletedItem>(
      `${environment.apiEndpoint}/individuals/${individualId}/financialdependents/${financialDependentId}`,
    );
  }
  createFinancialDependents(
    request: FinancialDependents,
  ): Observable<FinancialDependentsResponse> {
    return this.http.post<FinancialDependentsResponse>(
      `${environment.apiEndpoint}/individuals/financialdependents`,
      request,
    );
  }

}
