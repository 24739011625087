import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromAddressesState from './address.state';

export const getAddressesState = createFeatureSelector<fromAddressesState.AddressState>(fromAddressesState.featureKey);

export const getAllAddresses = createSelector(getAddressesState, state => state.addresses);

export const selectLoqateAddress = createSelector(getAddressesState, state => state.address);

/** V2 address search result selectors. */

/** Selected address from Loqate results. */
export const selectAddress = createSelector(getAddressesState, state => state.v2Address);

/** Select residential addresses loaded from the API. */
export const selectResidentialAddresses = createSelector(getAddressesState, state => state.v2ResidentialAddresses);

/** Select additional addresses loaded from the API. */
export const selectAdditionalAddresses = createSelector(getAddressesState, state => state.v2AdditionalAddresses);

export const v2SelectAddressSearchResults = createSelector(getAddressesState, state =>
  state.v2AddressSearchResults ? Array.from(state.v2AddressSearchResults.values()) : null,
);

export const selectAddAddressSuccess = createSelector(getAddressesState, state => state.addAddressSuccess);
export const selectPrincipalAddress = createSelector(getAddressesState, state => state.principalAddress);
