import { FinancialDependentsResponse } from '@app/shared/models/individuals/individual-financial-dependents.model';

export const featureKey = 'financial-dependents';

export interface financialdependentsState {
    financialDependents: FinancialDependentsResponse[];
    updateSuccess:boolean;
    isDeleted: boolean;
}
export const initialState: financialdependentsState = {
    financialDependents: null,
    updateSuccess: false,
    isDeleted: false
};


