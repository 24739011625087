import * as TaxDetailsActions from './tax-details.actions';
import * as TaxDetailsState from './tax-detail.state';
import * as TaxDetailsSelectors from './tax-detail.selectors';

export * from './tax-detail-state.module';

export {
  TaxDetailsActions,
  TaxDetailsState,
  TaxDetailsSelectors
};
