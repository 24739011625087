import { NotificationSummary } from '@app/shared/models/notification.model';

export const notificationFeatureKey = 'notification';

export interface NotificationState {
  notificationSummary: NotificationSummary;
}

export const initialState: NotificationState = {
  notificationSummary: null
};
