import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {catchError, exhaustMap, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {
  createMandate,
  createMandateSuccess,
  deleteMandate,
  deleteMandateSuccess,
  fetchMandate,
  fetchMandates,
  fetchMandatesFailure,
  fetchMandatesSuccess,
  fetchMandateSuccess,
  finaliseMandate,
  finaliseMandateFailure,
  finaliseMandateSuccess,
  updateMandate,
  updateMandateFailure,
  updateMandateSuccess,
} from './mandate.actions';
import {MandateState} from '.';
import {MandateService} from '@app/services/mandate-service/mandate.service';
import {ErrorActions} from '@app/state/error';
import {selectProfile} from '@app/state/profile/profile.selectors';
import {createRelationship} from '@app/state/relationships/relationship.actions';
import {RelatedObjectType} from '@app/shared/enums/related-object-type.enum';
import {RelationshipType} from '@app/shared/enums/relationship-type.enum';
import {MandateTypeEnum} from '@app/shared/enums/mandate.enum';
import {ProfileState} from '@app/state/profile';
import {OnboardingState} from '@app/state/onboarding/onboarding.state';
import {selectOnboardingMandateId} from '@app/state/onboarding/onboarding.selectors';
import {fetchJourneyConfigForNewMandate} from '@app/state/journey-config/journey-config.actions';
import {setOnboardingStateForMandate} from '@app/state/onboarding/onboarding.actions';
import {ProductState} from '@app/state/product/product.state';
import {selectProductId} from '@app/state/portfolio/portfolio.selectors';
import { environment } from 'environments/environment';
import { EnvironmentsEnum } from '@app/shared/enums/environments.enum';


@Injectable()
export class MandateEffects {
  env = environment.tenant;
  envEnum = EnvironmentsEnum.mcInroy;

  fetchMandates$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchMandates),
      exhaustMap(() =>
        this.mandateService.getMandates().pipe(
          map(response => fetchMandatesSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}), fetchMandatesFailure({error}))),
        ),
      ),
    ),
  );

  fetchMandate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchMandate),
      switchMap(({id}) =>
        this.mandateService.getMandateById(id).pipe(
          map(response => fetchMandateSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  createMandateForOnboardingJourney$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(createMandate),
      withLatestFrom(this.profileStore.select(selectProfile), this.productStore.select(selectProductId)),
      map(([, profile, productId]) => ({
        mandateName: 'New Mandate',
        mandateTypeId: MandateTypeEnum.Individual,
        principalId: profile.individualId,
        productId,
      })),
      exhaustMap(request =>
        this.mandateService.createMandate(request).pipe(
          switchMap(response => [
            setOnboardingStateForMandate({mandateId: response.id}),
            fetchJourneyConfigForNewMandate(),
            createMandateSuccess({response}),
          ]),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  /** Once the mandate is created a relationship must be created to link the individual to the new mandate. */
  createRelationshipToNewMandate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(createMandateSuccess),
      withLatestFrom(this.mandateStore.select(selectProfile)),
      map(([{response}, profile]) =>
        createRelationship({
          request: {
            childObjectId: profile.individualId,
            childObjectTypeId: RelatedObjectType.Individual,
            parentObjectId: response.id,
            parentObjectTypeId: RelatedObjectType.Mandate,
            relationshipTypeId: RelationshipType.PrimaryOwner,
          },
        }),
      ),
    ),
  );

  updateMandate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMandate),
      exhaustMap(({id, request}) =>
        this.mandateService.updateMandate(id, request).pipe(
          map(response => updateMandateSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}), updateMandateFailure({error}))),
        ),
      ),
    ),
  );

  finaliseMandate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(finaliseMandate),
      withLatestFrom(this.onboardingStore.select(selectOnboardingMandateId)),
      exhaustMap(([, mandateId]) =>
        this.mandateService.finaliseMandate(mandateId).pipe(
          map(response => finaliseMandateSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}), finaliseMandateFailure({error}))),
        ),
      ),
    ),
  );

  deleteMandate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteMandate),
      exhaustMap(({id}) =>
        this.mandateService.deleteMandate(id).pipe(
          map(mandate => deleteMandateSuccess({id: mandate.id})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private mandateService: MandateService,
    private profileStore: Store<ProfileState.ProfileState>,
    private productStore: Store<ProductState>,
    private mandateStore: Store<MandateState.MandateState>,
    private onboardingStore: Store<OnboardingState>,
  ) {}
}
