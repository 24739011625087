import {Directive, HostListener} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
  selector: '[ablTitleCaseFormCtrl]',
})
export class TitleCaseFormCtrlDirective {
  constructor(public control: NgControl) {}

  @HostListener('input', ['$event.target'])
  private onInput(input: HTMLInputElement) {
    const cursorPosition = input.selectionStart;
    this.control.control.setValue(
      input.value
        .split(' ')
        .map(str => str.charAt(0).toUpperCase() + str.substr(1).toLowerCase())
        .join(' '),
    );
    input.setSelectionRange(cursorPosition, cursorPosition);
  }
}
