/** Regular expressions used for form validation. */
export enum RegExpEnum {
  BankSortCode = '^\\d\\d[- /]?\\d\\d[- /]?\\d\\d$',
  Name = `^[a-zA-Z \\-\\']+$`,
  NameLineTerminators = `^([a-zA-Z]+|[a-zA-Z]+.+[a-zA-Z])+$`,
  PhoneNumber = '^[0-9]{7,14}$',
  TaxNI = '(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)(?:[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z])(?:\\s*\\d\\s*){6}([A-D]|\\s)',
  Letters = '^[a-zA-Z ]*$', // accept only letters and space
  AlphaNumberOnly = '^[a-zA-Z0-9 ]+$',
  Numbers = '^[0-9.]+$',
  AccountNumber = '^WO[A-Z]{2}[0-9]{4}$',
  EmailCode = '^[0-9]{6}$',
  EmailPattern = '^[a-zA-Z0-9][a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,}$',
  TINRegex = '(9\\d{2})([ \\-]?)([7]\\d|8[0-8])([ \\-]?)(\\d{4})',
  BIC = '^[A-Za-z]{6}[0-9a-zA-Z]{2}([0-9a-zA-Z]{3})?$',
  IBAN = '^[A-Z]{2}[0-9]{2}(?:[ ]?[A-Z0-9]{4}){4}(?:[ ]?[0-9]{1,2})?$',
  SecurityQuestionAnswer = '^[0-9-_, .A-Za-z]*$'
}

/** Used to extract the extension from a filename. */
export const fileExtensionRegExp = /\.[0-9a-z]+$/i;
export const  AlphaNumberWithSpecialCharOnly =  /^[a-zA-Z0-9/\\-]+$/;
