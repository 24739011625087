import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';

import {CurrenciesEffects} from './currencies.effects';
import * as fromReducer from './currencies.reducer';
import * as fromState from './currencies.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromState.featureKey, fromReducer.reducer),
    EffectsModule.forFeature([CurrenciesEffects]),
  ],
  declarations: [],
})
export class CurrenciesStateModule {}
