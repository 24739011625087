import { OverseasEmployment, OverseasEmploymentResponse } from '@app/shared/models/overseas-employment.model';

export const featureKey = 'overseasEmployment';

export interface OverseasEmploymentState {
    overseasEmployment: OverseasEmploymentResponse;
    updateSuccess:boolean;
}
export const initialState: OverseasEmploymentState = {
    overseasEmployment: null,
    updateSuccess: false
};


