import {createAction, props} from '@ngrx/store';
import {Currency} from '@app/shared/models/portfolio/currency.model';

export const fetchCurrencies = createAction('[Currencies API] Fetch Currencies');

export const fetchCurrenciesSuccess = createAction(
  '[Currencies API] Fetch Currencies Success',
  props<{payload: Currency[]}>(),
);

export const fetchCurrenciesFail = createAction('[Currencies API] Fetch Currencies Fail', props<{payload: any}>());
