import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { MaritalStatus } from '@app/shared/models/marital-status.model';

@Injectable({
  providedIn: 'root',
})
export class MaritalStatusService {
  constructor(private http: HttpClient) {}

  getMaritalStatuses(): Observable<MaritalStatus[]> {
    return this.http.get<MaritalStatus[]>(
      `${environment.apiEndpoint}/individuals/references/maritalStatuses`
    );
  }
}
