import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { RetirementProvisionResponse, RetirementProvision } from '@app/shared/models/individuals/individual-retirementprovision.model';
import { Patch } from '@app/shared/models/patch.model';

@Injectable({
  providedIn: 'root'
})

export class RetirementProvisionService {
  headers: HttpHeaders;

  constructor(private http: HttpClient,public router : Router) {
  }
 
  fetchRetirementProvision(individualId: number): Observable<RetirementProvisionResponse> {
    return this.http.get<RetirementProvisionResponse>(
      `${environment.apiEndpoint}/individuals/${individualId}/retirementprovision`,
    );
  }
  createRetirementProvision(request: RetirementProvision): Observable<RetirementProvisionResponse> {
    return this.http.post<RetirementProvisionResponse>(`${environment.apiEndpoint}/individuals/retirementprovision`,request
    );
  }

  patchRetirementProvision(individualId: number, patch: Patch[]): Observable<RetirementProvisionResponse> {
    return this.http.patch<RetirementProvisionResponse>(`${environment.apiEndpoint}/individuals/${individualId}/retirementprovision`, patch);
  }

}
