import {Directive, HostListener, OnDestroy, OnInit} from '@angular/core';
import {NgControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil, tap} from 'rxjs/operators';

@Directive({
  selector: '[ablTrimWhitespace]',
})
export class TrimWhitespaceDirective implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  private inputEvent$: Subject<HTMLInputElement> = new Subject();
  private trimWhitespace$ = this.inputEvent$.pipe(
    debounceTime(500),
    tap((input: HTMLInputElement) => {
      const cursorPosition = input.selectionStart;
      this.control.control.setValue(input.value.trim());
      input.setSelectionRange(cursorPosition, cursorPosition);
    }),
    takeUntil(this.destroy$),
  );

  constructor(public control: NgControl) {}

  ngOnInit() {
    this.trimWhitespace$.subscribe();
  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions when the component is destroyed.
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('input', ['$event.target'])
  private onInput(input: HTMLInputElement) {
    this.inputEvent$.next(input);
  }
}
