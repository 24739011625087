import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LiabilitiesEffects } from './liabilities.effects';
import * as fromLiabilities from './liabilities.reducer';
import * as fromLiabilitiesState from './liabilities.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromLiabilitiesState.featureKey, fromLiabilities.reducer),
    EffectsModule.forFeature([LiabilitiesEffects])
  ],
  declarations: []
})
export class LiabilitiesStateModule {
}
