import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {catchError, exhaustMap, map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {EidvActions} from '@app/state/eidv/index';
import {EidvService} from '@app/services/eidv-service/eidv.service';
import {EidvLatest} from '@app/shared/models/eidv.model';
import {ErrorActions} from '@app/state/error';

@Injectable()
export class EidvEffects {
  loadCollectionIndividual$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(EidvActions.loadEidv),
      exhaustMap(() =>
        this.eidvService.getEidvLatest().pipe(
          map((eidv: EidvLatest) => EidvActions.loadEidvLatestSuccess({payload: eidv})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private eidvService: EidvService) {}
}
