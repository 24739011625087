import {
  EmailVerificationCodeResponse,
  PasswordChangeResponse,
  SecurityQuestionAnswerList,
  SecurityQuestionList,
  UpdateSecurityQuestionAnswersResponse,
  resendEmailVerificationCodeResponse,
} from '@app/shared/models/user.model';

export const featureKey = 'user';

export interface UserState {
  passwordChangeResponse: PasswordChangeResponse | null;
  securityQuestionAnswers: SecurityQuestionAnswerList | null;
  securityQuestions: SecurityQuestionList;
  updateSecurityQuestionAnswersSuccess: UpdateSecurityQuestionAnswersResponse | null;
  emailCodeConfirmationResponse : EmailVerificationCodeResponse | null;
  emailSent: boolean | null;
}

export const initialState: UserState = {
  passwordChangeResponse: null,
  securityQuestionAnswers: [],
  securityQuestions: [],
  updateSecurityQuestionAnswersSuccess: null,
  emailCodeConfirmationResponse: null,
  emailSent: null,
};
