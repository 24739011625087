import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'environments/environment';
import {Observable} from 'rxjs';
import {Onboarding} from '@app/shared/models/onboarding-state.model';
import {DeepLinkCreateRequest} from '@app/shared/models/deeplink-create.model';
import {DeepLink} from '@app/shared/models/deeplink-model';

@Injectable({
  providedIn: 'root',
})
export class OnboardingStateService {
  constructor(private http: HttpClient) {
  }

  updateOnboardingState(onboardingState: Onboarding): Observable<Onboarding> {
    return this.http.post<Onboarding>(`${environment.apiEndpoint}/onboardingstate`, onboardingState);
  }

  createDeepLink(payload: DeepLinkCreateRequest): Observable<DeepLink> {
    return this.http.post<DeepLink>(`${environment.apiEndpoint}/onboardingstate/deeplink`, payload);


    // const headers = new HttpHeaders()
    // .append('Content-Type', 'application/json')
    // .append('Access-Control-Allow-Headers', 'Content-Type')
    // .append('Access-Control-Allow-Methods', 'POST')
    // .append('Access-Control-Allow-Origin', '*');

    // return this.http.post<DeepLink>(`${environment.deepLink.firebaseLink}`, payload, {headers});
  }
}
