import {Action, createReducer, on} from '@ngrx/store';
import {initialState, OnboardingState} from './onboarding.state';
import * as OnboardingActions from './onboarding.actions';
import {setIsNextDisabled, setIsPreviousDisabled, setViewWrapperData} from './onboarding.actions';
import {AppOnboardingState} from '@app/shared/enums/app-onboarding-state.enum';

const onboardingReducer = createReducer(
  initialState,

  on(OnboardingActions.initOnboardingState, state => ({
    ...state,
    onboarding: {
      ...state.onboarding,
      appState: AppOnboardingState.InProgress,
      startTime: new Date(),
    },
    deepLink: null,
    isNextDisabled: false,
    viewWrapperData: {
      heading: null,
      description: null,
      nextLabel: null,
      backLabel: null,
      isShowExit: true,
      isGDPRPage: false,
      isEmailVerification: false,
      isSecurityQuestions: false
    },
  })),

  on(OnboardingActions.endOnboardingRedirectSuccess, (state, {response}) => ({
    ...state,
    deepLink: response,
  })),

  on(OnboardingActions.setOnboardingStateForMandate, (state, {mandateId}) => ({
    ...state,
    onboarding: {
      ...state.onboarding,
      onboardingMandateId: mandateId,
      appState: AppOnboardingState.InProgress,
    },
  })),

  on(OnboardingActions.reinitialiseOnboardingState, state => ({
    ...state,
    onboarding: {
      ...state.onboarding,
      onboardingMandateId: null,
      appState: AppOnboardingState.None,
      startTime: null,
    },
  })),

  on(OnboardingActions.updateOnboardingStateSuccess, (state, {response}) => ({
    ...state,
    onboarding: response,
    isendOnboardingStateSuccess: true,
  })),

  on(setIsNextDisabled, (state, {isNextDisabled}) => ({
    ...state,
    isNextDisabled,
  })),

  on(setIsPreviousDisabled, (state, {isPreviousDisabled}) => ({
    ...state,
    isPreviousDisabled,
  })),

  on(setViewWrapperData, (state, {viewWrapperData}) => {
    return {
      ...state,
      viewWrapperData: {
        ...state.viewWrapperData,
        ...viewWrapperData,
      },
      isNextDisabled: false,
    };
  }),
);

export function reducer(state: OnboardingState, action: Action) {
  return onboardingReducer(state, action);
}
