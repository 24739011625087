import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable, of} from 'rxjs';
import {Action} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {ErrorActions} from '@app/state/error';
import {
  fetchConfiguration,
  fetchConfigurations,
  fetchConfigurationsSuccess,
  fetchConfigurationSuccess,
} from '@app/state/configuration/configuration.actions';
import {ConfigurationService} from '@app/services/configuration/configuration.service';

@Injectable()
export class ConfigurationEffects {
  fetchConfigurations$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchConfigurations),
      switchMap(() =>
        this.configurationService.fetchConfigurations().pipe(
          map(response => fetchConfigurationsSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  fetchConfiguration$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchConfiguration),
      switchMap(({request}) =>
        this.configurationService.fetchConfigurationByGroup(request).pipe(
          map(response => fetchConfigurationSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private configurationService: ConfigurationService) {}
}
