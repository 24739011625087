import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import * as fromReport from './report.reducer';
import * as fromReportState from './report.state';
import {EffectsModule} from '@ngrx/effects';
import {ReportEffects} from './report.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromReportState.featureKey, fromReport.reducer),
    EffectsModule.forFeature([ReportEffects]),
  ],
})
export class ReportStateModule {}
