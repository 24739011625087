import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { MaritalStatus } from '@app/shared/models/marital-status.model';
import * as MaritalStatusActions from './marital-status.actions';

export const featureKey = 'maritalStatus';
export interface MaritalStatusState extends EntityState<MaritalStatus> {
  loading: boolean;
}

export const adapter: EntityAdapter<MaritalStatus> = createEntityAdapter<
  MaritalStatus
>();

export const initialState: MaritalStatusState = adapter.getInitialState({
  loading: true,
});

const genderReducer = createReducer(
  initialState,
  on(MaritalStatusActions.loadMaritalStatuses, (state, action) => ({
    ...state,
    loading: true,
  })),
  on(MaritalStatusActions.loadMaritalStatusesSuccess, (state, action) =>
    adapter.addMany(action.maritalStatuses, { ...state, loading: false })
  ),
  on(MaritalStatusActions.loadMaritalStatusesFail, (state, action) => ({
    ...state,
    loading: false,
  })),
  on(MaritalStatusActions.clearMaritalStatuses, (state) =>
    adapter.removeAll(state)
  )
);

export function reducer(state: MaritalStatusState | undefined, action: Action) {
  return genderReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
