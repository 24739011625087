import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import * as fromStrategy from './strategy.reducer';
import * as fromStrategyState from './strategy.state';
import {EffectsModule} from '@ngrx/effects';
import {StrategyEffects} from './strategy.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromStrategyState.featureKey, fromStrategy.reducer),
    EffectsModule.forFeature([StrategyEffects]),
  ],
})
export class StrategyStateModule {}
