import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as configurationState from './configuration.state';
import {ConfigurationState} from '@app/state/configuration/configuration.state';

export const selectConfigurationState = createFeatureSelector<ConfigurationState>(
  configurationState.featureKey,
);

export const selectConfigurations = createSelector(
  selectConfigurationState,
  state => state.configurations,
);

export const selectConfiguration = createSelector(
  selectConfigurationState,
  state => state.configuration,
);
