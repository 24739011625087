import { TaxDetail } from '@app/shared/models/tax-detail.model';

export const featureKey = 'tax-detail';

export interface TaxDetailState {
  taxDetails: TaxDetail[];
  selectedTaxDetail: TaxDetail;
}

export const initialState: TaxDetailState = {
  taxDetails: [],
  selectedTaxDetail: null
};
