import { Action, createReducer, on } from '@ngrx/store';
import { initialState, UserState } from '@app/state/user/user.state';
import {
  updatePasswordResponse,
  fetchSecurityQuestionAnswersSuccess,
  fetchSecurityQuestionsSuccess,
  updateSecurityQuestionAnswersSuccess,
  fetchEmailVerificationCodeFailure,
  fetchEmailVerificationCodeSuccess,
  resendEmailVerificationCodeSuccess,
  resetEmailVerificationCodeSuccess
} from '@app/state/user/user.actions';

const userReducer = createReducer(
  initialState,

  on(fetchSecurityQuestionsSuccess, (state, { response }) => ({ ...state, securityQuestions: response })),

  on(fetchSecurityQuestionAnswersSuccess, (state, { response }) => ({
    ...state,
    securityQuestionAnswers: response,
  })),

  on(updateSecurityQuestionAnswersSuccess, (state, { response }) => ({
    ...state,
    updateSecurityQuestionAnswersSuccess: response,
  })),

  on(updatePasswordResponse, (state, { response }) => ({
    ...state,
    passwordChangeResponse: response,
  })),


  on(fetchEmailVerificationCodeSuccess, (state, { response }) => ({ ...state, emailCodeConfirmationResponse: response })),

  on(resendEmailVerificationCodeSuccess, (state, { response }) => ({ ...state, emailSent: response.emailSent? true : false })),

  on(resetEmailVerificationCodeSuccess, state => ({
    ...state,
    emailSent: false
  })),

);

export function reducer(state: UserState, action: Action) {
  return userReducer(state, action);
}
