import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { RelationshipType } from '@app/shared/models/relationship-type.model';
import { RelationshipStatusType } from '@app/shared/models/relationship-status-type.model';
import { Relationship } from '@app/shared/models/relationship.model';
import { RelationshipCreate } from '@app/shared/models/relationship-create.model';

@Injectable({
  providedIn: 'root'
})

export class RelationshipService {

  constructor(private http: HttpClient) {
  }

  getRelationshipTypes(): Observable<RelationshipType[]> {
    return this.http
      .get<RelationshipType[]>(`${environment.apiEndpoint}/relationships/references/types`);
  }

  getRelationship(relationshipId: number): Observable<Relationship> {
    return this.http
      .get<Relationship>(`${environment.apiEndpoint}/relationships/${relationshipId}`);
  }

  createRelationship(relationship: RelationshipCreate): Observable<Relationship> {
    return this.http
      .post<Relationship>(`${environment.apiEndpoint}/relationships`, relationship);
  }

  getRelationshipStatusTypes(): Observable<RelationshipStatusType[]> {
    return this.http
      .get<RelationshipStatusType[]>(`${environment.apiEndpoint}/relationships/references/statustypes`);
  }

  getRelationshipTypesByEntityType(entityTypeId: number): Observable<RelationshipType[]> {
    return this.http
      .get<RelationshipType[]>(`${environment.apiEndpoint}/relationships/references/types?entityTypeId=${entityTypeId}`);
  }
}
