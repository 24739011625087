import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EffectsModule} from '@ngrx/effects';
import {UserEffects} from './user.effects';
import {StoreModule} from '@ngrx/store';
import * as fromUserState from './user.state';
import * as fromUserReducer from './user.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromUserState.featureKey, fromUserReducer.reducer),
    EffectsModule.forFeature([UserEffects]),
  ],
})
export class UserStateModule {}
