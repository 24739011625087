import {
  ClosePortfolioRequest,
  ConnectedManagePortfolio,
  ConnectedWrapper,
  dataCharges,
  Portfolio,
  PortfoliosCharges,
} from '@app/shared/models/portfolio/portfolio.model';
import {Contribution, ContributionType, PaymentDay} from '@app/shared/models/portfolio/contribution.model';
import {PortfolioOverview} from '@app/shared/models/portfolio/overview.model';
import {InvestmentsOverview} from '@app/shared/models/portfolio/investments-overview.model';
import {Activity} from '@app/shared/models/portfolio/activity';
import {InvestableProportionOption, SourceOfFundsOption} from '@app/shared/models/portfolio/source-of-funds.model';
import {Payment} from '@app/shared/models/payment.model';
import { PaymentSupported } from '@app/shared/models/portfolio/paymentSupported.model';

export const featureKey = 'portfolio';

export interface PortfolioState {
  activities: Activity[];
  createContributionSuccess: boolean;
  deleteContributionSuccess: boolean;
  portfolios: Portfolio[];
  connectedPortfolios: ConnectedWrapper;
  connectedManagePortfolios: ConnectedManagePortfolio[];
  portfoliosCharges: PortfoliosCharges | null;
  portfolio: Portfolio | null;
  portfolioOverview: PortfolioOverview | null;
  selectedProductId: number | null;
  selectedPortfolioId: number | null;
  selectedStrategyId: number | null;
  portfolioClosure: ClosePortfolioRequest | null;
  directDebitContribution: Contribution | null;
  lumpSumContribution: Contribution | null;
  contributionTypes: ContributionType[];
  paymentDays: PaymentDay[];
  loading: boolean;
  investmentsOverview: InvestmentsOverview | null;
  sourceOfFundsOptions: SourceOfFundsOption[];
  investableProportionsOptions: InvestableProportionOption[];
  updateContributionSuccess: boolean;
  withdrawRequestSuccess: boolean;
  inviteRequestSuccess: boolean;
  isSuccess: boolean;
  stateChanged: boolean;
  inviteStateChanged: boolean;
  removeConnectedAccountSuccess: boolean;
  includeConnectedAccountsToggle: boolean;
  isAccepted: boolean;
  isDeclined: boolean;
  message: string;
  fetchPortfolioSuccess:boolean|null;
  paymentId: any;
  resourceToken: any;
  return_uri: string;
  addmoney: number;
  initialisePaymentRequestSuccess: boolean;
  portfolioId: number;
  paymentSupported: PaymentSupported | null;
  id: number;
  status: string;
  payment: Payment;
  amount:number;
  eventStatus: string;
  Id:string;
  ItemisedCharges: dataCharges[]
}

export const initialState: PortfolioState = {
  activities: [],
  createContributionSuccess: false,
  deleteContributionSuccess: false,
  portfolios: [],
  connectedPortfolios: null,
  connectedManagePortfolios: [],
  portfoliosCharges: null,
  portfolio: null,
  portfolioOverview: null,
  selectedProductId: null,
  selectedPortfolioId: null,
  selectedStrategyId: null,
  directDebitContribution: null,
  lumpSumContribution: null,
  contributionTypes: [],
  paymentDays: [],
  loading: false,
  investmentsOverview: null,
  sourceOfFundsOptions: [],
  investableProportionsOptions: [],
  updateContributionSuccess: false,
  withdrawRequestSuccess: false,
  inviteRequestSuccess: false,
  portfolioClosure: null,
  isSuccess: false,
  stateChanged: false,
  inviteStateChanged: false,
  removeConnectedAccountSuccess: false,
  isAccepted: false,
  includeConnectedAccountsToggle: false,
  isDeclined: false,
  message: null,
  fetchPortfolioSuccess:null,
  paymentId: null,
  resourceToken: null,
  return_uri: null,
  addmoney: null,
  initialisePaymentRequestSuccess : false,
  portfolioId: null,
  paymentSupported: null,
  id: null,
  status: null,
  payment: null,
  amount:null,
  eventStatus:null,
  Id:null,
  ItemisedCharges:[]
};
