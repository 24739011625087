import {Action, createReducer, on} from '@ngrx/store';
import {CountryState, initialState} from './country.state';
import {fetchCountries, fetchCountriesFail, fetchCountriesSuccess, fetchNationalities, fetchNationalitiesFail, fetchNationalitiesSuccess} from './country.actions';

const countriesReducer = createReducer(
  initialState,
  on(fetchCountries, state => ({...state, loading: true})),
  on(fetchCountriesFail, state => ({...state, loading: false})),
  on(fetchCountriesSuccess, (state, {payload}) => ({
    ...state,
    countries: payload,
    loading: false,
  })),

  on(fetchNationalities, state => ({...state, loading: true})),
  on(fetchNationalitiesFail, state => ({...state, loading: false})),
  on(fetchNationalitiesSuccess, (state, {payload}) => ({
    ...state,
    nationalities: payload,
    loading: false,
  })),
);

export function reducer(state: CountryState, action: Action) {
  return countriesReducer(state, action);
}
