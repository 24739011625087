import {Action, createReducer, on} from '@ngrx/store';
import {BankState, initialState} from './bank.state';
import * as BankActions from './bank.actions';

const bankDetailsReducer = createReducer(
  initialState,

  on(BankActions.fetchBankDetails, state => ({
    ...state,
    loqateBankValidationResult: null,
  })),

  on(BankActions.fetchBankDetailsSuccess, (state, {response}) => ({
    ...state,
    bankDetails: response,
  })),

  on(BankActions.createBankDetails, state => ({
    ...state,
  })),

  on(BankActions.createBankDetailsSuccess, (state, {response}) => ({
    ...state,
    bankDetails: response,
    createBankDetailsSuccess: response,
  })),

  on(BankActions.createBankDetailsFail, state => ({
    ...state,
  })),

  on(BankActions.updateBankDetails, state => ({
    ...state,
    updateBankDetailsSuccess: null,
  })),

  on(BankActions.updateBankDetailsSuccess, (state, {response}) => ({
    ...state,
    bankDetails: response,
    updateBankDetailsSuccess: response,
  })),
  on(BankActions.updateNonUkBankDetails, state => ({
    ...state,
    updateNonUkBankDetailsSuccess: null,
  })),

  on(BankActions.updateNonUkBankDetailsSuccess, (state, {response}) => ({
    ...state,
    bankDetails: response,
    updateNonUkBankDetailsSuccess: response,
  })),

  on(BankActions.validateBankDetails, state => ({
    ...state,
    isLoading: true,
  })),

  on(BankActions.validateBankDetailsSuccess, (state, {response}) => ({
    ...state,
    loqateBankValidationResult: response,
    isLoading: false,
  })),
);

export function reducer(state: BankState, action: Action) {
  return bankDetailsReducer(state, action);
}
