import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {catchError, exhaustMap, map, withLatestFrom} from 'rxjs/operators';

import {
  createBankDetails,
  createBankDetailsSuccess,
  createNonUkBankDetails,
  fetchBankDetails,
  fetchBankDetailsSuccess,
  updateBankDetails,
  updateBankDetailsSuccess,
  updateNonUkBankDetails,
  updateNonUkBankDetailsSuccess,
  validateBankDetails,
  validateBankDetailsSuccess,
} from './bank.actions';
import {BankDetailsService} from '@app/services/bank-service/bank.service';
import {BankAccountValidationResponse, BankDetailsCreateRequest} from '@app/shared/models/bank-details.model';
import {LoqateBankValidationService} from '@app/services/loqate/loqate-bank-details-validation.service';
import {ErrorActions} from '@app/state/error';
import {newError} from '@app/state/error/error.actions';
import {ProfileState} from '@app/state/profile/profile.state';
import {selectBankDetails, selectBankDetailsValidationResult} from '@app/state/bank/bank.selectors';
import {selectProfile} from '@app/state/profile/profile.selectors';
import {RelatedObjectType} from '@app/shared/enums/related-object-type.enum';
import Utils from '@app/shared/util/utils';
import {Patch} from '@app/shared/models/patch.model';
import {LoqateFailError} from '@app/shared/models/loqate.model';
import {mapLoqateFailErrorToWxlOnboardAPIError} from '@app/services/loqate/loqate-utils';

@Injectable()
export class BankEffects {
  validateBankDetails: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(validateBankDetails),
      exhaustMap(({request}) =>
        this.loqateService.validateBankAccount(request).pipe(
          map((response: BankAccountValidationResponse | LoqateFailError) => {
            if ('errorId' in response) {
              return newError({backEndError: mapLoqateFailErrorToWxlOnboardAPIError(response)}); // TODO(aled): Build proper error response for Loqate, so we can show the toast.
            } else if ('isValid' in response) {
              return validateBankDetailsSuccess({response});
            }
          }),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  fetchBankDetails: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchBankDetails),
      withLatestFrom(this.profileStore.select(selectProfile)),
      exhaustMap(([, profile]) =>
        this.bankDetailsService.fetchBankDetailsByIndividualId(profile.individualId).pipe(
          map(response => fetchBankDetailsSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  createBankDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(createBankDetails),
      withLatestFrom(this.bankStore.select(selectBankDetailsValidationResult), this.profileStore.select(selectProfile)),
      map(([{bankAccountName}, loqateBankDetails, profile]) => ({
        ...bankDetailsCreateReqFromValidationResp(loqateBankDetails),
        bankAccountName,
        relatedObjectId: profile.individualId,
      })),
      exhaustMap(request =>
        this.bankDetailsService.createBankDetails(request).pipe(
          map(response => createBankDetailsSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  createNonUkBankDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(createNonUkBankDetails),
      withLatestFrom(this.profileStore.select(selectProfile)),
      map(([{bankAccountName, iban, swiftBIC}, profile]) => ({
        bankAccountName,
        iban,
        swiftBIC,
        relatedObjectId: profile.individualId,
        relatedObjectTypeId: RelatedObjectType.Individual,
      })),
      exhaustMap(request =>
        this.bankDetailsService.createBankDetails(request).pipe(
          map(response => createBankDetailsSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  updateBankDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateBankDetails),
      withLatestFrom(
        this.bankStore.select(selectBankDetailsValidationResult),
        this.bankStore.select(selectBankDetails),
      ),
      map(([{bankAccountName}, loqateBankDetails, currentBankDetails]) => ({
        request: bankDetailsPatchReqFromValidationResp(loqateBankDetails, bankAccountName),
        bankDetailsId: currentBankDetails.id,
      })),
      exhaustMap(({request, bankDetailsId}) =>
        this.bankDetailsService.updateBankDetails(request, bankDetailsId).pipe(
          map(response => updateBankDetailsSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  updateNonUkBankDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateNonUkBankDetails),
      withLatestFrom(this.bankStore.select(selectBankDetails)),
      map(([{bankAccountName, iban, swiftBIC}, currentBankDetails]) => ({
        request: Utils.createPatchList({bankAccountName, iban, swiftBIC}),
        bankDetailsId: currentBankDetails.id,
      })),
      exhaustMap(({request, bankDetailsId}) =>
        this.bankDetailsService.updateBankDetails(request, bankDetailsId).pipe(
          map(response => updateNonUkBankDetailsSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private profileStore: Store<ProfileState>,
    private bankStore: Store<ProfileState>,
    private bankDetailsService: BankDetailsService,
    private loqateService: LoqateBankValidationService,
  ) {}
}

/** Used when creating the bank details directly from the BankAccountValidationResponse. */
function bankDetailsCreateReqFromValidationResp(loqateData: BankAccountValidationResponse): BankDetailsCreateRequest {
  return {
    bankAccountName: '',
    bankAccountNumber: loqateData.accountNumber,
    sortCode: loqateData.sortCode,
    bankAccountTypeId: 1,
    bankName: loqateData.bank,
    branch: loqateData.branch,
    description: null,
    swiftBIC: loqateData.bankBIC,
    iban: loqateData.iban,
    relatedObjectId: 0,
    relatedObjectTypeId: RelatedObjectType.Individual,
    address1: loqateData.addressLine1,
    address2: loqateData.addressLine2,
    town: loqateData.town,
    postZipCard: loqateData.postcode,
    countryId: null,
  };
}

function bankDetailsPatchReqFromValidationResp(
  loqateData: BankAccountValidationResponse,
  bankAccountName: string,
): Patch[] {
  const bankPatchData = {
    bankAccountName,
    bankAccountNumber: loqateData.accountNumber,
    sortCode: loqateData.sortCode,
    bankName: loqateData.bank,
    branch: loqateData.branch,
    swiftBIC: loqateData.bankBIC,
    iban: loqateData.iban,
    address1: loqateData.addressLine1,
    address2: loqateData.addressLine2,
    town: loqateData.town,
    postZipCard: loqateData.postcode,
  };
  return Utils.createPatchList(bankPatchData);
}
