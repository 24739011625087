<mat-form-field [fxFlex]="formFlexSize" *ngIf="isEdit">
  <mat-label>{{'Portfolio_name' | translate}}</mat-label>
  <input
    type="text"
    matInput
    [formControl]="ctrl"
    placeholder="Portfolio name"
    maxlength="254"
    minlength="1"
    autocomplete="off"
  >
  <button
    aria-label="save"
    *ngIf="!loading"
    matSuffix
    mat-icon-button
    matTooltip="Save"
    [disabled]="ctrl.invalid"
    (click)="save.emit(ctrl.value)"
  >
    <mat-icon>save</mat-icon>
  </button>
  <button
    aria-label="reset form"
    *ngIf="!loading"
    matSuffix
    mat-icon-button
    matTooltip="Cancel"
    (click)="isEdit = false; ctrl.reset(value)"
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-spinner *ngIf="loading" diameter="18" matSuffix></mat-spinner>
  <mat-error>
    <abl-form-validation-message [ctrlName]="placeholder" [formCtrl]="ctrl"></abl-form-validation-message>
  </mat-error>
</mat-form-field>
<div *ngIf="!isEdit" fxLayoutAlign=" center">
  <ng-content></ng-content>
  <button aria-label="edit" mat-icon-button matTooltip="Edit" (click)="isEdit = !isEdit">
    <mat-icon>edit</mat-icon>
  </button>
</div>
