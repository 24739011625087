import { RetirementProvisionResponse } from "@app/shared/models/individuals/individual-retirementprovision.model";

export const featureKey = 'retirement-provision';

export interface RetirementProvisionState {
    retirementProvision: RetirementProvisionResponse;
    updateSuccess:boolean;
}
export const initialState: RetirementProvisionState = {
    retirementProvision: null,
    updateSuccess: false,
};


