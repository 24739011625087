import {
  BankAccountValidationResponse,
  BankDetails,
  LoqateBankAccountValidationRequest,
} from '@app/shared/models/bank-details.model';
import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

/** Fetch bank details. */
export const fetchBankDetails = createAction('[Bank Details] Fetch Bank Details');

export const fetchBankDetailsSuccess = createAction(
  '[Bank Details] Fetch Bank Details Success',
  props<{response: BankDetails}>(),
);

export const fetchBankDetailsFail = createAction(
  '[Bank Details] Fetch Bank Details Fail',
  props<{error: HttpErrorResponse}>(),
);

/** Create bank details. */
export const createBankDetails = createAction('[Bank Details] Create Bank Details', props<{bankAccountName: string}>());

export const createBankDetailsSuccess = createAction(
  '[Bank Details] Create Bank Details Success',
  props<{response: BankDetails}>(),
);

export const createBankDetailsFail = createAction(
  '[Bank Details] Create Bank Details Fail',
  props<{error: HttpErrorResponse}>(),
);

/** Create non uk bank details. */
export const createNonUkBankDetails = createAction(
  '[Non uk Bank Details] Create Non UK Bank Details',
  props<{bankAccountName: string; iban: string; swiftBIC: string}>(),
);

export const createNonUkBankDetailsSuccess = createAction(
  '[Non uk Bank Details] Create Non UK Bank Details Success',
  props<{response: BankDetails}>(),
);

export const createNonUkBankDetailsFail = createAction(
  '[Non uk Bank Details] Create Non UK Bank Details Fail',
  props<{error: HttpErrorResponse}>(),
);

/** Update bank details. */
export const updateBankDetails = createAction('[Bank Details] Update Bank Details', props<{bankAccountName: string}>());

export const updateBankDetailsSuccess = createAction(
  '[Bank Details] Update Bank Details Success',
  props<{response: BankDetails}>(),
);

export const updateBankDetailsFail = createAction(
  '[Bank Details] Update Bank Details Fail',
  props<{error: HttpErrorResponse}>(),
);

/** update non uk bank details */

export const updateNonUkBankDetails = createAction(
  '[Non UK Bank Details] Update Non UK Bank Details',
  props<{bankAccountName: string; iban: string; swiftBIC: string}>(),
);

export const updateNonUkBankDetailsSuccess = createAction(
  '[Non UK Bank Details] Update Non UK Bank Details Success',
  props<{response: BankDetails}>(),
);

export const updateNonUkBankDetailsFail = createAction(
  '[Non UK Bank Details] Update Non UK Bank Details Fail',
  props<{error: HttpErrorResponse}>(),
);

/** Loqate validate bank details. */
export const validateBankDetails = createAction(
  '[Bank Details] Validate Bank Details',
  props<{request: LoqateBankAccountValidationRequest}>(),
);

export const validateBankDetailsSuccess = createAction(
  '[Bank Details] Validate Bank Details Success',
  props<{response: BankAccountValidationResponse}>(),
);

export const validateBankDetailsFail = createAction(
  '[Bank Details] Validate Bank Details Fail',
  props<{response: BankAccountValidationResponse}>(),
);

export const validateBankDetailsError = createAction(
  '[Bank Details] Validate Bank Details Error',
  props<{error: HttpErrorResponse}>(),
);
