import {Injectable} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {environment} from 'environments/environment';
import {AuthAction, AuthFlowType, AuthLaunchMode} from '@app/shared/enums/auth.enum';
import {JwksValidationHandler} from 'angular-oauth2-oidc-jwks';
import {interval, Observable, Subject} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {OAuthStorageService} from '@app/auth/services/oauth-storage.service';
import * as moment from 'moment';
import {switchMap, takeUntil, tap} from 'rxjs/operators';

const WEB_LAUNCH_URL = '/launch';
const MOBILE_LOGGED_OUT_URL = '/mobile-logged-out';
const ONE_SECOND = 1000;
const ONE_MINUTE = ONE_SECOND * 60;

/** Uses the component angular-oauth2-oidc from here: https://manfredsteyer.github.io/angular-oauth2-oidc/docs/index.html */
@Injectable({
  providedIn: 'root',
})
export class AuthOidcService {
  authFlowType: AuthFlowType|null = null;
  action: AuthAction|null = null;
  launchMode: AuthLaunchMode|null = null;
  private destroyRefreshTokenInterval$ = new Subject();

  /** Authentication navigation. */
  get hasValidToken(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  get authFailureUrl(): string {
    if (this.authFlowType === AuthFlowType.Code) {
      return WEB_LAUNCH_URL;
    } else if (this.authFlowType === AuthFlowType.Password) {
      return MOBILE_LOGGED_OUT_URL;
    }
  }

  get clientId(): string {
    return this.oauthStorageService.getItem('clientid') ?? this.oauthService.clientId;
  }

  get isMobileLaunchMode(): boolean {
    return this.launchMode === AuthLaunchMode.LaunchPasswordFlow;
  }

  get getRefreshToken(): string {
    return this.oauthService.getRefreshToken();
  }


  get flowString(): string {
    const expirationDifference = 0;
    const silentRefreshTimeout = ONE_MINUTE * 7;
    return `FlowType=${this.authFlowType}|LaunchType=${this.launchMode}|ActionType=${this.action}|ExpireDiff=${expirationDifference}|Calc=${silentRefreshTimeout}`;
  }
  

  constructor(
    private http: HttpClient,
    private oauthService: OAuthService,
    private route: ActivatedRoute,
    private oauthStorageService: OAuthStorageService,
  ) {
    this.initFlow();
  }

  login() {
    this.initFlow();
    if (this.launchMode === AuthLaunchMode.LaunchCodeFlow) {
      this.oauthService.initLoginFlow();
    }
  }

  setMode(action: AuthAction, launchMode: AuthLaunchMode|null, authMode: AuthFlowType|null) {
    this.action = action;
    this.launchMode = launchMode;
    this.authFlowType = authMode;
  }

  /** Desktop(code) login flow. */
  configureCodeFlow(): Promise<boolean> {
    this.authFlowType = AuthFlowType.Code;
    this.oauthService.configure(environment.codeFlowAuthConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.customQueryParams = {action: this.action};
    return this.oauthService.loadDiscoveryDocumentAndTryLogin();
  }

  /**
   * Mobile(password) login flow.
   * Set up custom interval that makes a call to fetch a new access token.
   */
  configureResourceOwnerPasswordFlow() {
    this.authFlowType = AuthFlowType.Password;
    this.setupAutomaticSilentRefreshForMobile().subscribe();
  }

  /**
   * When logging out from password flow, we pass true to the oAuth service
   * logout method which tells it to NOT redirect using the auth service.
   */
  logout(): boolean {
    this.oauthService.logOut(this.authFlowType === AuthFlowType.Password);
    // Returning true indicates we need to do a manual navigation to the launch page.
    this.stopRefreshTokenTimer();
    return this.authFlowType === AuthFlowType.Password;
  }

  /**
   * Used when navigating to a new route, validates the access token.
   * Only on mobile.
   */
  validateTokenForMobile(): Observable<{active: boolean}> {
    const body = new URLSearchParams();
    body.set('token', this.oauthStorageService.getItem('access_token'));
    const authorization = `Basic ${btoa('WXLAPI:secret')}`;
    const headers = new HttpHeaders()
      .set('accept', 'application/json')
      .set('content-type', 'application/x-www-form-urlencoded')
      .set('Authorization', authorization);
    return this.http.post<any>(`${environment.pwdFlowAuthConfig.issuer}/connect/introspect`, body, {headers});
  }

  refreshTokenForMobile(): Observable<any> {
    const body = new URLSearchParams();
    body.set('grant_type', 'refresh_token');
    body.set('scope', environment.pwdFlowAuthConfig.scope);
    body.set('refresh_token', this.oauthStorageService.getItem('refresh_token'));
    body.set('client_id', 'wxlapp-mobile');
    body.set('client_secret', 'secret');
    body.set('action', null);
    const headers = new HttpHeaders()
      .set('accept', 'application/json')
      .set('content-type', 'application/x-www-form-urlencoded');
    return this.http.post<any>(`${environment.pwdFlowAuthConfig.issuer}/connect/token`, body.toString(), {headers}).pipe(
      tap({
        next: (resp) => {
          console.log('HTTP request successful:', resp);
        },
        error: (err) => {
          console.error('HTTP request failed:', err);
        }
      })
    );
  }

  private initFlow() {
    /**
     * The launchMode is crucial for the app to decide weather to log in via Code Flow + PKCE(AuthLaunchMode.LaunchCodeFlow) for desktop browsers or
     * to login in via Password Flow (where a user enters their password into the client).
     * see https://manfredsteyer.github.io/angular-oauth2-oidc/docs/index.html
     */
    this.launchMode = Number(this.oauthStorageService.getItem('launchMode')) || AuthLaunchMode.LaunchCodeFlow;
    if (this.launchMode === AuthLaunchMode.LaunchPasswordFlow) {
      this.configureResourceOwnerPasswordFlow();
    } else {
      this.configureCodeFlow().then(() => this.oauthService.setupAutomaticSilentRefresh());
    }
  }

  // setupAutomaticSilentRefreshForMobile(): Observable<any> {
  //   // const expirationDifference = Number(this.oauthStorageService.getItem('expires_at')) - moment().valueOf();
  //   // const silentRefreshTimeout = expirationDifference <= 0 ? ONE_MINUTE * 2 : expirationDifference - (expirationDifference * 0.25); // Silent refresh 75% of the total expiration time.

  //   const silentRefreshTimeout = ONE_MINUTE ;

  //   this.oauthStorageService.setItem('refresh_timer_started', moment().toString());

  //   return interval(silentRefreshTimeout).pipe(
  //     switchMap(() =>
  //       this.refreshTokenForMobile().pipe(
  //         tap(resp => {
  //           this.oauthStorageService.setItem('refresh_token', resp.refresh_token);
  //           this.oauthStorageService.setItem('access_token', resp.access_token);
  //           this.oauthStorageService.setItem('expires_at', resp.expires_at);
  //           this.oauthStorageService.setItem('last_refreshed', moment().toString());
  //         }),
  //       ),
  //     ),
  //     takeUntil(this.destroyRefreshTokenInterval$),
  //   );
  // }

  setupAutomaticSilentRefreshForMobile(): Observable<any> {
    const silentRefreshTimeout = ONE_MINUTE * 7;
    this.oauthStorageService.setItem('refresh_timer_started', moment().toString());
    return interval(silentRefreshTimeout).pipe(
      switchMap(() =>{
        return this.refreshTokenForMobile().pipe(
          tap({
            next: (resp) => {
               this.oauthStorageService.setItem('refresh_token', resp.refresh_token);
               this.oauthStorageService.setItem('access_token', resp.access_token);
               this.oauthStorageService.setItem('expires_at', resp.expires_at);
              this.oauthStorageService.setItem('last_refreshed', moment().toString());
            },
            error: (err) => {
            }
          }),
        );
      }),
      takeUntil(this.destroyRefreshTokenInterval$),
    );
  }

  private stopRefreshTokenTimer() {
    this.destroyRefreshTokenInterval$.next();
    this.destroyRefreshTokenInterval$.complete();
    this.oauthStorageService.setItem('refresh_timer_stopped', moment().toString());
  }
}
