import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PhoneEffects } from './phone.effects';
import * as fromPhone from './phone.reducer';
import * as fromPhoneState from './phone.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromPhoneState.featureKey, fromPhone.reducer),
    EffectsModule.forFeature([PhoneEffects])
  ],
  declarations: []
})
export class PhoneStateModule {}
