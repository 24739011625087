import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, filter, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  createJourneyHistoryStep,
  createJourneyHistoryStepSuccess,
  fetchCurrentJourneyHistoryStep,
  fetchCurrentJourneyHistoryStepSuccess,
} from './journey-history.actions';
import {JourneyHistoryService} from '@app/services/journey-service/journey-history.service';
import {ErrorActions} from '@app/state/error';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {JourneyHistoryState} from '@app/state/journey-history/journey-history.state';
import {selectCurrentJourneyHistoryStep} from '@app/state/journey-history/journey-history.selectors';

@Injectable()
export class JourneyHistoryEffects {
  fetchCurrentJourneyHistoryStep$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchCurrentJourneyHistoryStep),
      withLatestFrom(this.journeyHistoryStore.select(selectCurrentJourneyHistoryStep)),
      filter(([, historyStep]) => !historyStep), // Only fetch the history step if there isn't already one.
      switchMap(([{mandateId}]) =>
        this.journeyHistoryService.getCurrentJourneyHistoryStep(mandateId).pipe(
          map(response => fetchCurrentJourneyHistoryStepSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    );
  });

  navigateToJourneyHistoryStepSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fetchCurrentJourneyHistoryStepSuccess),
        filter(step => !!step),
        tap(({response}) => this.router.navigateByUrl(response.url)),
      );
    },
    {dispatch: false},
  );

  createJourneyHistoryStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createJourneyHistoryStep),
      filter(() =>!this.router.url.includes('security-questions')),
      switchMap(({request}) =>
        this.journeyHistoryService.createJourneyHistoryStep(request).pipe(
          map(response => createJourneyHistoryStepSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private journeyHistoryStore: Store<JourneyHistoryState>,
    private journeyHistoryService: JourneyHistoryService,
    private router: Router,
  ) {}
}
