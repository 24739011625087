import {Component} from '@angular/core';
import {AuthCountdownService} from '@app/auth/services/auth-countdown.service';
import {DialogCloseEnum} from '@app/shared/enums/dialog.enum';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'abl-session-timeout-dialog',
  templateUrl: './session-timeout-dialog.component.html',
  styleUrls: ['./session-timeout-dialog.component.css']
})
export class SessionTimeoutDialogComponent {
  dialogCloseEnum = DialogCloseEnum;
  constructor(readonly countdownService: AuthCountdownService, private translate :TranslateService) {}
}
