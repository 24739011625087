import {createAction, props} from '@ngrx/store';
import {PhoneNumber, PhoneNumberCreateRequest} from '@app/shared/models/phone-details.model';
import {HttpErrorResponse} from '@angular/common/http';
import {DeletedItem} from '@app/shared/models/deleted-item.model';
import {DropDownItem} from '@app/shared/models/dropdownitem.model';

/** List phone numbers by individual ID . */
export const listPhoneNumbersByIndividualId = createAction(
  '[Phone Detail List] List Phone Numbers By Individual ID',
);

export const listPhoneNumbersByIndividualIdSuccess = createAction(
  '[Phone Detail List]  List Phone Numbers By Individual ID Success',
  props<{response: PhoneNumber[]}>(),
);

export const listPhoneNumbersByIndividualIdFail = createAction(
  '[Phone Detail List]  List Phone Numbers By Individual ID Fail',
  props<{error: HttpErrorResponse}>(),
);

/** List phone number types. */
export const listPhoneNumberTypes = createAction(
  '[Add Phone Detail] List Phone Number Types',
  props<{id?: number}>(),
);

export const listPhoneNumberTypesSuccess = createAction(
  '[Add Phone Detail] List Phone Number Types Success',
  props<{response: DropDownItem[]}>(),
);

export const listPhoneNumberTypesFail = createAction(
  '[Add Phone Detail] List Phone Number Types Fail',
  props<{error: HttpErrorResponse}>(),
);

/** Create phone number. */
export const createPhoneNumber = createAction(
  '[Add Phone Detail] Create Phone Number',
  props<{request: PhoneNumberCreateRequest}>(),
);

export const createPhoneNumberSuccess = createAction(
  '[Add Phone Detail] Create Phone Number Success',
  props<{response: PhoneNumber}>(),
);

export const createPhoneNumberFail = createAction(
  '[Add Phone Detail] Create Phone Number Fail',
  props<{error: HttpErrorResponse}>(),
);

/** Delete phone number. */
export const deletePhoneNumber = createAction(
  '[Delete Phone Detail] Delete Phone Number',
  props<{id: number}>(),
);

export const deletePhoneNumberSuccess = createAction(
  '[Phone Detail List] Delete Phone Number Success',
  props<{response: DeletedItem}>(),
);

export const deletePhoneNumberFail = createAction(
  '[Phone Detail List] Delete Phone Number Fail',
  props<{error: HttpErrorResponse}>(),
);
