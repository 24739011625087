import { Injectable } from '@angular/core';
import { AppState } from '../root-state';
import { Observable, of } from 'rxjs';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { switchMap, catchError, map, withLatestFrom, exhaustMap } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { ErrorActions } from '../error';
import { selectProfile } from '../profile/profile.selectors';
import { fetchRetirementProvisionDetails, fetchRetirementProvisionSuccess, saveRetirementProvisionDetails, saveRetirementProvisionSuccess, updateRetirementProvision, updateRetirementProvisionFailure, updateRetirementProvisionSuccess } from './retirement-provision.actions';
import { RetirementProvisionService } from '@app/services/retirement-provision/retirement-provision.service';


@Injectable()
export class RetirementProvisionEffects {

    saveRetirementProvision$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(saveRetirementProvisionDetails),
      switchMap(action => {
        return this.retirementProvisionService.createRetirementProvision(action.request).pipe(
          map(response => saveRetirementProvisionSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        );
      }),
    ),
  );
  fetchRetirementProvisionDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchRetirementProvisionDetails),
      withLatestFrom(this.store.select(selectProfile)),
      switchMap(([, profile]) =>
        this.retirementProvisionService.fetchRetirementProvision(profile.individualId).pipe(
          map(response => fetchRetirementProvisionSuccess({ response })),
          catchError(error => of(ErrorActions.newError({ backEndError: error }))),
        ),
      ),
    ),
  );
  updateRetirementProvision$: Observable<Action> = createEffect(() =>
  this.actions$.pipe(
    ofType(updateRetirementProvision),
    withLatestFrom(this.store.select(selectProfile)),
    exhaustMap(([{request}, profile]) =>
      this.retirementProvisionService.patchRetirementProvision(Number(profile.individualId), request).pipe(
        map(response => updateRetirementProvisionSuccess({response})),
        catchError(error => of(ErrorActions.newError({backEndError: error}), updateRetirementProvisionFailure({error}))),
      ),
    ),
  ),
);
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private retirementProvisionService: RetirementProvisionService,
  ) { }
}

