<div fxLayoutAlign="center">
  <h2 matDialogTitle>{{data.title}}</h2>
</div>
<mat-dialog-content>
  <p>{{data.text}}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    [attr.aria-label]="data.secondaryButton.text" *ngIf="data.secondaryButton" mat-dialog-close mat-stroked-button
  >
    {{data.secondaryButton.text}}
  </button>
  <button
    [attr.aria-label]="data.primaryButton.text"
    *ngIf="data.primaryButton"
    [mat-dialog-close]="data.primaryButton.close"
    mat-raised-button
    color="primary"
    cdkFocusInitial
  >
    {{data.primaryButton.text}}
  </button>
</mat-dialog-actions>
