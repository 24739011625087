import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {ConfigurationEffects} from './configuration.effects';
import * as configurationReducer from './configuration.reducer';
import * as fromConfigurationState from './configuration.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromConfigurationState.featureKey, configurationReducer.reducer),
    EffectsModule.forFeature([ConfigurationEffects]),
  ],
})
export class ConfigurationStateModule {
}
