import {AbstractControl, UntypedFormControl} from '@angular/forms';
import {RegExpEnum} from '@app/shared/enums/regexp.enum';
import * as moment from 'moment';

/**
 * Returns a message containing the control name and relevant validation error
 * message.
 */
export function validationMessageFormatter(
  ctrlName: string,
  ctrl: AbstractControl | UntypedFormControl,
  invalidMessage?: string,
  numericValueFormatFunc?: (value: number) => string,
): string {
  if (!ctrl || !ctrl.errors) {
    return '';
  }
  switch (!!ctrl.errors) {
    case ctrl.hasError('unicode'):
      return "Please enter a valid string with international characters, spaces, -, and '.";
    case ctrl.hasError('emailAddressError'):
      return invalidMessage;
    case ctrl.hasError('emailError'):
      return invalidMessage;
    case ctrl.hasError('required'):
      /** return `${capitalizeFirstLetter(ctrlName)} is required.`; */
      return `Required.`;

    /** Bank details. */
    case ctrl.hasError('accountNumberInvalid'):
      return `Invalid account number or sort code.`;
    case ctrl.hasError('sortCodeInvalid'):
      return `Sort code is invalid.`;

    /** Loqate. */
    case ctrl.hasError('loqateServiceError'):
      return `${ctrl.errors.loqateServiceError.description}`;

    /** Min max. */
    case ctrl.hasError('min'):
      return invalidMessage
        ? invalidMessage
        : `${capitalizeFirstLetter(ctrlName)} minimum value is ${
            numericValueFormatFunc ? numericValueFormatFunc(ctrl.errors.min?.min) : ctrl.errors.min?.min
          }`;
    case ctrl.hasError('max'):
      return invalidMessage
        ? invalidMessage
        : `${capitalizeFirstLetter(ctrlName)} maximum value is ${
            numericValueFormatFunc ? numericValueFormatFunc(ctrl.errors.max?.max) : ctrl.errors.max?.max
          }`;
    case ctrl.hasError('minlength'):
      return `${capitalizeFirstLetter(ctrlName)} must contain ${
        numericValueFormatFunc
          ? numericValueFormatFunc(ctrl.errors.minlength?.requiredLength)
          : ctrl.errors.minlength?.requiredLength
      } characters or more.`;
    case ctrl.hasError('maxlength'):
      return `${capitalizeFirstLetter(ctrlName)} must contain ${
        numericValueFormatFunc
          ? numericValueFormatFunc(ctrl.errors.maxlength?.requiredLength)
          : ctrl.errors.maxlength?.requiredLength
      } characters or less.`;

    //
    case ctrl.hasError('addressError'):
      return `Please provide at least one of the following: Flat number, Building number, Building name.`;

    /** ISA & JISA product minimumMonthlyContribution is less than product maximumYearlyContribution / monthsRemainingInTaxYear. */
    case ctrl.hasError('isaTopUpLimitInvalid'):
      return `Your monthly contribution will exceed the maximum subscription limit of ${numericValueFormatFunc(
        ctrl.errors.isaTopUpLimitInvalid,
      )} for this tax year so we are unable to process this.`;

    /** Invest More. */
    case ctrl.hasError('lowTargetAmount'):
      return `${capitalizeFirstLetter(ctrlName)} should be greater than initial investment.`;

    case ctrl.hasError('investMoreMax'):
      return `Your ${capitalizeFirstLetter(ctrlName)} will exceed the maximum subscription limit of ${
        numericValueFormatFunc
          ? numericValueFormatFunc(ctrl.errors.maximumLumpSumContribution)
          : ctrl.errors.maximumLumpSumContribution
      } for this tax year so we are unable to process this.`;

    /** Invest more value is higher than salary */
    case ctrl.hasError('investMoreIsHigherThanSalary'):
      return `Please note, your contribution figure of ${numericValueFormatFunc(
        ctrl.errors.investMoreIsHigherThanSalary,
      )} exceeds your annual salary of ${numericValueFormatFunc(
        ctrl.errors.salary,
      )} and you have selected excess salary as your source of funds. Please reconsider your contribution amount or select the correct funding source.`;

    /** Password. */
    case ctrl.hasError('invalidPassword'):
      return `${capitalizeFirstLetter(ctrlName)} does not meet the password requirements.`;

    case ctrl.hasError('invalidConfirmPassword'):
      return `${capitalizeFirstLetter(ctrlName)} does not match new password.`;

    /** Security questions. */
    case ctrl.hasError('duplicateQuestions'):
      return `${capitalizeFirstLetter(ctrlName)} ${ctrl.errors.duplicateQuestions}`;

    case ctrl.hasError('duplicateAnswers'):
      return `${ctrl.errors.duplicateAnswers}`;

    /** Pattern. */
    case ctrl.hasError('pattern'):
      return regExpPatternErrorMessage(ctrl.errors.pattern.requiredPattern, invalidMessage);

    /**
     * Custom date validators used with date Material date pickers that make use of min and max date input binding,
     * must be above the date picker messages in this list.
     */
    case ctrl.hasError('dateInvalid'):
      return `${capitalizeFirstLetter(ctrlName)} invalid date.`;
    case ctrl.hasError('dateIsInTheFuture'):
      return `${capitalizeFirstLetter(ctrlName)} cannot be in the future.`;
    case ctrl.hasError('dateIsInThePast'):
      return `${capitalizeFirstLetter(ctrlName)} cannot be more than ${
        ctrl.errors.dateIsInThePast.maxYears
      } years in the past.`;
    case ctrl.hasError('ageDateIsTooYoung'):
      return `${capitalizeFirstLetter(ctrlName)}, must be at least ${ctrl.errors.minAge} years or older.`;
    case ctrl.hasError('ageDateIsTooOld'):
      return `${capitalizeFirstLetter(ctrlName)}, must be younger than ${ctrl.errors.maxAge} years old.`;
    case ctrl.hasError('dateDebitPaymentIsInValid'):
      return `The first regular investment has to be at least ${ctrl.errors.offset} business days from today.`;
    case ctrl.hasError('datesAddUpToThreeYearsInvalid'):
      return 'you need more than 3 years worth of dates.';

    /** Datepicker. */
    /** Datepicker age. */
    case ctrl.hasError('matDatepickerMax') && ctrlName === 'date of birth':
      const minAge = ctrl.errors.matDatepickerMax.max.diff(moment(), 'years') * -1;
      return `Must be at least ${minAge} years or older.`;
    case ctrl.hasError('matDatepickerMin') && ctrlName === 'date of birth':
      const maxAge = ctrl.errors.matDatepickerMin.min.diff(moment(), 'years') * -1;
      return `Must be younger than ${maxAge} years old.`;
    /** Datepicker address from date. */
    case ctrl.hasError('matDatepickerMax') && ctrlName === 'from date':
      const maxDate = ctrl.errors.matDatepickerMax.max.format('DD/MM/YYYY');
      return `${capitalizeFirstLetter(ctrlName)}, cannot be after your current address from date, ${maxDate}.`;
    case ctrl.hasError('matDatepickerMin') && ctrlName === 'from date':
      const minDate = ctrl.errors.matDatepickerMin.min.format('DD/MM/YYYY');
      return `${capitalizeFirstLetter(ctrlName)}, cannot be before your date of birth, ${minDate}.`;

      case ctrl.hasError('matDatepickerMax') && ctrlName === 'Start month and year of tax residency if known':
        const minTax = ctrl.errors.matDatepickerMax.max.diff(moment(), 'years') * -1;
        return `${capitalizeFirstLetter(ctrlName)}, must be at least ${minTax} years or more.`;
      case ctrl.hasError('matDatepickerMin') && ctrlName === 'Start month and year of tax residency if known':
        const maxTax = ctrl.errors.matDatepickerMin.min.diff(moment(), 'years')* -1;
        return `${capitalizeFirstLetter(ctrlName)}, must be less than ${maxTax} years old.`;

    /** Datepicker default. */
    case ctrl.hasError('matDatepickerParse'):
      return `${capitalizeFirstLetter(ctrlName)} contains an invalid date.`;

      /** Datepicker mortgageRedemptionDate */
      case ctrl.hasError('matDatepickerMin') && ctrlName === 'mortgage redemption date':
        const min = ctrl.errors.matDatepickerMin.min.diff(moment(), 'years') * -1;
        return `${capitalizeFirstLetter(ctrlName)}, cannot be a previous date.`;

        /** Datepicker loanRedemptionDate */
      case ctrl.hasError('matDatepickerMin') && ctrlName === 'loan redemption date':
        const minloanRedemptionDate = ctrl.errors.matDatepickerMin.min.diff(moment(), 'years') * -1;
        return `${capitalizeFirstLetter(ctrlName)}, cannot be a previous date.`; 
        
        /** Datepicker mortgageRedemptionDate */
      case ctrl.hasError('matDatepickerMax') && ctrlName === 'mortgage redemption date':
        const max = ctrl.errors.matDatepickerMax.max.diff(moment(), 'years') * 40;
        return `${capitalizeFirstLetter(ctrlName)}, cannot be more than 40 years.`;

        /** Datepicker loanRedemptionDate */
      case ctrl.hasError('matDatepickerMax') && ctrlName === 'loan redemption date':
        const maxloanRedemptionDate = ctrl.errors.matDatepickerMax.max.diff(moment(), 'years') * 40;
        return `${capitalizeFirstLetter(ctrlName)}, cannot be more than 40 years.`;



    /** Autocomplete. */
    case ctrl.hasError('autocompleteInvalid'):
      return `'${ctrl.value}' is not a valid ${ctrlName}. Please select a country from the given list.`;

    /** Country. */
    case ctrl.hasError('invalidCountryId'):
      const allowedCountries = ctrl.errors.invalidCountryId
        .map(c => c.countryName)
        .reduce((prev, curr) => `${prev} and ${curr}`);
      return `You must be currently resident in ${allowedCountries} for this product.`;
    case ctrl.hasError('invalidNationalityId'):
      return `MWise services are only available to British nationals.`;
    default:
      return '';
  }
}

function capitalizeFirstLetter(str: string): string {
  return str[0].toUpperCase() + str.substring(1);
}

function regExpPatternErrorMessage(regExp: RegExpEnum, message?: string): string {
  switch (regExp) {
    case RegExpEnum.Name:
      return `Only letters, spaces, - and ' are allowed.`;
    case RegExpEnum.NameLineTerminators:
      return `Cannot begin or end with - and '.`;
    case RegExpEnum.PhoneNumber:
      return `Number must contain 7 characters or more.`;
    case RegExpEnum.BankSortCode:
      return 'Sort code must contain 6 numbers';
    case RegExpEnum.TaxNI:
      return message;
    case RegExpEnum.Letters:
      return `Only letters are allowed.`;
    case RegExpEnum.AlphaNumberOnly:
      return `Only letters and numbers are allowed.`;
    case RegExpEnum.Numbers:
      return `Only numbers are allowed.`;
    case RegExpEnum.AccountNumber:
      return `Account number should start with 'WO' followed by 2 letters and 4 digits. ex:'WOBY1111'`;
    case RegExpEnum.EmailCode:
      return `Only 6 digit numbers are allowed.`;
    case RegExpEnum.EmailPattern:
      return `Should be valid email address.`;
    case RegExpEnum.IBAN:
      return `Should be valid IBAN.`;
    case RegExpEnum.BIC:
      return `Should be valid BIC.`;
    case RegExpEnum.SecurityQuestionAnswer:
      return `The answer must not include special characters.`;
    default:
      return 'Does not match the required format.';
  }
}
