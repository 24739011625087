import {loginSuccess, logoutConfirmed} from '@app/state/auth/auth.actions';
import {Action, createReducer, on} from '@ngrx/store';
import {initialState, State} from './state';

const authReducer = createReducer(
  initialState,
  on(loginSuccess, state => ({...state, isLoggedIn: true})),
  on(logoutConfirmed, () => initialState), // the initial state has isLoggedIn set to false
);

export function reducer(state: State, action: Action) {
  return authReducer(state, action);
}
