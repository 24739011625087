import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromState from './country.state';
import {DropDownItem} from '@app/shared/models/dropdownitem.model';

export const getCountriesState = createFeatureSelector<fromState.CountryState>(fromState.featureKey);

export const selectCountries = createSelector(getCountriesState, state => state.countries);
export const selectNationalities = createSelector(getCountriesState, state => state.nationalities);

export const getCountriesLoading = createSelector(getCountriesState, state => state.loading);

export const selectCountriesDropDownValues = createSelector(selectCountries, countries =>
  countries.map(
    c =>
      <DropDownItem>{
        id: c.id,
        label: c.countryName,
        value: c.countryCode,
      },
  ),
);

export const selectNationalitiesDropDownValues = createSelector(selectNationalities, countries =>
  countries.map(
    c =>
      <DropDownItem>{
        id: c.id,
        label: c.nationality
      },
  ),
);

export const getNationalitiesDropDownValues = createSelector(selectCountries, countries =>
  countries
    .filter(c => !!c.nationality)
    .map(
      c =>
        <DropDownItem>{
          id: c.id,
          label: c.nationality,
          value: c.nationality,
        },
    ),
);

export const selectCountryDialingCodesDropDownValues = createSelector(selectCountries, countries =>
  countries
    .filter(c => !!c.dialCode)
    .map(
      c =>
        <DropDownItem>{
          id: c.id,
          label: `(${c.dialCode}) ${c.countryName}`,
          value: c.countryCode,
        },
    ),
);
