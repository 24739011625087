import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { Patch } from '@app/shared/models/patch.model';
import { SourceOfFunds, SourceOfFundsResponse, sourceOfFundsTransferTypesResponse } from '@app/shared/models/source-of-funds.model';

export const saveSourceOfFundsDetails = createAction(
  '[SourceOfFunds/API] Save Source-Of-Funds Details',
  props<{request: SourceOfFunds}>());

export const saveSourceOfFundsSuccess = createAction(
  '[SourceOfFunds/API] Save Source-Of-Funds Details Success',
  props<{response : SourceOfFundsResponse }>()
);
export const saveSourceOfFundsFail = createAction(
  '[SourceOfFunds/API] Save Source-Of-Funds Details Fail',
  props<{ error: any }>()
);
export const fetchSourceOfFundsDetails = createAction('[SourceOfFunds Details] Fetch Source-Of-Funds Details');

export const fetchSourceOfFundsSuccess = createAction(
  '[SourceOfFunds Details] Fetch Source-Of-Funds Success',
  props<{response: SourceOfFundsResponse}>(),
);

export const fetchSourceOfFundsFail = createAction(
  '[SourceOfFunds Details] Fetch Source-Of-Funds Fail',
  props<{error: HttpErrorResponse}>(),
);

export const fetchsourceOfFundsTransferTypes = createAction('[SourceOfFunds transfer types] Fetch Source-Of-Funds transfer types');

export const fetchsourceOfFundsTransferTypesSuccess = createAction(
  '[SourceOfFunds transfer types] Fetch Source-Of-Funds transfer types Success',
  props<{response: sourceOfFundsTransferTypesResponse}>(),
);

export const fetchsourceOfFundsTransferTypesFail = createAction(
  '[SourceOfFunds transfer types Details] Fetch Source-Of-Funds transfer types Fail',
  props<{error: HttpErrorResponse}>(),
);


/** Update SourceOfFunds. */
export const updateSourceOfFunds = createAction('[SourceOfFunds Details] Update Source-Of-Funds', props<{request: Patch[]}>());

export const updateSourceOfFundsSuccess = createAction(
  '[SourceOfFunds Details] Update Source-Of-Funds Success',
  props<{response: SourceOfFundsResponse}>(),
);

export const updateSourceOfFundsFailure = createAction(
  '[SourceOfFunds Details] Update Source-Of-Funds Failure',
  props<{error: HttpErrorResponse}>(),
);
