import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRelationshipState from './relationship.state';
import { DropDownItem } from '@app/shared/models/dropdownitem.model';


export const getRelationshipState = createFeatureSelector<fromRelationshipState.RelationshipState>(fromRelationshipState.featureKey);

export const getRelationshipTypes = createSelector(
  getRelationshipState,state =>
  state.relationshipTypes
);

export const getRelationshipTypesLoading = createSelector(
  getRelationshipState,
  (state) => state.relationshipTypesLoading
);

export const getRelationshipTypesDropDownValues = createSelector(
  getRelationshipTypes,
  state => {
    return state.map(item => {
      return <DropDownItem>{
        id: item.id,
        label: item.relationshipTypeName,
        value: item.type
      };
    });
  }
);
