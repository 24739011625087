import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromUserState from './user.state';
import {DropDownItem} from '@app/shared/models/dropdownitem.model';

export const selectUserState = createFeatureSelector<fromUserState.UserState>(fromUserState.featureKey);

export const selectSecurityQuestions = createSelector(selectUserState, state => state.securityQuestions);

export const selectUpdateSecurityQuestionAnswersSuccess = createSelector(
  selectUserState,
  state => state.updateSecurityQuestionAnswersSuccess,
);

export const selectSecurityQuestionAnswers = createSelector(selectUserState, state => state.securityQuestionAnswers);

export const selectSecurityQuestionsDropdownItems = createSelector(selectSecurityQuestions, questions =>
  questions.map(
    question =>
      <DropDownItem>{
        id: question.securityQuestionId,
        label: question.question,
        value: question.questionType,
      },
  ),
);

/** Select password change successful. */
export const selectPasswordChangeResponse = createSelector(selectUserState, state => state.passwordChangeResponse);

export const selectEmailCodeConfirmationResponse = createSelector(selectUserState, state => state.emailCodeConfirmationResponse);

export const selectResendEmailVerificationCodeResponse = createSelector(selectUserState, state => state.emailSent? true : false);
