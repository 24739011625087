import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {OrganisationService} from '@app/services/organisation/organisation.service';
import {Observable, of} from 'rxjs';
import {Action} from '@ngrx/store';
import * as OrganisationActions from '@app/state/organisation/organisation.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {ErrorActions} from '@app/state/error';

@Injectable()
export class OrganisationEffects {
  fetchOrganisations$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationActions.fetchOrganisations),
      switchMap(() =>
        this.organisationService.fetchOrganisations().pipe(
          map(response => OrganisationActions.fetchOrganisationsSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private organisationService: OrganisationService) {}
}
