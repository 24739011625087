import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromPortfolioState from '@app/state/portfolio/portfolio.state';

export const selectPortfolioState = createFeatureSelector<fromPortfolioState.PortfolioState>(
  fromPortfolioState.featureKey,
);

/** Select portfolios. */
export const selectPortfolios = createSelector(selectPortfolioState, state => state.portfolios);

/** Select connected portfolios */
export const selectConnectedPortfolios = createSelector(selectPortfolioState, state => state.connectedPortfolios);

/** Select connected portfolios */
export const selectConnectedManagePortfolios = createSelector(
  selectPortfolioState,
  state => state.connectedManagePortfolios,
);

/** Select connected portfolios invite successful. */
export const selectInviteSuccessful = createSelector(selectPortfolioState, state => ({
  inviteRequestSuccess: state.inviteRequestSuccess,
  message: state.message,
  inviteStateChanged: state.inviteStateChanged,
}));

/** Select invite confirm code successful. */
export const selectInviteConfirmSuccessful = createSelector(selectPortfolioState, state => ({
  isSuccess: state.isSuccess,
  stateChanged: state.stateChanged,
}));

/** Select remove connected account successful. */
export const selectRemoveSuccessful = createSelector(
  selectPortfolioState,
  state => state.removeConnectedAccountSuccess,
);

export const fetchPortfoliosSuccessful = createSelector(
  selectPortfolioState,
  state => state.fetchPortfolioSuccess,
);

/** Select include connected accounts toggle. */
export const selectIncludeConnectedAccountsToggle = createSelector(
  selectPortfolioState,
  state => state.includeConnectedAccountsToggle,
);

/** Select accept successful. */
export const selectAcceptSuccessful = createSelector(selectPortfolioState, state => state.isAccepted);

/** Select decline successful. */
export const selectDeclineSuccessful = createSelector(selectPortfolioState, state => state.isDeclined);

/** Select portfolios charges */
export const selectPortfoliosCharges = createSelector(selectPortfolioState, state => state.portfoliosCharges);

/** Selected portfolio. */
export const selectPortfolio = createSelector(selectPortfolioState, state => state.portfolio);

export const selectPortfolioClosure = createSelector(selectPortfolioState, state => state.portfolioClosure);

/** Selected portfolio product. */
export const selectPortfolioProduct = createSelector(selectPortfolioState, state => state.portfolio.product);

/** Selected portfolio overview. */
export const selectPortfolioOverview = createSelector(selectPortfolioState, state => state.portfolioOverview);

/** Selected product ID. */
export const selectProductId = createSelector(selectPortfolioState, state => state.selectedProductId);

/** Selected strategy ID. */
export const selectStrategyId = createSelector(selectPortfolioState, state => state.selectedStrategyId);

/** Select selected portfolio ID. */
export const selectSelectedPortfolioId = createSelector(selectPortfolioState, state => state.selectedPortfolioId);

/** Select portfolio loading. */
export const selectPortfolioLoading = createSelector(selectPortfolioState, state => state.loading);

/** Select lump sum contribution. */
export const selectLumpSumContribution = createSelector(selectPortfolioState, state => state.lumpSumContribution);

/** Select direct debit contribution. */
export const selectDirectDebitContribution = createSelector(
  selectPortfolioState,
  state => state.directDebitContribution,
);

export const selectContributionTypes = createSelector(selectPortfolioState, state => state.contributionTypes);

/** Select payment days. */
export const selectPaymentDays = createSelector(selectPortfolioState, state => state.paymentDays);

/** Select investments overview. */
export const selectInvestmentsOverview = createSelector(selectPortfolioState, state => state.investmentsOverview);

/** Select activities. */
export const selectPortfolioActivities = createSelector(selectPortfolioState, state => state.activities);

/** Select source of funds. */
export const selectSourceOfFundsOptions = createSelector(selectPortfolioState, state => state.sourceOfFundsOptions);

/** Select investable proportions. */
export const selectInvestableProportionsOptions = createSelector(
  selectPortfolioState,
  state => state.investableProportionsOptions,
);

/** Select has active portfolios. */
export const selectHasActivePortfolios = createSelector(selectPortfolioState, state =>
  state.portfolios.some(portfolio => portfolio.isActive),
);

/** Select withdraw successful. */
export const selectWithdrawSuccessful = createSelector(selectPortfolioState, state => state.withdrawRequestSuccess);

/** Select create contribution success. */
export const selectCreateContributionSuccess = createSelector(
  selectPortfolioState,
  state => state.createContributionSuccess,
);

/** Select update contribution success. */
export const selectUpdateContributionSuccess = createSelector(
  selectPortfolioState,
  state => state.updateContributionSuccess,
);

/** Select delete contribution success. */
export const selectDeleteContributionSuccess = createSelector(
  selectPortfolioState,
  state => state.deleteContributionSuccess,
);
/** trulayer api integration */
export const PaymentResponse = createSelector(selectPortfolioState, state => ({
  paymentId: state.paymentId,
  resourceToken: state.resourceToken,
  return_uri: state.return_uri,
  initialisePaymentRequestSuccess: state.initialisePaymentRequestSuccess,
}))

export const selectPaymentAddMoney = createSelector(selectPortfolioState, state =>  state.addmoney);

export const selectPaymentIsSupported = createSelector(selectPortfolioState, state =>  state.portfolioId);

export const selectPaymentIsSupportedSuccess = createSelector(selectPortfolioState, state => ({
  paymentSupported: state.paymentSupported
}))

export const fetchPaymentSupportedSuccess = createSelector(selectPortfolioState, state => ({
  paymentSupported: state.paymentSupported,
  message: state.message
}))




export const fetchPaymentSuccessResponse = createSelector(selectPortfolioState, state => ({
  isSuccess: state.isSuccess,
  message: state.message
}))

export const fetchPaymentFailedResponse = createSelector(selectPortfolioState, state => ({
  isSuccess: state.isSuccess,
  message: state.message
}))

export const fetchPaymentSuccess = createSelector(selectPortfolioState, state => ({
  id: state.Id,
  portfolioId: state.portfolioId,
  amount: state.amount,
  eventStatus: state.eventStatus
}))

export const fetchItemisedChargesResponse = createSelector(selectPortfolioState, state => state.ItemisedCharges)


