<div fxLayoutAlign="center">
    <h2 matDialogTitle>{{data.title}}</h2>
  </div>
  <mat-dialog-content>
    <p>Principal Value: {{data.text}}</p>
    <p>Access token: {{data.text1}}</p>
    <p>expires at: {{data.text2}}</p>
    <p>id_token: {{data.text3}}</p>
    <p>Session clientid: {{data.text4}}</p>
    <p>Store clientId: {{data.text5}}</p>
    <p>Auth clientId: {{data.text6}}</p>
    <p>Refresh Token: {{data.text7}}</p>
    <p>Refresh Token Storage: {{data.text8}}</p>
    <p>Flow: {{data.text9}}</p>
    <p>Last Refreshed: {{data.text10}}</p>
    <p>Timer Started: {{data.text11}}</p>
    <p>Timer Stopped: {{data.text12}}</p>
    
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      [attr.aria-label]="data.secondaryButton.text" *ngIf="data.secondaryButton" mat-dialog-close mat-stroked-button
    >
      {{data.secondaryButton.text}}
    </button>
    <button
      [attr.aria-label]="data.primaryButton.text"
      *ngIf="data.primaryButton"
      [mat-dialog-close]="data.primaryButton.close"
      mat-raised-button
      color="primary"
      cdkFocusInitial
    >
      {{data.primaryButton.text}}
    </button>
  </mat-dialog-actions>
  