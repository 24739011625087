import {Component, Input} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {validationMessageFormatter} from '@app/shared/validators/validation-message';
import {LiveAnnouncer} from '@angular/cdk/a11y';

@Component({
  selector: 'abl-form-validation-message',
  templateUrl: './form-validation-message.component.html',
  styleUrls: ['./form-validation-message.component.css'],
})
export class FormValidationMessageComponent {
  @Input() ctrlName = '';
  @Input() formCtrl: AbstractControl|null = null;
  @Input() invalidMessage?: string|null = null;
  @Input() numericValueFormatFunc?: (value: number) => string;

  constructor(private liveAnnouncer: LiveAnnouncer) {
  }

  message(): string {
    const message = validationMessageFormatter(this.ctrlName, this.formCtrl, this.invalidMessage, this.numericValueFormatFunc);
    if (message) {
      this.liveAnnouncer.announce(message);
    }
    return message;
  }
}
