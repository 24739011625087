import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ErrorComponent} from './error.component';
import {MaterialModule} from '@app/shared/material';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {OverlayModule} from '@angular/cdk/overlay';
import {ErrorListOverlayComponent} from './error-list-overlay/error-list-overlay.component';
import {ErrorListItemComponent} from './error-list-item/error-list-item.component';
import {TranslateModule} from '@ngx-translate/core';



@NgModule({
  declarations: [ErrorComponent, ErrorListOverlayComponent, ErrorListItemComponent],
  exports: [ErrorComponent],
  imports: [CommonModule, MaterialModule, FlexModule, OverlayModule, FlexLayoutModule,TranslateModule],
})
export class ErrorModule {}
