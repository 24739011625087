import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromInviteState from './state';
import { first, map } from 'rxjs/operators';
import { Invite } from '@app/shared/models/invite.model';

export const selectInviteState = createFeatureSelector<fromInviteState.State>(
  fromInviteState.featureKey
);

export const selectInvite = createSelector(
  selectInviteState,
  state => {
    return <Invite>{
      data: state.invite,
      isLoading: state.loading,
      lastLoaded: state.lastLoaded
    };
  }
);
