export enum MandateStatusEnum {
  InProgress = 'In Progress',
  Pending = 'Pending Approval',
  Complete = 'Complete',
  Validating = 'Validating',
}

export enum MandateStatusIdEnum {
  InProgress = 1,
  Pending,
  Complete,
  Validating,
}

export enum MandateCategoryEnum {
  All = 'All',
  InProgress = 'In Progress',
  Pending = 'Pending',
  Complete = 'Complete',
  Failed = 'Failed',
}

export enum MandateCategoryIdEnum {
  InProgress = 1,
  Pending,
  Complete,
  Failed,
  All,
}

export enum MandateTypeEnum {
  Individual = 1,
  JointIndividual,
  Entity,
}
