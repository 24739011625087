import * as fromRouter from '@ngrx/router-store';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {Params} from '@angular/router';
import * as fromMandateState from '@app/state/mandate/mandate.state';
import * as fromDashboardState from '@app/state/dashboard/dashboard.state';

/**
 * Place feature selectors here, so we don't have circular dependencies between either state.
 * E.g. Select mandate state and select dashboard state have dependencies between them.
 */
export const selectMandateState = createFeatureSelector<fromMandateState.MandateState>(fromMandateState.featureKey);

export const selectDashboardState = createFeatureSelector<fromDashboardState.DashboardState>(
  fromDashboardState.featureKey,
);

export interface RouterState {
  router: fromRouter.RouterReducerState<any>;
}

export const selectRouter = createFeatureSelector<fromRouter.RouterReducerState<any>>('router');

export const selectRouteNestedParams = createSelector(selectRouter, router => {
  let currentRoute = router?.state?.root;
  let params: Params = {};
  while (currentRoute?.firstChild) {
    currentRoute = currentRoute.firstChild;
    params = {
      ...params,
      ...currentRoute.params,
    };
  }
  return params;
});

export const selectRouteNestedParam = (param: string) =>
  createSelector(selectRouteNestedParams, params => params && params[param]);

export const {selectUrl} = fromRouter.getRouterSelectors(selectRouter);
