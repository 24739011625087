import {Address, AddressResponse, LoqateAddressId, V2Address} from '@app/shared/models/address.model';
import {LoqateAddressFindItem, RawLoqateAddressRetrieveResponse} from '@app/shared/models/loqate.model';

export const featureKey = 'address';

export interface AddressState {
  addresses: Address[];
  address: RawLoqateAddressRetrieveResponse | null;
  searchedAddress: LoqateAddressFindItem[] | null;
  // V2 state.
  v2AddressSearchResults: Map<LoqateAddressId, LoqateAddressFindItem> | null;
  v2Address: V2Address;
  v2ResidentialAddresses: AddressResponse[];
  v2AdditionalAddresses: AddressResponse[];
  addAddressSuccess: boolean;
  principalAddress: AddressResponse;
}

export const initialState: AddressState = {
  addresses: [],
  address: null,
  searchedAddress: null,
  // V2 state.
  v2AddressSearchResults: null,
  v2Address: null,
  v2ResidentialAddresses: [],
  v2AdditionalAddresses: [],
  addAddressSuccess: false,
  principalAddress: null,
};
