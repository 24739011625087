import {Action, createReducer, on} from '@ngrx/store';
import {initialState} from './attitude-to-risk.state';
import * as AttitudeToRiskActions from '@app/state/attitude-to-risk/attitude-to-risk.actions';
import {AttitudeToRiskState} from '@app/state/attitude-to-risk/attitude-to-risk.state';

export const attitudeToRiskReducer = createReducer(
  initialState,

  on(AttitudeToRiskActions.fetchAttitudeToRiskQuestionsSuccess, (state, {response}) => ({
    ...state,
    questions: response.riskQuestionnaire,
    numberOfAttempts: response.numberOfAttempts,
    fetchScoreUpdateSuccess: false,
  })),

  on(AttitudeToRiskActions.resetAttitudeToRiskQuestionsSuccess, (state, {response}) => ({
    ...state,
    questions: response.riskQuestionnaire,
    numberOfAttempts: response.numberOfAttempts,
    fetchScoreUpdateSuccess: false,
    currentQuestion: 1,
    confirmScoreUpdateSuccess: false,
  })),

  on(AttitudeToRiskActions.fetchRiskquestionnaireScoreResponse,(state, {response}) => ({
    ...state,
    isChangeRequired: response.isChangeRequired,
    lastUpdatedDate: response.lastUpdatedDate,
    lastChangedMonth: response.lastChangedMonth,
    isChangeMandatory: response.isChangeMandatory,
    directDebitClearDownDate: response.directDebitClearDownDate,
  })),

  on(AttitudeToRiskActions.setCurrentAttitudeToRiskQuestionIndex, (state, {index}) => ({
    ...state,

    currentQuestion: index,
  })),

  on(AttitudeToRiskActions.setAttitudeToRiskQuestionAnswerSuccess, (state, {response}) => {
    const updatedQuestions = state.questions.map(question => (question.id === response.id ? response : question));

    return {
      ...state,
      questions: updatedQuestions,
    };
  }),

  on(AttitudeToRiskActions.fetchAttitudeToRiskScoreSuccess, (state, {response}) => ({
    ...state,
    score: response,
    numberOfAttempts: response.numberOfAttempts,
    fetchScoreUpdateSuccess: true,
  })),

  on(AttitudeToRiskActions.fetchCurrentAttitudeToRiskScoreSuccess, (state, {response}) => ({
    ...state,
    score: response,
    numberOfAttempts: response.numberOfAttempts,
    fetchScoreUpdateSuccess: false,
    confirmScoreUpdateSuccess: false,
    confirmedCurrentRisk: response.riskTargets.find(target => target.isRecommended),
  })),

  on(AttitudeToRiskActions.setAttitudeToRiskScoreSuccess, state => ({
    ...state,
    confirmedCurrentRisk: state.score.riskTargets.find(target => target.isRecommended),
    confirmScoreUpdateSuccess: true,
  })),

  on(AttitudeToRiskActions.setFetchRiskScoreSuccess, (state, {fetchScoreSuccess}) => ({
    ...state,
    fetchScoreUpdateSuccess: fetchScoreSuccess,
  })),
);

export function reducer(state: AttitudeToRiskState, action: Action) {
  return attitudeToRiskReducer(state, action);
}
