import { SourceOfFundsResponse, sourceOfFundsTransferTypesResponse } from "@app/shared/models/source-of-funds.model";

export const featureKey = 'source-of-funds';

export interface SourceOfFundsState {
    sourceOfFunds: SourceOfFundsResponse;
    updateSuccess:boolean;
    sourceOfFundsTransferTypes:sourceOfFundsTransferTypesResponse;
}
export const initialState: SourceOfFundsState = {
    sourceOfFunds: null,
    updateSuccess: false,
    sourceOfFundsTransferTypes:null,
};



