import {createAction, props} from '@ngrx/store';
import {PatchDocument} from '@app/shared/models/patch-document.model';
import {Mandate} from '@app/shared/models/mandate.model';
import {HttpErrorResponse} from '@angular/common/http';

/** Fetch mandates. */
export const fetchMandates = createAction('[Mandate] Fetch Mandates');

export const fetchMandatesSuccess = createAction('[Mandate] Fetch Mandates Success', props<{response: Mandate[]}>());

export const fetchMandatesFailure = createAction(
  '[Mandate] Fetch Mandates Failure',
  props<{error: HttpErrorResponse}>(),
);

/** Fetch mandate. */
export const fetchMandate = createAction('[Mandate] Fetch Mandate', props<{id: number}>());

export const fetchMandateSuccess = createAction('[Mandate] Fetch Mandate Success', props<{response: Mandate}>());

export const fetchMandateFail = createAction('[Mandate] Fetch Mandate Fail', props<{error: HttpErrorResponse}>());

/** Create mandate. */
export const createMandate = createAction('[Mandate] Create Mandate');

export const createMandateSuccess = createAction('[Mandate] Create Mandate Success', props<{response: Mandate}>());

/** Update mandate. */
export const updateMandate = createAction('[Mandate] Update Mandate', props<{id: number; request: PatchDocument}>());

export const updateMandateSuccess = createAction('[Mandate] Update Mandate Success', props<{response: Mandate}>());

export const updateMandateFailure = createAction('[Mandate] Update Mandate Fail', props<{error: HttpErrorResponse}>());

/** finalise mandate. */
export const finaliseMandate = createAction('[Mandate] Finalise Mandate');

export const finaliseMandateSuccess = createAction('[Mandate] Finalise Mandate Success', props<{response: Mandate}>());

export const finaliseMandateFailure = createAction('[Mandate] Finalise Mandate Fail', props<{error: HttpErrorResponse}>());

/** Delete mandate. */
export const deleteMandate = createAction('[Mandate] Delete Mandate', props<{id: number}>());

export const deleteMandateSuccess = createAction('[Mandate] Delete Mandate Success', props<{id: number}>());
