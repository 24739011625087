import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { TaxDetail, TaxDetailCreateRequest } from '@app/shared/models/tax-detail.model';
import { DeletedItem } from '@app/shared/models/deleted-item.model';

@Injectable({
  providedIn: 'root'
})

export class TaxDetailService {

  constructor(private http: HttpClient) {}

  fetchTaxDetailsByIndividualId(id: number): Observable<TaxDetail[]> {
    return this.http.get<TaxDetail[]>(`${environment.apiEndpoint}/taxresidencies/individuals/${id}`);
  }

  createTaxDetail(request: TaxDetailCreateRequest): Observable<TaxDetail> {
    return this.http.post<TaxDetail>(`${environment.apiEndpoint}/taxresidencies`, request);
  }

  removeTaxDetail(id: number): Observable<DeletedItem> {
    return this.http.delete<DeletedItem>(`${environment.apiEndpoint}/taxresidencies/${id}`);
  }

}
