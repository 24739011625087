import {Action, createReducer, on} from '@ngrx/store';
import {AddressState, initialState} from './address.state';
import * as AddressActions from './address.actions';
import {AddressTypeIdEnum} from '@app/shared/enums/addresses.enum';

const addressReducer = createReducer(
  initialState,

  on(AddressActions.fetchAddressesSuccess, (state, {addresses}) => ({
    ...state,
    v2ResidentialAddresses: addresses.filter(a => a.addressType.id === AddressTypeIdEnum.Residential),
    v2AdditionalAddresses: addresses.filter(a => a.addressType.id === AddressTypeIdEnum.Postal),
  })),

  on(AddressActions.fetchPrincipalAddressSuccess, (state, {principalAddress}) => ({
    ...state,
    principalAddress: principalAddress,
  })),

  on(AddressActions.addAddress, state => ({
    ...state,
    addAddressSuccess: false,
  })),

  on(AddressActions.updateAddress, state => ({
    ...state,
    addAddressSuccess: false,
  })),

  on(AddressActions.addAddressSuccess, (state, {response}) => {
    if (response.addressType.id === AddressTypeIdEnum.Residential) {
      return {...state, v2ResidentialAddresses: state.v2ResidentialAddresses.concat(response), addAddressSuccess: true};
    } else if (response.addressType.id === AddressTypeIdEnum.Postal) {
      return {...state, v2AdditionalAddresses: state.v2AdditionalAddresses.concat(response), addAddressSuccess: true};
    }
    return {...state};
  }),

  on(AddressActions.removeAddressSuccess, (state, {response}) => ({
    ...state,
    addresses: state.addresses.filter(item => response.id !== item.id),
    v2ResidentialAddresses: state.v2ResidentialAddresses.filter(item => response.id !== item.id),
    v2AdditionalAddresses: state.v2AdditionalAddresses.filter(item => response.id !== item.id),
  })),

  on(AddressActions.retrieveLoqateAddressSuccess, (state, {response}) => ({
    ...state,
    v2Address: response,
    v2AddressSearchResults: null,
  })),

  on(AddressActions.findLoqateAddressSuccess, (state, {response}) => ({
    ...state,
    v2AddressSearchResults: new Map(response.map(a => [a.id, a])),
  })),

  on(AddressActions.updateAddressState, (state, newState) => ({
    ...state,
    ...newState,
  })),
);

export function reducer(state: AddressState, action: Action) {
  return addressReducer(state, action);
}
