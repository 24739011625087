import {createAction, props} from '@ngrx/store';
import {JourneyConfig} from '@app/shared/models/journey-config.model';
import {JourneyStepEnum} from '@app/shared/enums/journey-step.enum';

/** Fetch journey config for new mandate. */
export const fetchJourneyConfigForNewMandate = createAction('[Journey Config] Fetch Journey Config For New Mandate');

export const fetchJourneyConfigForNewMandateSuccess = createAction(
  '[Journey Config] Fetch Journey Config For New Mandate Success',
  props<{response: JourneyConfig}>(),
);

/** Fetch journey config for current mandate. */
export const fetchJourneyConfigForCurrentMandate = createAction('[Journey Config] Fetch Journey Config For Current Mandate');

export const fetchJourneyConfigForCurrentMandateSuccess = createAction(
  '[Journey Config] Fetch Journey Config For Current Mandate Success',
  props<{response: JourneyConfig}>(),
);

/** Current journey config. */
export const setCurrentJourneyConfig = createAction(
  '[Journey Config] Set Current Journey Config',
  props<{
    journeyConfig: JourneyConfig;
    currentStep: JourneyStepEnum;
  }>(),
);

export const updateJourneyConfig = createAction('[Journey Config] Update Journey Config', props<{journeyConfig: JourneyConfig}>());

export const setPrevJourneyStep = createAction('[Journey Config] Set Previous Journey Step');

/** Clear configs. */
export const clearJourneyConfigs = createAction('[Journey Config] Clear all journey config state');

/** Set instance ID, used by journey history. */
export const setJourneyInstanceId = createAction('[Journey Config] Set Journey Instance Id', props<{journeyInstanceId: number}>());
