import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AuthState} from '@app/state/auth';
import {login} from '@app/state/auth/auth.actions';

@Component({
  selector: 'launch-component',
  template:
    '<div fxFlexFill fxLayoutAlign="center center"><abl-custom-loading-spinner></abl-custom-loading-spinner></div>',
})
export class LaunchOptionsComponent implements OnInit {
  constructor(private authStore: Store<AuthState.State>) {}

  ngOnInit() {
    this.authStore.dispatch(login());
  }
}
