import {Action, createReducer, on} from '@ngrx/store';
import {clearProfiles, fetchProfile, fetchProfileFail, fetchProfileSuccess, updateProfileSuccess} from './profile.actions';
import {initialState, ProfileState} from '@app/state/profile/profile.state';

const profileReducer = createReducer(
  initialState,

  on(fetchProfile, state => ({
    ...state,
    loading: true,
  })),

  on(fetchProfileSuccess, (state, {profile}) => ({
    ...state,
    profile: profile,
    loading: false,
  })),
  on(updateProfileSuccess, (state, {response}) => ({
    ...state,
    profile: response,
    updateSuccess: true,
  })),

  on(fetchProfileFail, (state, {error}) => ({
    ...state,
    error,
    loading: false,
  })),

  on(clearProfiles, state => ({
    ...state,
    profile: null,
  })),
);

export function reducer(state: ProfileState, action: Action) {
  return profileReducer(state, action);
}
