import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EidvEffects } from './eidv.effects';
import * as fromEidv from './eidv.reducer';
import * as fromEidvState from './eidv.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromEidvState.featureKey, fromEidv.reducer),
    EffectsModule.forFeature([EidvEffects])
  ],
  declarations: []
})
export class EidvStateModule {
}
