import {createAction, props} from '@ngrx/store';
import {DashboardPageFilter, NavItem} from '@app/shared/models/dashboard.model';
import {AccountsPagesEnum} from '@app/shared/enums/dashboard-pages.enum';

export const setPageTitle = createAction('[Dashboard] Set Page Title', props<{pageTitle: string}>());

export const setPageData = createAction(
  '[Dashboard] Set Page Data',
  props<{pageTitle: string; subNavItems: NavItem[]}>(),
);

export const filterSubNavItems = createAction('[Dashboard] Filter Sub Nav Items', props<{path?: AccountsPagesEnum}>());

export const setActiveSubNavItem = createAction('[Dashboard] Set Active Sub Nav Item', props<{item: NavItem}>());

export const setPageFilter = createAction('[Dashboard] Set Page Filter', props<{filter: DashboardPageFilter}>());
