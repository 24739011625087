import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as individualState from './individual.state';
import {IndividualState} from './individual.state';
import {DropDownItem} from '@app/shared/models/dropdownitem.model';

export const selectIndividualState = createFeatureSelector<IndividualState>(individualState.featureKey);

export const selectIndividual = createSelector(selectIndividualState, state => state.individual);

export const selectRelationshipTypes = createSelector(selectIndividualState, state =>
  state.relationshipTypes.map(
    item =>
      <DropDownItem>{
        id: item.id,
        label: item.value,
        value: item.value,
      },
  ),
);

export const selectTitles = createSelector(selectIndividualState, state =>
  state.titles.map(
    item =>
      <DropDownItem>{
        id: item.id,
        label: item.value,
        value: item.value,
      },
  ),
);

export const selectSalary = createSelector(selectIndividualState, state => state.individual.employmentStatus.salary);

export const selectEmployment = createSelector(
  selectIndividualState,
  state => state.individual.employmentStatus.employmentStatusTypeName,
);

export const selectIndividualUpdateSuccess = createSelector(selectIndividualState, state => state.updateSuccess);

export const selectIndividualSpouseRelation = createSelector(selectIndividualState, state => state.spouseRelation);

export const selectUpdateSpouseRelationSuccess = createSelector(
  selectIndividualState,
  state => state.updateSpouseSuccess,
);

export const selectIndividualPowerOfAttorneySuccess = createSelector(
  selectIndividualState,
  state => state.createPowerOfAttorneySuccess,
);
export const selectIndividualInterestedPartiesSuccess = createSelector(
  selectIndividualState,
  state => state.createInterestedPartiesSuccess,
);
export const selectIndividualPowerOfAttorney = createSelector(
  selectIndividualState,
  state => state.powerOfAttorneyGetResponse,
);
export const selectIndividualInterestedParties = createSelector(
  selectIndividualState,
  state => state.InterestedPartiesGetResponse,
);

export const selectDeleteInterestedPartySuccess = createSelector(selectIndividualState, state => state.isDeleted);

export const selectInstructionsSuccess = createSelector(
  selectIndividualState,
  state => state.createAccountInstructionsSuccess,
);
export const selectAccountInstructions = createSelector(selectIndividualState, state => state.AccountInstructions);

export const selectIsLoadingAccountInstructions = createSelector(
  selectIndividualState,
  state => state.isLoadingAccountInstructions,
);

export const selectTaxableInstructions = createSelector(selectIndividualState, state => state.taxableInstructions);
export const selectPaymentFrequencyTypes = createSelector(selectIndividualState, state => state.paymentFrequencyTypes);
export const selectIncomeTypes = createSelector(selectIndividualState, state => state.incomeTypes);

export const selectIncomeDetailsSuccess = createSelector(selectIndividualState, state => state.incomeDetailsSuccess);
export const selectIncomeDetails = createSelector(selectIndividualState, state => state.incomeDetails);
export const selectRemoveIncomeDetailsSuccess = createSelector(
  selectIndividualState,
  state => state.removeIncomeDetailsSuccess,
);

export const selectInvestmentIncomeSuccess = createSelector(
  selectIndividualState,
  state => state.investmentIncomeSuccess,
);
export const selectAnnualSpendingSuccess = createSelector(selectIndividualState, state => state.annualSpendingSuccess);
export const selectUpdateInvestmentIncomeSuccess = createSelector(
  selectIndividualState,
  state => state.updateInvestmentIncomeSuccess,
);
export const selectUpdateAnnualSpendingSuccess = createSelector(
  selectIndividualState,
  state => state.updateAnnualSpendingSuccess,
);

export const selectInvestmentIncome = createSelector(selectIndividualState, state => state.investmentIncome);
export const selectAnnualSpending = createSelector(selectIndividualState, state => state.annualSpending);
export const selectIndividualSpouseRelationSuccess = createSelector(
  selectIndividualState,
  state => state.saveSpouseSuccess,
);

export const selectCreateAssetsSuccess = createSelector(selectIndividualState, state => state.createAssetsSuccess);
export const selectAssets = createSelector(selectIndividualState, state => state.assets);
export const selectRemoveAssetsSuccess = createSelector(selectIndividualState, state => state.removeAssetsSuccess);

export const selectTaxdetailsSuccess = createSelector(selectIndividualState, state => state.taxdetails);

export const selectCreateTaxdetailsSuccess = createSelector(
  selectIndividualState,
  state => state.createTaxdetailsSuccess,
);

export const selectUpdateTaxdetailsSuccess = createSelector(
  selectIndividualState,
  state => state.updateTaxdetailsSuccess,
);

export const selectCreateIsaaccountdetailsSuccess = createSelector(
  selectIndividualState,
  state => state.createIsaaccountdetailsSuccess,
);

export const selectIsaaccountdetails = createSelector(selectIndividualState, state => state.isadetails);

export const selectRemoveIsaaccountSuccess = createSelector(
  selectIndividualState,
  state => state.removeIsaaccountSuccess,
);

export const selectMonthendTypes = createSelector(selectIndividualState, state => state.monthendTypes);
export const selectQuarterendTypes = createSelector(selectIndividualState, state => state.quarterendTypes);

export const selectIndividualSummary = createSelector(selectIndividualState, state => state.individualSummary);

export const selectIndividualOverview = createSelector(selectIndividualState, state => state.individualOverview);


export const selectCompleteIndividualDmaSuccess = createSelector(selectIndividualState, state => state.completeIndividualDmaSuccess);
