import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable, of} from 'rxjs';
import {Action, Store} from '@ngrx/store';
import {catchError, exhaustMap, map, withLatestFrom} from 'rxjs/operators';
import {
  createChild,
  createChildFailure,
  createChildSuccess,
  fetchChildByMandateId,
  fetchChildByMandateIdFailure,
  fetchChildByMandateIdSuccess,
  updateChild,
  updateChildFailure,
  updateChildSuccess,
} from '@app/state/individual-child/individual-child.actions';
import {ErrorActions} from '@app/state/error';
import {IndividualChildService} from '@app/services/individuals/individual-child.service';
import {selectIndividual} from '@app/state/individual/individual.selectors';
import {IndividualState} from '@app/state/individual/individual.state';
import {OnboardingState} from '@app/state';
import {selectOnboardingMandateId} from '@app/state/onboarding/onboarding.selectors';
import {IndividualChildState} from '@app/state/individual-child/individual-child.state';
import {selectChildForCurrentMandate} from '@app/state/individual-child/individual-child.selectors';

@Injectable()
export class IndividualChildEffects {
  fetchIndividualChildByMandateId$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchChildByMandateId),
      withLatestFrom(
        this.individualStore.select(selectIndividual),
        this.onboardingStore.select(selectOnboardingMandateId),
      ),
      exhaustMap(([, individual, mandateId]) =>
        this.individualChildService.fetchIndividualChildByMandateId(individual.id, mandateId).pipe(
          map(response => fetchChildByMandateIdSuccess({response})),
          catchError(error => of(ErrorActions.newError({backEndError: error}), fetchChildByMandateIdFailure({error}))),
        ),
      ),
    ),
  );

  createIndividualChild$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(createChild),
      withLatestFrom(
        this.individualStore.select(selectIndividual),
        this.onboardingStore.select(selectOnboardingMandateId),
      ),
      exhaustMap(([{request}, individual, mandateId]) =>
        this.individualChildService.createIndividualChild(individual.id, mandateId, request).pipe(
          map(child => createChildSuccess({response: child})),
          catchError(error => of(ErrorActions.newError({backEndError: error}), createChildFailure({error}))),
        ),
      ),
    ),
  );

  updateIndividualChild$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateChild),
      withLatestFrom(
        this.individualStore.select(selectIndividual),
        this.individualChildStore.select(selectChildForCurrentMandate),
      ),
      exhaustMap(([{request}, individual, childForCurrentMandate]) =>
        this.individualChildService.patchIndividualChild(individual.id, childForCurrentMandate.id, request).pipe(
          map(child => updateChildSuccess({response: child})),
          catchError(error => of(ErrorActions.newError({backEndError: error}), updateChildFailure({error}))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private individualChildService: IndividualChildService,
    private individualChildStore: Store<IndividualChildState>,
    private individualStore: Store<IndividualState>,
    private onboardingStore: Store<OnboardingState.OnboardingState>,
  ) {}
}
