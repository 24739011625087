import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromEmailsState from './email.state';

export const getEmailsState = createFeatureSelector<fromEmailsState.EmailState>(fromEmailsState.featureKey);

export const selectEmailTypes = createSelector(getEmailsState, state => state.emailTypes);

export const getIndividualsEmail = createSelector(getEmailsState, state => state.emailAddresses);
export const getUserEmails = createSelector(getEmailsState, state => state.userEmailAddresses);

export const selectCurrentEmailId = createSelector(getEmailsState, state => state.currentEmailAddressId);

export const selectAddEmailSuccess = createSelector(getEmailsState, state => ({
  success: state.addEmailAddressSuccess,
  message: state.message,
  isStateChanged: state.isStateChanged
}));

export const selectChangeEmailSuccess = createSelector(getEmailsState, state => ({
  success: state.changeEmailSuccess,
  changeEmailMessage: state.changeEmailMessage,
  stateChanged: state.stateChanged,
}));

export const selectAdditionalEmailAddressSuccess = createSelector(getEmailsState, state => state.addEmailAddressSuccess);

export const selectEmailAddressErrorMessage = createSelector(getEmailsState, state => state.emailErrorMessage);
