import {Action, createReducer, on} from '@ngrx/store';
import { initialState, LiabilitiesState} from './liabilities.state';
import { deleteLiabilities, deleteLiabilitiesFailure, deleteLiabilitiesSuccess, fetchLiabilitiesSuccess, resetDeleteLiabilitiesSuccess, saveLiabilitiesSuccess} from './liabilities.actions';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Liabilities } from '@app/shared/models/liabilities.model';

export const adapter: EntityAdapter<Liabilities> = createEntityAdapter<Liabilities>();
const liabilitiesReducer = createReducer(
  initialState,

  on(saveLiabilitiesSuccess, (state, {response}) => ({
    ...state,
    updateSuccess:true,
    liabilities: response
  })),

  on(fetchLiabilitiesSuccess, (state, {response}) => ({
    ...state,
    liabilities: response
  })),

  on(deleteLiabilities, state => ({
    ...state,
    isDeleted: false,
  })),

  on(deleteLiabilitiesSuccess, (state) => ({
    ...state,
    isDeleted: true,
  })),

  on(deleteLiabilitiesFailure, state => ({
    ...state,
    isDeleted: false,
  })),

  on(resetDeleteLiabilitiesSuccess, state => ({
    ...state,
    isDeleted: false,
  })),
);

export function reducer(state: LiabilitiesState, action: Action) {
  return liabilitiesReducer(state, action);
}
