import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { Patch } from '@app/shared/models/patch.model';
import { RetirementProvision, RetirementProvisionResponse } from '@app/shared/models/individuals/individual-retirementprovision.model';

export const saveRetirementProvisionDetails = createAction(
  '[RetirementProvision/API] Save Retirement-Provision Details',
  props<{request: RetirementProvision}>());

export const saveRetirementProvisionSuccess = createAction(
  '[RetirementProvision/API] Save Retirement-Provision Details Success',
  props<{response : RetirementProvisionResponse }>()
);
export const saveRetirementProvisionFail = createAction(
  '[RetirementProvision/API] Save Retirement-Provision Details Fail',
  props<{ error: any }>()
);
export const fetchRetirementProvisionDetails = createAction('[RetirementProvision Details] Fetch Retirement-Provision Details');

export const fetchRetirementProvisionSuccess = createAction(
  '[RetirementProvision Details] Fetch Retirement-Provision Success',
  props<{response: RetirementProvisionResponse}>(),
);

export const fetchRetirementProvisionFail = createAction(
  '[RetirementProvision Details] Fetch Retirement-Provision Fail',
  props<{error: HttpErrorResponse}>(),
);

/** Update RetirementProvision. */
export const updateRetirementProvision = createAction('[RetirementProvision Details] Update RetirementProvision', props<{request: Patch[]}>());

export const updateRetirementProvisionSuccess = createAction(
  '[RetirementProvision Details] Update RetirementProvision Success',
  props<{response: RetirementProvisionResponse}>(),
);

export const updateRetirementProvisionFailure = createAction(
  '[RetirementProvision Details] Update RetirementProvision Failure',
  props<{error: HttpErrorResponse}>(),
);
