import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { OnboardingEffects } from './onboarding.effects';
import * as fromOnboarding from './onboarding.reducer';
import * as fromOnboardingState from './onboarding.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromOnboardingState.featureKey, fromOnboarding.reducer),
    EffectsModule.forFeature([OnboardingEffects])
  ],
  declarations: []
})
export class OnboardingStateModule {}
