import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { InviteEffects } from './invite.effects';
import * as fromInvite from './invite.reducer';
import * as fromInviteState from './state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromInviteState.featureKey, fromInvite.reducer),
    EffectsModule.forFeature([InviteEffects])
  ],
  declarations: []
})
export class InviteStateModule {}
