import {Onboarding, ViewWrapperData} from '@app/shared/models/onboarding-state.model';
import {AppOnboardingState} from '@app/shared/enums/app-onboarding-state.enum';
import {DeepLink} from '@app/shared/models/deeplink-model';

export const featureKey = 'onboarding';

export interface OnboardingState {
  onboarding: Onboarding;
  deepLink: DeepLink;
  isNextDisabled: boolean;
  isPreviousDisabled: boolean;
  viewWrapperData: ViewWrapperData;
  isendOnboardingStateSuccess: boolean;
}

export const initialState: OnboardingState = {
  onboarding: {
    onboardingMandateId: null,
    appState: AppOnboardingState.None,
    startTime: null,
  },
  deepLink: null,
  isNextDisabled: false,
  isPreviousDisabled: false,
  isendOnboardingStateSuccess: false,
  viewWrapperData: {
    heading: null,
    description: null,
    nextLabel: null,
    backLabel: null,
    isShowExit: true,
    isGDPRPage: false,
    isEmailVerification:  false,
    isSecurityQuestions: false,
  },
};
