import { APInviteData } from '@app/shared/models/invite.model';
export interface State {
  lastLoaded: string | null;
  invite: APInviteData | null;
  loading: boolean;
}

export const featureKey = 'invite';

export const initialState: State = {
  lastLoaded: null,
  invite: null,
  loading: true
};
