import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DocumentEffects } from './document.effects';
import * as documentReducer from './document.reducer';
import * as fromDocumentState from './document.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromDocumentState.featureKey, documentReducer.reducer),
    EffectsModule.forFeature([DocumentEffects])
  ],
  declarations: []
})
export class DocumentStateModule {}
