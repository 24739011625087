import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AlertEffects } from './alert.effects';
import * as fromAlert from './alert.reducer';
import * as fromAlertState from './alert.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromAlertState.alertFeatureKey, fromAlert.reducer),
    EffectsModule.forFeature([AlertEffects])
  ],
  declarations: []
})
export class AlertStateModule {}
