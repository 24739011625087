import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { EidvLatest } from '@app/shared/models/eidv.model';

@Injectable({
  providedIn: 'root'
})

export class EidvService {

  constructor(
    private http: HttpClient
  ) { }

  getEidvLatest(): Observable<EidvLatest> {
    return this.http.get<EidvLatest>(`${environment.apiEndpoint}/eidv/latest`);
  }

}
