import {Injectable} from '@angular/core';
import {Subject, timer} from 'rxjs';
import {takeWhile, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  isLoading$ = new Subject<boolean>();
  private serviceCallsCount = 0;
  private timer$ = timer(2000, 1000).pipe(
    takeWhile(() => this.serviceCallsCount > 0),
    tap(() => this.isLoading$.next(true)),
  );

  requestIntercepted() {
    if (this.serviceCallsCount <= 0) {
      this.timer$.subscribe();
    }
    this.serviceCallsCount++;
  }

  requestFinalized() {
    this.serviceCallsCount--;
    if (this.serviceCallsCount <= 0) {
      this.isLoading$.next(false);
    }
  }
}
