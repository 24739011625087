import {Action, createReducer, on} from '@ngrx/store';
import {initialState} from '@app/state/individual-child/individual-child.state';
import {
  createChildSuccess,
  fetchChildByMandateId,
  fetchChildByMandateIdFailure,
  fetchChildByMandateIdSuccess,
  resetIndividualChildState,
  updateChildSuccess,
} from '@app/state/individual-child/individual-child.actions';

const IndividualChildReducer = createReducer(
  initialState,

  on(fetchChildByMandateId, state => ({
    ...state,
    loading: true,
  })),

  on(fetchChildByMandateIdSuccess, (state, {response}) => ({
    ...state,
    childForCurrentMandate: response,
    loading: false,
  })),

  on(fetchChildByMandateIdFailure, state => ({
    ...state,
    loading: false,
  })),

  on(createChildSuccess, (state, {response}) => ({
    ...state,
    childForCurrentMandate: response,
  })),

  on(updateChildSuccess, (state, {response}) => ({
    ...state,
    childForCurrentMandate: response,
  })),

  on(resetIndividualChildState, state => ({
    ...state,
    childForCurrentMandate: null,
    loading: false,
  })),
);

export function reducer(state, action: Action) {
  return IndividualChildReducer(state, action);
}
