import {AddressState} from './address';
import {AlertState} from '@app/state/alert';
import {AttitudeToRiskState} from '@app/state/attitude-to-risk';
import {AuthState} from '@app/state/auth';
import {BankState} from './bank';
import {ConfigurationState} from '@app/state/configuration';
import {CountriesState} from './countries';
import {DashboardState} from '@app/state/dashboard';
import {DocumentState} from '@app/state/document';
import {EmailState} from './email';
import {IndividualChildState} from '@app/state/individual-child/individual-child.state';
import {IndividualState} from '@app/state/individual';
import {MandateState} from '@app/state/mandate';
import {MessageState} from './message';
import {OrganisationState} from '@app/state/organisation';
import {PhoneState} from './phone';
import {PortfolioState} from '@app/state/portfolio';
import {ProductState} from '@app/state/product';
import {ProfileState} from '@app/state/profile';
import {RelationshipState} from './relationships';
import {ReportState} from '@app/state/report';
import {StrategyState} from '@app/state/strategy';
import {UserState} from '@app/state/user';

// All state interfaces to be exposed as properties here
export interface AppState {
  address: AddressState.AddressState;
  alerts: AlertState.AlertState;
  attitudeToRisk: AttitudeToRiskState.AttitudeToRiskState;
  auth: AuthState.State;
  bank: BankState.BankState;
  configuration: ConfigurationState.ConfigurationState;
  countries: CountriesState.CountryState;
  dashboard: DashboardState.DashboardState;
  documents: DocumentState.DocumentState;
  email: EmailState.EmailState;
  individual: IndividualState.IndividualState;
  individualChild: IndividualChildState;
  mandate: MandateState.MandateState;
  message: MessageState.MessageState;
  organisation: OrganisationState.OrganisationState;
  phone: PhoneState.PhoneState;
  portfolio: PortfolioState.PortfolioState;
  product: ProductState.ProductState;
  profile: ProfileState.ProfileState;
  relationship: RelationshipState.RelationshipState;
  report: ReportState.ReportState;
  strategy: StrategyState.StrategyState;
  user: UserState.UserState;
}
