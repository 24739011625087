import {createFeatureSelector, createSelector} from '@ngrx/store';
import {DropDownItem} from '@app/shared/models/dropdownitem.model';
import * as fromIsaTransferState from '@app/state/isa-transfer/isa-transfer.state';
import {selectPortfolio} from '@app/state/portfolio/portfolio.selectors';
import {CombinedIsaTransferData} from '@app/shared/models/portfolio/isa.model';

export const selectIsaTransferState = createFeatureSelector<fromIsaTransferState.IsaTransferState>(
  fromIsaTransferState.featureKey,
);

/** Select ISA transfers. */
export const selectIsaTransfers = createSelector(selectIsaTransferState, state => state.isaTransfers);

/** Select ISA providers. */
export const selectIsaProviders = createSelector(selectIsaTransferState, state => state.isaProviders);

export const selectIsaProvidersDropDownValues = createSelector(selectIsaProviders, isaProviders =>
  isaProviders.map(
    p =>
      <DropDownItem>{
        id: p.id,
        label: p.registeredName,
        value: p.reference,
      },
  ),
);

/** Select ISA types. */
export const selectIsaTypes = createSelector(selectIsaTransferState, state => state.isaTypes);

export const selectIsaTypesDropDownValues = createSelector(selectIsaTypes, isaTypes =>
  isaTypes.map(
    t =>
      <DropDownItem>{
        id: t.id,
        label: t.isaTypeName,
        value: t.type,
      },
  ),
);

/** Select ISA transfer types. */
export const selectIsaTransferTypes = createSelector(selectIsaTransferState, state => state.isaTransferTypes);

export const selectIsaTransferTypesDropDownValues = createSelector(selectIsaTransferTypes, isaTransferTypes =>
  isaTransferTypes.map(
    t =>
      <DropDownItem>{
        id: t.id,
        label: t.isaTransferTypeName,
        value: t.type,
      },
  ),
);

/** Select combined ISA transfer value. */
export const selectCombinedIsaTransferValue = createSelector(
  selectIsaTransferState,
  selectPortfolio,
  (isaTransferState, portfolio) =>
    <CombinedIsaTransferData>{
      combinedIsaTransferValue: isaTransferState.combinedIsaTransferValue,
      totalIsaTransferValue: portfolio?.transferValue ?? null,
      currencyCode: portfolio?.currency.code ?? '',
    },
);
